exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1VVl0{width:100%;height:100%;color:#333;font-size:12px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;padding-bottom:48px}._1VVl0 ._2LhTy{color:var(--text-dark);font-weight:bold;font-size:12px;height:48px;text-transform:uppercase}._1VVl0 ._1-cBq{height:100%;overflow:auto}._1VVl0 ._1K7-5{display:-ms-flexbox;display:flex;border-bottom:1px solid #e6e6e6}._1VVl0 ._1K7-5:nth-child(even){background-color:rgba(0,0,0,0.02)}._1VVl0 ._3jDNd{height:48px;padding:0px 8px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._1VVl0 ._3jDNd._39G3u{margin-left:auto}._1VVl0 ._3jDNd._39G3u>*+*{margin-left:8px}\n", ""]);

// Exports
exports.locals = {
	"root": "_1VVl0",
	"header": "_2LhTy",
	"body": "_1-cBq",
	"row": "_1K7-5",
	"col": "_3jDNd",
	"actions": "_39G3u"
};