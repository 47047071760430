exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3LlCj{height:44px}._1L7uE{display:-ms-flexbox;display:flex}.lxWr7{-ms-flex-align:center;align-items:center}._3YtpK{width:100%}._1EXWC{font-weight:bold}._2x6YS{gap:16px}\n", ""]);

// Exports
exports.locals = {
	"h_44px": "_3LlCj",
	"flex": "_1L7uE",
	"items_center": "lxWr7",
	"w_full": "_3YtpK",
	"font_bold": "_1EXWC",
	"gap_16px": "_2x6YS"
};