import Api from './api';
import lodash from 'lodash';
/* const terms = [
    {id:1, name: 'List 1', date: new Date()},
    {id:2, name: 'List 2', date: new Date()},
    {id:3, name: 'List 3', date: new Date()},
    {id:4, name: 'List 4', date: new Date()},
] */
export default class Terms extends Api {
    static endpoint = '/preferences/byType'
    static type = 'terms'
    static async find(params) {
        let url = `${Terms.endpoint}/${params.termsType}`;
        if (params.name) {
            url += `?name=${params.name}`;
        }
        return await Terms.fetch(url, {
            method: 'GET',
          });
    }
    static async create(terms, name, termsType) {
        terms = lodash.cloneDeep(terms);
        return await Terms.fetch(`/preferences/${termsType}/${window.encodeURIComponent(name)}`, { method: 'POST', body: JSON.stringify(terms) });
    }
    static async update(terms, id, name) {
        terms = lodash.cloneDeep(terms);
        return await Terms.fetch(`/preferences/${id}/${window.encodeURIComponent(name)}`, { method: 'PUT', body: JSON.stringify(terms) });
    }
    static async findById(id) {
        return await Terms.fetch(`/preferences/${id}`, { method: 'GET' });
    }
    static async deleteById(id) {
        return await Terms.fetch(`/preferences/${id}`, { method: 'DELETE' });
    }
}
