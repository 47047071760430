import React from 'react';
import Formsy from 'formsy-react';
import createReactClass from 'create-react-class';

const FormsyInput = createReactClass({
  // Add the Formsy Mixin
  mixins: [{...Formsy.Mixin,
    setValue: function setValue(value) {
      this.setState({
        _value: value,
        _isPristine: false
      }, function () {
        this.context.formsy.validate(this);
        this.props.onSetValue && this.props.onSetValue(value);
        //this.props._validate(this);
      }.bind(this));
    }
  } ],

  // setValue() will set the value of the component, which in
  // turn will validate it and the rest of the form
  changeValue(event) {
    this.setValue(
      event.currentTarget[this.props.type === 'checkbox' ? 'checked' : 'value'],
    );
  },

  render() {
    const className =
      'form-group ' +
      (this.props.className || ' ') +
      (this.showRequired() ? 'required' : this.showError() ? 'error' : '');

    const errorMessage = this.getErrorMessage();

    return (
      <div className={className}>
        <input
          type={this.props.type || 'text'}
          name={this.props.name}
          onChange={this.changeValue}
          value={this.getValue()}
          checked={
            this.props.type === 'checkbox' && this.getValue() ? 'checked' : null
          }
        />
        <span className="validation-error">{errorMessage}</span>
      </div>
    );
  },
});

export default FormsyInput;
