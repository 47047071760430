exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1vkEO{overflow:auto;height:100vh}._3KSGs{display:table;min-height:100%;width:100%;font-family:Roboto , sans-serif}._3KSGs>div{display:table-row;width:100%;height:100vh;font-size:0}@media (max-width: 1023px){._3KSGs>div{display:block}}._2d17F,._3xXwZ{display:table-cell;width:50%;-webkit-box-sizing:border-box;box-sizing:border-box;vertical-align:top}@media (max-width: 1023px){._2d17F,._3xXwZ{display:block}}._2d17F{background:linear-gradient(333.95deg, #42469B 0%, #666ABB 100%)}@media (max-width: 1023px){._2d17F{display:none}}._2d17F ._1ia81{width:100%;padding-top:146px;background-color:#FBFBFB}._2d17F ._1ia81 img{width:100%;max-width:720px}._2d17F ._32ops>div{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-pack:justify;justify-content:space-between;padding:28px 100px}@media (max-width: 1300px){._2d17F ._32ops>div{padding-left:32px;padding-right:32px}}._2d17F ._32ops ._3-vpX{width:48%;margin-bottom:28px}._2d17F ._32ops ._3-vpX img{width:48px;height:48px;opacity:.4}._2d17F ._32ops ._3-vpX p{margin-bottom:0;font-family:Roboto, sans-serif;font-size:13px;line-height:17px;color:#ffffff}._3xXwZ{padding-top:74px}@media (max-width: 1023px){._3xXwZ{width:100%;padding-top:120px}}@media (max-width: 767px){._3xXwZ{padding-top:74px}}@media (max-width: 677px){._3xXwZ{padding-left:20px;padding-right:20px}}._3xXwZ>div{max-width:400px;margin:auto}._3xXwZ>div h2{margin-top:0;font-family:Roboto, sans-serif;font-size:25px;font-weight:400;line-height:40px;color:#000}._3xXwZ>div ._1fICZ{margin-bottom:10px}._3xXwZ>div ._3upAG>div>div{margin-right:8px !important}._3xXwZ>div ._2vMoZ{font-family:Roboto, sans-serif;font-size:13px;line-height:20px;letter-spacing:-0.1px;color:#000;opacity:.8}._3xXwZ>div ._2vMoZ a{text-decoration:underline !important;color:#000}\n", ""]);

// Exports
exports.locals = {
	"home": "_1vkEO",
	"root": "_3KSGs",
	"list_info": "_2d17F",
	"container": "_3xXwZ",
	"img_app_gmail": "_1ia81",
	"info_connect": "_32ops",
	"part_info_connect": "_3-vpX",
	"wrapper_input": "_1fICZ",
	"checkbox_terms": "_3upAG",
	"label_checkbox": "_2vMoZ"
};