import React, { Component } from "react";
import s from './Request.scss';
import withStyles from "isomorphic-style-loader/lib/withStyles";

class PortTooltip extends Component {

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = e => {
    const tooltip = document.getElementById('draggable');
    if (tooltip && !tooltip.contains(e.target)) {
      this.props.handleClose();
    }
  }
  handleAddAltPort = () => {
    this.props.handleAddAltPort();
    this.props.handleClose();
  }
  handleAddNextPort = () => {
    this.props.handleAddNextPort();
    this.props.handleClose();
  }
  render() {
    return (
      <ul className={s.wrapper_type_port}>
        <li onClick={this.handleAddAltPort}>Alternative port/place</li>
        <li onClick={this.handleAddNextPort}>Next port/place</li>
      </ul>
    );
  }
};

export default withStyles(s)(PortTooltip);
