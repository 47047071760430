import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListItem } from 'material-ui/List';
import MenuItem from 'material-ui/MenuItem';
import CustomCheckbox from '../Common/CustomCheckbox';
import ActionInfoOutline from 'material-ui/svg-icons/action/info-outline';
import FormsyText from '../Common/FormsyText';
import FormsyInput from '../Common/FormsyInput';
import Select from '../Common/Select';
import s from './Cargo.scss';
import { staticPopup } from '../../actions/runtime';

const styles = {
  container: { width: '100%' },
  info: {
    fill: '#7FC931',
    position: 'absolute',
    cursor: 'pointer',
    top: '15px',
    right: '0px',
    width: '20px',
    height: '20px',
  },
};
class StandardTerm extends PureComponent {
  static contextTypes = {
    store: PropTypes.object,
  };
  static propTypes = {
    index: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    template: PropTypes.string.isRequired,
    options: PropTypes.array,
    checked: PropTypes.bool,
    unchecked: PropTypes.bool, //if previous value was checked
    selectedValues: PropTypes.array,
    drybulk: PropTypes.bool,
    breakbulk: PropTypes.bool,
    wetbulk: PropTypes.bool,
    categoryFitted: PropTypes.bool,
  };

  parsedTemplate = [];
  reg = /{{(select|input|info)}}/g;
  state = { checked: undefined };

  constructor(props) {
    super(props);

    this.parseTemplate();
    this.state.checked = props.checked;
  }

  componentWillMount() {
    /*this.setState({checked: this.props.checked});*/
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      nextState.checked !== this.state.checked ||
      nextProps.hideTerms !== this.props.hideTerms ||
      nextProps.checked !== this.props.checked ||
      nextProps.selectedValues !== this.props.selectedValues ||
      nextProps.categoryFitted !== this.props.categoryFitted
    );
  }

  parseTemplate = () => {
    const props = this.props;
    let previousMatchIndex = 0;
    let i = 0;
    this.parsedTemplate = [];
    const { template } = props;
    template.replace(this.reg, (match, p1, offset, string) => {
      const before = string.substring(previousMatchIndex, offset);
      previousMatchIndex = offset + match.length;
      this.parsedTemplate.push(before);
      this.parsedTemplate.push({
        type: p1,
        index: i,
        options: props.options[i],
        selectedValue: props.selectedValues && props.selectedValues[i],
      });
      i += 1;
    });

    const after = template.substring(previousMatchIndex);
    this.parsedTemplate.push(after);
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.selectedValues !== this.props.selectedValues) {
      this.parseTemplate();
      this.setState({});
    }
    this.setState({
      checked: nextProps.checked
    })
  }

// eslint-disable-next-line class-methods-use-this
  onChange(type, i, e, v) {
    const part = this.parsedTemplate.find(tp => tp.index === i);
    if (part) {
      part.selectedValue = v;
      if (this.props.changeValue) {
        this.props.changeValue(this.props.index, part);
      }
    }
  }

  openDetails(termsLink, e) {
    e.stopPropagation();
    e.preventDefault();
    this.context.store.dispatch(staticPopup(termsLink, true));
  }

  onCheck = (e, checked) => {
    if (
      !checked &&
      this.props.previousValues[
        `terms[default][${this.props.index}][checked]`
      ] === undefined &&
      !this.props.new
    ) {
      checked = undefined;
    }
    this.setState({ checked });
    this.props.change && this.props.change({
      index: this.props.index,
      checked
    })
    if (
      this.props.previousValues[
        `terms[default][${this.props.index}][checked]`
      ] === undefined &&
      !this.props.new
    ) {
      this.props.termsCounter(checked, this.props.index);
    }
  };

  insertElement = {
    select(part, i) {
      const name = `terms[default][${this.props.index}][selectedValues][${part.index}]`;
      return (
        <Select
          className={s.terms_select}
          key={i}
          name={name}
          defaultValue={part.selectedValue ? part.selectedValue : part.options.defaultValue}
          onChange={this.onChange.bind(this, part.type, part.index)}
          autoWidth
          previousValue={this.props.previousValues[name]}
          data-tab="standard-terms"
        >
          {part.options.values.map(value => (
            <MenuItem key={value} value={value} primaryText={value} />
          ))}
        </Select>
      );
    },

    input(part, i) {
      const name = `terms[default][${this.props.index}][selectedValues][${part.index}]`;
      return (
        <FormsyText
          className={s.terms_input}
          key={i}
          name={name}
          requiredError="required"
          required={this.state.checked}
          validations={this.state.checked ? part.options.validations : null}
          validationErrors={part.options.validationErrors}
          validationError={part.options.validationError}
          defaultValue={part.selectedValue !== undefined ? part.selectedValue : part.options.defaultValue}
          onChange={this.onChange.bind(this, part.type, part.index)}
          previousValue={this.props.previousValues[name]}
          data-tab="standard-terms"
          data-link={`anchor${this.props.index}`}
        />
      );
    },

    justText(part, i) {
      return <span key={part + i}>{part}</span>;
    },

    info(part, i) {
      return (
        <ActionInfoOutline
          onClick={function (e) {
            e.stopPropagation();
          }}
          className={s.info_icon}
          style={styles.info}
          key={i}
          onClick={this.openDetails.bind(this, part.options.termsLink)}
        />
      );
    },
  };

  notSelectedRenderer = () => this.parsedTemplate.map((part, i) => {
    if (part.type === 'info') {
      return this.insertElement.info.call(this, part, i);
    }
    if (part.options) {
      return <a key={i}>{(part.selectedValue !== undefined ? part.selectedValue : part.options.defaultValue) || '____'}</a>;
    }
    return <span key={i}>{part}</span>;
  });

  render() {
    console.log('render standard term', this.state.checked);
    let className = '';
    if (this.props.new) {
      className += ' ' + s.new_term;
    }
    if (this.state.checked === false) {
      if (this.props.unchecked) {
        className += ' ' + s.strike;
      } else {
        className += ' ' + s.strike_blue;
      }
    }
    const canHide = this.state.checked === undefined;
    const hidden = (!this.props.categoryFitted || this.props.hideTerms) && canHide;
    return (
      <ListItem
        style={{
          display: hidden
            ? 'none'
            : 'block',
        }}
        className={s.terms_item}
        leftCheckbox={
          <CustomCheckbox
            checked={this.state.checked === undefined ? false : this.state.checked}
            onCheck={this.onCheck}
          />
        }
      >

        {this.state.checked === undefined
          ? <span>
            <span>{this.notSelectedRenderer()}</span>
          </span>
          : <span className={className}>
            <FormsyInput
              type="hidden"
              value={this.props.index}
              name={`terms[default][${this.props.index}][index]`}
            />
            <FormsyInput
              type="hidden"
              value={this.state.checked}
              name={`terms[default][${this.props.index}][checked]`}
            />
            {this.parsedTemplate.map((part, i) => {
              if (part.type) {
                return this.insertElement[part.type].call(this, part, i);
              }
              return this.insertElement.justText.call(this, part, i);
            })}
          </span>}
          <a href="javascript:void(0);" id={`anchor${this.props.index}`} style={{position: 'absolute', top: '-150px', visibility: 'hidden'}}></a>
      </ListItem>
    );
  }
}

export default StandardTerm;
