import React, { Component } from 'react';
import PropTypes from 'prop-types';
import s from './Cargo.scss';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import { animatedStep } from '../../components/Common/Stepper';
import ArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import ArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import { replaceInArray } from '../../core/utils';
import RaisedButton from '../Common/RaisedButton';
import CargoContent from './CargoContent';
import Stepper from 'material-ui/Stepper/Stepper';
import Step from 'material-ui/Stepper/Step';
import StepContent from 'material-ui/Stepper/StepContent';
import StepButton from 'material-ui/Stepper/StepButton';
import { uploadFile } from '../../actions/cargo';
import FormsyAttachments from '../Common/FormsyAttachments/FormsyAttachments';
import AddIcon from '../Common/AddIcon';
import styled from 'styled-components';
import FormsyAttachmentsStyles from './../Common/FormsyAttachments/FormsyAttachments.scss';
import FreightSection from './Request/FreightSection';
import MultiFreightEditNarrow from '../Monitor/Offer/MultiFreight/MultiFreightEditNarrow';
import ChargesEditNarrow from '../Monitor/Offer/Charges/ChargesEditNarrow';

const Attachments = styled(FormsyAttachments)`
  .${FormsyAttachmentsStyles.part_attached_file} {
    margin-bottom: 20px !important;
  }
  .${FormsyAttachmentsStyles.btn_close} {
    cursor: pointer;
  }
`;

function MultiFreightSection(props: {
  freights: any,
  onChangeFreights: () => any,
  charges: any,
  onChangeCharges: () => any,
  demurrage: any,
  demurrageOpts: any,
  detention: any,
  detentionOpts: any,
  onChangeFreightSection: () => any
}) {
  return <div>
    <div className={s.stowage_title}>
      FREIGHT
    </div>
    <MultiFreightEditNarrow
      value={props.freights}
      onChange={props.onChangeFreights}
    />
    <ChargesEditNarrow
      value={props.charges}
      onChange={props.onChangeCharges}
    />
    <FreightSection
      demurrage={props.demurrage}
      demurrageOpts={props.demurrageOpts}
      detention={props.detention}
      detentionOpts={props.detentionOpts}
      onChange={props.onChangeFreightSection}
    />
  </div>;
}

export default class CargoList extends React.PureComponent {
  static propTypes = {
    defaultValue: PropTypes.array,
    freightSectionEnabled: PropTypes.bool,
  };

  static contextTypes = {
    showMessage: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    freightSectionEnabled: true,
  }

  _handleNextInterceptor = null;


  handleOpenTab(index) {
    if (index == this.props.activeStepIndex) {
      index = -1;
    }
    this.props.handleGenericChange({ activeStepIndex: index });
  }

  interceptHandleNext = callback => {
    this._handleNextInterceptor = callback;
  }

  handleAddCargo = async () => {
    await this.props.handleGenericChange({
      cargoList: [...this.props.cargoList, {
        stowage: {},
        clauses: {},
      }],
      activeStepIndex: this.props.cargoCount,
      cargoCount: this.props.cargoCount + 1,
    });
    const cargoSteps = document.querySelectorAll('.' + s.cargo_step);
    const lastStep = cargoSteps[cargoSteps.length - 1];
    lastStep && lastStep.scrollIntoView();
  };
  handleDeleteCargo = (index, e) => {
    e && e.stopPropagation();
    const newList = replaceInArray(this.props.cargoList, index, null);
     this.props.handleGenericChange({
      cargoList: newList,
      cargoCount: this.props.cargoCount - 1,
      activeStepIndex: this.props.cargoCount - 2,
    });
  };


  handleChangeTitle = (index, value) => {
    const cargo = { ...this.props.cargoList[index] };
    if(cargo.title === value) {
      return;
    }
    cargo.title = value;
    this.props.handleGenericChange({
      cargoList: replaceInArray(this.props.cargoList, index, cargo),
    });
  };
  handleNext = () => {
    if (this._handleNextInterceptor) {
      this._handleNextInterceptor();
    }
    const stepNode = this._cargoListStep || document.querySelector(
      '.stepper_transition',
    );
    const invalidNodes = stepNode.querySelectorAll(
      '[data-valid=false]',
    );
    if (!invalidNodes || !invalidNodes.length) {
      return;
    }
    let invalidTab;
    for (let i = 0; i < invalidNodes.length; i++) {
      let node = invalidNodes[i];
      let index = node.getAttribute('data-tabindex') || node.closest("[data-tabindex]")?.getAttribute?.('data-tabindex');
      if (index !== null && index !== '' && typeof index !== 'undefined') {
        invalidTab = Number(index);
        break;
      }
    }
    if (invalidTab !== void 0 && this.props.activeStepIndex != invalidTab) {
      this.handleOpenTab(invalidTab);
      setTimeout(function() {
        invalidNodes[0].scrollIntoView();
      }, 400);
    } else {
      invalidNodes[0].scrollIntoView();
    }
  };

  handleAttachFile = (type, e) => {
    const file = e.currentTarget.files[0];
    if (!file) {
      return;
    }
    const sizeMb = file.size / (1024 * 1024);
    if (sizeMb > 50) {
      return this.context.showMessage({ message: 'sorry file to large' });
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    uploadFile(formData, { privateAttachment: true })
      .then(({ data }) => {
        this.setState({ [type]: data });
      })
      .catch(e => {
        const message = (e.message && e.message.errors && e.message.errors[0] && e.message.errors[0].messages && e.message.errors[0].messages[0]) || 'Sorry  can`t upload file. Please try again later';
        this.context.showMessage({
          message,
        });
        console.error(e);
      });
  };

  detachDrawing= () => {
    this.setState({ drawingFile: undefined });
    const input = document.querySelectorAll('input[type="file"]')[0];
    if (input) {
      input.type = '';
      input.type = 'file';
    }
  };
  detachPackingList= () => {
    this.setState({ packingListFile: undefined });
    const input = document.querySelectorAll('input[type="file"]')[1];
    if (input) {
      input.type = '';
      input.type = 'file';
    }
  };

  changeFreightSection = name => this.context.onChange(name)();

  changeFreights = this.changeFreightSection("freights");

  changeCharges = this.changeFreightSection("charges");

  render() {
    console.debug('cargoList render called');
    let stepIndex = -1;
    return (
      <div ref={el => this._cargoListStep = el}>
        <Stepper
          className={s.cargo_stepper}
          activeStep={this.props.activeStepIndex}
          linear={false}
          orientation={'vertical'}
        >
          {this.props.cargoList
            .map((cargo, index) => {
              if (!cargo) {
                return null;
              }
              stepIndex++;
              return (
                <Step
                  data-tabindex={index}
                  key={cargo.id}
                  className={s.cargo_step}
                >
                  <StepButton
                    className={this.props.activeStepIndex === index && this.props.cargoList?.length > 1 ? s.active_step : ""}
                    onClick={this.handleOpenTab.bind(this, stepIndex)}
                    style={{
                      height: '32px',
                      backgroundColor: 'rgba(0,0,0,0.07)',
                      display: this.props.cargoCount > 1
                        ? 'inline-block'
                        : 'none',
                    }}
                    icon={
                      this.props.activeStepIndex === stepIndex
                        ? <ArrowUp />
                        : <ArrowDown />
                    }
                  >
                    <div className={s.cargo_list_title}>{cargo.title || 'Enter cargo name'}</div>
                    {
                      index > 0
                      && (
                        <span
                          onClick={this.handleDeleteCargo.bind(this, index)}
                          className={s.delete_button}
                        >
                            <NavigationClose
                              style={{height: '16px', width: '16px'}}
                            />
                          </span>
                      )
                    }

                  </StepButton>
                  <StepContent transition={animatedStep}>
                    <StepWrapper
                      stepIndex={stepIndex}
                      activeStepIndex={this.props.activeStepIndex}
                    >
                      <CargoContent
                        tabIndex={stepIndex}
                        cargoInArray={cargo}
                        index={index}
                        defaultValues={cargo.defaultValues}
                        handleChangeTitle={this.handleChangeTitle.bind(
                          this,
                          index,
                        )}
                        handleCargoNameChange={this.handleCargoNameChange}
                        handleCargoTypeChange={this.handleCargoTypeChange}
                        handleAddCargo={this.handleAddCargo}
                      />
                    </StepWrapper>
                  </StepContent>

                </Step>
              );
            })
            .filter(cargo => !!cargo)}
        </Stepper>
        <div className={s.cargo_footer}>
          <div
            className={s.add_cargo_button}
            onClick={this.handleAddCargo}
          >
            <AddIcon label="Add cargo" />
          </div>
        </div>
        <div className={s.add_cargo_attachments_wrapper}>
          <Attachments
            name={'files'}
            files={this.props.files}
            privateAttachment
            buttonPosition={"right"}
          />
        </div>
        {this.props.freightSectionEnabled ? (
          <MultiFreightSection
            freights={this.props.freights}
            onChangeFreights={this.changeFreights}
            charges={this.props.charges}
            onChangeCharges={this.changeCharges}
            demurrage={this.props.demurrage}
            demurrageOpts={this.props.demurrageOpts}
            detention={this.props.detention}
            detentionOpts={this.props.detentionOpts}
            onChangeFreightSection={this.changeFreightSection}
          />
        ) : null}
        <div style={{margin: '28px 0 16px 0'}}>
          <RaisedButton
            label="NEXT"
            disableTouchRipple
            disableFocusRipple
            primary
            type="submit"
            onClick={this.handleNext}
            style={{marginRight: 12}}
          />
        </div>
      </div>
    );
  }
}

class StepWrapper extends Component {

  state = { mounted: false }

  constructor(props, context) {
    super(props, context);
    if (props.activeStepIndex === props.stepIndex) {
      this.state.wasOpened = true;
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true });
    }, 200);

  }


  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.activeStepIndex === nextProps.stepIndex) {
      if (!this.state.wasOpened) {
        this.setState({ wasOpened: true });
      }
    }
  }


  /*shouldComponentUpdate(nextProps, nextState) {

    if (this.state.mounted !== nextState.mounted || this.state.wasOpened !== nextState.wasOpened) {
      return true;
    }
    if (nextProps.activeStepIndex !== nextProps.stepIndex) {
      return false;
    }
    return true;
  }*/
  render(){
    if (!this.state.mounted) {
      return null;
    }
    return this.props.children;
  }

}
