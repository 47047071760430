import React, { Component } from "react";
import WithTooltip from "../Common/WithTooltip";
import s from "./RatingBlock.scss";
import cx from "classnames";
import InfoIcon from "material-ui/svg-icons/action/info";
import withStyles from "isomorphic-style-loader/lib/withStyles";
export class CompanyChangesTooltip extends Component {
  static defaultProps = {
    positionInsideTransform: false,
    whiteArrow: true,
    horizontalPosition: "left",
    dark: false,
    classes: "",
    offset: { x: 8, y: 0 },
    text:
      "Any amendments will remain personal or available to your team, and will not be visible to other users of SHIPNEXT"
  };
  tip = ({ text, whiteArrow, classes = "" } = this.props) => (
    <AlertTooltip text={text} dark={!whiteArrow} classes={classes} />
  );
  render() {
    const {
      bottomArrow,
      text,
      positionInsideTransform,
      whiteArrow,
      offset,
      horizontalPosition,
      dark,
      classes,
      children = (
        <span style={{ pointerEvents: "all" }}>
          <InfoIcon
            color="#DADADA"
            style={{
              marginTop: "-3px",
              pointerEvents: "all",
              marginLeft: "12px",
              zIndex: 9999,
              position: "relative",
              width: "18px",
              height: "18px"
            }}
          />
        </span>
      )
    } = this.props;
    return (
      <span style={{ marginTop: "-2px" }}>
        <WithTooltip
          bottomArrow={bottomArrow}
          offset={offset}
          positionInsideTransform={positionInsideTransform}
          horizontalPosition={horizontalPosition}
          tip={this.tip.bind(this, this.props)}
        >
          {children}
        </WithTooltip>
      </span>
    );
  }
}
export class DarkCompanyChangesTooltip extends CompanyChangesTooltip {
  tip = ({ text, classes = "" } = this.props) => {
    return (
      <AlertTooltip
        {...this.props}
        text={text}
        dark={true}
        classes={`${classes} ${s.dark_tooltip}`}
      />
    );
  };
}

export const AlertTooltip = ({
  text = "Any amendments will remain personal or available to your team, and will not be visible to other users of SHIPNEXT",
  classes,
  dark,
  ...rest
}) => (
  <div className={cx(s.alert, dark && s.dark, classes || "")} {...rest}>
    <p>{text}</p>
  </div>
);

export const StyledAlertTooltip = withStyles(s)(AlertTooltip);

export default CompanyChangesTooltip;
