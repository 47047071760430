import React from "react";
import pure from "recompose/pure";
import SvgIcon from "material-ui/SvgIcon";

let Cargo = ({ fill = "#FFFFFF", opacity = 1, ...props }) => (
  <span {...props}>
    <span className="icon-wrapper">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        opacity={opacity}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.0004 4.4H15.6004V6.68021L10.8371 10H9.00286L4.40039 6.79229V4.4H6.00039V2H7.60039V4.4H12.4004V2H14.0004V4.4ZM9.20039 13.2V11.7008C9.45609 11.635 9.72415 11.6 10.0004 11.6C11.7677 11.6 13.2004 13.0327 13.2004 14.8C13.2004 16.5673 11.7677 18 10.0004 18C8.23308 18 6.80039 16.5673 6.80039 14.8H8.40039C8.40039 15.6837 9.11673 16.4 10.0004 16.4C10.884 16.4 11.6004 15.6837 11.6004 14.8C11.6004 13.9163 10.884 13.2 10.0004 13.2H9.20039Z"
          fill={fill}
        />
      </svg>
    </span>
  </span>
);

Cargo = pure(Cargo);
Cargo.displayName = "Cargo";
Cargo.muiName = "SvgIcon";

export default Cargo;
