exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2najp{font-size:10px;font-weight:500;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;height:100%;width:100%;overflow:hidden;line-height:14px;cursor:pointer}._2najp span{white-space:normal}._2najp ._2mT2I{white-space:nowrap;width:100%;text-overflow:ellipsis;overflow:hidden}._3l5i_{display:-ms-flexbox;display:flex;width:100%}.TFtlv{border-radius:4px !important}.TFtlv div,.TFtlv a{font-size:13px !important;color:#333333 !important}.TFtlv span,.TFtlv div{line-height:24px !important;min-height:24px !important}.TFtlv button{top:8px !important}.TFtlv>div>div{top:-2px !important}._18slk._2HnW5 div,._18slk._2HnW5 span{color:var(--text-green-dark) !important}\n", ""]);

// Exports
exports.locals = {
	"default_label": "_2najp",
	"channel_label": "_2mT2I",
	"wrapper": "_3l5i_",
	"default_popover_style": "TFtlv",
	"menu_item": "_18slk",
	"active": "_2HnW5"
};