import CalculatorApi from '../../../core/api/Calculator';
import CalculatorV2Api from "../../../core/api/CalculatorV2Api";
import {uniqBy} from "lodash";
import {convertUnit, getMtCmbFrtFromIntake} from "../utils";

const CALCULATOR_SAVE_SETTINGS = 'CALCULATOR_SAVE_SETTINGS';
const CALCULATOR_GET_SETTINGS = 'CALCULATOR_GET_SETTINGS';
const CALCULATOR_SAVE_LDTERMS = 'CALCULATOR_SAVE_LDTERMS';
const CALCULATOR_GET_LDTERMS = 'CALCULATOR_GET_LDTERMS';
const CALCULATOR_GET_LIST = 'CALCULATOR_GET_LIST';
const CALCULATOR_LIST_CHANGES = 'CALCULATOR_LIST_CHANGES';
const CALCULATOR_CREATE = 'CALCULATOR_CREATE';
const CALCULATOR_UPDATE = 'CALCULATOR_UPDATE';
const CALCULATOR_FIX = 'CALCULATOR_FIX';
const CALCULATOR_REMOVE = 'CALCULATOR_REMOVE';

export default {
  [CALCULATOR_SAVE_SETTINGS]:
    {
      action: saveSettings,
      reducer: (state,action) => {
        return {...state,settings:action.payload.settings};
      }
    },
  [CALCULATOR_GET_SETTINGS]:
    {
      action: getSettings,
      reducer: (state, { res }) => {
        return {...state,settings: {...res.data.calculatorSettings, _id:res.data._id}};
      }
    },
  [CALCULATOR_SAVE_LDTERMS]:
    {
      action: saveLdTerms,
      reducer: (state,action) => {
        return {...state, settings:action.payload.settings};
      }
    },
  [CALCULATOR_GET_LDTERMS]:
    {
      action: getLdTerms,
      reducer: (state, { res }) => {
        if(!res?.data?.[0]){
          return state;
        }
        const data = res.data[0];
        const ldTerms = {
          ...data.calculatorCustomLdTerms,
          updatedAt: data.updatedAt,
          updatedBy: data.updatedBy,
          _id: data._id,
        };
        return { ...state, ldTerms };
      },
    },
  [CALCULATOR_GET_LIST]: {
    action: getList,
    reducer: (state, { res, payload }) => {
      if (!res.data) {
        return state;
      }
      const newState = { ...state };
      let next = null;
      if (res.data.length === 50) {
        next = payload.page + 1;
      }
      const data = mapServerList(res.data, payload.filters);
      newState.calculatorList = { ...newState.calculatorList };
      newState.calculatorList.page = payload.page;
      newState.calculatorList.next = next;
      newState.calculatorList.filters = payload.filters || {};
      if (payload.page === 1) {
        newState.calculatorList.data = data;
      } else {
        newState.calculatorList.data = [...newState.calculatorList.data, ...data];
      }
      newState.calculatorList.data = uniqBy(newState.calculatorList.data, (c) => c.key);
      newState.calculatorList.isLoading=false;
      return newState;
    }
  },
  [CALCULATOR_LIST_CHANGES]: {
    action: changeList,
    reducer: (state, { payload }) => {
      if (!payload.data.length) {
        return state;
      }
      const newState = { ...state };

      newState.calculatorList = { ...newState.calculatorList };
      newState.calculatorList.data = [...newState.calculatorList.data];
      let data = newState.calculatorList.data;
      if (payload.addNew) {
        data = data.filter(c => !payload.data.some(cc => cc.key === c.key));
      }
      payload.data.reverse();
      payload.data.forEach(calc => {
        const index = data.findIndex(d => d.key === calc.key);
        if (index === -1) {
          if(!payload.addNew){
            return;
          }
          data.unshift({ ...calc });
        } else {
          data[index] = { ...data[index], ...calc };
        }

      });
      newState.calculatorList.data = data;

      return newState;
    }
  },
  [CALCULATOR_CREATE]: {
    action: create,
    reducer: (state, { payload, res }) => {
      return state;
    },
  },
  [CALCULATOR_UPDATE]: {
    action: update,
    reducer: (state, { payload, res }) => {
      return state;
    },
  },
  [CALCULATOR_FIX]: {
    action: fix,
    reducer: (state, { payload, res }) => {
      return state;
    },
  },
  [CALCULATOR_REMOVE]: {
    action: remove,
    reducer: (state, { payload, res }) => {
      const newState = { ...state };
      const [_id, status] = payload.key.split(":");
      newState.calculatorList = { ...newState.calculatorList };
      newState.calculatorList.data = newState.calculatorList.data.filter(c => c.key !== payload.key);
      if (status === 'planned'){
        newState.calculatorList.data = newState.calculatorList.data.filter(c => c.key !== `${_id}:actual`);
      }
      return newState;
    },
  },


}

const emptyCalc = { vessel: {}, totals: {}, cargoList: [], legList: [] };

function calculateTotalValues(type, calculation) {
 switch (type) {
   case 'freightCalculator':
     return calculateFreightTotalValues(calculation);
   case 'intermodalCalculator':
     return calculateIntermodalTotalValues(calculation);
 }
 return {};
}

function calculateFreightTotalValues(calculation) {
  const { cargoList } = calculation;
  const totalValues = { weight: 0, volume: 0};
  const loading = [];
  const unloading = [];
  const deliveryPlaces = [];
  const redeliveryPlaces = [];
  const cargoNames = [];
  for (let i = 0; i < cargoList.length; i++) {
    const cargo = cargoList[i];
    if (cargo.cargoName) {
      cargoNames.push(cargo.cargoName);
    }
    if (cargo.loadingPort) {
      deliveryPlaces.push({port: cargo.loadingPort});
      loading.push({oneOf: [{port: cargo.loadingPort}]});
    }
    if (cargo.unloadingPort) {
      redeliveryPlaces.push({port: cargo.unloadingPort});
      unloading.push({oneOf: [{port: cargo.unloadingPort}]});
    }

    if(!cargo.unit) {
      continue;
    }
    try {
      const unit = String(cargo.unit).toLowerCase();
      switch (unit) {
        case 'mt':
        case 'lt':
          totalValues.weight += (convertUnit(unit, 'mt', 1, cargo.quantity) * 1000);
          break;
        case 'cbm':
        case 'cbf':
          totalValues.volume += convertUnit(unit, 'cbm', 1, cargo.quantity);
      }
    } catch (e) {
      console.error(e);
    }
  }
  return { totalValues, loading, unloading, deliveryPlaces, redeliveryPlaces, cargoNames };
}

function calculateIntermodalTotalValues(calculation) {
  const { cargoList, units } = calculation;
  const totalValues = { weight: 0, volume: 0};
  const loading = [];
  const unloading = [];
  const deliveryPlaces = [];
  const redeliveryPlaces = [];
  const cargoNames = [];
  const totals = { currency: calculation.currency, profit: calculation.totalNetProfit };
  for (let i = 0; i < cargoList.length; i++) {
    const cargo = cargoList[i];
    if (cargo.cargoName) {
      cargoNames.push(cargo.cargoName);
    }
    if (cargo.loadingPort) {
      deliveryPlaces.push({port: cargo.loadingPort});
      loading.push({oneOf: [{port: cargo.loadingPort}]});
    }
    if (cargo.unloadingPort) {
      redeliveryPlaces.push({port: cargo.unloadingPort});
      unloading.push({oneOf: [{port: cargo.unloadingPort}]});
    }
    try {
      totalValues.weight += (convertUnit(units.weight, 'mt', 1, cargo.weight) * 1000);
      totalValues.volume += (convertUnit(units.volume, 'cbm', 1, cargo.volume));
    } catch (e) {
      console.error(e);
    }
  }
  return { totalValues, loading, unloading, deliveryPlaces, redeliveryPlaces, cargoNames, totals };
}


 export function mapServerList(data, filters = {}){
  const newData = [];
  const preparedStatus = { ...filters.status };
  if (!filters.status?.planned && !filters.status?.actual) {
    preparedStatus.planned = true;
    preparedStatus.actual = true;
  }
  const statusFilter = { planned: true, actual: true, ...(preparedStatus || {}) };
  data.forEach((calc) => {
    if (calc.planned && statusFilter.planned) {
      newData.push({
        _id: calc._id, ...emptyCalc, ...calc.planned.parameters,
        name: calc.name,
        status: 'planned',
        key: `${calc._id}:planned`,
        updatedBy: calc.planned.updatedBy,
        updatedAt: calc.planned.updatedAt,
        ...calculateTotalValues(calc.type, calc.planned.parameters),
      });
    }
    if (calc.actual && statusFilter.actual) {
      newData.push({
        _id: calc._id, ...emptyCalc, ...calc.actual.parameters,
        name: calc.name,
        status: 'actual',
        key: `${calc._id}:actual`,
        updatedBy: calc.actual.updatedBy,
        updatedAt: calc.actual.updatedAt,
        ...calculateTotalValues(calc.type, calc.actual.parameters),

      });
    }
  });
  return newData;
}

function saveSettings(settings) {
  return {
    type: CALCULATOR_SAVE_SETTINGS,
    promise: CalculatorApi.saveSettings(settings),
    payload: { settings: settings },
  }
}
function getSettings() {
  return {
    type: CALCULATOR_GET_SETTINGS,
    promise: CalculatorApi.getSettings(),
  }
}

function saveLdTerms(terms) {
  return {
    type: CALCULATOR_SAVE_LDTERMS,
    successMessage: "Terms updated.",
    promise: CalculatorApi.saveCustomLdTerms(terms),
    payload: {terms}
  }
}
function getLdTerms() {
  return {
    type: CALCULATOR_GET_LDTERMS,
    promise: CalculatorApi.getCustomLdTerms()
  }
}

function getList(page = 1, filters = {}) {
  return {
    type: CALCULATOR_GET_LIST,
    promise: CalculatorV2Api.getList(page, filters),
    payload: { page, filters },
    takeLatest: true,
  }
}
function changeList(payload) {
  return {
    type: CALCULATOR_LIST_CHANGES,
    payload: payload,
  }
}

function create(...payload) {
  return {
    type: CALCULATOR_CREATE,
    promise: CalculatorV2Api.create(...payload),
    payload: payload,
  }
}

function update(...payload) {
  return {
    type: CALCULATOR_UPDATE,
    promise: CalculatorV2Api.update(...payload),
    payload: payload,
  }
}

function fix(...payload) {
  return {
    type: CALCULATOR_FIX,
    promise: CalculatorV2Api.fix(...payload),
    payload: payload,
  }
}

function remove(calculation) {
  return {
    type: CALCULATOR_REMOVE,
    promise: CalculatorV2Api.remove(calculation._id, calculation.status),
    payload: calculation,
  }
}

function simulateSocketUpdate(calculation){

  return (dispatch, getState) => {
    const state = getState();
    const data = mapServerList([calculation], state.calculator.calculatorList.filters);
    return dispatch({
      type: CALCULATOR_LIST_CHANGES,
      payload: { data, addNew: true},
    })
  }
}

export const calculatorActions = { saveSettings, getSettings, saveLdTerms, getLdTerms, getList, changeList, create, update, fix, remove, simulateSocketUpdate };
