import React, { useState, useEffect, useContext } from 'react';
import EmailsTable from './EmailsTable';
import ComposeEmailDialog from './ComposeEmailDialog';
import EmailDetails from './EmailDetails';
import DialogHalfScreen from '../Common/DialogHalfScreen';
import history from '../../core/history';
import MenuContext from '../context/Menu';

const EmailsInMonitor = (props) => {
  const [detailsOpened, setDetailsOpened] = useState(false);
  const [composeNewOpened, setComposeNewOpened] = useState(false);
  const [emailId, setEmailId] = useState(null);
  const conntext = useContext(MenuContext);

  useEffect(() => {
    if (props.isFleetMonitor) {
      const { bidId } = props;
      if (bidId && bidId !== '-') {
        handleOpenDetails(bidId);
      }
    }
  }, [props.isFleetMonitor, props.bidId]);

  const handleOpenDetails = (emailId) => {
    if (props.isFleetMonitor) {
      if (props.tcOffer) {
        history.push(`/$1/$2/$3/${emailId}/$5`);
      } else {
        if (props.isMyVessel) history.push(`/$1/$2/$3/$4/${emailId}`);
        else history.push(`/$1/$2/$3/${emailId}`);
      }
    }
    setDetailsOpened(true);
    setEmailId(emailId);
  };

  const handleCloseDetails = () => {
    if (props.isFleetMonitor) {
      if (props.tcOffer) {
        history.push(`/$1/$2/$3/-/$5`);
      } else {
        if (props.isMyVessel) history.push(`/$1/$2/$3/$4`);
        else history.push(`/$1/$2/$3/`);
      }
    }
    setDetailsOpened(false);
    setEmailId(null);
  };

  const handleOpenCompose = () => setComposeNewOpened(true);
  const handleCloseCompose = () => setComposeNewOpened(false);

  console.log(conntext);
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <DialogHalfScreen noEddie position={props.position} open>
        <EmailsTable
          isMonitor
          isContactBook={props.isContactBook}
          emailsForFilter={props.emailsForFilter}
          cargoId={props.cargoId}
          vesselId={props.vesselId}
          handleCloseTable={props.closeMails}
          openEmailDetails={handleOpenDetails}
          openCompose={handleOpenCompose}
          isFleetMonitor={props.isFleetMonitor}
        />
      </DialogHalfScreen>
      {detailsOpened && (
        <DialogHalfScreen noEddie position={props.position} open>
          <EmailDetails
            cargoId={props.cargoId}
            vesselId={props.vesselId}
            emailId={emailId}
            handleClose={handleCloseDetails}
            showRecognizedItems={false}
            mailFlowProps={{
              transitionLockerPredicate: (location, action) =>
                location.pathname.indexOf(`/${props.cargoId}/`) === -1,
            }}
            shouldConfirmClose
            action={props.action}
          />
        </DialogHalfScreen>
      )}
      {composeNewOpened && (
        <ComposeEmailDialog
          composeProps={{ to: props.emailsForFilter }}
          cargoId={props.cargoId}
          vesselId={props.vesselId}
          onClose={handleCloseCompose}
          position={props.position}
        />
      )}
    </div>
  );
};

export default EmailsInMonitor;