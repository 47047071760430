exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3rlw3{margin-bottom:4px;padding:4px}._3rlw3 ._2CTb1{fill:var(--text-light) !important;margin-right:10px;-webkit-transform:rotate(45deg);transform:rotate(45deg);width:20px !important;height:20px !important}._3rlw3 a{font-family:'Roboto';font-weight:500;font-size:13px;line-height:130%;color:var(--text-links);text-transform:none;text-decoration:underline !important;word-break:break-all}._17mz4{margin-bottom:4px}._17mz4 path{fill:var(--text-links)}._17mz4 label{display:-ms-flexbox;display:flex;cursor:pointer}._17mz4 label span{margin-left:6px;color:var(--text-links);font-family:'Roboto';font-weight:500;font-size:13px;line-height:22px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._17mz4 label input{display:none}._3Rv38{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;width:100%;background-color:#F5F5F5;margin-bottom:4px;padding:6px 8px}._3Rv38 ._3AzEK{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._3Rv38 a{color:var(--text-links);font-family:'Roboto';font-weight:500;font-size:13px;line-height:130%;text-decoration:underline !important;word-break:break-all}._2yPkS{width:24px;height:24px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;cursor:pointer;padding:2px;justify-self:flex-end}\n", ""]);

// Exports
exports.locals = {
	"attachment_file": "_3rlw3",
	"attach_icon": "_2CTb1",
	"add_attachments": "_17mz4",
	"label_select": "_3Rv38",
	"label_name": "_3AzEK",
	"delete": "_2yPkS"
};