import React, { Component } from "react";
import { connect } from "react-redux";
import ArrowDownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';

import Vessel from "../../.././core/api/Vessel";
import { getSnpList } from "../../../actions/vessel";
import { CreateChatWrapper } from '../../Chat/CreateChat';
import { TableRow, TableCell, Text, FirstCell, Wrapper, IconWrapper, EmailRow, EmailText, EmailSubHeader, ShowMessageToggle, OwnerChatWrapper } from "./StyledComponents";
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import MailIcon from '../../Icons/Mail';
import CircularProgress from 'material-ui/CircularProgress';
import Loader from "../../Common/Loader";

import { prepareDataForRender } from "../../Vessel/Spfleet/SpfleetUtil"

import OriginalMessageBody from './../../Common/OriginalMessageBody';
class ForSaleOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      loading: false,
      originalMsg: null
    };
  }

  static mailTextMock = "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. ";

  handleShowMessage = (mailId) => {
    if (this.state.originalMsg) {
      this.setState({originalMsg: null});
      return;
    }

    this.setState({loading: true})
    Vessel.getForSaleMail(mailId)
    .then(res => {
      this.setState({loading: false, originalMsg: res.data })
    })
    .catch(res => {
      this.setState({loading: false})
    })
  }

  handleDelete = () => {
    if (this.props.data.type === 1) return;

    Vessel.deleteOfferByVessel(this.props.data._id)
      .then(() => {
        this.props.getForSaleInfo(this.props.vessel._id);
        this.props.getSnpList();
      })
      .catch(err => {
        console.log(err);
      });
  }

  //TODO - prevent unneed rerender via lifecycle methods

  createMarkup(text) { return {__html: text}; };

  render() {
	 const dataForRender = prepareDataForRender(this.props.data)
    return (
      <Wrapper>
        <TableRow style={{ borderRadius: "8px" }}>
          <FirstCell>
            {dataForRender.isReceived && <IconWrapper rotate={this.state.opened} onClick={() => this.setState({opened: !this.state.opened, mailText: ""})}>
              <ArrowDownIcon/>
            </IconWrapper>}
            <TableCell>
              <Text ellipsis bold>{dataForRender.addedBy}</Text>
              <Text>{dataForRender.addedDate}</Text>
            </TableCell>
          </FirstCell>
          <TableCell>
            <Text link bold>
              {dataForRender.realUserEmail || dataForRender.company}
            </Text>
            {/* TO BE RESTYLED */}
            <OwnerChatWrapper>
            {dataForRender.chatId && <div style={{display: "block", cursor: "pointer"}}>
                <CreateChatWrapper fill addedBy={dataForRender.chatId} refNo={dataForRender.refNo} hideForSelf={true} />
              </div>}
              <Text medium>{dataForRender.renderType}</Text>
            </OwnerChatWrapper>
          </TableCell>
          <TableCell right>
            <Text bold>{dataForRender.ideaPrice !== "---" ? dataForRender.ideaPrice : ""}</Text>
            <Text>{dataForRender.salePrice !== "---" ? dataForRender.salePrice : ""}</Text>
          </TableCell>
          <TableCell right>
            {this.props.data.type !== 1 && <span onClick={this.handleDelete} style={{ width: '24px', height: '24px', display: 'flex', justifyContent: "center", alignItems: 'center', cursor: 'pointer', padding: '0px 4px', borderRadius: '50%', background: '#E6E6E6'}}>
              <CloseIcon style={{width: 16, height: 16}}></CloseIcon>
            </span>}
          </TableCell>
        </TableRow>
        {this.state.opened &&
          <EmailRow>
            <EmailSubHeader>
              <EmailText><span style={{fontWeight: "bold"}}>Ref: </span>{dataForRender.refNo}</EmailText>
              <ShowMessageToggle
                onClick={() => this.handleShowMessage(dataForRender.mailId)}
              >
                {this.state.loading ? <div style={{width: "16px", height: "16px", top: "-3px"}}><Loader size={16} thickness={2} opaque/></div> : <MailIcon/>}
                <span>{this.state.originalMsg ? "Hide original message" : "Show original message"}</span>
              </ShowMessageToggle>
            </EmailSubHeader>
            {/* <EmailText dark>{emailHeader}</EmailText> */}
            {/* {this.state.mailText && <EmailText style={{paddingTop: "30px"}} dangerouslySetInnerHTML={this.createMarkup(this.state.mailText)}></EmailText>} */}
            {
              this.state.originalMsg
              ? <OriginalMessageBody style={{padding: '0px', paddingTop: '8px'}} originalMsg={this.state.originalMsg}></OriginalMessageBody>
              : null
            }
          </EmailRow>
        }
      </Wrapper>
    );
  }
}

export default connect(null, { getSnpList })(ForSaleOffer);
