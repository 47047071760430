import React from "react";

const LeftBarIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_40691_8041)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.19341 4.25986H14.8276C15.799 4.25985 16.5826 4.25984 17.2171 4.31125C17.8704 4.36419 18.4443 4.47602 18.9752 4.74428C19.8185 5.17037 20.2444 5.60968 20.674 6.44596C20.9446 6.97244 21.0573 7.5415 21.1107 8.18936C21.1625 8.81858 21.1625 9.59559 21.1625 10.559V13.4795C21.1625 14.4429 21.1625 15.22 21.1107 15.8491C21.0573 16.497 20.9446 17.0661 20.674 17.5926C20.2444 18.4288 19.8185 18.8296 18.9752 19.2557C18.4443 19.5239 17.8704 19.6358 17.2171 19.6887C16.5826 19.7401 15.799 19.7401 14.8276 19.7401H9.1934C8.22194 19.7401 7.4384 19.7401 6.80389 19.6887C6.15058 19.6358 5.57674 19.5239 5.04584 19.2557C4.20253 18.8296 3.75557 18.4288 3.32589 17.5926C3.05538 17.0661 2.94261 16.497 2.88923 15.8491C2.83738 15.2199 2.83739 14.4429 2.8374 13.4795V10.559C2.83739 9.59561 2.83738 8.81859 2.88923 8.18936C2.94261 7.5415 3.05538 6.97244 3.32589 6.44596C3.75557 5.60968 4.20253 5.17037 5.04584 4.74428C5.57674 4.47602 6.15058 4.36419 6.80389 4.31125C7.43841 4.25984 8.22196 4.25985 9.19341 4.25986ZM11.2823 18.4863H14.7892C15.808 18.4863 16.5182 18.4856 17.0712 18.4408C17.6135 18.3969 17.9252 18.3149 18.1613 18.1957C18.6673 17.94 19.2813 17.5926 19.6041 16.7855C19.7244 16.5513 19.807 16.2423 19.8513 15.7044C19.8965 15.156 19.8972 14.4518 19.8972 13.4415V10.597C19.8972 9.58671 19.8965 8.88243 19.8513 8.33413C19.807 7.7962 19.7244 7.48714 19.6041 7.25305C19.3486 6.4542 18.6811 6.11703 18.1769 5.86231L18.1613 5.85446C17.9252 5.73518 17.6135 5.65325 17.0712 5.60931C16.5182 5.56451 15.808 5.56381 14.7892 5.56381H11.2823V18.4863ZM5.62812 8.71369C5.62812 8.19209 6.05452 7.76925 6.5805 7.76925H7.53288C8.05887 7.76925 8.48526 8.19209 8.48526 8.71369C8.48526 9.2353 8.05887 9.65814 7.53288 9.65814H6.5805C6.05452 9.65814 5.62812 9.2353 5.62812 8.71369ZM6.5805 11.0748C6.05452 11.0748 5.62812 11.4976 5.62812 12.0192C5.62812 12.5409 6.05452 12.9637 6.5805 12.9637H7.53288C8.05887 12.9637 8.48526 12.5409 8.48526 12.0192C8.48526 11.4976 8.05887 11.0748 7.53288 11.0748H6.5805Z"
          fill="#0F4778"
        />
      </g>
      {/* <defs>
        <clipPath id="clip0_40691_8041">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs> */}
    </svg>
  );
};
export default LeftBarIcon;
