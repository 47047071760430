exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ARua9{display:-ms-flexbox;display:flex}._2SQzA{-ms-flex-align:center;align-items:center}._2Q6MN{width:100%}._3_S-a{gap:16px}._3pi--{display:grid}.Ojb4Z{grid-template-columns:repeat(4, minmax(0, 1fr))}._1Synh{margin-left:16px}\n", ""]);

// Exports
exports.locals = {
	"flex": "ARua9",
	"items_center": "_2SQzA",
	"w_full": "_2Q6MN",
	"gap_16px": "_3_S-a",
	"grid": "_3pi--",
	"grid_cols_4": "Ojb4Z",
	"ml_16px": "_1Synh"
};