exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2QYMi{display:-ms-flexbox;display:flex;-ms-flex:column 1;flex:column 1;width:100%;-ms-flex-pack:start;justify-content:flex-start;margin-top:8px}._2QYMi>div{width:100%}._2QYMi form{width:100%}._2O8em{width:24px;height:24px;background:#fff;border:1px solid #285596;border-radius:50%;padding:2px;margin-right:12px;fill:#285596;color:#285596 !important}._26AYP{color:#285596;font-size:13px;font-weight:500;line-height:22px;margin:0px}._2m5w8{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:end;align-items:flex-end}._2m5w8 textarea{resize:none;min-height:140px}._2OBy6{width:100%}._35SoK{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;width:100%}._35SoK h5{line-height:14px;font-size:12px;text-transform:uppercase;font-weight:500;margin:0px}._3e0pu{color:#999999;font-size:11px;font-weight:400;margin:0px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._3e0pu ._2tVJL{text-transform:capitalize}._3e0pu ._1nngn{margin:0px 8px;height:14px;width:1px;background:#999999}._3e0pu span._3LFlR{margin-left:8px;color:#E34848;text-transform:capitalize}._3sTsm,._2CYTV{cursor:pointer;width:18px !important;height:18px !important}._3sTsm{margin-right:8px}._4RVuW{margin:0px;font-size:12px;color:#333;line-height:18px;font-weight:400;width:100%;font-family:'Roboto';white-space:pre-wrap;word-wrap:unset;margin-top:8px}._4RVuW._30iQD{width:100%;display:block;display:-webkit-box;-webkit-line-clamp:3;overflow:hidden;text-overflow:ellipsis}._3Pwmi{color:var(--text-green-dark);font-size:13px;font-weight:400;line-height:15px;cursor:pointer}.anonymous ._26AYP,.anonymous ._2O8em{display:none !important}\n", ""]);

// Exports
exports.locals = {
	"additional_desc": "_2QYMi",
	"plus_sign": "_2O8em",
	"add_new": "_26AYP",
	"text_area": "_2m5w8",
	"add_desc": "_2OBy6",
	"label": "_35SoK",
	"last_update": "_3e0pu",
	"name": "_2tVJL",
	"divider": "_1nngn",
	"del": "_3LFlR",
	"edit": "_3sTsm",
	"delete": "_2CYTV",
	"description": "_4RVuW",
	"truncate": "_30iQD",
	"green": "_3Pwmi"
};