/* eslint-disable react/prefer-es6-class */
import PropTypes from 'prop-types';

import React from 'react';
import Formsy from 'formsy-react';
import DatePicker from './DatePicker';
import { setMuiComponentAndMaybeFocus } from 'formsy-material-ui/lib/utils';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import utils from 'formsy-react/lib/utils';
import { isInputWithError } from '../../core/utils';
const {setValue, ...mixin} =  Formsy.Mixin;
import createReactClass from 'create-react-class';

const textFieldStyle = { fontSize:'13px'};
const FormsyDate = createReactClass({
  propTypes: {
    defaultDate: PropTypes.object,
    startDate: PropTypes.object,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    requiredError: PropTypes.string,
    validationError: PropTypes.string,
    validationErrors: PropTypes.object,
    validations: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    value: PropTypes.object,
  },

  mixins: [{
    ...mixin,
    componentDidUpdate(prevProps) {

      const newValue = this.props.value instanceof Date && new Date(this.props.value);
      const oldValue = prevProps.value instanceof Date && new Date(prevProps.value);
      // If the value passed has changed, set it. If value is not passed it will
      // internally update, and this will never run
      if ((newValue && oldValue && newValue.getTime() !== oldValue.getTime()) || !utils.isSame(this.props.value, prevProps.value)) {
        this.setValue(this.props.value);
      }

      // If validations or required is changed, run a new validation
      if (!utils.isSame(this.props.validations, prevProps.validations) || !utils.isSame(this.props.required, prevProps.required)) {
        this.context.formsy.validate(this);
      }
    }
  }],

  componentDidMount() {
    const { defaultDate } = this.props;
    const value = this.getValue();
    if (typeof value === 'undefined' && typeof defaultDate !== 'undefined') {
      this.setValue(defaultDate);
    }
  },

  setValue: function (value) {
    if(value && !(value instanceof Date)) {
      value = new Date(value);
    }
    this.setState({
      _value: value,
      _isPristine: false
    }, function () {
      this.context.formsy.validate(this);
      //this.props._validate(this);
    }.bind(this));
  },

  handleChange(event, value) {
    this.setValue(value);
    if (this.props.onChange) {
      if(event) this.props.onChange(event, value);
      else this.props.onChange({target:{name:this.props.name,value:value}},value)
    }
  },

  setMuiComponentAndMaybeFocus: setMuiComponentAndMaybeFocus,
  onShow() {
    if (this.props.startDate && !this.getValue()) {
      setTimeout(() => {
        this.muiComponent.refs.dialogWindow.refs.calendar.setDisplayDate(
          this.props.startDate,
        );
      }, 0);
    }

    this.props.onShow && this.props.onShow();
  },

  clear(e) {
    e.stopPropagation();
    e.preventDefault();
    this.handleChange(e, undefined);
  },

  render() {
    const {
      defaultDate, // eslint-disable-line no-unused-vars
      validations, // eslint-disable-line no-unused-vars
      validationErrors, // eslint-disable-line no-unused-vars
      validationError, // eslint-disable-line no-unused-vars
      requiredError,
      startDate,
      previousValue,
      className = '',
      disabled=false,
      ...rest
    } = this.props;
    const { isValid } = this;
    let { className: cn, errorText } = isInputWithError(this);
    const v = this.getValue();
    cn = 'sn-date ' + cn;
    if (
      previousValue !== void 0 &&
      v &&
      previousValue.getTime() !== v.getTime()
    ) {
      cn += ' dirty';
    }
    const picker = (<DatePicker
      fullWidth={this.props.fullWidth}
      disabled={disabled || this.isFormDisabled()}
      textFieldStyle={textFieldStyle}
      {...rest}
      className={cn}
      errorText={errorText}
      onChange={this.handleChange}
      onShow={this.onShow}
      ref={this.setMuiComponentAndMaybeFocus}
      value={v}
      data-valid={isValid()}
    />);
    if (!this.props.clearable) {
      return picker;
    }
    return <span className="clearable_datepicker">{picker} {v && <CloseIcon className={"clear_icon"} onClick={this.clear} /> }</span>;
  },
});

export default FormsyDate;
