import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cx from 'classnames';
import s from './Request.scss';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import ALtNextPort from './ALtNextPort';
import RaisedButton from '../../Common/RaisedButton';
import { getDefaultPorts } from '../utils';
import {SubForm} from "../../Common/ExtendedForm";
import formDataToObject from 'form-data-to-object';
import {debounceWithoutFirstCall} from "../../../core/utils";
import {IconButton, IconMenu, MenuItem} from "material-ui";
import NavigationMoreVert from "material-ui/svg-icons/navigation/more-vert";
import NarrowMenuItem from "../../NewInputs/NarrowMenuItem";
import {v4} from "uuid";
import {NarrowSelect} from "../../NewInputs/NarrowSelect";
import NarrowFormsyText from "../../NewInputs/NarrowFormsyText";
import WithTooltip from '../../Common/WithTooltip';
import PortTooltip from './PortTooltip';

class Ports extends PureComponent {

  static contextTypes = {
    onChange: PropTypes.func,
    selector: PropTypes.func,
  }

  static defaultProps = {
    onChange: () => undefined,
    withSubmitButton: true,
    incoterms: {},
    incotermsEnabled: true,
  }
  state = {};
  onChange = this.context.onChange("ports");

  onIncotermsChange = (e, value) =>  {
    const { incoterms = {} } = this.props.ports;
    this.onChange('incoterms')({ ...incoterms, kind: value });
  }
  onIncoPlaceChange = (e, value) =>  {
    const { incoterms = {} } = this.props.ports;
    this.onChange('incoterms')({ ...incoterms, place: value });
  }

  handleAddPort=(collectionName = 'loadingPorts', type = 'next', index) => {
    let ports = this.props.ports[collectionName];

    let prevPort = {};
    for (let i = ports.length; i >= 0; i--) {
      if (ports[i]) {
        if (ports[i].type && !prevPort.type) {
          prevPort = ports[i];
        }
        ports[i].opened = false;
      }
    }
    const i = Math.max(...ports.map(p => p.i), ports.length, 0) + 1;
    const newPort = { _id: v4(), type, i: i, opened: true, readinessDate: prevPort.readinessDate, cancellingDate: prevPort.cancellingDate }
    ports = [...ports];
    if (typeof index !== 'number') {
      index = ports.length - 1;
    }
    ports.splice(index + 1, 0, newPort);
    this.onChange(collectionName)(ports);

  };
  handleRemovePort =(collectionName = 'loadingPorts', index) => {
    let ports = this.props.ports[collectionName];
    ports = [...ports];
    const port = ports[index];
    let nextPort = ports[index + 1];
    if (port.type === 'next') {
      if (nextPort && nextPort.type === 'alt') {
          nextPort = { ...nextPort, type: 'next' };
          ports[index + 1] = nextPort;
      }
    }
    ports[index] = undefined;
    ports = ports.filter(p => !!p);
    this.onChange(collectionName)(ports);
  };

  handleAddNextLoadingPort = this.handleAddPort.bind(this, 'loadingPorts', 'next');
  handleAddNextUnloadingPort = this.handleAddPort.bind(this, 'unloadingPorts', 'next');
  handleAddAltLoadingPort = this.handleAddPort.bind(this, 'loadingPorts', 'alt');
  handleAddAltUnloadingPort = this.handleAddPort.bind(this, 'unloadingPorts', 'alt');
  handleRemoveLoadingPort = this.handleRemovePort.bind(this, 'loadingPorts');
  handleRemoveUnloadingPort = this.handleRemovePort.bind(this, 'unloadingPorts');


  handleTogglePort = (collectionName = 'loadingPorts', index) => {
    let ports = this.props.ports[collectionName];
    ports = [...ports];
    ports[index] = { ...ports[index], opened: !ports[index].opened };
    this.onChange(collectionName)(ports);
  };

  handleToggleLoadingPorts = this.handleTogglePort.bind(this, 'loadingPorts');
  handleToggleUnloadingPorts = this.handleTogglePort.bind(this, 'unloadingPorts');

  loadingTip = ({ handleClose }) => (
    <PortTooltip handleAddAltPort={this.handleAddAltLoadingPort} handleAddNextPort={this.handleAddNextLoadingPort} handleClose={handleClose} />
  )
  unloadingTip = ({ handleClose }) => (
    <PortTooltip handleAddAltPort={this.handleAddAltUnloadingPort} handleAddNextPort={this.handleAddNextUnloadingPort} handleClose={handleClose} />
  )

  render() {
    const ports = this.props.ports;
    const loadings = ports.loadingPorts.filter(p => !!p);
    const dischargings = ports.unloadingPorts.filter(p => !!p);
    const { incoterms = {} } = ports;
    return (<div data-id="ports" id="ports_wrapper" className={this.props.insideOffer && s.inside_offer}>
      <div className={cx(s.pol_pod_wrapper)}>
        {this.props.incotermsEnabled ? <div className={cx(s.label_for_form, s.incoterms_label)}>INCOTERMS</div> : null}
        <div className={cx("row", s.incoterms_row)}>
          {this.props.incotermsEnabled ?
            ( <div className="col-6">
              <IncotermsSelect value={incoterms.kind} onChange={this.onIncotermsChange} />
            </div>)
            : null}
          {
            this.props.incotermsEnabled && incoterms.kind ?
              (<div className="col-6">
              <NarrowFormsyText
                name="incoterms.place"
                fullWidth
                requiredError="Define port or place"
                onChange={this.onIncoPlaceChange}
                value={incoterms.place || ""}
                floatingLabelText="Port or place"
                validationErrors={{
                  isDefaultRequiredValue: "Define port or place",
                }}
              />
            </div>) : null
          }
        </div>
        </div>
      <div className={cx(s.pol_pod_wrapper)}>
        <div className={cx(s.label_for_form)}>PORT OF LOADING</div>
        <div>
        {loadings.map((p, i) => (
          <div className={cx("row", s.pol_pod_row)}>
          <div data-portindex={i} data-portcollection="loadingPorts" className={cx('col-sm-12', s.wrapper_accordion, s[`wrapper_accordion_pol_${p.type}`], loadings.length === 1 && s.wrapper_accordion_solo)}>
            <ALtNextPort key={p._id} i={i} isPol port={p} showDates prevPort={loadings[i - 1]} nextPort={loadings[i + 1]} namePrefix="loadingPorts" onToggle={this.handleToggleLoadingPorts} user={this.props.user} />
            <IconMenu
              key={i}
              className={s.menu_wrapper}
              targetOrigin={{ vertical: 'top', horizontal: 'left'}}
              useLayerForClickAway
              iconButtonElement={<IconButton><NavigationMoreVert/></IconButton>} >
              <NarrowMenuItem primaryText="Alternative port/place" onClick={this.handleAddAltLoadingPort.bind(this, i)} />
              <NarrowMenuItem primaryText="Next port/place" onClick={this.handleAddNextLoadingPort.bind(this, i)} />
              <NarrowMenuItem onClick={this.handleRemoveLoadingPort.bind(this, i)} primaryText="Delete" />
            </IconMenu>
          </div></div>))}
          <div className="row">
            <div className={cx('col-sm-12', s.wrapper_add)} >
              <WithTooltip
                showOnEventName="onClick"
                tip={this.loadingTip}
              >
                <span className={s.clickable_port_button}>
                  <span className={s.add_port} />
                  <p>Add port/place of loading</p>
                </span>
              </WithTooltip>
            </div>
          </div>
        </div>
      </div>
      <div className={cx(s.pol_pod_wrapper)}>
      <div className={cx(s.label_for_form)} >PORT OF DISCHARGING</div>
      <div>
      {dischargings.map((p, i) => (
        <div className={cx("row", s.pol_pod_row)}>
        <div data-portindex={i} data-portcollection="unloadingPorts" className={cx('col-sm-12', s.wrapper_accordion, s[`wrapper_accordion_pod_${p.type}`], dischargings.length === 1 && s.wrapper_accordion_solo)}>
          <ALtNextPort key={p._id} i={i} isPol={false} port={p} prevPort={dischargings[i - 1]} nextPort={dischargings[i + 1]} namePrefix="unloadingPorts" onRemove={this.handleRemoveUnloadingPort.bind(this, p.i)} onToggle={this.handleToggleUnloadingPorts} user={this.props.user} />
          <IconMenu
            key={i}
            className={s.menu_wrapper}
            targetOrigin={{ vertical: 'top', horizontal: 'left'}}
            iconButtonElement={<IconButton><NavigationMoreVert/></IconButton>} >
            <NarrowMenuItem primaryText="Alternative port/place" onClick={this.handleAddAltUnloadingPort.bind(this, i)} />
            <NarrowMenuItem primaryText="Next port/place" onClick={this.handleAddNextUnloadingPort.bind(this, i)} />
            <NarrowMenuItem onClick={this.handleRemoveUnloadingPort.bind(this, i)} primaryText="Delete" />
          </IconMenu>
        </div></div>))}
        <div className="row">
          <div className={cx('col-sm-12', s.wrapper_add)}>
            <WithTooltip
              showOnEventName="onClick"
              tip={this.unloadingTip}
            >
              <span className={s.clickable_port_button}>
                <span className={s.add_port} />
                <p>Add port/place of discharging</p>
              </span>
            </WithTooltip>
          </div>
        </div>
      {this.props.withSubmitButton ?
        <div style={{ margin: '12px 0' }}>
          <RaisedButton
            label="NEXT"
            disableTouchRipple
            disableFocusRipple
            primary
            type="submit"
            style={{ margin: '13px 0 6px 0' }}
          />
        </div>
        : null}

      </div>
      </div>
    </div>);
  }
}
const StyledPorts = withStyles(s)(Ports);
export default StyledPorts;

export class PortsSubForm extends PureComponent {

  static contextTypes = {
    onChange: PropTypes.func,
    selector: PropTypes.func,
  }

  submit = () => {
    this.form.submit();
  }

  isValid = () => {
    return this.form.state.isValid;
  }

  refForm = (form) => {
    this.form = form;
  }

  getPorts = () => {
    return this.props.ports;
  }

  onInvalidSubmit = () => {
      const wrapper = document.getElementById('ports_wrapper'); //TODO return with better solution
      const invalidNodes = wrapper.querySelectorAll('input[data-valid=false]');
      if (!invalidNodes || !invalidNodes.length) {
        return;
      }
      const ports = { ...this.props.ports };
      ports.loadingPorts = [...ports.loadingPorts ];
      ports.unloadingPorts = [...ports.unloadingPorts];
      for (let i = 0; i < invalidNodes.length; i++) {
        let node = invalidNodes[i];
        while (node = node.parentNode) {
          if (node.getAttribute && node.getAttribute('data-portindex')) {
            const index = parseInt(node.getAttribute('data-portindex'), 10);
            const collectionName = node.getAttribute('data-portcollection');
            ports[collectionName][index].opened = true;
            this.context.onChange('ports')(collectionName)(ports[collectionName]);
            break;
          }
        }
      }
  }

  render() {
    return (
      <SubForm onInvalidSubmit={this.onInvalidSubmit} ref={this.refForm} >
        <Ports ports={this.props.ports} withSubmitButton={false} insideOffer={this.props.insideOffer}  incotermsEnabled={this.props.incotermsEnabled}/>
      </SubForm>
    );
  }
}

function IncotermsSelect(props){
  return (<NarrowSelect name={'incoterms.kind'} floatingLabelText="INCOTERMS" fullWidth {...props} >
    <NarrowMenuItem primaryText="" value="" />
    <NarrowMenuItem primaryText="EXW" value="EXW" />
    <NarrowMenuItem primaryText="FCA" value="FCA" />
    <NarrowMenuItem primaryText="FAS" value="FAS" />
    <NarrowMenuItem primaryText="FOB" value="FOB" />
    <NarrowMenuItem primaryText="CFR" value="CFR" />
    <NarrowMenuItem primaryText="CIF" value="CIF" />
    <NarrowMenuItem primaryText="CIP" value="CIP" />
    <NarrowMenuItem primaryText="CPT" value="CPT" />
    <NarrowMenuItem primaryText="DPU" value="DPU" />
    <NarrowMenuItem primaryText="DAP" value="DAP" />
    <NarrowMenuItem primaryText="DDP" value="DDP" />
  </NarrowSelect>)
}
