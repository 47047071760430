import Api from './api';
import {convertToSameTimeZ} from './api'
export default class Orders extends Api {

  static findRelevantShips(_id, page = 1, filters={}, isHidden, sort) {
    if(!_id) {
      return;
    }
    return this.fetch(`/timecharters/relevant/vessels?tcOrderId=${_id}`, {
      method: 'POST',
      body: JSON.stringify({
        page,
        filters,
        sort,
        hidden: !!isHidden,
      }),
    }, '/api/v2', undefined, true)
  }

  static createTCOrder(payload) {
    convertToSameTimeZ(payload);
    return this.fetch(`/timecharters/orders/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    }, '/api/v2')
  }

  static saveAccountName(orderId, data) {
    return this.fetch(`/timecharters/orders/${orderId}/account`, {
      method: 'PUT',
      body: JSON.stringify(data),
    }, "/api/v2");
  }

  static getOriginalMail(_id){
    return this.fetch(`/timecharters/orders/fetchOriginalMail/${_id}`, { method: "GET" }, '/api/v2');
  }

  static getTCOrders(payload) {
    convertToSameTimeZ(payload);
    return this.fetch(`/timecharters/orders/`, {
      method: 'POST',
      body: JSON.stringify(payload),
    }, '/api/v2', undefined, true)
  }

  static getById(id, token) {
    return this.fetch(`/timecharters/orders/${id}`, {
      method: 'GET',
      token
    }, '/api/v2')
  }

  static getOrderFilters() {
    return this.fetch('/preferences/byType/tcOrdersFilter', {
      method: 'GET',
    });
  }
  static async saveOrderFilter(name, body) {
    return this.fetch(`/preferences/tcOrdersFilter/${name}`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  static async updateOrderFilter(filterId, formData) {
    return this.fetch(`/preferences/${filterId}`, {
      method: 'PUT',
      body: JSON.stringify(formData),
    });
  }

  static async deleteOrderFilter(filterId) {
    return this.fetch(`/preferences/${filterId}`, {
      method: 'DELETE'
    });
  }
}
