exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1snmI{position:relative}._1snmI.Jqngo .xWHLo svg path{fill:var(--text-links)}._1snmI.Jqngo .xWHLo svg:hover path{fill:var(--bg-blue)}._1snmI.Jqngo ._3Fqes{background:#ffffff;-webkit-box-shadow:1px 1px 15px rgba(0,0,0,0.3);box-shadow:1px 1px 15px rgba(0,0,0,0.3)}._1snmI.Jqngo ._3Fqes p{color:var(--text-medium)}._1snmI .xWHLo{display:inline-block;width:16px;height:16px;margin-left:8px;cursor:pointer}._1snmI .xWHLo svg{width:100%;height:100%}._1snmI .xWHLo svg path{fill:var(--stroke-light-gray1)}._1snmI .xWHLo svg:hover path{fill:var(--text-light)}._1snmI ._3Fqes{display:none;top:28px;left:-38px;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:68px;height:36px}._1snmI ._3Fqes:before{right:6px;left:auto}._1snmI:hover ._3Fqes{display:-ms-flexbox;display:flex}.PmEpp,._1snmI ._3Fqes{position:absolute;z-index:998;background:var(--bg-table-dark);border-radius:4px;cursor:default}.PmEpp p,._1snmI ._3Fqes p{margin:0;font-size:12px;font-weight:400;line-height:20px;color:#FFFFFF}.Dm-GV{background:#ffffff}.Dm-GV p{color:var(--text-medium)}\n", ""]);

// Exports
exports.locals = {
	"wrapper_toggle": "_1snmI",
	"wrapper_toggle_dark": "Jqngo",
	"toggle_icon_moon": "xWHLo",
	"tooltip_toggle": "_3Fqes",
	"tooltip": "PmEpp",
	"dark_tooltip": "Dm-GV"
};