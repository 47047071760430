exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2-mkr{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex:1 1;flex:1 1;margin:0;-webkit-transition:height 300ms;transition:height 300ms;height:100%}._2-mkr.FgaC6{height:100%}.dz6e3{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.dz6e3.K1Bbg{margin:26px 0 22px}@media (max-width: 991px) and (min-width: 769px){.dz6e3.K1Bbg{margin-top:22px;margin-bottom:20px}}@media (max-width: 600px){.dz6e3.K1Bbg{margin-top:22px;margin-bottom:19px}}.dz6e3 svg{fill:#999 !important}@media (max-width: 991px) and (min-width: 769px){.dz6e3 svg{height:20px !important}}@media (max-width: 600px){.dz6e3 svg{height:20px !important}}.dz6e3 ._rl-p{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:start;align-items:flex-start;margin:0 0 0 10px}.dz6e3 ._rl-p p{margin:0;font-size:12px;line-height:16px;color:var(--text-dark)}.dz6e3 ._rl-p p ._3id0P{font-weight:600}.dz6e3 ._rl-p p:last-child{display:-ms-flexbox;display:flex}@media (max-width: 991px) and (min-width: 769px){.dz6e3 ._rl-p p:last-child{-ms-flex-direction:column;flex-direction:column}}@media (max-width: 600px){.dz6e3 ._rl-p p:last-child{-ms-flex-direction:column;flex-direction:column}}._3JTi4{-ms-flex:1 1;flex:1 1;margin-bottom:20px}._3JTi4._2paiC{width:100%;height:320px;margin:0}@media (max-width: 991px) and (min-width: 769px){._3JTi4._2paiC{height:240px}}@media (max-width: 600px){._3JTi4._2paiC{height:240px}}._3JTi4._1yKJz{margin-bottom:0}\n", ""]);

// Exports
exports.locals = {
	"wrapper_map": "_2-mkr",
	"map_main_deck": "FgaC6",
	"position_info": "dz6e3",
	"position_info_public_vessel": "K1Bbg",
	"position": "_rl-p",
	"bold": "_3id0P",
	"map": "_3JTi4",
	"map_public_vessel": "_2paiC",
	"map_ports": "_1yKJz"
};