exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".U9sTd{display:-ms-flexbox;display:flex}._2cUm2{-ms-flex-align:center;align-items:center}._3-7xy{margin-right:18px}.rDO7y{width:100%}._3mUQn{gap:16px}._1fIkc{font-size:12px}\n", ""]);

// Exports
exports.locals = {
	"flex": "U9sTd",
	"items_center": "_2cUm2",
	"mr_18px": "_3-7xy",
	"w_full": "rDO7y",
	"gap_16px": "_3mUQn",
	"text_xs": "_1fIkc"
};