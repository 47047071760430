exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".fflI7{white-space:nowrap;font-size:12px}.fflI7 label{color:var(--text-light) !important}._1Goyp{display:-ms-flexbox;display:flex}._2Yz1x{-ms-flex-align:center;align-items:center}._2Mw2j{margin-right:18px}._2A2pi{width:100%}._1tICq{gap:16px}\n", ""]);

// Exports
exports.locals = {
	"checkbox": "fflI7",
	"flex": "_1Goyp",
	"items_center": "_2Yz1x",
	"mr_18px": "_2Mw2j",
	"w_full": "_2A2pi",
	"gap_16px": "_1tICq"
};