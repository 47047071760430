exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2gQi7 ._3OUNo{display:-ms-flexbox;display:flex}._2gQi7 ._3OUNo._19JLt{-ms-flex-pack:end;justify-content:flex-end}._2gQi7 ._3OUNo._2UcVX{-ms-flex-pack:start;justify-content:flex-start}._2gQi7 .FYG_C{display:-ms-flexbox;display:flex;cursor:pointer;-ms-flex-align:center;align-items:center;width:116px;height:24px;-webkit-box-sizing:border-box;box-sizing:border-box;font-size:13px;font-weight:500;line-height:24px;margin:8px 0;color:var(--text-links);position:relative}._2gQi7 .FYG_C._3W4TX{pointer-events:none;cursor:initial;opacity:0.7;color:var(--gray-color)}._2gQi7 .FYG_C._3W4TX ._2Lzc2{fill:var(--gray-color) !important}._2gQi7 .FYG_C>input{position:absolute;top:0;left:0;width:100%;visibility:hidden;height:100%;opacity:0}._2gQi7 ._2Lzc2{fill:var(--text-links) !important;margin-right:8px;-webkit-transform:rotate(45deg);transform:rotate(45deg);width:20px !important;height:20px !important}._2gQi7 ._32Xxk{fill:var(--text-medium) !important;margin-right:8px}._2SNGo{font-size:13px;font-weight:500;line-height:22px;margin-top:16px;margin-bottom:16px}._2SNGo ._2o5Mo{background:#FFFDD6;color:var(--text-warning);border:1px solid rgba(227,72,72,0.3);border-radius:4px;padding:8px 12px 8px 12px;-webkit-box-sizing:border-box;box-sizing:border-box}._2SNGo ._2o5Mo>div:nth-child(2){border-top:1px solid rgba(227,72,72,0.3);padding-top:8px;margin-top:8px;color:var(--text-dark333)}._2SNGo ._2o5Mo+._2o5Mo{margin-top:4px}\n", ""]);

// Exports
exports.locals = {
	"attachments": "_2gQi7",
	"attach_file_wrapper": "_3OUNo",
	"right": "_19JLt",
	"left": "_2UcVX",
	"attach_file": "FYG_C",
	"disabled": "_3W4TX",
	"attach_icon": "_2Lzc2",
	"attach_icon_second": "_32Xxk",
	"errors_block": "_2SNGo",
	"error": "_2o5Mo"
};