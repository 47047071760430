exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1Wflt{width:calc(100% - 50px);height:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._1Wflt .QRAXT{position:relative;width:100%;height:calc(100% - 48px);overflow:auto;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}.page_root_open ._1Wflt{width:calc(100% - 160px)}.page_root_open ._1Wflt ._1Wflt{width:100%}@media (min-width: 1900px){.page_root_open ._1Wflt{width:calc(100% - 170px)}.page_root_open ._1Wflt ._1Wflt{width:100%}}._1Wflt ._1Wflt{width:100%}._5ABU4{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-webkit-box-sizing:border-box;box-sizing:border-box;width:100%;height:100%}._13n2F{width:100%;height:100%;top:0;right:0;position:fixed;z-index:1001;background-color:white}._13n2F ._2pFlh{position:absolute;top:0;right:0;cursor:pointer;margin:12px}.tKQBR{z-index:10 !important;width:calc(50% - 25px) !important;-webkit-transition:0.3s;transition:0.3s;top:48px;padding-bottom:50px}.page_root_open .tKQBR{width:calc(50vw - 72px) !important}.RkbMs{left:0}._3irNc{-webkit-box-shadow:inset 6px 0px 18px rgba(128,128,128,0.14);box-shadow:inset 6px 0px 18px rgba(128,128,128,0.14)}\n", ""]);

// Exports
exports.locals = {
	"root": "_1Wflt",
	"content": "QRAXT",
	"page_root": "_5ABU4",
	"overlay": "_13n2F",
	"close_icon": "_2pFlh",
	"overlay_half": "tKQBR",
	"left": "RkbMs",
	"right": "_3irNc"
};