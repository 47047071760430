import React from 'react';
import styled from 'styled-components';
import WithTooltip from '../WithTooltip';
import t from './../../Monitor/TableNew.scss';
import cx from 'classnames';

const TagsTooltip = styled(({ tags, getName, className = '', ...rest }) => {
  const tooltip = tags.map(c => getName(c)).join('<span class="separator"> and </span>');
  return (
    <div draggable="false" className={cx(t.generic_table_tooltip, t.new, t.cargoes, className)} {...rest}>
      <div className={cx(t.top_loading, "top_loading", "tags_exchange")}>
       <div>
          <p>
              <span dangerouslySetInnerHTML={{ __html: tooltip }} className={t.text_green}/>
          </p>
       </div>
      </div>
    </div>
  );
})`
.separator {
  text-transform: lowercase;
  color: ${props => props.theme.name === 'dark_theme' ? '#000' : '#fff'};
}
.tags_exchange {
  &>div {
    p {
      span {
        &:first-child {
          span {
            &:first-child {
              color: var(--text-links-light);
            }
            &:last-child {
              display: inline-block;
            }
          }
        }
      }

    }
  }
}`;

const TagsView = styled(({ tags, className, showMore = 3, horizontalPosition = 'left', theme, isDark }) => {
  const moreTags = tags?.length - showMore;
  return (<div className={className}>
    {
      tags?.length
        ? (
          <WithTooltip dark={isDark} horizontalPosition={horizontalPosition} tip={<TagsTooltip theme={theme} getName={tag => tag ? `<span class="tag_name">#${tag}</span>` : ""} tags={tags} />}>
            <div className={'tag'}>
              <span>#{tags.slice(0, showMore).join(", #")}</span>&nbsp;
              {moreTags > 0 ? <span className={"more_tags"}> +{moreTags}</span> : null }
            </div>
          </WithTooltip>
        )
        : <span className={"tags_empty"}>No tags</span>
    }
  </div>);
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 400;
  overflow: hidden;
  & > div {
    display: flex;
    width: 100%;
    max-width: 100%;
  }
  .tag {
    width: 100%;

    display: flex;
    span:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

    }
  }
  .more_tags {
    color: var(--text-links-light);
  }

  .tags_empty {
    color: ${props => props.theme.name === 'dark_theme' ? '#bec3c9' : 'var(--text-light)'};
  }
  .tag_name {
    display: inline-block !important;
    color: ${props => props.theme.name === 'dark_theme' ? 'var(--text-medium)' : 'var(--text-links-light)'} !important;
  }
`;


export default TagsView;
