import React from "react";

const LogoIconMini = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_40050_20349)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.532 10.9441L16.4156 4.4714L15.0034 2L9.58984 10.9441H12.532Z"
          fill="#75C422"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.8297 17.0638C21.8297 14.357 19.476 12.0033 16.6515 12.0033H9.00197L7.47206 14.4747H16.6515C18.0638 14.4747 19.2406 15.6516 19.2406 16.9461C19.2406 18.3583 18.0638 19.4175 16.6515 19.4175H4.41223L3 21.8889H17.24C19.8291 21.7712 21.8297 19.6529 21.8297 17.0638Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_40050_20349">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default LogoIconMini;
