import React from "react";
import pure from "recompose/pure";
import SvgIcon from "material-ui/SvgIcon";

let ChatMailIcon = ({ fill = "#76818D", opacity = 1, ...props }) => (
  <span style={{ opacity: opacity }}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4713 2.47583H2.96493C2.24262 2.47583 1.6582 2.97447 1.6582 3.58393V13.9672L4.27754 11.3406H13.4713C14.1936 11.3406 14.7846 10.8419 14.7846 10.2325V3.58393C14.7846 2.97447 14.1936 2.47583 13.4713 2.47583ZM8.21821 7.46227L13.4714 4.69203V3.58393L8.21821 6.35417L2.96502 3.58393V4.69203L8.21821 7.46227Z"
        fill={fill}
      />
      <path
        d="M16.2896 6.58008H17.6576C18.0338 6.58008 18.3416 6.88788 18.3416 7.26408V17.5242L15.6055 14.7882H8.08148C7.70527 14.7882 7.39746 14.4804 7.39746 14.1042V12.7362H16.2896V6.58008Z"
        fill={fill}
      />
    </svg>
  </span>
);

ChatMailIcon = pure(ChatMailIcon);
ChatMailIcon.displayName = "ChatMailIcon";
ChatMailIcon.muiName = "SvgIcon";

export default ChatMailIcon;
