import React, { Component } from 'react';
import ChatMailIcon from '../Icons/ChatMailIcon';
import EmptyList from '../Common/EmptyList';
import RaisedButton from '../Common/RaisedButton';
import Link from '../Link/Link';

export default function MailsNotConnected({isBgDark}){
  return (
  <EmptyList isBgDark={isBgDark} Icon={ChatMailIcon} title="No e-mails yet"
  description={[`To use this Service, please connect your mail.
  Shipnext is a Microsoft Partner solution, and provides an email service that can easily pair with Outlook, Gmail, Sedna and other email services.`,
  `To set up E-mail tagging, please go to Settings/Tags. This will help you and your team use Shipnext algorithm and AI to automate tagging.`
]} button={<div style={{display:'flex',gap:'8px'}}>
  <RaisedButton
  containerElement={<Link to={`/settings/tags`} />}
  styles="xs"
  style={{background:'none'}}
  buttonStyle={{
    background: "#4380C7",
    color: "#fff",
  }}
  primary
  label="tags"
/>
<RaisedButton
  style={{background:'none'}}
  containerElement={<Link to={`/settings/connect`} />}
  styles="xs"
  buttonStyle={{
    background: "#4380C7",
    color: "#fff",
  }}
  primary
  label="connect"
/></div>} />
)
}