import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import cx from "classnames";
import styled from "styled-components";

const style = { display: "inline-block" };

const Tooltip = styled.div`
  > div {
    background: ${props =>
      props.theme.name === "dark_theme"
        ? "#FFF"
        : "var(--bg-table-dark)"} !important;
    color: ${props =>
      props.theme.name !== "dark_theme" ? "#FFF" : "#333"} !important;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 12px;
    white-space: normal;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    text-transform: initial;
    margin: 0px;
    .top_loading {
      border-bottom: 1px solid
        ${props =>
          props.theme.name !== "dark_theme"
            ? "rgba(#FFF, 0.2)"
            : "var(--stroke-light-gray2)"} !important;
      & > div {
        p {
          &:first-child {
            span {
              &:first-child {
                color: ${props =>
                  props.theme.name !== "dark_theme"
                    ? "var(--text-links-light)"
                    : "var(--text-links)"} !important;
              }
              .tag_name {
                color: ${props =>
                  props.theme.name === "dark_theme"
                    ? "var(--text-medium)"
                    : "var(--text-links-light)"} !important;
              }
            }
          }
          &:last-child {
            span {
              &:first-child {
                color: ${props =>
                  props.theme.name !== "dark_theme" ? "#FFF" : "#000"};
              }
              &:last-child {
                color: ${props =>
                  props.theme.name !== "dark_theme"
                    ? "rgba(#FFF, 0.7)"
                    : "var(--text-medium)"} !important;
              }
            }
          }
        }
      }
    }
    .bottom_discharging {
      & > div {
        p {
          &:first-child {
            span {
              &:first-child {
                color: ${props =>
                  props.theme.name !== "dark_theme"
                    ? "var(--text-green-light)"
                    : "var(--text-green-dark)"} !important;
              }
            }
          }
          &:last-child {
            span {
              color: ${props =>
                props.theme.name !== "dark_theme"
                  ? "rgba(#FFF, 0.7)"
                  : "var(--text-medium)"} !important;
            }
          }
        }
      }
    }
    &.info_position {
        & > div {
          a {
            color: ${props =>
              props.theme.name === "dark_theme" && "var(--text-links)"};
          }
          p {
            color: ${props =>
              props.theme.name === "dark_theme" && "var(--text-medium)"};
          }
        }
        .percent {
          color: ${props =>
              props.theme.name === "dark_theme" && "var(--text-medium)"};
          &:before,
          &:after {
            border: 2px solid ${props =>
              props.theme.name === "dark_theme" && "var(--text-medium)"};
          }
        }
      }
  }
  p {
    color: ${props =>
      props.theme.name !== "dark_theme" ? "#FFF" : "#333"} !important;
  }

  &.top_arrow_tooltip {
    &:before {
      border-bottom: 4px solid
        ${props =>
          props.theme.name !== "dark_theme" ? "var(--bg-table-dark)" : "#FFF"};
    }
  }
  &.bottom_arrow_tooltip {
    &:before {
      border-bottom: 4px solid transparent;
      border-top: 4px solid
        ${props =>
          props.theme.name !== "dark_theme" ? "var(--bg-table-dark)" : "#FFF"};
    }
  }
`;

export class WithTooltip extends PureComponent {
  static propTypes = {
    showOnEventName: PropTypes.oneOf(["onMouseOver", "onClick"]),
    offset: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
    horizontalPosition: PropTypes.oneOf(["left", "right"]),
    closeOnChildrenClick: PropTypes.bool,
    positionInsideTransform: PropTypes.bool,
    bottomArrow: PropTypes.bool,
    position: PropTypes.oneOf(["absolute", "fixed"]),
    noArrow: PropTypes.bool,
    tooltipStyle: PropTypes.object,
    fixedPosition: PropTypes.bool
  };

  static defaultProps = {
    showOnEventName: "onMouseOver",
    offset: { x: 0, y: 0 },
    horizontalPosition: "right",
    closeOnChildrenClick: false,
    positionInsideTransform: false,
    bottomArrow: false,
    endAnchor: false,
    position: "fixed",
    noArrow: false,
    fixedPosition: false
  };

  constructor(props) {
    super(props);
    this.state = {
      arrow: "up",
      className: "",
      styleTop: null,
      coords: null,
      anchor: null
    };
    this.tooltipContainer = null;
    this.spanTooltip = React.createRef();
  }

  componentDidMount() {
    this.tooltipContainer = document.createElement("div");
    document.body.appendChild(this.tooltipContainer);
  }

  componentWillUnmount() {
    this.removeListeners();
    if (this.tooltipContainer) {
      document.body.removeChild(this.tooltipContainer);
      this.tooltipContainer = null;
    }
  }

  removeListeners = () => {
    window.removeEventListener("scroll", this.handleWindowScroll);
    window.removeEventListener("resize", this.handleWindowScroll);
  };

  handleClose = () => {
    this.setState({ coords: null, anchor: null });
    this.removeListeners();
    clearTimeout(this.openTimeout);
    this.props.onClose && this.props.onClose();
  };

  handleLeave = () => {
    if (this.props.showOnEventName === "onMouseOver") {
      clearTimeout(this.openTimeout);
      this.closeTimer = setTimeout(() => {
        this.handleClose();
      }, 300);
    }
  };

  handleOver = e => {
    if (!this.props.tip) {
      return;
    }
    if (e) {
      if (this.props.showOnEventName === "onClick") {
        e.stopPropagation();
      }

      e.stopPropagation();
      clearTimeout(this.closeTimer);
      window.addEventListener("scroll", this.handleWindowScroll);
      window.addEventListener("resize", this.handleWindowScroll);
      if (this.props.showOnEventName === "onClick") {
        document.addEventListener("mousedown", this.handleClickOutside);
      }
    }

    clearTimeout(this.openTimeout);
    const anchor = this.state.anchor || (e && e.currentTarget);
    const rect = anchor?.getBoundingClientRect() || { x: 0, y: 0, height: 0 };
    const coords = {
      x: this.props.positionInsideTransform
        ? this.props.offset.x || 0
        : rect.x +
          (this.props.offset.x || 0) +
          (this.props.endAnchor ? anchor?.offsetWidth || 0 : 0),
      y: rect.y + rect.height + (this.props.offset.y || 0)
    };

    if (this.props.closeOnChildrenClick) {
      this.setState(state => ({
        coords: state.coords ? null : coords
      }));
      return;
    }

    this.openTimeout = setTimeout(() => {
      this.setState({ coords, anchor }, () => {
        if (this.spanTooltip.current) {
          let heightDraggableSpan = this.spanTooltip.current.offsetHeight;
          let widthDraggableSpan =
            this.props.horizontalPosition === "left"
              ? 0
              : this.spanTooltip.current.offsetWidth;
          let heightWindow = window.innerHeight;
          let widthWindow = window.innerWidth;
          let classNames = [];
          const coords = { ...(this.state.coords || { x: 0, y: 0 }) };

          if (this.props.positionInsideTransform) {
            coords.x =
              this.state.anchor.offsetLeft + this.state.anchor.offsetWidth / 4;
            coords.y =
              this.state.anchor.offsetTop + this.state.anchor.offsetHeight;
            this.spanTooltip.current.style.transition = "0s";
            this.setState({ coords });
            return;
          }

          if (coords.x + widthDraggableSpan > widthWindow) {
            coords.x = widthWindow - widthDraggableSpan - 10;
          }

          if (coords.x < 0) {
            coords.x = 10;
          }

          if (coords.y + heightDraggableSpan > heightWindow) {
            coords.y =
              rect.y - heightDraggableSpan - (this.props.offset.y || 0);
            classNames.push("bottom_arrow_tooltip");
          }

          this.setState({
            coords,
            className: classNames.join(" "),
            styleTop: coords.y
          });
        }
      });
      this.props.onOpen && this.props.onOpen();
    }, 300);
  };

  handleWindowScroll = () => {
    // TODO write a solution for smooth scroll
    this.handleClose();
  };

  handleClickOutside = (event) => {
    const appDiv = document.getElementById('app');
    const dialogDiv = document.querySelector('.unique-dialog-class');
    let isTargetAModal = true;
  
    if (appDiv) {
      isTargetAModal = !appDiv.contains(event.target);

      if (dialogDiv && !isTargetAModal) {
      isTargetAModal = !dialogDiv.contains(event.target);
    }
  }

    if (
      this.tooltipContainer &&
      !this.tooltipContainer.contains(event.target)
    ) {
      this.handleClose();
    }
  };

  renderTooltip() {
    if (!this.state.coords || !this.props.tip) return null;

    const {
      tip,
      tooltipStyle,
      position,
      bottomArrow,
      noArrow,
      horizontalPosition,
      fixedPosition
    } = this.props;

    const tooltipContent =
      typeof tip === "function"
        ? tip({ handleClose: this.handleClose, reposition: this.handleOver })
        : tip;

    const theme = this.props.dark ? { name: this.props.dark && "dark_theme" } : null;
    const arrowClass = theme?.name === "dark_theme" ? "arrow_tooltip--dark" : "arrow_tooltip--white";
    const positionStyles = !fixedPosition
      ? {
          position: position,
          left: this.state.coords.x,
          top: this.state.styleTop || this.state.coords.y
        }
      : { position: "absolute", left: this.props.offset.x + 10, top: this.props.offset.y + 10 };

    const tooltipJSX = (
      <span
        ref={this.spanTooltip}
        id="draggable"
        draggable="false"
        onDragStart={this.muteEvent}
        onMouseDown={this.muteEvent}
        onClick={this.muteEvent}
        className={cx(
          arrowClass,
          bottomArrow && "bottom_arrow_tooltip",
          "arrow_tooltip",
          "top_arrow_tooltip",
          this.state.className,
          horizontalPosition === "left" && "right_arrow_tooltip",
          this.props.whiteArrow && "white_arrow",
          noArrow && "no_arrow"
        )}
        style={{
          zIndex: 10000,
          ...positionStyles,
          ...tooltipStyle
        }}
      >
        <Tooltip theme={theme || this.props.theme}>{tooltipContent}</Tooltip>
      </span>
    );

    if (fixedPosition) {
      return tooltipJSX;
    } else {
      return ReactDOM.createPortal(tooltipJSX, this.tooltipContainer);
    }
  }

  render() {
    const { style: customStyle, className, children, fixedPosition } = this.props;
    return (
      <div
        style={{ ...style, ...customStyle, position: fixedPosition ? 'relative' : '' }}
        className={className}
        onMouseLeave={this.handleLeave}
        onMouseDown={this.handleClickOutside}
        {...{ [this.props.showOnEventName]: this.handleOver }}
      >
        {children}
        {this.renderTooltip()}
      </div>
    );
  }
}
