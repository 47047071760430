import React, { Component } from 'react';
import ActionPrint from 'material-ui/svg-icons/action/print';
import Loader from '../../../Common/Loader';
import { Title } from '../../../Documents/CargoHeader';
import { Pane } from '../../../Common/SplitPane';
import s from '../../Contracts.scss';

export class ContractPDFView extends Component {
  state ={
    isLoading: true,
    previewPdf: null,
  };
  createPreviewPdf = async () => {
    const pdf = await this.props.createPdf(this.props.preview);
    this.setState({ previewPdf: pdf.data, isLoading: false });
  }
  componentDidMount = () => {
    this.createPreviewPdf();
  }
  render() {
    const { previewPdf, isLoading } = this.state;

    let content = null;
    if (isLoading) {
      content = <Loader isDark />;
    } else if (previewPdf) {
      content = (
        <object
          style={{ height: '100%' }}
          width="100%"
          type="application/pdf"
          data={`/api/v1/file/${previewPdf._id}.pdf?_id=${previewPdf._id}&inline=true`}
          id="pdf_content"
        >
          <p className={s.contract_preview_pdf}>This browser does not support PDFs. Please download the PDF to view it:
            <a download href={`/api/v1/file/${previewPdf._id}.pdf?_id=${previewPdf._id}`}>Download PDF</a>.</p>
        </object>
      );
    } else {
      content = (
        <Title style={{ color: "#fff", textAlign: 'center' }}><br /><br />Contract does not have a generated PDF file </Title>
      );
    }

    return (
      <Pane
        headerProps={{
          style: {
            display: "none",
          },
        }}
        bodyProps={{
          style: {
            height: '100%',
            background: "#26374C",
          }
        }}
      >
        <div style={{ height: '100%', width: '100%' }} >
          {
            content
          }
        </div>
      </Pane>
    )
  }
}

export default ContractPDFView;
