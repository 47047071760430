import React from 'react';
import NarrowSelect from '../../NewInputs/NarrowSelect';
import MenuItem from 'material-ui/MenuItem/MenuItem';

export const ACCOUNT = {
  label: "Account Name",
  value: "Account name",
};
export const SHIPPER = {
  label: "Shipper",
  value: "Shipper",
};
export const CHARTERER = {
  label: "Charterer",
  value: "Charterer",
};
export const MERCHANT = {
  label: "Merchant",
  value: "Merchant",
};
export const BROKER = {
  label: "Broker",
  value: "Broker",
};
export const CARRIER = {
  label: "Carrier",
  value: "Carrier",
};
export const MANAGER = {
  label: "Ship Manager",
  value: "Ship manager",
};
export const OWNER = {
  label: "Ship Owner",
  value: "Ship owner",
};
export const OPERATOR = {
  label: "Ship Operator",
  value: "Ship operator",
};
export const RECEIVER = {
  label: "Receiver",
  value: "Receiver"
};
export const NOTIFY = {
  label: "Notify Party",
  value: "Notify party",
};
export const DISPOWNER = {
  label: "Disponent Owner",
  value: "Disponent Owner",
};
export const CHARTER = {
  label: "Charterer",
  value: "Charter",
};

const types = [
  ACCOUNT,
  SHIPPER,
  CHARTERER,
  MERCHANT,
  BROKER,
  CARRIER,
  MANAGER,
  OWNER,
  OPERATOR,
  RECEIVER,
  NOTIFY,
];
const tcOfflineTypes = [
  CHARTER,
  BROKER,
  OWNER,
  MANAGER,
  OPERATOR,
  DISPOWNER,
];

function CounteragentTypes(props) {
  let { as, namePrefix, value, isTcOffline, ...rest } = props;

  const El = as ?? NarrowSelect;

  const name = namePrefix ? `${namePrefix}[type]` : "type";
  value = isTcOffline ? (props.value || CHARTER.value) : (props.value || CARRIER.value);
  const typesToPreview = isTcOffline ? tcOfflineTypes : types;
  return (
    <El
      name={name}
      hintText="Counteragent type"
      value={value}
      {...rest}
    >
      {
        typesToPreview.map(({ label, value }) => <MenuItem key={value} value={value} primaryText={label} />)
      }
    </El>
  );
}

export default CounteragentTypes;
