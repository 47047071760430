import React from "react";

const MainDeckIcon = ({ fill = "white" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_39822_56564)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9754 3.60718V16.39H10.7764V3.60718H16.9754ZM13.234 8.39903L12.0052 9.66623L13.101 10.7963L16.2005 7.59994L13.101 4.40357L12.0052 5.53366L13.234 6.80085H10.7764V8.39903H13.234Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.02656 16.3899V3.6071H9.22559V16.3899H3.02656ZM6.768 11.598L7.99679 10.3308L6.90095 9.20076L3.80144 12.3971L6.90095 15.5935L7.99679 14.4634L6.768 13.1962H9.22559V11.598H6.768Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_39822_56564">
          <rect
            width="13.9478"
            height="12.7855"
            fill={fill}
            transform="translate(3.02637 3.60718)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default MainDeckIcon;
