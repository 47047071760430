import React from "react";

const LogoutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.5 17C4.0875 17 3.73437 16.8531 3.44062 16.5594C3.14687 16.2656 3 15.9125 3 15.5V4.5C3 4.0875 3.14687 3.73438 3.44062 3.44063C3.73437 3.14688 4.0875 3 4.5 3H9.25C9.4625 3 9.64062 3.07146 9.78438 3.21437C9.92812 3.35729 10 3.53437 10 3.74562C10 3.95687 9.92812 4.13542 9.78438 4.28125C9.64062 4.42708 9.4625 4.5 9.25 4.5H4.5V15.5H9.25C9.4625 15.5 9.64062 15.5715 9.78438 15.7144C9.92812 15.8573 10 16.0344 10 16.2456C10 16.4569 9.92812 16.6354 9.78438 16.7812C9.64062 16.9271 9.4625 17 9.25 17H4.5ZM14.125 10.75H8.74917C8.53694 10.75 8.35903 10.6785 8.21542 10.5356C8.07181 10.3927 8 10.2156 8 10.0044C8 9.79313 8.07181 9.61458 8.21542 9.46875C8.35903 9.32292 8.53694 9.25 8.74917 9.25H14.125L12.9583 8.08333C12.8056 7.93056 12.7292 7.75299 12.7292 7.55062C12.7292 7.34826 12.8056 7.17118 12.9583 7.01937C13.1111 6.86757 13.2882 6.79167 13.4896 6.79167C13.691 6.79167 13.8681 6.86806 14.0208 7.02083L16.4792 9.47917C16.6319 9.62694 16.7083 9.79931 16.7083 9.99625C16.7083 10.1932 16.6319 10.3681 16.4792 10.5208L14.0208 12.9792C13.8681 13.1319 13.6944 13.2049 13.5 13.1979C13.3056 13.191 13.1286 13.1111 12.9692 12.9583C12.8231 12.8056 12.75 12.6285 12.75 12.4271C12.75 12.2257 12.8264 12.0486 12.9792 11.8958L14.125 10.75Z"
        fill="white"
      />
    </svg>
  );
};
export default LogoutIcon;
