exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1dcGo{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._3qSPi{max-width:405px}._18XvA{color:#000;font-family:Roboto;font-size:64px;font-style:normal;font-weight:700;line-height:normal}._1PRlO{color:#000;font-family:Roboto;font-size:56px;font-style:normal;font-weight:300;line-height:normal}._3w1TF{margin:32px 0;color:#2D4642;-webkit-font-feature-settings:'clig' off, 'liga' off;font-feature-settings:'clig' off, 'liga' off;font-family:Roboto;font-size:18px;font-style:normal;font-weight:600;line-height:normal}\n", ""]);

// Exports
exports.locals = {
	"wrapper": "_1dcGo",
	"text": "_3qSPi",
	"title": "_18XvA",
	"semiTitle": "_1PRlO",
	"description": "_3w1TF"
};