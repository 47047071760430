import React from "react";

const LogoNameIcon = () => {
  return (
    <svg
      width="76"
      height="24"
      viewBox="0 0 76 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.60098 12C3.20047 12 1.61442 13.1485 1.61442 14.9552C1.61442 18.4286 6.90126 17.3922 6.90126 19.1289C6.90126 19.6891 6.40115 19.9832 5.60098 19.9832C4.5579 19.9832 3.01472 19.395 1.98592 18.5126L1 20.4314C2.18597 21.3838 3.90062 22 5.5724 22C7.80145 22 9.57326 20.9636 9.57326 19.0028C9.57326 15.4594 4.28642 16.4258 4.28642 14.7311C4.28642 14.2549 4.72937 14.0168 5.38665 14.0168C6.20111 14.0168 7.52996 14.437 8.58733 15.0392L9.54468 13.0924C8.51589 12.4342 7.04415 12 5.60098 12ZM20.1895 21.916V12.1121H17.6461V16.1457H13.5024V12.1121H10.959V21.916H13.5024V18.1065H17.6461V21.916H20.1895ZM24.7329 21.916V12.1121H22.1895V21.916H24.7329ZM26.7344 12.1121H31.0924C33.6501 12.1121 35.1219 13.3446 35.1219 15.5294C35.1219 17.8263 33.6501 19.1569 31.0924 19.1569H29.2778V21.916H26.7344V12.1121ZM29.2775 14.0728V17.1961H30.9635C32.0781 17.1961 32.7211 16.6499 32.7211 15.6134C32.7211 14.591 32.0781 14.0728 30.9635 14.0728H29.2775ZM45.6817 21.916V12.1121H43.3098V18.1625L38.6945 12.1121H36.4512V21.916H38.8374V15.8796L43.4384 21.916H45.6817ZM55.5119 14.0728V12.1121H47.6816V21.916H55.6691V19.9552H50.225V17.9804H54.9975V16.0196H50.225V14.0728H55.5119ZM63.1128 16.7899L66.4135 12.1121H63.4272L61.4982 14.9692L59.5692 12.1121H56.6114L59.955 16.902L56.3828 21.916H59.3406L61.4982 18.7927L63.6558 21.916H66.6993L63.1128 16.7899ZM75.4451 14.1148V12.098H66.9004V14.1148H69.8867V21.9159H72.4301V14.1148H75.4451Z"
        fill="white"
      />
    </svg>
  );
};
export default LogoNameIcon;
