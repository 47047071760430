exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2dQm7{white-space:nowrap;font-size:12px}._2dQm7 label{color:var(--text-light) !important}.Vqhy9{padding-top:8px;padding-bottom:8px}._2jcbO{min-height:44px}.jfpiw{display:-ms-flexbox;display:flex}._1Vngh{-ms-flex-wrap:wrap;flex-wrap:wrap}._2wFsM{-ms-flex-align:center;align-items:center}._1t3Ho{margin-top:8px}._2FStN{margin-right:16px}._2-Y5l{white-space:nowrap}._18093{display:grid}.UKISg{grid-template-columns:repeat(4, minmax(0, 1fr))}._10C58{-webkit-column-gap:16px;-moz-column-gap:16px;column-gap:16px}._1a98_{font-size:12px;line-height:16px}._1403Z{color:var(--text-green-dark)}._3Raga{color:var(--bg-blue)}._560C8{margin:0}\n", ""]);

// Exports
exports.locals = {
	"checkbox": "_2dQm7",
	"py_8px": "Vqhy9",
	"form_row_min_h": "_2jcbO",
	"flex": "jfpiw",
	"flex_wrap": "_1Vngh",
	"items_center": "_2wFsM",
	"mt_8px": "_1t3Ho",
	"mr_16px": "_2FStN",
	"whitespace_nowrap": "_2-Y5l",
	"grid": "_18093",
	"grid_cols_4": "UKISg",
	"gap_x_16px": "_10C58",
	"text_xs": "_1a98_",
	"text_green_dark": "_1403Z",
	"text_bg_blue": "_3Raga",
	"m_0": "_560C8"
};