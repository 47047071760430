import Api from './api';
import { round } from '../utils'
import {getSuezDirection, or0} from "../../components/Calculator/utils";

/**
 * @class
 */
export default class Price extends Api {

  static endpoint = '/prices';

  static async priceByPorts(portList, ship = {}) {
    const req = {};
    req.portList = portList;
    req.shipParam = ship.specialType || {};
    req.shipParam.vesselId = ship._id;
    req.shipParam.dwtSummer = ship.dw && ship.dw.summer;
    /*req.shipParam = {
      "ourGroup": "mpp",
      "dwtSummer": 2654,
      "dwtGroupName": "3000_4500",
      "vesselId": "58f8a99fe4fec41fbc0eff71"
    }*/
    return await Price.fetch('/prices/priceByPorts', { method: 'POST', body: JSON.stringify(req) });
  }

  static async fuelPriceByPorts(portList) {
    const req = {};
    req.portIds = portList.map(port => port._id || port);
    return await Price.fetch('/prices/fuelPriceByPort', { method: 'POST', body: JSON.stringify(req) });
  }

  static async fuelPriceByPortV2(port) {
    const req = {
      isArea: true,
    };
    return await Price.fetch(`/prices/fuel/getByPortId/${port._id || port}`, { method: 'POST', body: JSON.stringify(req) }, '/api/v2');
  }


  static async populateDA(legList, vessel, forceAll = false, priceConverter = (val) => val, toUsdConverter = (val, currency) => val) {
    if (!vessel || !vessel.dw?.summer || vessel._id?.length !== 24) {
      return legList;
    }
    if (!legList?.length) {
      return legList;
    }
    const newList = await this.populateCanalsDa(legList, vessel, forceAll, priceConverter, toUsdConverter);
    const promises = []
    for (let i = 0; i < newList.length; i++) {
      const prevLeg = newList[i - 1];
      const leg = newList[i];
      if (prevLeg && prevLeg.port._id === leg.port._id && prevLeg.type !== 'opening' && prevLeg.type !== 'passage') {
        promises.push(null);
        continue;
      }
      if (leg.port.isChannel || leg.type === 'opening') {
        promises.push(null);
        continue;
      }
      if (!leg._shouldPopulateDa && !forceAll) {
        promises.push(null);
        continue;
      }
      const req = this.priceByPorts([leg.port._id], vessel)

      promises.push(req);
    }
    try {
      const responses = await Promise.all(promises);
      legList = [...newList];
      for (let i = 0; i < responses.length; i++) {
        const response = responses[i];

        if (response !== null) {
          const { pda = {} } = response.data?.[0];
          let leg = { ...legList[i] };
          pda.averagePortCostsUsdDay = priceConverter(pda.averagePortCostsUsdDay);
          leg.calculatedDa = round(pda.averagePortCostsUsdDay, 0) || '';
          if (typeof leg.manuallyChangedFields?.da === 'undefined') {
            leg.da = round(pda.averagePortCostsUsdDay, 0) || '';
          }
          leg.isFakeDa = pda.average;
          leg._shouldPopulateDa = false;
          legList[i] = leg;
        }

      }
    } catch (e) {
      console.error(e);
    }


    return legList;

  }

  static async populateCanalsDa(legList, vessel, force, priceConverter = (val) => val, toUsdConverter = (val, currency) => val){
    const recalculatedList = [];
    for (let i = 0; i < legList.length; i++) {
      const leg = { ...legList[i] };
      const suezDa = leg.viaDaDetails?.daList.find(da => da.canalId === '5ada202ea63c49531e40f0bc');
      if (suezDa) {
        if (!force && (suezDa.manuallyChanged || leg._shouldPopulateCanalsDa === false)) {
          recalculatedList.push(leg);
          continue;
        }
        const status = leg.speedType === 'ladenSpeed' ? 'laden' : 'ballast';
        let data = { total_usd: 0, total_sdr: 0 };
        try {
          const direction = getSuezDirection(leg.points);
          if(!vessel.tonnage) {
            vessel.tonnage = {};
          }
          if(!vessel.tonnage.gt && vessel.rt?.gt) {
              vessel.tonnage.gt = vessel.rt.gt;
          }
          if (vessel && vessel.typeByPurpose && vessel.tonnage?.gt) {
            const res = await this.getSuezDa(vessel, status, direction);
            data = res.data?.[0] || data;
          }
        } catch (e) {
          console.error(e);
        }
        if (data) {
          const newSuezDa = {
            ...suezDa,
            value: round(priceConverter(or0(data.total_usd) + toUsdConverter(or0(data.total_sdr), 'SDR')), 0),
            manuallyChanged: false,
          };
          const newList = leg.viaDaDetails.daList.map((da) => da.canalId === newSuezDa.canalId ? newSuezDa : da);
          const newViaDetails = {
            ...leg.viaDaDetails,
            daList: newList,
            total: newList.reduce((acc, da) => acc + or0(da.value), 0),
          };
          leg.viaDa = newViaDetails.total;
          leg.viaDaDetails = newViaDetails;
        }
        leg._shouldPopulateCanalsDa = false;
      }
      recalculatedList.push(leg);
    }

    return recalculatedList;
  }

  static async getSuezDa(vessel, status, direction) {
    const req = {
      "tolls": [
        {
          "imonumber": vessel.imoNumber || '1111111',
          "name": vessel.name,
          "typeByPurpose": vessel.typeByPurpose,
          "typeBySpeciality": vessel.typeBySpecialty,
          "grt": vessel.tonnage?.gt || 0,
          "snrt": vessel.tonnage?.suezNt || 0,
          "draft": vessel.draft || 0,
          "loa": vessel.dimensions?.loa || 0,
          "beam": vessel.dimensions?.beam || 0,
          "direction": direction,
          "status": status,
          "tiersContainer": 0,
          "floatingUnits": 0,
        }
      ]
    }
    return Price.fetch('/suez-calc/tolls', { method: 'POST', body: JSON.stringify(req) })
  }

  static async reportNewDA ({ value, port, vessel }){
    if(!vessel || !port || !value) {
      return;
    }
   const req = {averagePortCostsUsdDay: value, portId: port._id, vesselId: vessel._id}
   return Price.fetch('/prices/pdaCustom', { method: 'POST', body: JSON.stringify(req) });

  }

  static async currency(){
    return Price.fetch('/currency/?base=USD');
  }

  static async carbonPrice(){
    return Price.fetch('/prices/co2');
  }

}
