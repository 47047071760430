import PropTypes from 'prop-types';
import React from 'react';
import keycode from 'keycode';
import Formsy from 'formsy-react';
import TextField from 'material-ui/TextField';
import {
  setMuiComponentAndMaybeFocus,
  debounce,
} from 'formsy-material-ui/lib/utils';
import FormsyInput from './FormsyInput';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { getNamesInBrackets } from './../Documents/utils';
import createReactClass from 'create-react-class';

const weightCoeff = {
  mt: 1,
  kg: 1000,
};

function maxWeightValidator(values, value, otherValue) {
  const v = Number(value);
  if (!isFinite(v)) {
    return true;
  }
  const maxValue = this._validations.maxWeight * weightCoeff[this.props.unit];
  if (value > maxValue) {
    this.props.validationErrors.maxWeight1 =
      'max ' + maxValue.toString() + this.props.unit;
    return false;
  }
  return true;
}

function totalWeightValidator(values, value, other) {
  const totalVolumeValue = values[this.props.name.replace('unitsWeight', 'unitsVolume')];
  if (!value && !totalVolumeValue) {
    this.customRequiredError = this.props.validationErrors.totalWeight;
    return false;
  }
  this.customRequiredError = undefined;
  return true;
}
function totalVolumeValidator(values, value, other) {
  const totalWeightValue = values[this.props.name.replace('unitsVolume', 'unitsWeight')];
  if (!value && !totalWeightValue) {
    this.customRequiredError = this.props.validationErrors.totalVolume;
    return false;
  }
  this.customRequiredError = undefined;
  return true;
}


const defaultStyle = { fontSize: '13px' }

function cleanNumber(value = "") {
  return value.replace(/[^0-9,.\-]/, "");
}

// eslint-disable-next-line react/prefer-es6-class
const FormsyText = createReactClass({
  propTypes: {
    defaultValue: PropTypes.any,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    requiredError: PropTypes.string,
    underlineFocusStyle: PropTypes.object,
    floatingLabelFocusStyle: PropTypes.object,
    underlineStyle: PropTypes.object,
    updateImmediately: PropTypes.bool,
    validationColor: PropTypes.string,
    validationError: PropTypes.string,
    validationErrors: PropTypes.object,
    validations: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    value: PropTypes.any,
  },

  contextTypes: {
    muiTheme: PropTypes.object
  },

  mixins: [
    {
      ...Formsy.Mixin,
      getValue() {
        const value = this.state._value;
        if (
          this.props.validations &&
          this.props.validations.indexOf('isNumeric') !== -1 &&
          typeof value !== 'number'
        ) {
          return undefined;
        }
        return value;
      },
    },
  ],

  defaultProps: {
    underlineFocusStyle: {},
    underlineStyle: {},
    invalid: undefined,
    errorText: '',
    disableBlurHandler: false,
  },

  componentWillMount() {
    if (
      this.props.validations &&
      this.props.validations.indexOf('maxWeight') !== -1
    ) {
      this.maxWeightValidator = maxWeightValidator.bind(this);
      this._validations.maxWeight1 = this.maxWeightValidator;
    }
    if (this.props.validations && !this.props.required) {
      if (this.props.validations.indexOf('totalWeight') !== -1) {
        this.totalWeightValidator = totalWeightValidator.bind(this);
        this._validations.totalWeight1 = this.totalWeightValidator;
      }
      if (this.props.validations.indexOf('totalVolume') !== -1) {
        this.totalVolumeValidator = totalVolumeValidator.bind(this);
        this._validations.totalVolume1 = this.totalVolumeValidator;
      }
    }
    this.setValue(this.controlledValue());
    this.calculated = this.props.calculated;
  },

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.validations &&
      nextProps.validations.indexOf('maxWeight') !== -1
    ) {
      this._validations.maxWeight1 = this.maxWeightValidator;
    }
    if (nextProps.validations && !nextProps.required) {
      if (nextProps.validations.indexOf('totalWeight') !== -1) {
        if (!this.totalWeightValidator) {
          this.totalWeightValidator = totalWeightValidator.bind(this);
        }
        this._validations.totalWeight1 = this.totalWeightValidator;
      }
      if (nextProps.validations.indexOf('totalVolume') !== -1) {
        if (!this.totalVolumeValidator) {
          this.totalVolumeValidator = totalVolumeValidator.bind(this);
        }
        this._validations.totalVolume1 = this.totalVolumeValidator;
      }
    }
    const isValueChanging = nextProps.value !== this.props.value;
    if (isValueChanging || nextProps.defaultValue !== this.props.defaultValue) {
      const value = this.controlledValue(nextProps);
      const isValid = this.isValidValue(value);

      if (isValueChanging || this.props.defaultValue === this.getValue()) {
        this.setState({ isValid });
        this.setValue(value);
      }
    }
  },

  componentWillUpdate(nextProps, nextState) {
    if (
      nextState._isPristine && // eslint-disable-line no-underscore-dangle
      nextState._isPristine !== this.state._isPristine
    ) {
      // eslint-disable-line no-underscore-dangle
      // Calling state here is valid, as it cannot cause infinite recursion.
      const value = this.controlledValue(nextProps);
      const isValid = this.isValidValue(value);
      this.setValue(value);
      this.setState({ isValid });
    }
  },

  controlledValue(props = this.props) {
    return props.value || props.defaultValue;
  },

  validationColor(props = this.props) {
    return props.validationColor || this.context.muiTheme?.palette?.primary1Color || '#4CAF50';
  },

  handleBlur(event) {
    if (this.props.disableBlurHandler) { // disable default blur handler for MultiCheckbox div doesnt have "value" property
      return;
    }
    let value = event.currentTarget.value;
    if (value && value.trim && !this.props.multiLine) {
      value = value.trim();
    }
    if (
      this.props.validations &&
      this.props.validations.indexOf('isNumeric') !== -1
    ) {
      const floatValue = parseFloat(value);
      if (!isNaN(floatValue)) {
        value = floatValue;
      } else if (value === '') {
        value = undefined;
      }
    }
    this.setValue(value);
    delete this.changeValue;
    if (this.props.onBlur) this.props.onBlur(event, value);
  },

  handleChange(event) {
    // Update the value (and so display any error) after a timeout.
    this.touched = true;
    if (
      this.props.validations &&
      this.props.validations.indexOf('isNumeric') !== -1
    ) {
      if (this.props.isInteger) {
        event.currentTarget.value = event.currentTarget.value.replace(',', '').replace('.', '');
      } else {
        event.currentTarget.value = event.currentTarget.value.replace(',', '.');
      }
      event.currentTarget.value = cleanNumber(event.currentTarget.value);
    }
    if (this.props.updateImmediately) {
      if (!this.changeValue) {
        this.changeValue = debounce(this.setValue, 400);
      }
      this.changeValue(event.currentTarget.value);
    } else {
      // If there was an error (on loss of focus) update on each keypress to resolve same.
// eslint-disable-next-line no-lonely-if
      if (this.getErrorMessage() != null) {
        this.setValue(event.currentTarget.value);
      } else {
        // Only update on valid values, so as to not generate an error until focus is lost.
// eslint-disable-next-line no-lonely-if
        if (this.isValidValue(event.target.value)) {
          this.setValue(event.currentTarget.value);
          // If it becomes invalid, and there isn't an error message, invalidate without error.
        } else {
          this.setState({
            _value: event.currentTarget.value,
            _isPristine: false,
          });
        }
      }
    }

    this.setState({ isValid: this.isValidValue(event.currentTarget.value) });
    if (this.props.onChange) {
      this.props.onChange(event, event.currentTarget.value);
    }
  },

  handleKeyDown(event) {
    if (this.props.isInteger) {
      const forbiddenChars = ["-", "+", ".", ",", "e", "E"];
      if (forbiddenChars.includes(event.key)) {
        event.preventDefault();
      }
    }
    if (keycode(event) === 'enter') this.setValue(event.currentTarget.value);
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event, event.currentTarget.value);
    }
  },

  setMuiComponentAndMaybeFocus(el) {
    if (this.props.innerRef) {
      this.props.innerRef(el);
    }
    setMuiComponentAndMaybeFocus.call(this, el);
  },

  render() {
    const {
      defaultValue, // eslint-disable-line no-unused-vars
      requiredError,
      underlineFocusStyle = {},
      underlineStyle = {},
      updateImmediately, // eslint-disable-line no-unused-vars
      validations, // eslint-disable-line no-unused-vars
      validationError, // eslint-disable-line no-unused-vars
      validationErrors, // eslint-disable-line no-unused-vars
      value, // eslint-disable-line no-unused-vars
      calculated, // eslint-disable-line no-unused-vars
      unit, // eslint-disable-line no-unused-vars
      className = '',
      previousValue,
      id,
      style = defaultStyle,
      floatingLabelFocusStyle = {},
      disabled=false,
      ...rest
    } = this.props;
    const _style = {...style, ...defaultStyle};
    const { isRequired, isPristine, isValid, isFormSubmitted, customRequiredError } = this;
    const isRequiredError =
      !isPristine() &&
      !isValid() &&
      isFormSubmitted() &&
      ((isRequired() && requiredError) || customRequiredError);
    const errorText = this.getErrorMessage() || isRequiredError || (this.props.invalid ? this.props.errorText : '');
    let cn = className + ' text_field' + (isValid() ? ' valid' : ' invalid');
    if(errorText) {
       cn +=" with_error";
    }
    let v = this.state._value;
    if(this.props.showEmptyOnDisabled){
      v = this.state._value ? this.state._value : (disabled || this.isFormDisabled()) ? '—' : '';
    }
    if (previousValue !== undefined && previousValue !== v) {
      cn += ' dirty';
    }

    let unitsName = '';

    if (this.props.unit) {
      const nameNesting = getNamesInBrackets(this.props.name);
      if (nameNesting.length) {
        unitsName = this.props.name.replace(nameNesting[nameNesting.length - 1], '[unit]');
      }
    }

    return (
      <span className="w-100">
        <TextField
          disabled={disabled || this.isFormDisabled()}
          className={cn}
          data-valid={isValid() && this.props.invalid !== true}
          id={id || this.props.name}
          {...rest}
          style={_style}
          errorText={errorText}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          ref={this.setMuiComponentAndMaybeFocus}
          value={v}
          underlineStyle={
            this.state.isValid
              ? { ...underlineStyle, color: this.validationColor() }
              : underlineStyle
          }
          underlineFocusStyle={
            this.state.isValid
              ? { ...underlineFocusStyle, color: this.validationColor() }
              : underlineFocusStyle
          }
          floatingLabelFocusStyle = {{
            ...(floatingLabelFocusStyle),
            ...(this.state._isValid || !this.state._value ? {color:  floatingLabelFocusStyle?.color ? floatingLabelFocusStyle.color : this.context.muiTheme?.palette?.primary1Color} : {})

          }}

        />
        {this.props.unit
          ? <FormsyInput
            style={{ display: 'none' }}
            name={unitsName}
            type="hidden"
            value={this.props.unit}
          />
          : null}

      </span>
    );
  },
});

export default FormsyText;
