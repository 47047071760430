import React, { PureComponent } from 'react';
import FormsyDate from './FormsyDate';
import DateRangeIcon from 'material-ui/svg-icons/action/date-range';

import NarrowFormsyDate from "../NewInputs/NarrowFormsyDate";
import TimePicker from './TimePicker/TimePicker';


export default class FormsyDateTime extends PureComponent {

  constructor(props) {
    super(props);
    const initialTime = this.props.initialTime || this.props.minDate || (new Date());
    this.state = { time: initialTime, timeFormat: '24hr', date: this.props.initialTime, isNew: true };
  }

  componentDidMount() {
    const s = (new Date()).toLocaleTimeString().slice(-2).toLocaleLowerCase();
    if (s === 'am' || s === 'pm') {
      this.setState({ timeFormat: 'ampm' });
    }
  }


  static mergeTime(date, time) {
    const dateTime = new Date(date);
    dateTime.setHours(time.getHours());
    dateTime.setMinutes(time.getMinutes());
    dateTime.setSeconds(0);
    dateTime.setMilliseconds(0);
    return dateTime;
  }
  static formatDateTime(date) {
    if (!date) {
      return '';
    }
    return date.toLocaleString().replace(':00 ', '');
  }

  static styles = { timePicker: { display: 'none' },
    icon: {
      width: '12px',
      height: '12px',
      position: 'absolute',
      right:'2px',
      top:'0',
      bottom: '0',
      margin: 'auto',
    },
    wrapper: {
     position: 'relative',
    }

  };
  openDatePicker = () => {
    this.refs.datePicker.refs.formsyDate.focus();
  }

  handleTimeChange = (e, newTime) => {
    const dateTime = FormsyDateTime.mergeTime(this.state.date, newTime);
    this.setState({ time: dateTime, date: dateTime, isNew: false });
    this.refs.datePicker.refs.formsyDate.setValue(dateTime);
    this.props.onChange && this.props.onChange(e,dateTime);
  };
  handleDateChange = (e, newDate) => {
    if (!newDate) {
      this.refs.datePicker.refs.formsyDate.setValue(undefined);
      this.props.onChange && this.props.onChange(e,newDate);
      return;
    }
    const dateTime = FormsyDateTime.mergeTime(newDate, this.state.time);
    this.setState({ date: dateTime, timePickerOpened: true });
    this.props.onChange && this.props.onChange(e,newDate);
  };

  closeTimepicker = () => {
    this.setState({timePickerOpened: false})
  }

  setValue = (newDate) => {
    this.refs.datePicker.refs.formsyDate.setValue(newDate)
    this.setState({ date: newDate });
  }
  onDrop = (e) => {
    e.preventDefault();
    return false;
  }
  handleCancel = () => {
    if (this.state.isNew) {
      this.refs.datePicker.refs.formsyDate.setValue(undefined);
    } else if (this.state.date !== this.state.time.setSeconds(0)) {
        this.refs.datePicker.refs.formsyDate.setValue(this.state.time);
    }
    this.closeTimepicker()
  }

  render() {
    return (
      <div style={FormsyDateTime.styles.wrapper}>
        <NarrowFormsyDate onDrop={this.onDrop} formatDate={FormsyDateTime.formatDateTime} {...this.props} strictCompare ref="datePicker" startDate={this.props.minDate} value={this.state.date} onChange={this.handleDateChange} />
        {this.props.withIcon ?
          <DateRangeIcon style={FormsyDateTime.styles.icon} onClick={this.openDatePicker} />
          : null}
          <TimePicker opened={this.state.timePickerOpened} onDismiss={this.handleCancel} value={this.state.time} onChange={this.handleTimeChange} onClose={this.closeTimepicker} minutesStep={5}/>
      </div>
    );
  }
}
