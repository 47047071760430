import React from "react";
import pure from "recompose/pure";
import SvgIcon from "material-ui/SvgIcon";

let Port = (opacity = 0.4, ...props) => (
  <span {...props}>
    <span className="icon-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M9.20039 6.66344C8.26824 6.33397 7.60039 5.44498 7.60039 4.4C7.60039 3.07452 8.67491 2 10.0004 2C11.3259 2 12.4004 3.07452 12.4004 4.4C12.4004 5.44498 11.7325 6.33397 10.8004 6.66344V8.4H13.6004V10H10.8004V16.32C12.6262 15.9494 14.0004 14.3352 14.0004 12.4H15.6004C15.6004 15.2212 13.5142 17.5551 10.8004 17.9433V18H9.20039V17.9433C6.48656 17.5551 4.40039 15.2212 4.40039 12.4H6.00039C6.00039 14.3352 7.3746 15.9494 9.20039 16.32V10H6.40039V8.4H9.20039V6.66344ZM10.0004 5.2C10.4422 5.2 10.8004 4.84183 10.8004 4.4C10.8004 3.95817 10.4422 3.6 10.0004 3.6C9.55856 3.6 9.20039 3.95817 9.20039 4.4C9.20039 4.84183 9.55856 5.2 10.0004 5.2Z"
          fill="white"
        />
      </svg>
    </span>
  </span>
);

Port = pure(Port);
Port.displayName = "Port";
Port.muiName = "SvgIcon";

export default Port;
