export default function saveFiltersState(state) {
  try {
    if (typeof window !== 'undefined') {
      if (window.sessionStorage && (state.cargoList.page === 1 || state.vesselList.page === 1)) {
        const stateToSave = JSON.stringify({
          monitor: {
            cargoList: {
              ...state.cargoList,
              data: [],
              page: 1,
            },
            vesselList: { ...state.vesselList, data: [], page: 1 },
          },
        });

        const themeObj = JSON.stringify({
          cargoList: state.cargoList.dark,
          vesselList: state.vesselList.dark,
        });

        window.sessionStorage.setItem('state', stateToSave);

        // Save state to cookies
        document.cookie = `theme=${encodeURIComponent(themeObj)}; path=/; max-age=31536000`;
      }
    }
  } catch (e) {
    console.error(e);
  }
}
