import React, { PureComponent } from "react";
import { CardTitle } from "material-ui/Card";
import Highlight from './../../Monitor/Highlight';
import s from "./CustomAutocomplete.scss";
import cx from 'classnames';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import BasicCard from './BasicCard';
import BasicAutocompleteList from './BasicAutocompleteList';
import { withPopover } from './../../../core/HOC';
import BasicItem from './BasicItem';
import NarrowFormsyText from '../../NewInputs/NarrowFormsyText';
import NarrowFieldSet from '../../NewInputs/NarrowFieldSet';
import createReactClass from 'create-react-class';
import { HOC } from "formsy-react";

const subtitleStyle = {
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "10px",
  textTransform: "uppercase",
  color: "#999999"
};



const CustomAutoComplete = createReactClass({
  // TODO : add formsy
  anchorEl: null,
  mixins: [],
  getDefaultProps() {
    return {
      onChange: () => null,
      firstSubtitle: "MY FLEET",
      secondSubtitle: "ALL FLEET",
      placeholder: "Vessel",
      list: [],
      load: () => null
    };
  },
  getInitialState() {
    return {
      search: "",
      item: null
    };
  },
  shouldComponentUpdate(nextState, nextProps) {
    if (nextState !== this.state || nextProps !== this.props) {
      return true;
    }
    return false;
  },
  componentDidMount() {
    this.props.adjustRef(this);
  },
  updateSearch(ev) {
    this.props.load(ev.target.value);
    if (ev.target.value.length === 0) {
      this.setState({
        item: null,
      })
      if(this.props.setValue) this.props.setValue(undefined)
    }
    this.setState({
      search: ev.target.value,
    });
    this.props.showSelect();
  },
  handleClick(ev, item) {
    this.props.closeSelect();
    this.setState({
      item,
      search: item.name
    })
    if(this.props.setValue) this.props.setValue(item)
  },
  getItem() {
    return this.state.item;
  },
  focusCard(ev) {
    ev.preventDefault();

    if (this.select) {
      this.select.focus();
    }
  },
  render() {
    const {
      firstSubtitle,
      secondSubtitle,
      onChange,
      placeholder,
      list,
      customHeight,
      open,
      showSelect,
      setAnchorEl,
      onEmptyResult,
      inputProps = {},
    } = this.props;
    const isError = this.props.isValid && this.props.isFormSubmitted && this.props.isFormSubmitted() && !this.props.isValid();
    const { search, item } = this.state;
    let otherShips = [];
    let myShips = list.filter(ship => {
      if (ship.isMine) {
        return true;
      }
      else {
        otherShips.push(ship);
        return false;
      }
    });

    const isOpen =
      (open && list.length > 0) || (open && search.length >= 3);
    return (
      <div
        ref={setAnchorEl}
        style={{ zIndex: 999, position: "relative" }}
      ><NarrowFieldSet>
        <NarrowFormsyText
          ref={node => this.input = node}
          tabIndex="1"
          floatingLabelText={placeholder}
          autoComplete="off"
          name={"preferredCargo"}
          value={search}
          onChange={this.updateSearch}
          onFocus={showSelect}
          fullWidth
          invalid={isError}
          errorText={isError ? this.props.requiredError : ''}
          onKeyDown={ev => {
            if (ev.key === "ArrowDown") {
              this.focusCard(ev);
            }
          }}
          {...inputProps}
        />
        </NarrowFieldSet>
        <div></div>
        <BasicCard
          open={isOpen}
          adjustRef={node => this.select = node}
          handleClick={this.persistSelect}
          customHeight={this.props.customHeight || "280px"}
        >
          {myShips.length > 0 && (
            <div>
              <CardTitle
                style={{ padding: "12px" }}
                subtitleStyle={subtitleStyle}
                subtitle={firstSubtitle}
              />
              <BasicAutocompleteList
                list={myShips}
                renderItem={
                  listItem => (
                    <ListItem currentItem={item} handleClick={this.handleClick} keyword={search} item={listItem}></ListItem>
                  )
                }
              />
            </div>
          )}
          {((otherShips.length > 0 && search.length >= 3)
            || (otherShips.length > 0 && item))
            && (
              <div>
                <CardTitle
                  subtitle={secondSubtitle}
                  subtitleStyle={subtitleStyle}
                  style={{ padding: "12px" }}
                />
                <BasicAutocompleteList
                  fallback={
                    <CardTitle
                      style={{ padding: "12px" }}
                      subtitleStyle={{
                        ...subtitleStyle,
                        color: 'red'
                      }}
                      subtitle={"No ships found"}
                    />
                  }
                  list={otherShips}
                  renderItem={
                    listItem => (
                        <ListItem currentItem={item} handleClick={this.handleClick} keyword={search} item={listItem}></ListItem>
                    )
                  }
                />
              </div>
            )}
            {myShips.length<1 && otherShips.length<1 ? onEmptyResult : null}
        </BasicCard>

      </div>
    );
  }
});

function ListItem(props) {
  const { keyword, item, handleClick, currentItem, ...rest } = props;
  const regExp = new RegExp(keyword ? keyword.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : "", 'i');
  return (
    <BasicItem
      item={item}
      handleClick={handleClick}
      {...rest}
      className={cx(item === currentItem && s.active)}
    >
        <div className={s.flex}>
          <Highlight search={regExp} children={item.name} />
          {item.exName
            && <span style={{ marginLeft: '5px' }}>(<Highlight search={regExp} children={item.exName} />)</span>
            }
        </div>
        <div className={s.item_info}>
          <span className={s.imo}>IMO NO: {item.imoNumber}</span>
          <span className={s.dwt}>DWT: {item.dwt}</span>
        </div>
    </BasicItem>
  )
}


const DefaultAutocomplete = withStyles(s)(withPopover(CustomAutoComplete));
export const FormsyCustomAutocomplete = HOC(DefaultAutocomplete)

export default DefaultAutocomplete;
