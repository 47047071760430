import React from "react";
import pure from "recompose/pure";
import SvgIcon from "material-ui/SvgIcon";

let BL = ({ fill = "#7D8794", opacity = 0.4, ...props }) => (
  <span {...props}>
    <span className="icon-wrapper">
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        opacity={opacity}
      >
        <g clip-path="url(#clip0_40100_290223)">
          <mask
            id="mask0_40100_290223"
            maskUnits="userSpaceOnUse"
            x="2"
            y="2"
            width="17"
            height="16"
          >
            <path d="M18.5 2H2.5V18H18.5V2Z" fill={fill} />
          </mask>
          <g mask="url(#mask0_40100_290223)">
            <path
              d="M13.7125 4.58057H4.52476C4.19121 4.58057 3.91992 4.85186 3.91992 5.18541V17.3951C3.91992 17.7287 4.19121 17.9999 4.52476 17.9999H13.7125C14.0457 17.9999 14.317 17.7287 14.317 17.3951V5.18541C14.317 4.85186 14.0457 4.58057 13.7125 4.58057ZM11.6618 13.0967H6.75863C6.47347 13.0967 6.2425 12.8657 6.2425 12.5806C6.2425 12.2954 6.47347 12.0645 6.75863 12.0645H11.6618C11.947 12.0645 12.178 12.2954 12.178 12.5806C12.178 12.8657 11.947 13.0967 11.6618 13.0967ZM11.6618 11.0322H6.75863C6.47347 11.0322 6.2425 10.8012 6.2425 10.5161C6.2425 10.2309 6.47347 9.99992 6.75863 9.99992H11.6618C11.947 9.99992 12.178 10.2309 12.178 10.5161C12.178 10.8012 11.947 11.0322 11.6618 11.0322ZM11.6618 8.96765H6.75863C6.47347 8.96765 6.2425 8.73672 6.2425 8.45153C6.2425 8.16637 6.47347 7.93541 6.75863 7.93541H11.6618C11.947 7.93541 12.178 8.16637 12.178 8.45153C12.178 8.73672 11.947 8.96765 11.6618 8.96765Z"
              fill={fill}
            />
            <path
              d="M17.0817 3.54839V14.9032C17.0817 15.6826 16.4546 16.4516 15.3498 16.4516V5.18549C15.3498 4.28258 14.6156 3.54839 13.713 3.54839H5.65234C5.65234 2.69451 6.38815 2 7.29234 2H15.4417C16.3459 2 17.0817 2.69451 17.0817 3.54839Z"
              fill={fill}
            />
          </g>
        </g>
      </svg>
    </span>
  </span>
);

BL = pure(BL);
BL.displayName = "BL";
BL.muiName = "SvgIcon";

export default BL;
