import React, { useEffect, useRef } from "react";
import IconMoon from "../Icons/IconMoon";
import s from "./ThemeToggle.scss";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { connect } from "react-redux";
import { changeVesselStyle, changeCargoStyle } from "../../actions/monitor";

function ThemeToggle(props) {
  const { dark } = props;
  const changeTheme = () => props.changeTheme({ dark: !props.dark });
  const { style = {} } = props;
  const wrapperClass = cx(
    s.wrapper_toggle,
    props.dark ? s.wrapper_toggle_dark : null
  );

  return (
    <div style={style} className={wrapperClass}>
      <IconMoon className={cx(s.toggle_icon_moon)} onClick={changeTheme} />
      <div
        className={cx(s.tooltip_toggle, s.arrow_tooltip, s.top_arrow_tooltip)}
      >
        <p>{!props.dark ? "Dark view" : "Light view"}</p>
      </div>
    </div>
  );
}

export default withStyles(s)(ThemeToggle);
