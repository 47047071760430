
import React from 'react';
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import UniversalRouter from 'universal-router';
import queryString from 'query-string';
import { createPath } from 'history/PathUtils';
import history from './core/history';
import App from './components/App';
import configureStore from './store/configureStore';
import { ErrorReporter, deepForceUpdate } from './core/devUtils';
import theme from './themes/shipnext.com_theme';
import { isAllowedPathForUnsubscribed } from './core/utils';
import { ThemeProvider } from 'styled-components';
window.installedCssChunks = {};

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const context = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: (...styles) => {
    // eslint-disable-next-line no-underscore-dangle
    const removeCss = styles.map(x => x._insertCss());
    return () => {
      removeCss.forEach(f => f());
    };
  },
  // Initialize a new Redux store
  // http://redux.js.org/docs/basics/UsageWithReact.html
  store: configureStore(window.APP_STATE, { history }),
  muiTheme: theme,
};
function updateTag(tagName, keyName, keyValue, attrName, attrValue) {
  const node = document.head.querySelector(
    `${tagName}[${keyName}="${keyValue}"]`,
  );
  if (node && node.getAttribute(attrName) === attrValue) return;

  // Remove and create a new tag in order to make it work with bookmarks in Safari
  if (node) {
    node.parentNode.removeChild(node);
  }
  if (typeof attrValue === 'string') {
    const nextNode = document.createElement(tagName);
    nextNode.setAttribute(keyName, keyValue);
    nextNode.setAttribute(attrName, attrValue);
    document.head.appendChild(nextNode);
  }
}
function updateScript(tagName, keyName, keyValue, children) {
  const node = document.head.querySelector(
    `${tagName}[${keyName}="${keyValue}"]`,
  );
  if (node && !children) {
    node.parentNode.removeChild(node);
    return;
  }
  const scriptContent = JSON.stringify(children);
  if (node && node.innerHTML === scriptContent) return;

  if (node) {
    node.parentNode.removeChild(node);
  }
  if (typeof scriptContent === 'string') {
    const nextNode = document.createElement(tagName);
    nextNode.setAttribute(keyName, keyValue);
    nextNode.innerHTML = scriptContent;
    document.head.appendChild(nextNode);
  }
}
function updateMeta(name, content) {
  updateTag('meta', 'name', name, 'content', content);
}
function updateCustomMeta(property, content) {
  // eslint-disable-line no-unused-vars
  updateTag('meta', 'property', property, 'content', content);
}
function updateLink(rel, href) {
  // eslint-disable-line no-unused-vars
  updateTag('link', 'rel', rel, 'href', href);
}

// Switch off the native scroll restoration behavior and handle it manually
// https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
const scrollPositionsHistory = {};
if (window.history && 'scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

let onRenderComplete = function initialRenderComplete() {
  const elem = document.getElementById('css');
  if (elem) elem.parentNode.removeChild(elem);
  onRenderComplete = function renderComplete(route, location) {
    document.title = route.title;

    updateMeta('description', route.description);
    updateMeta('keywords', route.keywords || "");
    updateMeta('robots', route.robots);
    updateCustomMeta('og:description', route.description);
    updateCustomMeta('og:title', route.title);
    updateCustomMeta('og:url', window.location.href);
    updateCustomMeta('og:image', route.ogImg);
    updateCustomMeta('og:video', route.ogVideo);
    updateScript("script", "type", "application/ld+json", route.seoScript)
    // Update necessary tags in <head> at runtime here, ie:
    // updateMeta('keywords', route.keywords);
    // updateCustomMeta('og:url', route.canonicalUrl);
    // updateCustomMeta('og:image', route.imageUrl);
    // updateLink('canonical', route.canonicalUrl);
    // etc.

    let scrollX = 0;
    let scrollY = 0;
    const pos = scrollPositionsHistory[location.key];
    if (pos) {
      scrollX = pos.scrollX;
      scrollY = pos.scrollY;
    } else {
      const targetHash = location.hash.substr(1);
      if (targetHash) {
        const target = document.getElementById(targetHash);
        if (target) {
          scrollY = window.pageYOffset + target.getBoundingClientRect().top;
        }
      }
    }

    // Restore the scroll position if it was saved into the state
    // or scroll to the given #hash anchor
    // or scroll to top of the page
    window.scrollTo(scrollX, scrollY);

    // Google Analytics tracking. Don't send 'pageview' event after
    // the initial rendering, as it was already sent
    if (window.ga) {
      //window.ga('send', 'pageview', createPath(location));
    }
  };

  try {
    import(/* webpackChunkName: 'SAB' */ 'smart-app-banner/dist/smart-app-banner.css').then((res) => {
      res.default._insertCss();
    });
    import(/* webpackChunkName: 'SAB' */ 'smart-app-banner/dist/smart-app-banner.js').then((res) => {
      window.SmartBanner = res.default;
      new window.SmartBanner({
        daysHidden: 1,
        daysReminder: 1,
        appStoreLanguage: 'us',
        title: 'SHIPNEXT',
        author: 'SHIPNEXT',
        button: 'VIEW',
        store: {
          ios: 'On the App Store',
          android: 'In Google Play',
        },
        price: {
          ios: 'FREE',
          android: 'FREE',
        },
      });
    });
    import(/* webpackChunkName: 'SAB'*/ 'tinycon/tinycon.min.js').then((res) => {
     window.Tinycon = res.default;
    });
  } catch (e) {
    console.error(e);
  }
};


const container = document.getElementById('app');
let appInstance;
let currentLocation = history.location;
let routes = require('./routes').default;
let previousLocation;
// Re-render the app when window.location changes
async function onLocationChange(location) {
  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any
  if (history.action === 'PUSH') {
    delete scrollPositionsHistory[location.key];
  }
  // getting custom mui theme ( if subdomain has one. Defaults to shipnext.com_theme.js )
  currentLocation = location;
  try {
    const {default: theme} = await import(`./themes/${window.location.hostname}_theme.js`);
    if (theme) {
      context.muiTheme = theme;
    }
  } catch (error) {
    console.error("ERROR GETTING THEME ON CLIENT", error);
  }
  try {

    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const route = await UniversalRouter.resolve(routes, {
      ...context,
      path: location.pathname,
      previousPath: previousLocation && previousLocation.pathname,
      query: queryString.parse(location.search),
      hash: location.hash,
      pathname:location.pathname,
    });

    previousLocation = currentLocation;
    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return;
    }

    if (route.redirect) {
      history.replace(route.redirect);
      return;
    }

    appInstance = ReactDOM.hydrate(
      <ThemeProvider defaultProps={{ theme: { name: 'white_theme' }}} theme={{ name: 'white_theme' }}>
        <App path={location.pathname}
           isPublicPath={route.public}
           isUnLockedPathForUnverified={route.isUnLockedPathForUnverified}
           isUnLockedPathForUnapproved={route.isUnLockedPathForUnapproved}
           hideMessageBar={route.hideMessageBar}
           context={context}
           store={context.store}
          >{route.component}
          </App>
      </ThemeProvider>,
      container,
      () => onRenderComplete(route, location),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line no-console

    // Current url has been changed during navigation process, do nothing
    if (currentLocation.key !== location.key) {
      return;
    }

    // Display the error in full-screen for development mode
    if (process.env.NODE_ENV !== 'production') {
      appInstance = null;
      document.title = `Error: ${error.message}`;
      ReactDOM.render(<ErrorReporter error={error} />, container);
      return;
    }

    // Avoid broken navigation in production mode by a full page reload on error
    //window.location.reload();
  }
}

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
history.listen(onLocationChange);
onLocationChange(currentLocation);

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./routes', () => {
    routes = require('./routes').default; // eslint-disable-line global-require

    if (appInstance) {
      try {
        // Force-update the whole tree, including components that refuse to update
        deepForceUpdate(appInstance);
      } catch (error) {
        appInstance = null;
        document.title = `Hot Update Error: ${error.message}`;
        ReactDOM.render(<ErrorReporter error={error} />, container);
        return;
      }
    }

    onLocationChange(currentLocation);
  });
}
