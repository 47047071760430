exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1FV5F{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;gap:2px;height:24px;width:56px;border-radius:100px;background:#fff;border:1px solid #e6e6e6;padding:0 6px;font-family:Roboto;font-size:11px;font-weight:400;line-height:14px;letter-spacing:0px;color:#333}._1FV5F ._2ELym{width:16px !important;height:16px !important;fill:#787878 !important}._1FV5F.UVTIj{width:72px;background:var(--active-filter-sort) !important;border:1px solid var(--active-filter-sort-brd);color:var(--active-filter-sort-clr)}._1FV5F.UVTIj ._2ELym{fill:var(--active-filter-sort-clr) !important}.QAHwA{width:15px !important;height:15px !important;background:#0F4778 !important;border-radius:50%;padding:0px !important;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}.QAHwA svg{fill:#fff !important;width:12px !important;height:12px !important}\n", ""]);

// Exports
exports.locals = {
	"filterWrapper": "_1FV5F",
	"filterIcon": "_2ELym",
	"dirty": "UVTIj",
	"clearBtn": "QAHwA"
};