import MenuItem from 'material-ui/MenuItem';
import ContentAdd from 'material-ui/svg-icons/content/add';
import ContentClear from 'material-ui/svg-icons/content/clear';
import React, { Component } from 'react'
import NarrowSelect from './../../NewInputs/NarrowSelect'
import { withRows } from './../../../core/HOC';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import CalculatorInput from './../../Calculator/Input';
import v from './VesselEdit.scss';
import cx from "classnames";
import TextArea from './TextArea';

const Input = (props) => <CalculatorInput {...props} locale={'en-US'} decimalSeparator="." separator="," />;
class _SpeedConsumptions extends React.Component {

  static defaultProps = {
    attachAsRef: () => void(0),
    changesCallback: () => void(0),
    withoutBeaufort: false
  }

  static field = 'speedConsumptionV2'

  static defaultRows = [
    {
      mode: 'Laden',
      id: 0,
    },
    {
      mode: 'Ballast',
      id: 1,
    },
    {
      mode: 'Eco Laden',
      id: 2,
    },
    {
      mode: 'Eco Ballast',
      id: 3,
    },
    {
      mode: 'Idle',
      id: 4,
    },
    {
      mode: 'Working',
      id: 5,
    }
  ]

  static excludeSpeed = ['Working', 'Idle']


  static parseRows = rows => {
    return rows.map(row => {
      if (typeof row === 'string') return row;
      const fields = {};
      for (let key in row) {
        if (typeof row[key] === 'object' && row[key] !== null) {
          for (let subKey in row[key]) {
            fields[`[${key}][${subKey}]`] = row[key][subKey]
          }
        }
        else {
          fields[key] = row[key];
        }
      }
      return fields
    })
  }

  changeMode = (row, ev, val) => {
    if (_SpeedConsumptions.excludeSpeed.includes(val)) {
      this.changeRowField(row, 'speed', ev, "")
    }
    this.changeRowField(row, 'mode', ev, val);
  }

  changeRowField = (row, field, _, val) => {
    this.props.changesCallback();
    this.props.changeField({id: row?.id ?? row, field}, _, val);
  }

  renderRow = (row, index) =>
    (
      <div key={row.id ?? row} className={v.table_row}>
        <div colSpan="1" className={cx(v.big_cell, v.table_cell)}>
          {
            row.mode && index <= 5
              ? <div className={v.cell_bold}>
                {row.mode}
                <TextArea
                    type="text"
                    style={{display: 'none'}}
                    name={`${_SpeedConsumptions.field}[${index}][mode]`}
                    id={`${_SpeedConsumptions.field}[${index}][mode]`}
                    value={row.mode || ""}
                    fullWidth
                />
              </div>
              :
                <NarrowSelect
                    hintText="Mode"
                    underlineStyle={{display: 'none'}}
                    name={`${_SpeedConsumptions.field}[${index}][mode]`}
                    // labelStyle={{top: '4px'}}
                    // containerStyle={{ left: '25%', transform: 'translate(-50%, -50%)', maxHeight: '27px'}}
                    fullWidth
                    className={v.select}
                    value={row.mode || ""}
                    defaultValue={row.mode}
                    onChange={this.changeMode.bind(this, row)}
                >
                    <MenuItem value="Laden" primaryText="Laden" />
                    <MenuItem value="Ballast" primaryText="Ballast" />
                    <MenuItem value="Eco Laden" primaryText="Eco Laden" />
                    <MenuItem value="Eco Ballast" primaryText="Eco Ballast" />
                    <MenuItem value="Idle" primaryText="Idle" />
                    <MenuItem value="Working" primaryText="Working" />
                </NarrowSelect>
          }
        </div>
        <div colSpan="1" className={cx(v.small_cell, v.table_cell)}>
          <div className={v.cell_input}>
            <Input
              type="text"
              numeric
              validations={`isNumeric,min:0,max:50`}
              disabled={!row?.mode || (row.mode === 'Idle' || row.mode === 'Working')}
              name={`${_SpeedConsumptions.field}[${index}][speed]`}
              fullWidth
              value={row?.speed || ""}
              onChange={this.changeRowField.bind(this, row, 'speed')}
            />
          </div>
        </div>
        <div colSpan="1" className={cx(v.small_cell, v.table_cell)}>
            <NarrowSelect
                hintText="Select"
                underlineStyle={{display: 'none'}}
                name={`${_SpeedConsumptions.field}[${index}][ifo][typeIfo]`}
                fullWidth
                disabled={!row?.mode}
                validations={'requiredIf:' + _SpeedConsumptions.field + '[' + index + ']' + '[ifo]' + '[cons]'}
                validationErrors={{
                  "requiredIf": "required"
                }}
                //defaultValue={row['[ifo][typeIfo]']}
                value={row['[ifo][typeIfo]'] || ""}
                className={v.select}
                onChange={this.changeRowField.bind(this, row, '[ifo][typeIfo]')}
                dropDownMenuProps={{ menuStyle: { width: 'unset' } }}
            >
                <MenuItem value="VLS FO" primaryText="VLS FO" />
                <MenuItem value="ULS FO" primaryText="ULS FO" />
                <MenuItem value="HFO 180" primaryText="HFO 180" />
                <MenuItem value="HFO 380" primaryText="HFO 380" />
            </NarrowSelect>
        </div>
        <div colSpan="1" className={cx(v.small_cell, v.table_cell)}>
          <div className={v.cell_input}>
            <Input
              type="text"
              numeric
              disabled={!row?.mode}
              validations={{
                isNumeric: true,
                min: 0,
                max: 50000,
              }}
              validations={`isNumeric,min:0,max:50000`}
              name={`${_SpeedConsumptions.field}[${index}][ifo][cons]`}
              fullWidth
              onChange={this.changeRowField.bind(this, row, '[ifo][cons]')}
              value={row['[ifo][cons]'] || ""}
            />
          </div>
        </div>
        <div colSpan="1" className={cx(v.small_cell, v.table_cell)}>
            <NarrowSelect
                underlineStyle={{display: 'none'}}
                hintText="Select"
                name={`${_SpeedConsumptions.field}[${index}][mdo][typeMdo]`}
                validations={'requiredIf:' + _SpeedConsumptions.field + '[' + index + ']' + '[mdo]' + '[cons]'}
                validationErrors={{
                  "requiredIf": "required"
                }}
                fullWidth
                disabled={!row?.mode}
                className={v.select}
                //defaultValue={row['[mdo][typeMdo]']}
                dropDownMenuProps={{ menuStyle: { width: 'unset' } }}
                value={row['[mdo][typeMdo]'] || ""}
                onChange={this.changeRowField.bind(this, row, '[mdo][typeMdo]')}
            >
                <MenuItem key={0} value="MGO" primaryText="MGO" />
                <MenuItem key={1} value="LS MGO" primaryText="LS MGO" />
            </NarrowSelect>
        </div>
        <div colSpan="1" className={cx(v.small_cell, v.table_cell)}>
          <div className={v.cell_input}>
            <Input
              type="text"
              numeric
              disabled={!row?.mode}
              validations="isNumeric,min:0,max:50000"
              name={`${_SpeedConsumptions.field}[${index}][mdo][cons]`}
              fullWidth
              value={row['[mdo][cons]'] || ""}
              onChange={this.changeRowField.bind(this, row, '[mdo][cons]')}
            />
          </div>
        </div>
        <div colSpan="1" className={cx(v.small_cell, v.table_cell)}>
          <div className={v.cell_input}>
            <Input
              type="text"
              disabled={!row?.mode}
              validations="isNumeric,min:0,max:50000"
              numeric
              name={`${_SpeedConsumptions.field}[${index}][lngCons]`}
              value={row?.lngCons || ""}
              fullWidth
              onChange={this.changeRowField.bind(this, row, 'lngCons')}
            />
            <TextArea
                type="text"
                style={{display: 'none'}}
                name={`${_SpeedConsumptions.field}[${index}][id]`}
                id={`${_SpeedConsumptions.field}[${index}][id]`}
                value={row?.id ?? row}
                fullWidth
            />
          </div>
        </div>
      </div>
    )

  componentDidMount() {
    this.props.attachAsRef(this);
  }

  renderRows = rows => {
    return rows.map(this.renderRow);
  }
  addRow = () => {
    this.props.changesCallback();
    this.props.handleAddRow();
  }

  removeRow = (row) => {
    this.props.changesCallback();
    this.props.handleRemoveRow(row);
  }

  render() {
    const {rows, handleAddRow, handleRemoveRow} = this.props;
    return (
      <div className={cx(v.section, v.speedConsumptions)}>
        <div className={v.row}>
          <div className={v.table_wrapper}>
            <div className={cx(v.table)}>
              <div style={{border: 'none'}} className={cx(v.table_row, v.table_header)}>
                <div colSpan="1" className={cx(v.big_cell, v.table_cell)}>
                  <div className={v.cell_bold}>
                    Mode (sea, port)
                  </div>
                </div>
                <div colSpan="1" className={cx(v.table_cell, v.small_cell)}>
                  <div className={v.cell_bold}>
                    Speed, kn
                  </div>
                </div>
                <div colSpan="1" className={cx(v.table_cell, v.small_cell)}>
                  <div className={v.cell_bold}>
                    IFO
                  </div>
                </div>
                <div colSpan="1" className={cx(v.table_cell, v.small_cell)}>
                  <div className={v.cell_bold}>
                    Cons
                  </div>
                </div>
                <div colSpan="1" className={cx(v.table_cell, v.small_cell)}>
                  <div className={v.cell_bold}>
                    MGO
                  </div>
                </div>
                <div colSpan="1" className={cx(v.table_cell, v.small_cell)}>
                  <div className={v.cell_bold}>
                    Cons
                  </div>
                </div>
                <div colSpan="1" className={cx(v.table_cell, v.small_cell)}>
                  <div className={v.cell_bold}>
                    LNG Cons
                  </div>
                </div>
              </div>
              <div className={v.table_body}>
                {
                  this.renderRows(rows)
                }
              </div>
            </div>
          </div>
          <div className={v.side_column}>
            {rows?.map((row, index) => (
                  index > 4
                  ? (
                      index >= rows.length - 1
                      ?
                      <div onClick={this.addRow} key={row?.id ? row.id : row} className={v.cell}>
                        <ContentAdd
                            color="#285596"
                        />
                      </div>
                      : index > 5
                        ?
                        <div data-id={row?.id ? row.id : row}
                              onClick={this.removeRow.bind(this, row)} key={row?.id ? row.id : row}
                              className={v.cell}>
                            <ContentClear

                            />
                        </div>
                        :
                        null
                    )
                  : null
            ))}
          </div>
        </div>
      </div>
    );
  }
}
export {
  _SpeedConsumptions
};

export default withStyles(v)(withRows(_SpeedConsumptions));
