import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import Dialog from '../../Common/Dialog';
import PortLink from '../../Common/PortLink';
import Vessel from '../../../core/api/Vessel';
import {
  atDateTime,
  date,
  dateRange,
  fittedToString,
  formatTypeByPurposePlural,
  isObjHasBoolean,
  number,
  pointToDMS,
  renderSeparatedRow,
} from '../../../core/format';
import s from './VesselPreviewNew.scss';
import v from './../../Monitor/Edit/VesselEdit.scss';
import ZoomInIcon from 'material-ui/svg-icons/action/zoom-in';
import StarIcon from 'material-ui/svg-icons/toggle/star';
import StarIconBorder from 'material-ui/svg-icons/toggle/star-border';
import LocationIcon from 'material-ui/svg-icons/communication/location-on';
import Channels from '../../Monitor/Channels/Channels';
import ProgressVesselPosition from '../../Common/ProgressVesselPosition';
import MapPosition from '../../Common/MapPosition';
import PrintIcon from 'material-ui/svg-icons/action/print';
import {connect} from 'react-redux';
import {openSubscribeDialog} from '../../../actions/login';
import VesselRating, {DisplayRating} from '../../Common/VesselRating';
import {_SpeedConsumptions} from './../../Monitor/Edit/SpeedConsumptions';
import PublicPrivateSpeedCons from './../../Monitor/Edit/PublicPrivateSpeedCons';
import Exclusions from './../../Monitor/Edit/Exclusions';
import Collapse from './../../Common/Collapse.js';
import AddPosition from './AddPosition';
import PreviousPorts from './PreviousPorts';
import PastNames from './PastNames';
import history from '../../../core/history';
import ExtendedForm from '../../Common/ExtendedForm';
import Attachments, {AddAttachment} from './../../Monitor/Edit/Attachments';
import {
  getSnpList,
  updateAttachments,
  updateExclusionsAndRestrictions,
  updatePreferences,
  updateSingleSalesOrder,
  updateSpeedConsumption,
  vesselChanged,
  vesselChangesSeen,
} from './../../../actions/vessel';
import {mapTypeByConstruction, scrollToAnchor} from './../../../core/utils';
import PubSub from 'pubsub-js';
import CompanyChangesTooltip, {DarkCompanyChangesTooltip} from './../../Common/CompanyChangesTooltip';
import WithTooltip from '../../Common/WithTooltip';
import Loader from './../../Common/Loader';
import ChangesTriangle from './../../Common/ChangesTriangle';
// import CheckBox from '../../Common/CheckBox';
import VesselFooter from '../VesselFooter'

import ForSaleWrapper from '../../Monitor/ForSaleOffers/ForSaleWrapper';
import ForSaleOffer from '../../Monitor/ForSaleOffers/ForSaleOffer';
import BlurIfNoSubscription from '../../Signup/BlurIfNoSubscription';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import VesselStatus from './VesselStatus';
import Formsy from 'formsy-react';
import SeparateCompanyAutocomplete from './ChangeCompanyAutocomplete';
import AdditionalDescription from './AdditionalDescription';
import RatingComments from './../../Common/Comments/RatingComments';
import RoundBorderedBtn from './../../Common/RoundBorderedBtn';
import {mergeDisponentOwner, mergeTags} from './../../../reducers/monitor/helpers';
import {updateVesselsList} from './../../../actions/monitor';
import {isUserSubscribedToMarketplace} from '../../../core/utils';
import SanctionsFlag from './../../Common/SanctionsFlag';
import HireIndicesIcon from '../../Icons/HireIndicesIcon';
import DialogHalfScreen from '../../Common/DialogHalfScreen';
import HireIndices from '../HireIndices';
import {HiddenButtons} from "../../Common/HiddenButtons";
import ChatAndMailIcon from "../../Icons/ChatAndMailIcon";
import Link from "../../Link";
import {WithUserUploads} from "../../../core/HOC";
import UploadedFileExtensionErr from "../../Common/UploadedFileExtensionErr";
import SelectableElement from '../../IssueReport/SelectableElement.js';
import Tags from '../../Common/Tags/Tags.js';
import TagsApi from '../../../core/api/Tags.js';

function consToString(cons) {
  if (!cons || !cons.length) {
    return "---";
  }
  return cons
    .map(
      con => con.quantity + "mt\u00A0" + con.typeOfFuel.replace(" ", "\u00A0")
    )
    .join(", ");
}

function _Gears({arr}) {
  return (
        arr?.length > 0
            ?
              <div>
                {
                  arr.map(({type = '---', outreach = ' --- ', maxSWL = ' ---', quantity = '', capacityMin = '', capacityMax = '', capacity = ''}, i) => <div key={i}>{renderSeparatedRow({type: `${type}${quantity ? ' - ' + quantity : ''}${capacityMin || capacityMax ? `${capacityMin ? ` - ${capacityMin} min` : ''} ${capacityMax ? `(${capacityMax} max)` : ''}` :  capacity ? ' - ' + capacity : ''}`, outreach, maxSWL})}</div>)
                }
              </div>
            :
              <div>---</div>
  )
}

function _Grabs({arr}) {
  return (
          arr?.length > 0
              ?
                <div>
                  {
                    arr.map(({type = '---', quantity = '', volumeMin = '', volumeMax = '', volume = ''}, i) => <div key={i}>{`${type}${quantity ? ' - ' + quantity : ' / --- '}${volumeMin || volumeMax ? `${volumeMin ? ` -${volumeMin} min` : ''}${volumeMax ? ` (${volumeMax} max)` : ''}` : volume ? ' - ' + volume : ' / --- '}`}</div>)
                  }
                </div>
              :
                <div>---</div>
  )
}

export function TechEquipment({ obj, withoutHyphen = false }) {
  const isEmpty = !isObjHasBoolean(obj);
  if (isEmpty) {
    if (withoutHyphen) {
      return null;
    }
    return <div>---</div>;
  }
  return (<div>
    <div>{fittedToString('Ventilation', obj.ventilationFitted)}{obj.ventilation ? (': ' + obj.ventilation) : ''}</div>
    <div>{fittedToString('Reefer plug', obj.referPlugFitted)}{obj.referPlugsAmount > 0 && (': ' + number(obj.referPlugsAmount))}</div>
    <div>{fittedToString('Imo', obj.imoFitted)}{obj.imoApp && (': ' + obj.imoApp)}{obj.imoClass && ('; Class: ' + obj.imoClass)}</div>
    <div>{fittedToString('Scrubber', obj.scrubberFitted)}</div>
    <div>{fittedToString('CO2', obj.co2Fitted)}</div>
    <div>{fittedToString('A60 bulkhead', obj.a60BulkheadFitted)}</div>
    <div>{fittedToString('Itf', obj.itfFitted)}</div>
    <div>{fittedToString('Cement holes', obj.cementHolesFitted)}</div>
    <div>{fittedToString('Lakes', obj.lakesFitted)}</div>
    <div>{fittedToString('Logs', obj.logsFitted)}</div>
    <div>{fittedToString('Aussie', obj.aussieFitted)}</div>
    <div>{fittedToString('Heavy cargoes', obj.strengthenHeavy)}</div>
  </div>);
}
export function TechEquipmentTank({ obj, withoutHyphen = false }) {
  const isEmpty = !isObjHasBoolean(obj);
  if (isEmpty) {
    if (withoutHyphen) {
      return null;
    }
    return <div>---</div>;
  }
  const {
    ngs,
    ngsCapacity,
    igs = {},
    vrs,
    vrsNumLines,
    bwts,
    bowChainStopper,
    chainSize,
    bowThruster,
    bowThrusterNum,
    bowThrusterKw,
    bowThrusterBhp,
    scrubberFitted,
    coiled,
  } = obj;
  return (<div>
    <div>{fittedToString('NGS', ngs)}{ngsCapacity ? number(ngsCapacity, ": ", "cbm/hr (95%)") : null}</div>
    <div>{fittedToString('IGS', igs.fitted)}{igs.systems?.length ? ":" : null}{igs.systems?.length ? igs.systems.map(i => (<div>
      {(i.type || '---') + ' / ' + number(i.capacity, "", `cbm/hr at ${i.percentage}%`)}
    </div>)) : null}</div>
    <div>{fittedToString('VRS', vrs)}{vrsNumLines ? ":" : null}{vrsNumLines ? <div>{number(vrsNumLines, "Num of independent lines: ", "")}</div> : null}</div>
    <div>{fittedToString('BWTS', bwts)}</div>
    <div>{fittedToString('Bow-chain stopper', bowChainStopper)}</div>
    {chainSize && <div>• Chain size:{" "} {number(chainSize, "", "mm")}</div>}
    <div>{fittedToString('Bowthruster', bowThruster)}{ bowThrusterNum ? ":" : null}{ bowThrusterNum ? <div>{number(bowThrusterNum, "", "pc") + number(bowThrusterKw, " / ", "kW") + number(bowThrusterBhp, " / ", "bhp")}</div> : null}</div>
    <div>{fittedToString('Scrubber', scrubberFitted)}</div>
    <div>{fittedToString('Coiled', coiled)}</div>
  </div>);
}

export function ForLoggedInTooltip({ iconStyle = {} }) {
  return <WithTooltip
    tip={
      <div
        style={{
          lineHeight: '16px',
          width: '140px',
          borderRadius: '4px',
          background: 'var(--bg-table-dark)',
          color: '#fff',
          fontSize: '12px',
          padding: '12px',
          boxShadow: `1px 1px 15px rgba(0, 0, 0, 0.3)`,
          fontWeight: 'normal',
          textTransform: 'none',

        }}
        className={cx(s.alert, s.dark)}

      >
        Only for logged users
      </div>
    }
    horizontalPosition="right"
    offset={{x:5,y:5}}
  >
      <span style={{fontSize: '17px',color:"var(--icon-default)", marginLeft:'6px' , cursor: 'pointer',  ...iconStyle}} className="material-icons">
      lock
    </span>

  </WithTooltip>;
}

function VesselDescription(props: { vessel: Object }) {
  const parts = [];
  const { vessel } = props;
  parts.push(<span><strong>{`${vessel.name} ${vessel.imoNumber}`}</strong></span>);
  if (vessel.typeByPurpose) {
    parts.push(<span> is a <strong>{formatTypeByPurposePlural(vessel.typeByPurpose, 1)}</strong></span>);
  }
  if (vessel.blt) {
    parts.push(<span>, built in {vessel.blt} (<strong>{`${(new Date()).getFullYear() - vessel.blt} years old`}</strong>)</span>);
  }
  if (vessel.flag) {
    parts.push(<span>. It's sailing under the flag of <strong>{vessel.flag.name}</strong>.</span>);
  }
  const to = vessel.lastKnownRoute?.to;
  const from = vessel.lastKnownRoute?.from;
  const toPort = to?.portId?.name ? `${to.portId.name}, ${to.portId.country?.name || ''}` : null;
  const fromPort = from?.portId?.name ? `${from.portId.name}, ${from.portId.country?.name || ''}` : null;
  if (toPort) {
    parts.push(<br/>);
    parts.push(<br/>);
    parts.push(
      <span>According to the latest AIS and Satellite data, the vessel is currently on route to <strong>{toPort}</strong>.</span>);
    if (to.date) {
      parts.push(<span> Estimated time of arrival at <strong>{toPort}</strong> is {atDateTime(to.date)}.</span>);
    }
  }
  if (fromPort || toPort || vessel.location) {
    parts.push(<br/>);
    parts.push(<br/>);
  }
  if (vessel.location?.coordinates) {
    parts.push(<span>Vessel's current position is <strong>{pointToDMS(vessel.location)}.</strong> </span>);
  }
  if (fromPort || toPort) {
    parts.push(<span>The vessel is proceeding</span>);
    if (fromPort) {
      parts.push(<span> from <strong>{fromPort}</strong></span>);
    }
    if (toPort) {
      parts.push(<span> to <strong>{toPort}</strong></span>);
    }
    parts.push(<span>.</span>);
  }
  parts.push(<br/>);
  parts.push(<br/>);
  parts.push(<span>For more information please go to <a href={`/main-deck/general/-/${vessel._id}`}
                                                        target="_blank">{vessel.name}</a>.</span>);
  return <div style={{fontSize: '12px', lineHeight: '18px', padding:'12px 0' }}>{parts}</div>;
}

class VesselPreviewNew extends Component {

  constructor(props) {
    super(props);
    this.state = {
      exclusionsChanged: false,
      preferencesChanged: false,
      consumptionsChanged: false,
      attachmentsChanged: false,
      additionalDescription: this.props.vessel?.additionalDescription || "",
      attachmentsKey: 1,
      clampDescription: false,
      exclKey: 1,
      prefKey: 1,
      vessel: {},
      attachments: [],
      showedRating: false,
      rating: {
        average: null,
        reviews: null,
      },
      salesOffers: {
        isExist: false,
        checkboxStatus: 0,
        loading: false,
        forSaleList: [],
        offerToFooter: {}
      }
    };
  }
  /*   _onOpenMap = () => {
    this.setState({
      showMap: !this.state.showMap,
    });
  }; */

  changePositionStatus = (channelId, status) => {
    try {
      /* const res = Vessel.changePositionStatus({
        vesselRequestId: channelId,
        status
      }) */
      const index = this.state.channels.findIndex(channel => channel._id === channelId);
      if (index < 0) return;
      const newChannel = {
        ...(this.state.channels[index] || {}),
        userStatus: status
      }
      const newChannels = [
        ...this.state.channels
      ];
      newChannels[index] = newChannel;
      this.setState(state => ({
        ...state,
        channels: newChannels
      }));
    } catch (error) {
      console.log(error);
    }
  }

  handleShowRating = () => {
    let paid = this.props.user?.activeProducts.find(item =>
      item.match(/^shipnext:paid$/)
    );
    if (!paid) {
      return this.props.openSubscribeDialog();
    }

    this.setState({ showedRating: !this.state.showedRating });
  };
  static propTypes = {
    vessel: PropTypes.object,
    vesselId: PropTypes.string,
    showName: PropTypes.bool,
    shouldScrollInside: PropTypes.bool,
    displayCommonHeader: PropTypes.bool,
    showHireIndexIcon: PropTypes.bool,
    hireDialogProps: PropTypes.object,
  };
  static defaultProps = {
    showName: true,
    removeTriangle: true,
    shouldScrollInside: false,
    displayCommonHeader: false,
    dontShowRatingAndComments: false,
    onAddedChannel: () => undefined,
    onUpdateFleet: () => undefined,
    changesCallback: () => undefined,
    showHireIndexIcon: true,
    hireDialogProps: {},
  };
  collapses = {};
  state = { loading: true };
  /* handleZoomImage = (url) => {
    this.setState({ zoomedImg: url });
  };
  handleCloseZoom = () => {
    this.setState({ zoomedImg: null });
  }; */

  componentWillMount() {
    this.prepareState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.vessel?._id !== this.props.vessel?._id) {
      this.prepareState(nextProps);
    }

    if (nextProps.channels && Array.isArray(nextProps.channels) && nextProps.channels !== this.props.channels) {
      this.setState({
        channels: [...nextProps.channels]
      })
    }
  }

  handleDisponentOwner = ({ res, value }) => {
    this.setState(state => ({
      ...state,
      vessel: {
        ...state.vessel,
        disponentOwner: value
      }
    }));
    const newDisponentOwner = mergeDisponentOwner(value);
    this.props.updateVesselsList({
      vesselId: this.state.vessel._id,
      data: newDisponentOwner
    })
  }

  getForSaleInfo = vesselId => {
	  if (!vesselId) return;
    let paid = this.props.user?.activeProducts.find(item =>
      item.match(/^shipnext:paid$/)
    );
    if (!paid) return;

    Vessel.getOffersByVessel(vesselId)
      .then(({ data, status }) => {
        const forSaleList = [
          data.ownerOffer,
          data.myOffer,
          ...data.receivedOffers
        ].filter(item => item !== null).sort((a, b) => a.createdAt - b.createdAt);

        if (!forSaleList.length) throw new Error(); //Careful on megre

        this.setState({
          salesOffers: {
            ...data,
            loading: false,
            isExist: true,
            forSaleList,
            offerToFooter: data.myOffer || data.ownerOffer || {}
          }
        });
      })
      .catch(err => {
        this.setState({
          salesOffers: {
            loading: false,
            isExist: false,
            checkboxStatus: 0
          }
        });
      });
  };

  prepareState = (props = this.props) => {
    props.vessel?.imoNumber && Vessel.getLocation(+props.vessel.imoNumber).then(data => {
      this.setState({
        vesselPosition: { ...data, typeByPurpose: props.vessel.typeByPurpose },
      });
    });
    this.getForSaleInfo(props.vessel?._id);
    this.getVessel(props);
  };

  updateVessel = (tcIndex, hireIdea) => {
    this.getForSaleInfo(this.props.vessel?._id);
    //TODO - make separate event for tc/hire update
    this.refetchVessel();
    //PubSub.publish('vessel:ratingUpdated', {tcIndex, hireIdea: {isFake: false, value: hireIdea}});
    return true
  };

  handleForSaleSockets = (msg, data) => {
    if (data.vesselId === this.props.vessel?._id) {
      // console.log('DATA ID', data)
      // console.log('VESSEL ID', this.props)
      // console.warn('UPDATE OPENED VESSEL');
      this.getForSaleInfo(this.props.vessel?._id);
    }
  }

  parseSpeedConsOnLoad = speedCons => {
    let defaultRows = _SpeedConsumptions.defaultRows || [];
    const rows = _SpeedConsumptions.parseRows(speedCons);
    if (defaultRows && defaultRows.length) {
      defaultRows = defaultRows.map(row => {
        const inRows = rows.findIndex(i => i.mode.trim() === row.mode);
        if (inRows >= 0) {
          const obj = {
            ...rows[inRows],
            id: row.id
          };
          rows.splice(inRows, 1);
          return obj;
        }
        return row;
      });
    }
    const parsed = [...defaultRows, ...rows].map(item => ({...item, id: item.id ? item.id : String(Math.random()).replace('.', '')}));
    return parsed
  }

  getVessel = async (props = this.props, force = false) => {
    if (
      !props.vesselId &&
      !props.vessel &&
      !props.vessel?.vessel &&
      !props.vessel?._id
    )
      return null;
    try {
      let res = {
        data: props.vessel,
      };
      if (!res.data || !res.data._id || force) {
        res = await Vessel.getDictionaryVesselById(
          props.vesselId || props.vessel._id || props.vessel.vessel
        );
        this.props.updateCurrentVessel &&
          this.props.updateCurrentVessel(res.data);
      }

      const newState = { ...res.data };
      if (newState.speedConsumptionV2) {
        newState.speedConsumptionV2 = this.parseSpeedConsOnLoad(newState.speedConsumptionV2);
      }
      if (newState.speedConsumptionV2Private) {
        newState.speedConsumptionV2Private = this.parseSpeedConsOnLoad(newState.speedConsumptionV2Private);
      }
       newState.preparedEngines = newState?.engines?.reduce((pe, e) => {
        if (!Object.keys(e).length) {
          return pe;
        }
        if (pe[e.type]) {
          pe[e.type] = [...pe[e.type], e];
        } else {
          pe[e.type] = [e];
        }
        return pe;
      }, {}) || {};

      /* const dataObj = newState.speedConsumptionV2.reduce((acc, item, index) => {
        Object.keys(item).forEach(key => {
          acc[`speedConsumptionV2[${index}]${key[0] === '[' ? key : `[${key}]`}`] = item[key];
        })
        return acc;
      }, {}); */
      // TODO: need to get rid of consumptions form reset
      // TODO: because it creates race condition with controlled speedconsumptions state and formsy
      this.setState(
        state => ({
          ...state,
          vessel: {
            ...newState,
            userGroups: newState?.hasOwnProperty('userGroups') ? newState.userGroups : state.vessel.userGroups
          },
          attachments: newState?.isUserOwner
            ? newState.attachments
            : newState?.attachments?.sort(a => (a?.belongsToOwner ? -1 : 1)),
          additionalDescription: newState?.additionalDescription || "",
          channels:
            state.channels && state.channels.length && force ? state.channels : props.channels && props.channels.length ? props.channels : []
        }),
        /* () => {
          this.refs.consumptionsForm &&
            this.refs.consumptionsForm.reset(
              dataObj
            );
        } */
      );
      return res;
    } catch (e) {
      console.error(e);
      this.setState({ vessel: undefined });
    }
  };

  onRatingChanged = (msg, vessel = {}) => {
    this.setState(state => ({
      vessel: {
        ...state.vessel,
        ...vessel
      }
    }));
  };

  async componentDidMount() {
    const { vessel = {} } = this.state;
    if (this.props.monitor && vessel?.changes) {
      if (
        vessel.changes?.lastSeenVersion
          ? vessel.changes?.lastSeenVersion < vessel.changes?.lastDocVersion
          : true
      ) {
        try {
          const res = await Vessel.setChangesSeen(
            vessel?._id,
            vessel.changes.lastDocVersion
          );
          this.setState(state => ({
            ...state,
            vessel: {
              ...state.vessel,
              changes: {
                ...state.changes,
                lastSeenVersion: res.data.lastDocVersion
              }
            }
          }));
          this.props.vesselChangesSeen(vessel._id, res.data);
        } catch (error) {
          console.log(error);
        }
      }
    }
    PubSub.subscribe("vessel:ratingUpdated", this.onRatingChanged);
    PubSub.subscribe("vessel:commentsUpdated", this.refetchVessel);
    PubSub.subscribe('FOR_SALE_NEW_ITEM', this.handleForSaleSockets);
    PubSub.subscribe('FOR_SALE_ITEM_PRICE_UPDATED', this.handleForSaleSockets);
    PubSub.subscribe('FOR_SALE_ITEM_REMOVED', this.handleForSaleSockets);
    PubSub.subscribe('CHANGE_POSITION_STATUS', this.handleChangePositionStatus);
    PubSub.subscribe('CHANGE_VESSEL_STATUS', this.handleChangeVesselStatus);
    if (this.props.attachAsRef) {
      this.props.attachAsRef(this);
    }
    if (this.descOverflow()) {
      this.setState({
        clampDescription: true
      })
    }
  }

  handleChangeVesselStatus = (eventName, data) => {
    if (!data || !data.vesselGroups) return;
    
    if (data.vesselId === this.state.vessel._id) {
      this.vesselStatusChange(data.vesselGroups.groups || this.props?.vessel?.userGroups?.groups);
    }
  }

  handleChangePositionStatus = (eventName, data) => {
    if (!data || !data.positionStatus) return;
    const { positionId, positionStatus } = data;
    this.changePositionStatus(positionId, positionStatus);
  }

  toggleDescription = () => this.setState( state => ({ clampDescription: !state.clampDescription }) )

  refetchVessel = async () => {
    try {
      const { data: { _id, changes = null } = {} } = await this.getVessel(undefined, true);
      if (!_id) throw Error('Error getting updated vessel from /api/v1/vessels');
      this.props.vesselChanged(_id, changes);
    } catch (error) {
      console.log(error);
    }
  }

  formatDate(dateValue) {
    const locale = "en-US";
    const date = new Date(dateValue);

    return `${date.getUTCDate()} ${date
      .toLocaleDateString(locale, { timeZone: "UTC", month: "short" })
      .toUpperCase()}, ${date.getUTCFullYear()}`;
  }
  updateShowRating = ({ average, reviews }) => {
    this.setState({
      rating: { average, reviews }
    });
  };

  /* padArray(arr, length) {
    let pad = new Array(length - arr.length).fill(null);
    return arr.concat(pad);
  } */

  /*  async printPdf() {
    let {vessel, vesselPosition} = this.state;
    vessel = Object.assign({}, vessel)
    let route = null;
    if (vesselPosition && vesselPosition.route) route = vesselPosition.route;
    if (vessel.exNames) {
      vessel.exNames = this.padArray(Array.isArray(vessel.exNames) ? vessel.exNames.slice(-5).reverse() : [], 5);
    }
    await Vessel.printPdf({
      vessel,
      route
    })
  } */
  componentWillUnmount() {
    this.unmounting = true;
    PubSub.unsubscribe(this.onRatingChanged);
    PubSub.unsubscribe(this.refetchVessel);
    PubSub.unsubscribe(this.handleForSaleSockets);
    PubSub.unsubscribe(this.handleChangePositionStatus);
  }

  refresh = async () => {
    if (this.unmounting) {
      return;
    }
    history.replace(window.location.pathname);
    this.props.onAddedChannel();
  };

  renderSeparatedRow = (obj, transformFunc = item => (item ? item : "---"), separator = " / ") => {
    if (!obj || typeof obj !== "object") return null;
    return Object.values(obj)
      .map(value => transformFunc(value))
      .join(separator);
  };

  renderBunkers = arr => {
    if (!arr || !arr.length) return "---";
    return arr
      .map(item =>
        item ? `${number(+item.quantity)}mt ${item.typeOfFuel}` : "---"
      )
      .join(", ");
  };
  renderBunkersTank = arr => {
    if (!arr || !arr.length) return "---";
    return arr
      .map(item =>
        item ? <div>{`• ${number(+item.quantity)}mt ${item.typeOfFuel}`}</div>
        : "---"
      );
  };

  renderConsumptions = arr => {
    return arr && arr?.length
      ? arr?.map(item => {
        return (
          <div>
            <div>
              • {item?.mode || "---"}:{" "}
              {item?.speed ? number(item.speed) + "kn" : "---"};{" "}
              {item?.ifo && item.ifo?.cons
                ? number(item.ifo.cons) + "mt"
                : "---"}{" "}
              {item?.ifo?.typeIfo || "---"},{" "}
              {item?.mdo && item.mdo?.cons
                ? number(item.mdo.cons) + "mt"
                : "---"}{" "}
              {item?.mdo?.typeMdo || "---"},{" "}
              {item?.lngCons ? item.lngCons + "mt LNG" : "---"}
            </div>
          </div>
        )
      })
      : "---";
  };

  isFalsyObj = obj => {
    if (!obj || typeof obj !== "object") return true;
    return Object.values(obj).every(value => !value);
  };

  setExclusionsChange = () => this.setState({ exclusionsChanged: true });
  setPreferencesChange = () => this.setState({ preferencesChanged: true });

  cancelExclusions = () =>
    this.setState(state => ({
      exclKey: state.exclKey + 1,
      exclusionsChanged: false
    }));
  cancelPreferences = () =>
    this.setState(state => ({
      prefKey: state.prefKey + 1,
      preferencesChanged: false,
    }));

  setConsumptionsChange = () => this.setState({ consumptionsChanged: true });

  cancelConsumptions = () =>
    this.setState(state => ({
      consKey: state.consKey + 1,
      consumptionsChanged: false
    }));

  setAttachmentsChange = () => this.setState({ attachmentsChanged: true });

  cancelAttachments = () => {
    this.setState(state => ({
      attachmentsKey: state?.attachmentsKey + 1 || 1,
      attachments: state.vessel.attachments,
      attachmentsChanged: false
    }));
  };

  openVesselParticularsCollapse = () => {
    if (this.collapses && this.collapses.vesselParticulars) {
      this.collapses.vesselParticulars?.show();
    }
  }

  handleSaveExclusions = formData => {
    const { getOnlyUserExclusions } = this.exclusions;
    const vessel = this.state.vessel || this.props.vessel;

    if (vessel.isUserOwner) {
      formData.cargoExclusionsV2 = this.exclusions.getCargoExclusions() || [];
      formData.tradingRestrictions =
        this.exclusions.getTradingRestrictions() || [];
    } else {
      formData.cargoExclusionsV2 =
        getOnlyUserExclusions("cargoExclusions") || [];
      formData.tradingRestrictions =
        getOnlyUserExclusions("tradingRestrictions") || [];
    }
    this.props
      .updateExclusionsAndRestrictions(vessel._id, formData)
      .then(res => {
        if (res.status === 200) {
          this.setState(
            {
              exclusionsChanged: false
            },
            () => this.refetchVessel(this.props, true)
          );
          PubSub.publish("UPDATE_RELEVANT_CARGOES", {
            vesselRequest: { vessel: vessel._id }
          });
        }
      });
  }

  handleSavePreferences = formData => {
    const { getOnlyUserExclusions } = this.preferences;
    const vessel = this.state.vessel || this.props.vessel;

    if (vessel.isUserOwner) {
      formData.cargoPreferences = this.preferences.getCargoExclusions() || [];
      formData.areaPreferences =
        this.preferences.getTradingRestrictions() || [];
    } else {
      formData.cargoPreferences =
        getOnlyUserExclusions("cargoExclusions") || [];
      formData.areaPreferences =
        getOnlyUserExclusions("tradingRestrictions") || [];
    }
    this.props
      .updatePreferences(vessel._id, formData)
      .then(res => {
        if (res.status === 200) {
          this.setState(
            {
              preferencesChanged: false,
            },
            () => this.refetchVessel(this.props, true)
          );
        }
      });
  }
  handleUpdateVesselRating = ({rating}) => {
    const vessel = {
      ...(this.state.vessel || this.props.vessel),
      rating: rating || (this.state.vessel.rating || this.props.vessel.rating)
    }
    PubSub.publish('vessel:ratingUpdated', vessel);
  }

  handleSaveAttachments = () => {
    let { attachments, vessel: { _id } = {} } = this.state || {};
    if (!attachments) {
      return;
    }
    const attachmentsStrArr = attachments.map(({ url, name, _id }) => _id);
    const attachmentVisibility = attachments.map(({ visibility, _id }) => ({visibility,id:_id}));
    this.props.updateAttachments(_id, { attachments:attachmentsStrArr,attachmentVisibility }).then(res => {
      if (res.status === 200) {
        this.setState(
          {
            attachmentsChanged: false
          },
          () => this.getVessel(this.props, true)
        );
        PubSub.publish("UPDATE_RELEVANT_CARGOES", {
          vesselRequest: { vessel: _id }
        });
      }
    });
  };

  removeEmptyStrings = obj => {
    if (!obj || typeof obj !== "object") return obj;
    obj = { ...obj };
    for (let key in obj) {
      if (obj[key] && typeof obj[key] === "object")
        obj[key] = this.removeEmptyStrings(obj[key]);
      if (!obj[key]) {
        delete obj[key];
      }
    }
    return obj;
  };

  filterSpeedConsumptions = item => {
    const itemCopy = { ...item };
    delete itemCopy.mode;
    delete itemCopy.id;
    const values = Object.values(itemCopy);
    if (values.length) {
      return values.some(val =>
        val
          ? typeof val === "object"
            ? Object.values(val).length
            : !!val
          : false
      );
    } else {
      return false;
    }
  };

  prepareSpeedConsumptions = speedConsumptionV2 => {
    const filtered = speedConsumptionV2?.length
      ? this.removeEmptyStrings(speedConsumptionV2)
      : [];
    speedConsumptionV2 = Object.values(filtered)
      .filter(this.filterSpeedConsumptions)
      .map(item => {
        const ifo =
          item.ifo && item.ifo?.typeIfo
            ? { ...item.ifo }
            : { cons: item.ifo?.cons };
        const mdo =
          item.mdo && item.mdo?.typeMdo
            ? { ...item.mdo }
            : { cons: item.mdo?.cons };
        return {
          ...item,
          ifo,
          mdo
        };
      });
    return speedConsumptionV2;
  }

  handleSaveConsumptions = async (formData) => {
    try {
      const vessel = this.state.vessel || this.props.vessel;
      if (formData.speedConsumptionV2) {
        formData.speedConsumptionV2 = this.prepareSpeedConsumptions(formData.speedConsumptionV2);
      }
      let privateSave = false;
      if (formData?.hasOwnProperty('isPrivate')) {
        privateSave = formData.isPrivate;
        formData.isSpeedConsumptionV2Private = formData.isPrivate;
        delete formData.isPrivate;
      }

      const res = await this.props.updateSpeedConsumption(vessel._id, formData);
      let field = privateSave ? 'privateConsumptionsChanged' : 'publicConsumptionsChanged';
      this?.consumptions?.setChanged(field)(false)
      if (res.status === 200) {
        // to prevent reset other table (either public or private) we don't fetch new vessel info after consum,ptions change
        //await this.getVessel(this.props, true);
        PubSub.publish("UPDATE_RELEVANT_CARGOES", {
          vesselRequest: { vessel: vessel._id }
        });
        const field = privateSave ? 'speedConsumptionV2Private' : 'speedConsumptionV2';
        this.setState(state => ({
          ...state,
          vessel: {
            ...state.vessel,
            [field]: this.prepareSpeedConsumptions(formData.speedConsumptionV2)
          }
        }))
        //this?.consumptions?.resetTable(privateSave ? 'privateKey' : 'publicKey');
      }
    } catch (error) {
      console.error(error)
    }
  };

  invalidSubmit = (model) => {
    console.log(model);
    const invalid =
      document.querySelector(`.${v.content} input[data-valid=false]`) ||
      document.querySelector(`.${v.content} .error`) ||
      document.querySelector(`.${v.content} .with_error`);
    console.log(
      "form inputs",
      this.refs.form.inputs.filter(input => !input.state._isValid)
    );
    this.openCollapsesWithErrors();
    if (invalid) {
      /* this.setState({
            hasPlugs: true
        }, () => invalid.scrollIntoView()) */
      scrollToAnchor(invalid);
    }
  };
  openCollapsesWithErrors = () => {
    for (let ref in this.collapses) {
      if (this.collapses[ref] && this.collapses[ref].findInvalidInputs) {
        this.collapses[ref].findInvalidInputs();
      }
    }
  };
  onFilesUploaded = (validatedFiles, uploadedFiles) => {
    const newAttachments = [...this.state.attachments, ...(uploadedFiles.map( f => ({ ...f, canEdit: true,visibility:'private' })))];
    this.setState(
      state => ({ attachments: newAttachments }),
      this.checkForAttachmentsChanges.bind(this, newAttachments)
    );
    this.collapses.attachmentsConsCollapse &&
      this.collapses.attachmentsConsCollapse.show();
  };

  checkForAttachmentsChanges = newAttachments => {
    const noChanges =
      newAttachments.every(
        (attachment, i) =>
          this.state.vessel.attachments.findIndex(
            a => a._id === attachment._id
          ) >= 0
      ) && newAttachments.length === this.state.vessel.attachments.length;
    this.setState(state => ({
      attachmentsChanged: !noChanges,
    }));
  };

  handleAttachmentVisibility = (id) => {
    this.setState(prevState => {
      const newAttachments = prevState.attachments.map(attachment => 
        attachment._id === id 
          ? { ...attachment, visibility: attachment.visibility === 'private' ? 'public' : 'private' } 
          : attachment
      );
      return { attachments: newAttachments };
    });
  }

  removeAttachment = (e, id) => {
    const newAttachments = this.state.attachments.filter(
      attachment => attachment?._id !== id
    );
    this.setState(
      state => ({
        attachments: newAttachments,
        attachmentsKey: state.attachmentsKey + 1 || 1
      }),
      this.checkForAttachmentsChanges.bind(this, newAttachments)
    );
  };
  vesselStatusChange = statuses => {
    const newVessel = { ...this.state.vessel };
    const groups = { ...newVessel.userGroups };
    groups.groups = [...statuses];
    newVessel.userGroups = groups;
    this.setState(state => ({
      ...state,
      vessel: newVessel
    }))
  }

  updateTags = tags => {
    const val = mergeTags(tags);
    this.props.updateVesselsList({ vesselId: this.state.vessel._id, data: val });
  }
  handleSaveTags = async (id, tags) => {
   return await TagsApi.saveVesselTags(id, tags);
  }

  descOverflow = () => this.description ? this.description.scrollHeight > 54 : false

  changeAdditionalDesc = (val) => {
    const { additionalDescription } = this.state;
    let obj = typeof val === 'object' ? { ...val } : { ...additionalDescription, value: val };
    if (typeof val === 'object') {
      this.setState(state => ({...state, vessel: { ...(state.vessel || {}), additionalDescription: obj }}));
    }
    this.setState(state => ({...state, additionalDescription: obj }))
  }
  resetAdditionalDescription = () => this.setState(state => ({ ...state, additionalDescription: state.vessel?.additionalDescription || "" }))

  openHireIndices = () => {
    this.setState({ hireIndicesOpened: true });
  };

  closeHireIndices = () => {
    this.setState({ hireIndicesOpened: false });
  };

  render() {
    const vesselRequest = this.props.vessel;
    const vessel = this.state.vessel;
    const salesOffers = this.state.salesOffers;
    const user = this.props.user;
    const isTank = vessel?.typeByPurpose === "TANK";
    const preparedEngines = vessel?.preparedEngines;
    if (!vessel || !vessel._id) {
      return (
        <div style={{ height: "636px", width: "100%" }}>
          {" "}
          <Loader />
        </div>
      );
    }
    const vesselPosition = this.state.vesselPosition;
    const channels = this.state.channels;
    const {
      exclusionsChanged,
      preferencesChanged,
      exclKey,
      prefKey,
      consumptionsChanged,
      consKey,
      attachmentsChanged,
      attachments,
      attachmentsKey
    } = this.state;
    const { dontShowRatingAndComments, headerStyles } = this.props;
    if (this.state.rating.average && !dontShowRatingAndComments) {
      vessel.rating = this.state.rating;
    }
    const isOwner = vessel?.isUserOwner || false;
    const tags = vessel.tags || [];
    const shouldHideTweendeckProperties = vessel?.typeByConstruction?.toLowerCase() === 'sid'; 
    const isSubscribed = isUserSubscribedToMarketplace(this.props.user);
    return (
      <div className={cx(s.root, this.props.monitor ? s.root_monitor : "", s.scrollable_flex_wrapper, !user ? '' : null )}>
        <ChangesTriangle vessel={vessel}></ChangesTriangle>
        {this.props.dialog ? (
          <h2 className={s.name_in_dialog}>{vesselRequest.name}</h2>
        ) : null}
        {this.state.vessel ? (
          <div
            className={cx("container-fluid", s.wrapper_vessel_preview)}
            style={{ paddingBottom: "60px", width: "100%" }}
          >
            {this.props.displayCommonHeader && <CommonVesselHeader headerStyles={headerStyles} handleClose={this.props.handleClose} />}
            <div className="row" className={s.main_vessel_details}>
              <VesselGeneralInfo
                channels={channels}
                dontShowRatingAndComments={dontShowRatingAndComments}
                dialog={this.props.dialog}
                vessel={vessel}
                vesselPosition={vesselPosition}
                vesselRequest={vesselRequest}
                salesOffers={salesOffers}
                afterSend={this.refresh}
                getForSaleInfo={this.getForSaleInfo}
                refetchVessel={this.refetchVessel}
                handleVesselStatus={this.vesselStatusChange}
                user={this.props.user}
                openHireIndices={this.openHireIndices}
                showHireIndexIcon={this.props.showHireIndexIcon}
              />
              <Tags handleSuccess={this.updateTags} handleSave={this.handleSaveTags} entityId={vessel._id} tags={tags} entity="cargoRequest"/>
              <div className={s.first_collapse}></div>
              {(channels && channels[0]) || !user ? (
                <Collapse
                  defaultOpen={true}
                  title="Open Positions"
                  locked={!user}
                  lockIcon={<ForLoggedInTooltip iconStyle={{position: 'absolute', top: '6px'}} />}
                >
                  <div key="1" style={{ width: "100%" }}>
                    <Channels
                      emailDialogPosition={this.props.emailDialogPosition }
                      channels={channels}
                      cargo={this.props.cargo}
                      vessel={this.state.vessel}
                      orderId={this.props.orderId}
                      basePath={this.props.basePath}
                      channelsChanged={this.props.onAddedChannel}
                      changePositionStatus={this.changePositionStatus}
                    />
                  </div>
                </Collapse>
              ) : null
              }
              {this.props.showDescription ?
                <Collapse innerRef={instance => this.collapses.description = instance} defaultOpen title="Information">
                  <VesselDescription vessel={this.props.vessel} />
                </Collapse>
                : null}
              {salesOffers?.isExist && !salesOffers?.loading && (
                <Collapse defaultOpen={true} title="For Sale">
                  <ForSaleWrapper>
                    {salesOffers.forSaleList.map(offer => (
                      <ForSaleOffer data={offer} key={offer._id} getForSaleInfo={this.getForSaleInfo} vessel={vessel} />
                    ))}
                  </ForSaleWrapper>
                </Collapse>
              )}
              <SelectableElement entity={this.props.selectEntity || "vessels"} entityId={vessel._id} type="vessel imo" id={vessel.imoNumber}>
                <Collapse innerRef={instance => this.collapses.vesselParticulars = instance} defaultOpen={true} title="Vessel Particulars">
                <div style={{display: 'flex', alignItems: 'center', paddingTop: 3, marginBottom:'-16px', minHeight:'44px'}}>
                  <div style={{maxWidth: '127px', minWidth: '127px'}} className={cx("col", "preview_label", s.label)}>
                    DISPONENT OWNER
                  </div>
                  {
                      !isSubscribed || !vessel?.disponentOwnerEditable
                      ? (
                        <div
                          className={cx(
                            "col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <BlurIfNoSubscription showPlaceholder user={this.props.user}>
                            {vessel?.disponentOwner?.name || '---'}
                          </BlurIfNoSubscription>
                        </div>
                      )
                      : (
                        <div style={{ width: '100%' }}>
                            <SeparateCompanyAutocomplete fieldSaved={this.handleDisponentOwner} _id={vessel?._id} value={vessel?.disponentOwner}></SeparateCompanyAutocomplete>
                        </div>
                      )
                  }
                </div>
                  <div className={s.vessel_columns}>
                    <div className={cx(s.vessel_column, s.first)}>

                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          NAME
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {this.state.vessel.name
                            ? this.state.vessel.name
                            : "---"}
                        </div>
                      </div>
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          DEADWEIGHT
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {number(vessel.deadWeight?.sDWT,'', 'mt')}
                        </div>
                      </div>
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          IMO
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel.imoNumber ? vessel.imoNumber : "---"}
                        </div>
                      </div>

                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          FLAG
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel.flag && vessel.flag.name
                            ? vessel.flag.name
                            : "---"}
                        </div>
                      </div>
                      {isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          ICE CLASS
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel?.techEquipCertificate?.iceClass
                            ? vessel.techEquipCertificate.iceClass
                            : "---"}
                        </div>
                      </div>}
                      {isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          OFFICERS / CREW
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel.nationalities?.map(n => n.names[0]).join(' / ') || "---"}
                        </div>
                      </div>}
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          YEAR
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel.blt || "---"}
                        </div>
                      </div>
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          SHIP OWNER
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <div className={s.blue}>
                            <BlurIfNoSubscription>
                              {vessel?.registeredOwner?.name || "---"}
                            </BlurIfNoSubscription>
                          </div>
                        </div>
                      </div>
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          SHIP MANAGER
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <div className={s.blue}>
                            <BlurIfNoSubscription>
                              {vessel?.manager?.name || "---"}
                            </BlurIfNoSubscription>
                          </div>
                        </div>
                      </div>
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          BUILDER COUNTRY
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel?.builderV2?.country?.name || "---"}
                        </div>
                      </div>
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          BUILDER YARD
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel?.builderV2?.yard?.name || "---"}
                        </div>
                      </div>
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          PORT OF REGISTRY
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel.homePort ? vessel.homePort.name : "---"}
                        </div>
                      </div>
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          CLASS
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel?.registerClasses?.length ? (
                            vessel?.registerClasses.map((rc, i) => {
                                if (rc.name || rc.iacsRegister) {
                                  return (<div>
                                    {rc?.name}{i !== vessel?.registerClasses?.length - 1 && !rc.iacsRegister ? ", " : null}
                                    {rc.iacsRegister ? (
                                      <div>IACS register {i !== vessel?.registerClasses?.length - 1 ? ", " : null}</div>
                                    ) : null}
                                  </div>);
                                }
                                return "---";
                            })
                          ) : (
                            "---"
                          )}
                        </div>
                      </div>
                      {!isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          ICE CLASS
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel?.techEquipCertificate?.iceClass
                            ? vessel.techEquipCertificate.iceClass
                            : "---"}
                        </div>
                      </div>}
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          P&I CLUB
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel?.piClub?.name ? vessel.piClub.name : "---"}
                        </div>
                      </div>
                      {isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          IMO TYPE
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel.techEquipCertificate?.imoClass
                            ? vessel.techEquipCertificate?.imoClass
                            : "---"}
                        </div>
                      </div>}
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          TYPE BY PURPOSE
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {formatTypeByPurposePlural(vessel?.typeByPurpose, 1) || "---"}
                        </div>
                      </div>
                      {!isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          BY CONSTRUCTION
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {mapTypeByConstruction(vessel?.typeByConstruction)|| "---"}
                        </div>
                      </div>}
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          BY SPECIALTY
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel?.typeBySpecialty || "---"}
                        </div>
                      </div>
                      {isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          CLASS NOTATION
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel.classNotation || "---"}
                        </div>
                      </div>}
                      {!isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          DESIGN
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel?.design?.name || "---"}
                        </div>
                      </div>}
                      {!isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          OTHER
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {isObjHasBoolean({
                            openHatch: vessel?.openHatch,
                            boxShaped: vessel?.boxShaped
                          }) ? (
                            <div>
                              {<div>{fittedToString('Open Hatch', vessel.openHatch)}</div>}
                              {<div>{fittedToString('Box Shaped', vessel.boxShaped)}</div>}
                            </div>
                          ) : (
                            <div>---</div>
                          ) }
                        </div>
                      </div>}
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          TONNAGE
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <div>
                            • GT/NT: {number(vessel.rt && vessel.rt.gt)} /{" "}
                            {number(vessel.rt && vessel.rt.nt)}
                          </div>
                          <div>
                            • Suez GT/NT: {number(vessel.rt && vessel.rt.suezGt)}{" "}
                            / {number(vessel.rt && vessel.rt.suezNt)}
                          </div>
                          <div>
                            • Panama GT/NT:{" "}
                            {number(vessel.rt && vessel.rt.panamaGt)} /{" "}
                            {number(vessel.rt && vessel.rt.panamaNt)}
                          </div>
                        </div>
                      </div>
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          DEADWEIGHT/OTHER
                        </div>

                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <table>
                            <tbody>
                              <tr className={s.bold}>
                                <td>&nbsp;</td>
                                <td className={s.dwt_table}>Load&nbsp;dis.</td>
                                <td className={s.dwt_table}>DWT,&nbsp;mt</td>
                                <td className={s.dwt_table}>Draft,&nbsp;m</td>
                              </tr>
                              <tr>
                                <td className={s.bold}>S:</td>
                                <td>{number(+vessel?.deadWeight?.sLoaded)}</td>
                                <td>{number(+vessel?.deadWeight?.sDWT)} </td>
                                <td>{number(+vessel?.deadWeight?.sDraft)}</td>
                              </tr>
                              {!isTank && <tr>
                                <td className={s.bold}>T:</td>
                                <td>{number(+vessel?.deadWeight?.tLoaded)}</td>
                                <td>{number(+vessel?.deadWeight?.tDWT)}</td>
                                <td>{number(+vessel?.deadWeight?.tDraft)}</td>
                              </tr>}
                              {!isTank && <tr>
                                <td className={s.bold}>W:</td>
                                <td>{number(+vessel?.deadWeight?.wLoaded)}</td>
                                <td>{number(+vessel?.deadWeight?.wDWT)}</td>
                                <td>{number(+vessel?.deadWeight?.wDraft)}</td>
                              </tr>}
                              {!isTank && <tr>
                                <td className={s.bold}>F:</td>
                                <td>{number(+vessel?.deadWeight?.fLoaded)}</td>
                                <td>{number(+vessel?.deadWeight?.fDWT)} </td>
                                <td>{number(+vessel?.deadWeight?.fDraft)}</td>
                              </tr>}
                            </tbody>
                          </table>

                        </div>
                      </div>
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          TPC/TPI
                        </div>

                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <table>
                            <tbody>
                              <tr className={s.bold}>
                                <td>&nbsp;</td>
                                <td className={s.dwt_table}>TPC</td>
                                <td className={s.dwt_table}>TPI</td>
                              </tr>
                              <tr>
                                <td className={s.bold}>S:</td>
                                <td>{number(+vessel?.deadWeight?.sTpc)}</td>
                                <td>{number(+vessel?.deadWeight?.sTpi)}</td>
                              </tr>
                              {!isTank && <tr>
                                <td className={s.bold}>T:</td>
                                <td>{number(+vessel?.deadWeight?.tTpc)}</td>
                                <td>{number(+vessel?.deadWeight?.tTpi)}</td>
                              </tr>}
                              {!isTank && <tr>
                                <td className={s.bold}>W:</td>
                                <td>{number(+vessel?.deadWeight?.wTpc)}</td>
                                <td>{number(+vessel?.deadWeight?.wTpi)}</td>
                              </tr>}
                              {!isTank && <tr>
                                <td className={s.bold}>F:</td>
                                <td>{number(+vessel?.deadWeight?.fTpc)}</td>
                                <td>{number(+vessel?.deadWeight?.fTpi)}</td>
                              </tr>}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {!isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          CAPACITY
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <div>
                            • Grain:{" "}
                            {number(+vessel?.capacityV2?.grain, "", "m3")}{" "}
                          </div>
                          <div>
                            • Bale: {number(+vessel?.capacityV2?.bale, "", "m3")}{" "}
                          </div>
                          <div>
                            • TEU: {number(+vessel?.capacityV2?.teu, "", "")}
                          </div>
                          <div>
                            • FEU: {number(+vessel?.capacityV2?.feu, "", "")}
                          </div>
                          <div>
                            • Constant:{" "}
                            {number(+vessel?.capacityV2?.constant, "", "mt")}
                          </div>
                          <div className={s.bold}>Grain / Bale / TEU / FEU</div>
                          <div>Hold:</div>
                          {vessel?.capacityV2?.summary
                            ? vessel.capacityV2.summary.map(s =>
                                s?.hold ? (
                                  <div>
                                    •{" "}
                                    {this.renderSeparatedRow(
                                      {
                                        grain: "---",
                                        bale: "---",
                                        teu: "---",
                                        feu: "---",
                                        ...s.hold
                                      },
                                      number
                                    )}
                                  </div>
                                ) : (
                                  "---"
                                )
                              )
                            : "---"}
                          {!shouldHideTweendeckProperties && <div>Tweendeck:</div>}
                          {!shouldHideTweendeckProperties  && vessel?.capacityV2?.summary
                            ? vessel.capacityV2.summary.map(s =>
                                s?.tweenDeck ? (
                                  <div>
                                    •{" "}
                                    {this.renderSeparatedRow(
                                      {
                                        grain: "---",
                                        bale: "---",
                                        teu: "---",
                                        feu: "---",
                                        ...s.tweenDeck
                                      },
                                      number
                                    )}
                                  </div>
                                ) : (
                                  "---"
                                )
                              )
                            : "---"}
                        </div>
                      </div>}
                      {isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          TECH. EQUIPMENT
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <TechEquipmentTank obj={vessel.techEquipCertificate}/>
                        </div>
                      </div>}
                    </div>
                    <div className={cx(s.vessel_column, s.second)}>
                    {isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          TANK COATING
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                        {vessel.capacityTanker?.coatingType || "---"}
                        {vessel.capacityTanker?.wholeTank && " / whole tank"}
                        </div>
                      </div>}
                      {isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          CARGO CAPACITY
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <div>
                            • Cargo tank capacity (98%):{" "}
                            {number(
                              vessel.capacityTanker?.cargoCapacity,
                              "",
                              "m3"
                            )}
                          </div>
                          <div>
                            • Segregations:{" "}
                            {number(
                              vessel.capacityTanker?.segregation,
                              "",
                              " "
                            )}
                            {vessel.capacityTanker?.details}
                          </div>
                        </div>
                      </div>}
                      {isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          TANK CAPACITY
                        </div>

                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <table>
                            <tbody>
                              <tr className={s.bold}>
                                <td style={{ width: "16px" }}>&nbsp;</td>
                                <td className={s.dwt_table}>Port side</td>
                                <td style={{ width: "30px" }}>&nbsp;</td>
                                <td className={s.dwt_table}>Starboard side</td>
                              </tr>
                              {vessel.capacityTanker?.tanks?.map((t, i) =>
                                <tr>
                                  <td className={s.bold}>{i + 1}</td>
                                  <td style={{ textAlign: "right" }}>{number(t.port, "", "m3")}</td>
                                  <td style={{ width: "30px" }}>&nbsp;</td>
                                  <td>{number(t.starboard, "", "m3")}</td>
                                </tr>
                              )}
                              <tr>
                                <td className={s.bold}>&#931;</td>
                                <td style={{ textAlign: "right" }} className={s.bold}>{number(vessel.capacityTanker?.tanks?.reduce((acc, t) => acc + t.port, 0), "", "m3")}</td>
                                <td style={{ width: "30px" }}>&nbsp;</td>
                                <td className={s.bold}>{number(vessel.capacityTanker?.tanks?.reduce((acc, t) => acc + t.starboard, 0), "", "m3")}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>}
                      {isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          SLOP CAPACITY
                        </div>

                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <table>
                            <tbody>
                              <tr className={s.bold}>
                                <td style={{ width: "16px" }}>&nbsp;</td>
                                <td className={s.dwt_table}>Port side</td>
                                <td style={{ width: "30px" }}>&nbsp;</td>
                                <td className={s.dwt_table}>Starboard side</td>
                              </tr>
                              <tr>
                                <td className={s.bold}>&#931;</td>
                                <td style={{ textAlign: "right" }} className={s.bold}>{number(vessel.capacityTanker?.slops?.reduce((acc, t) => acc + t.port, 0), "", "m3")}</td>
                                <td style={{ width: "30px" }}>&nbsp;</td>
                                <td className={s.bold}>{number(vessel.capacityTanker?.slops?.reduce((acc, t) => acc + t.starboard, 0), "", "m3")}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>}
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          DIMENSIONS
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <div>
                            • LOA:{" "}
                            {number(
                              vessel.dimensions && vessel.dimensions.loa,
                              "",
                              "m"
                            )}
                          </div>
                          <div>
                            • Beam:{" "}
                            {number(
                              vessel.dimensions && vessel.dimensions.beam,
                              "",
                              "m"
                            )}
                          </div>
                          <div>
                            • LBP:{" "}
                            {number(
                              vessel.dimensions && vessel.dimensions.lbp,
                              "",
                              "m"
                            )}
                          </div>
                          <div>
                            • Depth:{" "}
                            {number(
                              vessel.dimensions && vessel.dimensions.depth,
                              "",
                              "m"
                            )}
                          </div>
                          {isTank && <div>
                            • Parallel body, loaded:{" "}
                            {number(
                              vessel.dimensions && vessel.dimensions.parallelBodyLoaded,
                              "",
                              "m"
                            )}
                          </div>}
                          {isTank && <div>
                            • Parallel body fore:{" "}
                            {number(
                              vessel.dimensions && vessel.dimensions.parallelBodyFore,
                              "",
                              "m"
                            )}
                          </div>}
                          {isTank && <div>
                            • Parallel body aft:{" "}
                            {number(
                              vessel.dimensions && vessel.dimensions.parallelBodyAft,
                              "",
                              "m"
                            )}
                          </div>}
                        </div>
                      </div>
                      {!isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          HOLDS / HATCHES
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <div>
                            • HO/ HA:{" "}
                            {number(vessel.holds && vessel.holds?.quantity)}/
                            {number(vessel.hatches && vessel.hatches?.quantity)}
                          </div>
                          <div>
                            • Hatch type:{" "}
                            {(vessel.hatches && vessel.hatches?.hatchType) ||
                              "---"}
                          </div>
                          <div>
                            • Area main deck:{" "}
                            {number(
                              vessel.areas && vessel.areas?.mainDeck,
                              "",
                              "m2"
                            )}{" "}
                          </div>
                          {!shouldHideTweendeckProperties &&  <div>
                            • Area tween deck:{" "}
                            {number(
                              vessel.areas && vessel.areas?.tweenDeck,
                              "",
                              "m2"
                            )}{" "}
                          </div>}
                          <div>
                            • Area weather deck:{" "}
                            {number(
                              vessel.areas && vessel.areas?.weatherDeck,
                              "",
                              "m2"
                            )}
                          </div>
                          <div>
                            • Total:{" "}
                            {number(
                              vessel.areas && vessel.areas?.total,
                              "",
                              "m2"
                            )}{" "}
                          </div>
                        </div>
                      </div>}
                      {!isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          HOLDS
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <table className={s.dims_table}>
                            <tbody>
                              <tr>
                                <th>L</th>
                                <th>B</th>
                                <th>H</th>
                                <th>Area</th>
                              </tr>
                              {vessel?.cells?.length ? (
                                vessel.cells.map((cell, i) => (
                                  <tr key={i}>
                                    <td>{number(cell?.holds?.l)}</td>
                                    <td>{number(cell?.holds?.b)}</td>
                                    <td>{number(cell?.holds?.h)}</td>
                                    <td>{number(cell?.holds?.area)}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="1">---</td>
                                  <td colSpan="1">---</td>
                                  <td colSpan="1">---</td>
                                  <td colSpan="1">---</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>}
                      {!isTank && !shouldHideTweendeckProperties && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          TWEENDECKS
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <table className={s.dims_table}>
                            <tbody>
                              <tr>
                                <th colSpan="1">L</th>
                                <th colSpan="1">B</th>
                                <th colSpan="1">H</th>
                                <th colSpan="1">Area</th>
                              </tr>
                              {vessel?.cells?.length ? (
                                vessel.cells.map((cell, i) =>
                                  cell?.tweenDecks && cell?.tweenDecks?.length ? (
                                    cell.tweenDecks.map((tw, index) => (
                                      <tr key={i + "row" + index}>
                                        <td colSpan="1">{number(tw?.l)}</td>
                                        <td colSpan="1">{number(tw?.b)}</td>
                                        <td colSpan="1">{number(tw?.h)}</td>
                                        <td colSpan="1">{number(tw?.area)}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="1">---</td>
                                      <td colSpan="1">---</td>
                                      <td colSpan="1">---</td>
                                      <td colSpan="1">---</td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <tr>
                                  <td colSpan="1">---</td>
                                  <td colSpan="1">---</td>
                                  <td colSpan="1">---</td>
                                  <td colSpan="1">---</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>}
                      {!isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          HATCHES
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <table
                            style={{ width: "75%" }}
                            className={s.dims_table}
                          >
                            <tbody>
                              <tr>
                                <th colSpan="1">L</th>
                                <th colSpan="1">B</th>
                                <th colSpan="1">Area</th>
                              </tr>
                              {vessel?.cells?.length ? (
                                vessel.cells.map((cell, i) =>
                                  cell?.hatches && cell?.hatches?.length ? (
                                    cell.hatches.map((tw, index) => (
                                      <tr key={i + "row" + index}>
                                        <td colSpan="1">{number(tw?.l)}</td>
                                        <td colSpan="1">{number(tw?.b)}</td>
                                        <td colSpan="1">{number(tw?.area)}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="1">---</td>
                                      <td colSpan="1">---</td>
                                      <td colSpan="1">---</td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <tr>
                                  <td colSpan="1">---</td>
                                  <td colSpan="1">---</td>
                                  <td colSpan="1">---</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>}
                      {/* {!isOwner ? (
                        <div className={s.vessel_row}>
                          <div className={cx("col", "preview_label", s.label)}>
                            SPEED & CONSUMPTION
                          </div>
                          <div
                            className={cx(
                              "col-12 col-md-auto preview_value grow",
                              s.value
                            )}
                          >
                            {this.renderConsumptions(vessel?.speedConsumptionV2)}
                          </div>
                        </div>
                      ) : null} */}
                      {isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          PUMPS & GEARS
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel.pumps?.cargo?.length ? <div className={s.bold}>Cargo Pumps:</div> : null}
                          {vessel.pumps?.cargo?.map((c, i) =>
                            <div>
                              • {c.type}
                              {number(
                                c.quantity,
                                "-",
                                ""
                              )}
                              {number(
                                c.capacity,
                                "-",
                                ""
                              )}
                            </div>
                          )}
                          {vessel.pumps?.ballast?.length ? <div className={s.bold}>Ballast Pumps:</div> : null}
                          {vessel.pumps?.ballast?.map((b, i) =>
                            <div>
                              • {b.type}
                              {number(
                                b.quantity,
                                "-",
                                ""
                              )}
                              {number(
                                b.capacity,
                                "-",
                                ""
                              )}
                            </div>
                          )}
                          {vessel?.gearV2 && vessel?.gearV2?.summary?.length ? <div className={s.bold}>Gears:</div> : null}
                          {(vessel?.gearV2 && vessel?.gearV2?.summary?.length) && vessel?.gearV2?.summary?.map((g, i) => {
                              return g.type && <div>
                              • {g.type}
                              {number(
                                g.quantity,
                                "-",
                                ""
                              )}
                              {number(
                                g.capacity,
                                "-",
                                ""
                              )}
                            </div>;
                            }
                          )}
                        </div>
                      </div>}
                      {isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          HEATING
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <div>{fittedToString('Heat Exchanger', vessel.capacityTanker?.heating?.exchanger)}</div>
                          <div>• Heating medium:{" "}
                            {vessel.capacityTanker?.heating?.medium || "---"}
                          </div>
                          <div>• Max loaded °C:{" "}
                            {vessel.capacityTanker?.heating?.maxLoaded || "---"}</div>
                          <div>• Max maintained °C:{" "}
                            {vessel.capacityTanker?.heating?.maxMaintained || "---"}</div>
                        </div>
                      </div>}
                      {isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          MANIFOLDS
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel.manifolds && vessel.manifolds.list?.map(m =>
                            <div>•{number(
                              m?.number,
                              "",
                              "pc"
                            )}
                            {number(
                              m?.size,
                              "/",
                              "m"
                            )}
                            {m?.commonLine && " Common line"}
                            </div>
                          )}
                          <div>• BCM:{" "}
                            {number(
                              vessel.manifolds && vessel.manifolds.bcm,
                              "",
                              "m"
                            )}</div>
                          <div>• SCM:{" "}
                            {number(
                              vessel.manifolds && vessel.manifolds.scm,
                              "",
                              "m"
                            )}</div>
                          <div>• Between manifolds:{" "}
                            {number(
                              vessel.manifolds && vessel.manifolds.betweenManifolds,
                              "",
                              "m"
                            )}</div>
                        </div>
                      </div>}
                      <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          BUNKERS
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {isTank ? this.renderBunkersTank(vessel?.bunkers) : this.renderBunkers(vessel?.bunkers)}
                        </div>
                      </div>
                      {isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          ENGINES
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {preparedEngines && Object.keys(preparedEngines)?.length ?
                            Object.keys(preparedEngines)?.map(pe =>
                              <div>
                                <div className={s.bold}>{pe}:</div>
                                {preparedEngines[pe].map(e =>
                                  <div>• {number(
                                    e?.number,
                                    "",
                                    "pcs"
                                  )}
                                  {number(
                                    e?.capacity,
                                    "/",
                                    "kW"
                                  )}
                                  {number(
                                    e?.rpm,
                                    "/",
                                    "rpm/"
                                  )}
                                  {e?.makeType ? e?.makeType : "---"}
                                  </div>
                                )}
                              </div>
                            ) : "---"}
                        </div>
                      </div>}
                      {!isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          PERMISSIBLE LOADS
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <div>
                            • Max TT strength:{" "}
                            {(vessel.strength && number(vessel.strength.maxTt)) ||
                              "---"}
                          </div>
                          {!shouldHideTweendeckProperties &&  <div>
                            • Max Tween strength:{" "}
                            {(vessel.strength &&
                              number(vessel.strength.maxTween)) ||
                              "---"}
                          </div>}
                          <div>
                            • Max Weather Deck strength:{" "}
                            {(vessel.strength &&
                              number(vessel.strength.maxWeather)) ||
                              "---"}
                          </div>
                          <div>
                            • 20' Max stackweight hold:{" "}
                            {(vessel.stackWeight &&
                              number(vessel.stackWeight["20hold"])) ||
                              "---"}
                          </div>
                          <div>
                            • 20' Max stackweight deck:{" "}
                            {(vessel.stackWeight &&
                              number(vessel.stackWeight["20deck"])) ||
                              "---"}
                          </div>
                          <div>
                            • 40' Max stackweight hold:{" "}
                            {(vessel.stackWeight &&
                              number(vessel.stackWeight["40hold"])) ||
                              "---"}
                          </div>
                          <div>
                            • 40' Max stackweight deck:{" "}
                            {(vessel.stackWeight &&
                              number(vessel.stackWeight["40deck"])) ||
                              "---"}
                          </div>
                        </div>
                      </div>}
                      {!isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          GEAR & GRABS
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <div className={s.bold}>Gear/Outreach/SWL:</div>
                          {vessel?.geared ? <div>Geared</div> : vessel?.geared === false ? <div>Gearless</div>: null}

                          {vessel?.geared && vessel?.gearV2 && vessel?.gearV2?.summary?.length ? (
                            vessel?.gearV2?.summary.map(
                              (
                                {
                                  type = "---",
                                  outreach = " --- ",
                                  maxSWL = " ---",
                                  quantity = "",
                                  capacity = "",
                                },
                                i
                              ) => (
                                <div key={i}>
                                  {this.renderSeparatedRow({
                                    type: `${type}${
                                      quantity ? " - " + quantity : ""
                                    }${capacity ? " - " + capacity : ""}`,
                                    outreach,
                                    maxSWL: `${maxSWL ? number(maxSWL) : ""}`,
                                  }, undefined, " - ")}
                                </div>
                              )
                            )
                          ) : vessel?.geared !== false ? (
                            <div>---</div>
                          ) : null}
                          <div className={s.bold}>Grabs</div>
                          {vessel?.grabbed ? <div>Grab Fitted</div> : null}
                          {vessel?.grabV2 && vessel?.grabV2?.summary ? (
                            vessel?.grabV2?.summary.map(
                              (
                                { type = "---", quantity = "", volume = "" },
                                i
                              ) => (
                                <div key={i}>{`${type || "---"}${
                                  quantity ? " - " + quantity : " - --- "
                                }${volume ? " - " + number(volume) : " - --- "}`}</div>
                              )
                            )
                          ) : (
                            <div>---</div>
                          )}
                        </div>
                      </div>}
                      {!isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          TECH. EQUIPMENT
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          <TechEquipment obj={vessel.techEquipCertificate} />
                        </div>
                      </div>}
                      {!isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          ENGINE LOCATION
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel.engineLocation || "---"}
                        </div>
                      </div>}
                      {!isTank && <div className={s.vessel_row}>
                        <div className={cx("col", "preview_label", s.label)}>
                          LANE METER
                        </div>
                        <div
                          className={cx(
                            "col-12 col-md-auto preview_value grow",
                            s.value
                          )}
                        >
                          {vessel.linerMeter ? number(vessel.linerMeter, "", " M") : "---"}
                        </div>
                      </div>}
                    </div>
                  </div>
                    <div className="row">
                      <div className={cx("col-12", "preview_label", s.label, s.description)}>
                        DESCRIPTION
                      </div>
                    </div>
                    <div className="row">
                      <div style={{ width: "100%" }}>
                        <div
                          style={{ lineHeight: "18px", overflow: "hidden", textOverflow: "ellipsis" }}
                          className={cx("preview_value grow", s.value, s.main_desk, this.state.clampDescription && s.description)}
                          ref={el => this.description = el}
                          title={vessel?.description}
                        >
                          {vessel?.description || "---"}
                        </div>
                        {this.descOverflow() ? <span className={s.toggle_desc} onClick={this.toggleDescription}>{this.state.clampDescription ? 'Show more' : 'Show less'}</span> : null}
                      </div>
                    </div>
                    <div className="row">
                      {this.props.user ?
                        <AdditionalDescription resetValue={this.resetAdditionalDescription} handler={this.changeAdditionalDesc} vessel={vessel} additionalDescription={this.state.additionalDescription} />
                        : null}
                    </div>

                    <ExtendedForm
                      ref="form"
                      noValidate
                      onValidSubmit={this.handleSaveExclusions}
                      onInvalidSubmit={this.invalidSubmit}
                    >
                      <div className="row" style={{ marginTop: "12px" }}>
                        <div className={cx("col-12", "preview_label", s.label)}>
                          Exclusions
                          {vessel?.isUserOwner || !user ? null : (
                            <CompanyChangesTooltip
                              offset={{ x: 12, y: 0 }}
                              whiteArrow={false}
                              horizontalPosition="right"
                              classes={s.darkAlert}
                            />
                          )}
                          {!user ? <ForLoggedInTooltip iconStyle={{position: 'absolute', top: '2px'}} />
                            : null}
                        </div>
                      </div>
                      {user && <div className="row" style={{ paddingBottom: "16px" }}>
                        <Exclusions
                          key={exclKey}
                          changesCallback={this.setExclusionsChange}
                          attachAsRef={instance => (this.exclusions = instance)}
                          userRole={this.props.user?.role}
                          isVesselOwner={isOwner}
                          cargoExclusions={vessel?.cargoExclusionsV2}
                          tradingRestrictions={vessel?.tradingRestrictions}
                          isChanged={exclusionsChanged}
                        ></Exclusions>
                      </div> }
                      <HiddenButtons
                        shouldShow={exclusionsChanged}
                        handleSave={this.handleSaveExclusions}
                        handleCancel={this.cancelExclusions}
                      ></HiddenButtons>
                    </ExtendedForm>

                  <ExtendedForm
                    ref="form"
                    noValidate
                    onValidSubmit={this.handleSavePreferences}
                    onInvalidSubmit={this.invalidSubmit}
                  >
                    <div className="row" style={{ marginTop: "12px" }}>
                      <div className={cx("col-12", "preview_label", s.label)}>
                        Preferences
                        {vessel?.isUserOwner || !user ? null : (
                          <CompanyChangesTooltip
                            offset={{ x: 12, y: 0 }}
                            whiteArrow={false}
                            horizontalPosition="right"
                            classes={s.darkAlert}
                          />
                        )}
                        {!user ? <ForLoggedInTooltip iconStyle={{position: 'absolute', top: '2px'}} />
                          : null}
                      </div>
                    </div>
                    {user && <div className="row" style={{ paddingBottom: "16px" }}>
                      <Exclusions
                        key={prefKey}
                        changesCallback={this.setPreferencesChange}
                        attachAsRef={instance => (this.preferences = instance)}
                        userRole={this.props.user?.role}
                        isVesselOwner={isOwner}
                        cargoExclusions={vessel?.cargoPreferences}
                        tradingRestrictions={vessel?.areaPreferences}
                        cargosFieldName={"cargoPreferences"}
                        cargosFieldLabel={"Cargo"}
                        areasFieldName={"areaPreferences"}
                        areasFieldLabel={"Trading Area"}
                        isChanged={preferencesChanged}
                      ></Exclusions>
                    </div> }
                    <HiddenButtons
                      shouldShow={preferencesChanged}
                      handleSave={this.handleSavePreferences}
                      handleCancel={this.cancelPreferences}
                    ></HiddenButtons>
                  </ExtendedForm>

                </Collapse>
              </SelectableElement>
              {(
                <Formsy.Form
                  ref="consumptionsForm"
                  noValidate
                  onValidSubmit={this.handleSaveConsumptions}
                  onInvalidSubmit={this.invalidSubmit}
                >
                  <Collapse
                    defaultOpen={true}
                    innerRef={el => (this.collapses.speedConsCollapse = el)}
                    titleClass={v.collapse_title}
                    title="SPEED & CONSUMPTION"
                    noBorder
                    locked={!user}
                    lockIcon={<ForLoggedInTooltip iconStyle={{position: 'absolute', top: '6px'}} />}
                  >
                    <PublicPrivateSpeedCons
                      companyId={this.props.user?.company?._id}
                      isVesselOwnerInSameCompany={vessel?.isVesselOwnerInSameCompany}
                      publicCons={vessel?.speedConsumptionV2}
                      privateCons={vessel?.speedConsumptionV2Private}
                      attachRef={el => this.consumptions = el}
                    />
                  </Collapse>
                </Formsy.Form>
              )}
              <WithUserUploads key={attachmentsKey} shouldUpload onSuccess={this.onFilesUploaded} maxFiles={Number.MAX_VALUE} privateAttachment={true}>
                {({ erroredFiles, validateUserUploads, uploadedFiles, accept, uploading }) => {
                  return (<Collapse
                    defaultOpen={true}
                    innerRef={el => (this.collapses.attachmentsConsCollapse = el)}
                    titleClass={v.collapse_title}
                    title="ATTACHMENTS"
                    locked={!user}
                    lockIcon={<ForLoggedInTooltip iconStyle={{position: 'absolute', top: '6px'}} />}
                    additionals={
                      <div style={{ display: "flex" }}>
                    <span style={{ marginRight: 12, marginTop: "-2px" }}>
                      {vessel?.isVesselOwnerInSameCompany ? null : (
                        <DarkCompanyChangesTooltip />
                      )}
                    </span>
                        <AddAttachment
                          key={attachmentsKey}
                          changesCallback={this.setAttachmentsChange}
                          success={validateUserUploads}
                          uploadOpts={{privateAttachment: !(vessel?.isVesselOwnerInSameCompany || user?.role === 'moderator' || user?.role === 'admin')}}
                        />
                      </div>
                    }
                  >
                    <ExtendedForm
                      ref="attachmentsForm"
                      noValidate
                      onValidSubmit={this.handleSaveAttachments}
                      onInvalidSubmit={this.invalidSubmit}
                    >
                      <Attachments
                        isUserOwner={vessel?.isUserOwner}
                        changesCallback={this.setAttachmentsChange}
                        handleRemove={this.removeAttachment}
                        handleAttachmentVisibility={this.handleAttachmentVisibility}
                        attachments={attachments}
                      />
                      <div>{erroredFiles.length ? <UploadedFileExtensionErr className={'upload_error'} accept={accept} erroredFiles={erroredFiles} /> : null}</div>
                      <HiddenButtons
                        shouldShow={attachmentsChanged}
                        handleCancel={this.cancelAttachments}
                      />
                    </ExtendedForm>
                  </Collapse>)
                }}
              </WithUserUploads>
              <Collapse
                defaultOpen={true}
                titleClass={v.collapse_title}
                innerRef={el => (this.collapses.previousPortsCollapse = el)}
                title="PREVIOUS PORTS"
              >
                  <PreviousPorts vessel={vessel} />
              </Collapse>
              <Collapse
                defaultOpen={true}
                innerRef={el => (this.collapses.pastNamesConsCollapse = el)}
                titleClass={v.collapse_title}
                title="PAST NAMES"
              >
                <PastNames pastNames={vessel?.exNames}></PastNames>
              </Collapse>
              {!dontShowRatingAndComments
                ?
                  <div style={{width: '100%'}}>
                    <RatingComments
                      isCollapsible
                      collapseProps={{
                        additionals: (
                          <DisplayRating
                            dark={true}
                            average={vessel?.rating?.average}
                          />
                        ),
                        innerRef: el => (this.collapses.commentsCollapse = el),
                        titleClass: v.collapse_title,
                        locked: !user,
                        lockIcon: <ForLoggedInTooltip iconStyle={{position: 'absolute', top: '6px'}} />
                      }}
                      updateRating={this.handleUpdateVesselRating}
                      dark
                      entityType={'vessel'}
                      entity={vessel} />
                  </div>
                : null}
            </div>
          </div>
        ) : (
          <div style={{ minHeight: "700px" }} />
        )}
        {this.props.monitor && this.state.vessel ? (
          <VesselFooter vessel={vessel} updateVessel={this.updateVessel} salesOffers={salesOffers}
                        getForSaleInfo={this.getForSaleInfo} prepareState={this.prepareState} channels={channels}
                        closeHireIndices={this.closeHireIndices} openHireIndices={this.openHireIndices}
          />
        ) : null}
        {this.state.hireIndicesOpened ?
          <DialogHalfScreen open {...this.props.hireDialogProps}>
            <HireIndices vessel={vessel} onClose={this.closeHireIndices} />
          </DialogHalfScreen>
          : null}
      </div>
    );
  }
}

function VesselRatingView({ rating }) {
  return (
    <span className="flex">
      {rating?.average ? (
        <StarIcon
          style={{
            width: "16px",
            height: "16px"
          }}
          color="#82C3FF"
        />
      ) : (
        <StarIconBorder
          style={{
            position: "relative",
            width: "15px",
            height: "15px"
          }}
          color="#82C3FF"
        />
      )}
      <span className={s.rating_toggle}>
        {rating?.average ? (rating.average * 5).toFixed(1) : "0.0"}
      </span>
    </span>
  );
}

class VesselGeneralInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMap: false,
      zoomedImg: false
    };
  }
  handleZoomImage = url => this.setState({zoomedImg: url})
  _onOpenMap = () =>  this.setState({
    showMap: !this.state.showMap,
  })
  handleCloseZoom = () => {
    this.setState({ zoomedImg: null });
  };
  printPdf = async () => {
    let { vessel, vesselPosition, vesselRequest } = this.props;
    vessel = Object.assign({}, vessel);
    vesselRequest = vesselRequest ?? vessel;
    let route = null;
    if (vesselPosition && vesselPosition.route) route = vesselPosition.route;
    if (vessel.exNames) {
      vessel.exNames = this.padArray(
        Array.isArray(vessel.exNames) ? vessel.exNames.slice(-5).reverse() : [],
        5
      );
    }
    await Vessel.printPdf({
      vessel: {
        ...vessel,
        speedConsumptionV2: vesselRequest.speedConsumptionV2
          ? [...vesselRequest.speedConsumptionV2]
          : [...vessel.speedConsumptionV2]
      },
      route
    });
  };
  padArray = (arr, length) => {
    let pad = new Array(length - arr.length).fill(null);
    return arr.concat(pad);
  };

  render() {
    let {
      vesselRequest,
      vessel,
      vesselPosition,
      salesOffers,
      handleCheckSalesOffers,
      checked,
      dontShowRatingAndComments,
      channels
    } = this.props;
    vessel = vessel || vesselRequest;
    if (!vessel) return null;
    return (
      <div className={s.root} style={{ width: "100%" }}>
        <SelectableElement entity="ais" entityId={vessel._id} type="ais imo" id={vessel.imoNumber}>
          <div className={cx(s.wrapper_first_info)}>
            {vessel?.images && vessel?.images.length ? (
              <div className={cx(s.thumbs)}>
                {vessel.images?.map((image, i) => (
                  <div key={image._id || i} className={s.thumb}>
                    {!this.props.dialog ? (
                      <span>
                        <ZoomInIcon
                          color="#fffff"
                          style={{ width: 22, height: 22 }}
                          onClick={this.handleZoomImage.bind(this, image)}
                        />
                      </span>
                    ) : null}
                    <img alt={`${vessel.name}`} title={`${vessel.name}`} srcSet={image.url} />
                  </div>
                ))}
              </div>
            ) : null}
            <div
              className={s.vessel_right}
              style={{
                // width: vessel.images && vessel.images.length ? '58%' : '100%',
                width: "100%"
              }}
            >
              <div className={s.left_info_header}>
                <h1 className={s.vessel_name}>
                  <SanctionsFlag hasSanctions={vessel.hasSanctions} />
                  M/V {vessel.name}
                </h1>
                <div className={s.icons}>
                  {this.props.showHireIndexIcon ?
                    <HireIndicesIcon onClick={this.props.openHireIndices} />
                    : null}
                  <PrintIcon
                    style={{
                      width: "24px",
                      height: "24px",
                      cursor: "pointer"
                    }}
                    onClick={this.printPdf}
                    color="#999"
                    className={cx(s.print_button)}
                  />
                  {vessel.hasEmailsInExchange ? (
                    <Link to={`/emails/received/-/filter${encodeURIComponent("?imo=" + vessel.imoNumber)}/-/---/all-fleet/${vessel.imoNumber}-${vessel.name.replace(/ /g, '-').toLowerCase()}/-/-`}><ChatAndMailIcon /></Link>
                  ): null}
                  {dontShowRatingAndComments || !this.props.user ? null : (
                    <div className={s.raiting_wrapper}>
                      <div className={s.raiting_button_open}>
                        <VesselRating
                          whiteArrow={false}
                          dontMutate
                          vessel={vessel}
                          dark={false}
                          tooltipHorizontalPosition="left"
                        >
                          <VesselRatingView />
                        </VesselRating>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div style={!channels?.length && vessel?.onRoute || vessel?.lastUpdatedByOwner ? { marginTop: '-8px' } : {}} className={vesselPosition && !vesselRequest?.open ? "" : s.border_bottom}>
              <span>
                {
                  !channels?.length && vessel?.onRoute
                      ? (
                        <span className={s.on_route}>On route</span>
                      )
                      : null
                }
                {
                  vessel?.lastUpdatedByOwner
                    ? (
                      <span className={s.last_update}>
                        {
                          !channels?.length && vessel?.onRoute
                              ? <span>&nbsp;/&nbsp;</span>
                              : null
                        }
                        Last update: {
                          date(vessel.lastUpdatedByOwner)
                        }
                      </span>
                    )
                    : null
                }
              </span>
              {vesselRequest && vesselRequest.open
                ? [
                    <div
                      key="1"
                      className={cx(
                        "col-md-auto preview_value grow",
                        s.title_open
                      )}
                    >
                      <div>
                        Open{" "}
                        {
                          <PortLink
                            port={{
                              _id:
                                vesselRequest.open._id ||
                                vesselRequest.open.where[0],
                              name: vesselRequest.open.name
                            }}
                          />
                        }
                        ,{" "}
                        {dateRange(
                          vesselRequest.open.from,
                          vesselRequest.open.until
                        )}
                      </div>
                    </div>
                  ]
                : null}
              </div>
              <div className={cx(s.from_to, vesselRequest?.open ? s.with_open : "", vesselPosition ? s.ready : "")}>
                {vesselPosition && vesselPosition.route ? (
                  <div>
                    <ProgressVesselPosition onRoute={vesselPosition} />
                  </div>
                ) : null}
              </div>
                <div>
                  <div className={s.wrapper_coordinates}>
                    <div className={cx(s.wrapper_coordinates_btn, vessel?.images?.length ? s.collapsed : "")}>
                      {
                        vesselPosition &&
                        vesselPosition.lastPos &&
                        vesselPosition.lastPos.coords ? (
                          <div className={cx(s.bordered_btn, s.show_map)}>
                            <RoundBorderedBtn onClick={this._onOpenMap} labelStyle={{textTransform: 'initial'}}><LocationIcon />
                              {
                                this.state.showMap ? "Close map" : "Show on map"
                              }
                            </RoundBorderedBtn>
                          </div>
                        )
                        : null
                      }
                      <div className={cx(s.bordered_btn, s.add_position_button)}>
                        {
                          !dontShowRatingAndComments ? (
                            <AddPosition vessel={vessel} afterSend={this.props.afterSend} />
                          ) : null
                        }
                      </div>
                      <div className={cx(s.bordered_btn, s.vessel_status_button)}>
                        <VesselStatus
                          salesOffers={salesOffers}
                          getForSaleInfo={this.props.getForSaleInfo}
                          vessel={this.props?.vessel}
                          handleChange={this.props.handleVesselStatus}
                          selectedStatuses={this.props?.vessel?.userGroups?.groups}
                          isBlurred={this.props?.vessel?.userGroupsBlurred}
                        />
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </SelectableElement>
        {vesselPosition && this.state.showMap  ? (
          <div
            className={cx(s.map_wrapper)}
          >
            <MapPosition vessel={vesselPosition} />
          </div>
        ) : null}
        {vesselRequest &&
        vesselRequest.addedBy &&
        vesselRequest.addedBy.company &&
        !vesselRequest.channels
          ? [
              <div key={0} className={s.wrapper_data_vessel}>
                <div className="preview_label">SHIPNEXT USER</div>
                <div className={cx("preview_value grow")}>
                  {vesselRequest.addedBy.company.name}
                </div>
              </div>
            ]
          : null}
        {this.state.zoomedImg ? (
          <Dialog
            contentStyle={{ width: "100%", maxWidth: "min-content" }}
            autoScrollBodyContent
            open
            handleClose={this.handleCloseZoom}
          >
            <img
              role="presentation"
              style={{ maxWidth: "calc(100vw - 48px)" }}
              src={this.state.zoomedImg.url}
            />
          </Dialog>
        ) : null}
      </div>
    );
  }
}


VesselGeneralInfo = withStyles(s)(VesselGeneralInfo);
export const Gears = withStyles(s)(_Gears);
export const Grabs = withStyles(s)(_Grabs);
function CommonVesselHeader({ handleClose, headerStyles }) {
  return (
  <div className={s.common_header} style={headerStyles}>
    SHIP
    <NavigationClose className={s.close_icon} onClick={handleClose} />
  </div>);
}

export { VesselGeneralInfo };
export default connect(state => ({ user: state.login.user }), {
  openSubscribeDialog,
  vesselChangesSeen,
  updateExclusionsAndRestrictions,
  updateSpeedConsumption,
  updateAttachments,
  vesselChanged,
  updateSingleSalesOrder,
  getSnpList,
  updateVesselsList,
  updatePreferences,
})(withStyles(s, v)(VesselPreviewNew));
