exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".p22b8{position:relative;display:-ms-flexbox;display:flex;-ms-flex-align:end;align-items:flex-end}.p22b8 .fWf9l{display:-ms-flexbox;display:flex;height:48px;width:82px;margin-right:12px}.p22b8 .fWf9l>span>div>div:nth-child(4){position:absolute !important;bottom:-8px !important}.p22b8 ._11Hxf,.p22b8 ._2PRaI{position:relative;width:63px;height:24px;top:-10px;margin-top:-20px;margin-left:6px}.p22b8 ._11Hxf>div>div>div,.p22b8 ._2PRaI>div>div>div{margin-right:10px !important}\n", ""]);

// Exports
exports.locals = {
	"root": "p22b8",
	"commission": "fWf9l",
	"deductible": "_11Hxf",
	"pus": "_2PRaI"
};