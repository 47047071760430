import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import update from 'immutability-helper';
import Dialog from '../Common/Dialog';
import ButtonForm from './ButtonForm';
import { MenuItem } from 'material-ui/Menu/index';
import FormsyText from '../Common/FormsyText';
import Restrictions from './Restrictions';
import { Email, Phone } from '../Settings/ProfileEdit';
import s from './Port.scss';
import sg from './General.scss';
import Formy from 'formsy-react';
import cx from 'classnames';
import ContentAdd from 'material-ui/svg-icons/content/add';
import ContentRemove from 'material-ui/svg-icons/content/remove';
import ActionRestore from 'material-ui/svg-icons/action/settings-backup-restore';


import {
  floatingLabelStyle,
  floatingLabelFocusStyle,
  floatingLabelShrinkStyle,
  inputStyle,
  CRANE_GROUPS,
  availebleLiftCranes,
  WAREHOUSES,
  availableFacilities,
  restrictions,
  restrictionsService,
  serviceProviders,
  timezoneList,
  ACTIVITIES,
} from './settings';
import RaisedButton from '../Common/RaisedButton';
import Select from '../Common/Select';
import { removeFromArray, replaceInArray } from '../../core/utils';
import Collapse from '../Common/Collapse';
import AddCapacity from '../Icons/AddCapacity';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import ServiceProvider from "./ServiceProvider"


const Form = Formy.Form;

const labelStyle = { fontSize: '12px', lineHeight: '12px', color: '#999999', top: '46px', transform: 'scale(1) translate(0px, 0px)' };
const labelShrinkStyle = { transform: 'scale(1) translate(0px, -20px)' };

const providerSample = {status: 'initial', isSaveReady: false, activities: [''], name: '', address: '', PIC: '', emails: [], phones: [], limitations: [{}] };

export default class ServiceProviders extends PureComponent {

  static propTypes = {
    providers: PropTypes.array,
    originProviders: PropTypes.array,
    country: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {spChanged: props.providers.map(() => false), addable: true, providers: props.providers, originProviders: props.originProviders};
  }


  isChanged = () => this.state.spChanged.includes(true) || this.state.providers.find(p => p.status === 'initial' && !p.isSaveReady);
  handleChange = (i, value) => {
    const newChanged = [...this.state.spChanged];
    newChanged[i] = value;
    this.setState({spChanged: newChanged})
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.spChanged !== this.state.spChanged) {
      this.props.handleAddConfirmModal();
    }
  }

  getModel = () => {
    const providers = this.state.providers.filter(cr => cr.status !== 'removed');
    return providers.map(({isSaveReady, status, dirty, mergedEmails, mergedPhones, origin, history, ...p}) => ({
      ...p,
      _id: isFinite(p._id) ? undefined : p._id, //prevent sending custom id
      phones: p.phones.map(({status, formatted, ...ph}) => ({...ph})),
      limitations: p.limitations
        .map(({customId, removed, ...lim}) => {delete lim.new; return JSON.parse(JSON.stringify(lim))}) //convert values, delete unnecessary keys
        .map(item => ({
          ...item, //check if any field exist (except _id)
          shouldSave: item && Object.keys(item)?.length && Object.keys(item).filter(key => key !== "_id").some(key => !!item[key])
        }))
        .filter((item, index, self) => {
          //if only one lim exist, allow to save empty
          if (self.length === 1 || item.shouldSave) {
            delete item.shouldSave;
            return true;
          } else {
            return false;
          }
        }),
      activities: p.activities.map(activity => activity.toString()).filter(item => !!item)
    }));
  };

  handleSaveProvider = (e, newProvider) => {
    const index = this.state.providers.findIndex(pr => pr._id === newProvider._id);
    this.setState({
      providers: update(this.state.providers, {[index]: {$merge: newProvider}}),
    });
  };

  handleRemoveProvider = (index) => {
    this.props.handleAddConfirmModal();
    this.handleChange(index, false);

    let action;
    if (this.state.providers[index].status === 'initial') {
      action = { $splice: [[index, 1]] }
    } else {
      action = {[index]: {$merge: {status: 'removed'}}}
    }
    this.setState({providers: update(this.state.providers, action) });
  };

  handleAddProvider = () => {
    this.setState({providers: update(this.state.providers, {$push: [{...providerSample, _id: Math.random()}]})}, () => this.handleChange(this.state.providers.length - 1, true));
  };

  handleRestoreProvider = (index) => {
    const provider = this.state.providers[index];
    delete provider.status;

    this.setState({ providers: update(this.state.providers, {[index]: {$set: provider}})});
  };

  render() {
    return (<div className={s.edit_container}>
      <h2 className={s.edit_container_header}>
        Service providers
      </h2>
        <div className={s.wrapper_service_providers}>
          <div className={s.body}>
            {
              this.state.providers.map((provider, i) => (
                <ServiceProvider
                  key={i}
                  sp={provider}
                  originProviders={this.state.originProviders}
                  handleSaveSp={this.handleSaveProvider}
                  handleAddConfirmModal={this.props.handleAddConfirmModal}
                  handleRemove={this.handleRemoveProvider.bind(this, i)}
                  handleRestore={this.handleRestoreProvider.bind(this, i)}
                  parentChangeCb={this.handleChange.bind(this, i)}
                />
              ))
            }
          </div>
        </div>
      {this.state.addable && <div className={sg.add_capacity}>
        <div
          onClick={this.handleAddProvider}
          style={{
            marginRight: '12px',
            cursor: 'pointer',
          }}>
          <AddCapacity/>
        </div>
        <span>Add Service Provider</span>
      </div>}
    </div>);
  }
}
