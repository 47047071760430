import React from "react";
import pure from "recompose/pure";
import SvgIcon from "material-ui/SvgIcon";

let ContactBookIcon = ({ fill = "#76818D", opacity = 1 }) => (
  <span style={{ opacity: opacity }}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2561 4.4178H15.7715V7.45777H16.2561C16.943 7.45777 17.5 6.89913 17.5 6.21016V5.66518C17.5 4.97621 16.943 4.4178 16.2561 4.4178Z"
        fill={fill}
      />
      <path
        d="M16.2561 8.414H15.7715V11.4537H16.2561C16.943 11.4537 17.5 10.8953 17.5 10.2064V9.66138C17.5 8.97241 16.943 8.414 16.2561 8.414Z"
        fill={fill}
      />
      <path
        d="M16.2561 12.5425H15.7715V15.5825H16.2561C16.943 15.5825 17.5 15.0241 17.5 14.3349V13.7899C17.5 13.1012 16.943 12.5425 16.2561 12.5425Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4972 3.74716V16.2528C14.4972 16.9417 13.9404 17.5 13.2533 17.5H3.74385C3.05684 17.5 2.5 16.9417 2.5 16.2528V13.6651H3.64705C4.13217 13.6651 4.5253 13.2705 4.5253 12.7841C4.5253 12.2978 4.13217 11.9032 3.64705 11.9032H2.5V8.09671H3.64705C4.13217 8.09671 4.5253 7.70217 4.5253 7.21604C4.5253 6.72969 4.13217 6.3353 3.64705 6.3353H2.5V3.74716C2.5 3.05819 3.05684 2.5 3.74385 2.5H13.2533C13.9404 2.5 14.4972 3.05819 14.4972 3.74716ZM8.875 9.64524C9.80734 9.64524 10.5625 8.88794 10.5625 7.95294C10.5625 7.01795 9.80734 6.26065 8.875 6.26065C7.94266 6.26065 7.1875 7.01795 7.1875 7.95294C7.1875 8.88794 7.94266 9.64524 8.875 9.64524ZM5.5 12.1837C5.5 11.0583 7.74859 10.4914 8.875 10.4914C10.0014 10.4914 12.25 11.0583 12.25 12.1837V13.0298H5.5V12.1837Z"
        fill={fill}
      />
    </svg>
  </span>
);

ContactBookIcon = pure(ContactBookIcon);
ContactBookIcon.displayName = "ContactBookIcon";
ContactBookIcon.muiName = "SvgIcon";

export default ContactBookIcon;
