import PropTypes from 'prop-types';
import React, { Component } from 'react';
import IconButton from 'material-ui/IconButton';
import ActionSearch from 'material-ui/svg-icons/action/search';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Search.scss';
import cx from 'classnames';
class Search extends Component {
  constructor(props) {
    super(props);
    this.state = { opened: true, text: props.query || '' };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!this.focused && this.props.query !== nextProps.query && nextProps.query !== this.state.text) {
      this.setState({ text: nextProps.query || '' });
    }
  }

  refInput = (element) => {
    this.input = element;
  };
  timer = null;
  handleChange = (e) => {
    const value = e.target.value.trim();
    this.setState({ text: e.target.value });
    if (this.props.searchOnEnter) {
      return;
    }
    clearTimeout(this.timer);
    if (value && value.length < 2 && !this.props.anyValueLength) {
      return;
    }
    this.timer = setTimeout(() => {
      this.props.handleSearch(value);
    }, 300);
  };

  handleToggle = () => {
    if (!this.state.opened) {
      setTimeout(() => {
        this.input && this.input.focus && this.input.focus();
      }, 100);
    } else {
      this.setState({ text: '' });
      clearTimeout(this.timer);
      this.props.handleSearch('');
    }
    /*this.setState({ opened: !this.state.opened });*/
  };
  handleFocus = () => {
    this.focused = true;
  };
  handleBlur = () => {
    this.focused = false;
  };
  onKeyDown = (e) => {
    if (this.props.searchOnEnter && e.keyCode === 13) {
      this.props.handleSearch(e.target.value.trim());
    }
  }

  render() {
    const { style, styleInput, className = "", isNarrow = false} = this.props;
    return this.state.opened
      ? <div className={cx(s.search, isNarrow ? s.narrow_search : "", className)} style={style}>
        <ActionSearch />
        <input
          ref={this.refInput}
          value={this.state.text}
          onChange={this.handleChange}
          type="text"
          style={styleInput}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          placeholder={this.props.placeholder}
          onKeyDown={this.onKeyDown}
        />
        {this.state.text ?
          <NavigationClose
            onClick={this.handleToggle}
            className={s.close}
            style={{
              width: '16px',
              height: '16px',
            }}
          />
          : null}
      </div>
      : <div>
        <IconButton onClick={this.handleToggle} >
          <ActionSearch />
        </IconButton>
      </div>;
  }
}
Search.propTypes = {
  handleSearch: PropTypes.func,
  query: PropTypes.string,
  searchOnEnter: PropTypes.bool,
};
export default withStyles(s)(Search);
