exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".eYzOT{max-width:680px !important;width:100% !important}.eYzOT>div>div:first-child svg{display:none !important}._1hO4O{overflow-y:auto;height:inherit;max-height:778px;padding-left:28px !important;padding-bottom:14px !important}._1fksB{padding:28px 0 12px;font-size:14px;line-height:20px;color:var(--text-dark)}._2QFtJ{margin-top:16px}._2QFtJ a{font-size:14px;line-height:16px;color:#4D9E0E}._2QFtJ a:first-child,._2QFtJ a:nth-child(2){padding-right:6px;border-right:1px solid #C7C7C7}._2QFtJ a:nth-child(2){padding-left:9px}._2QFtJ a:nth-child(3){padding-left:9px}\n", ""]);

// Exports
exports.locals = {
	"dialogContent": "eYzOT",
	"dialogBody": "_1hO4O",
	"wrapper_access": "_1fksB",
	"info": "_2QFtJ"
};