import Api from './api';

import {convertToSameTimeZ} from './api'

export default class Fleet extends Api {

  static async getAllFleet(filters) {
    return this.fetch('/fleet/all-fleet', {
      method: 'POST',
      body: JSON.stringify(filters)
    }, '/api/v2', undefined, true)
  }
  static async getAllFleetFilters() {
    return this.fetch('/preferences/byType/allFleetFilter', {
      method: 'GET',
    });
  }

  static async saveAllFleetFilter(name, body) {
    return this.fetch(`/preferences/allFleetFilter/${name}`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  static async updateAllFleetFilter(filterId, formData) {
    return this.fetch(`/preferences/${filterId}`, {
      method: 'PUT',
      body: JSON.stringify(formData),
    });
  }

  static async deleteAllFleetFilter(filterId) {
    return this.fetch(`/preferences/${filterId}`, {
      method: 'DELETE'
    });
  }

  static async getVesselFilters() {
    return this.fetch('/preferences/byType/myFleetFilter', {
      method: 'GET',
    });
  }

  static async saveVesselFilter(name, body) {
    return this.fetch(`/preferences/myFleetFilter/${name}`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  static async updateVesselFilter(filterId, formData) {
    return this.fetch(`/preferences/${filterId}`, {
      method: 'PUT',
      body: JSON.stringify(formData),
    });
  }

  static async deleteVesselFilter(filterId) {
    return this.fetch(`/preferences/${filterId}`, {
      method: 'DELETE'
    });
  }

  static async printPdf(body) {
    body = convertToSameTimeZ(body);
    return this.fetch('/vessels/fleet/pdf', {
      method: 'POST',
      body: JSON.stringify(body)
    });
  }
}
