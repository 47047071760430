import PropTypes from 'prop-types';
import React, { PureComponent, Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import ContentAddCircle from 'material-ui/svg-icons/content/add-circle';
import ContentAdd from 'material-ui/svg-icons/content/add';
import { List, ListItem } from 'material-ui/List';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import StandardTerm from './StandardTerm';
import Toggle from '../Common/StyledToggle';
import FormsyInput from '../Common/FormsyInput';
import FormsyText from '../Common/FormsyText';
import { removeFromArray, parseTextIntoParagraphs } from '../../core/utils';
import s from './Cargo.scss';
import t from './Table.css';
import FormsySelect from '../Common/Select';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import SaveIcon from 'material-ui/svg-icons/content/save';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import IconMenu from 'material-ui/IconMenu';
import TermsAutocomplete from './../Common/TermsAutocomplete'
import Terms from './../../core/api/Terms';
import WarningIcon from 'material-ui/svg-icons/alert/warning';
import InfoIcon from "material-ui/svg-icons/action/info";
import ContentClear from 'material-ui/svg-icons/content/clear';
import AutoComplete from 'material-ui/AutoComplete';
import RaisedButton from './../Common/RaisedButton';

import lodashUnionBy from 'lodash/unionBy';
import lodashEscapeRexExp from 'lodash/escapeRegExp';

import Dialog from './../Common/Dialog';
import MultiCheckbox from "../Common/MultiCheckbox";
import TextArea from '../Monitor/Edit/TextArea';
import WithTooltip from '../Common/WithTooltip';


const floatingLabelStyle = { fontSize: '13px' };
const floatingLabelFocusStyle = { fontSize: '15px' };
const inputStyle = { fontSize: '13px' };
const hintStyle = { fontSize: '13px' };
const MENU_ITEM_STYLE = {fontSize:'13px',minHeight:'28px',lineHeight:'28px' }

const emptyName = {
  id: 1,
  text: "Please enter the Name to Save your terms and conditions."
};
const emptyList = {
  id: 2,
  text: "Please make changes to terms and conditions to Save them."
};

const textAreaStyles = {
  fontSize: "12px",
  fontWeight: "400",
  color: "#333",
  marginTop: "8px",
  borderRadius: "8px",
  border: "1px solid #E6E6E6",
  background: "#fff",
  padding: "12px"
}

class StandardTermsList extends PureComponent {
  static propTypes = {
    namePrefix: PropTypes.string,
    addLabel: PropTypes.string,
    standardTermsList: PropTypes.array,
    previousValues: PropTypes.object,
    billOfLading: PropTypes.array,
    showBillOfLading: PropTypes.boolean,
    termsType: PropTypes.string,
    isCounterOffer: PropTypes.boolean,
    selectedCategories: PropTypes.array,
    categories: PropTypes.array,
    refTerms: PropTypes.func,
  };
  static defaultProps = {
    addLabel: 'Add your term',
    title: 'YOUR TERMS',
    namePrefix: 'terms[user]',
    showStandard: false,
    previousValues: {},
    billOfLading: [],
    showBillOfLading: false,
    termsType: null,
    isCounterOffer: false,
    topOffset: 0,
    hasPreview: false,
    softDelete: true,
    selectedCategories: [],
    categories: [{ id: 'drybulk', value: 'drybulk', name: 'Drybulk' }, { id: 'breakbulk', value: 'breakbulk', name: 'Breakbulk' }, { id: 'wetbulk', value: 'wetbulk', name: 'Wet bulk' }],
    categoriesSelectorEnabled: true,
  };

  state = {
    userTerms: [],
    defaultTerms: [],
    selectedTerms: [],
    hideTerms: false,
    termsCount: 0,
    standardTermsList: [],
    billOfLading: [],
    needSave: false,
    termsListName: '',
    selectedList: null,
    alerts: [],
    renaming: false,
    changes: [],
    newUserTermValue: '',
    newBillOfLadingValue: '',
    showReplaceDialog: false,
    standardLimit: 0,
  };

  constructor(props) {
    super(props);
    if (props.selectedTerms) {
      this.state.userTerms = this.filterTermsArr(props.selectedTerms.user.map((ut) => { ut.key = Math.random(); return ut; }));
      this.state.billOfLading = this.filterTermsArr(props.billOfLading);
      const list = JSON.parse(JSON.stringify(props.standardTermsList)).sort((a, b) => a.number - b.number);
      this.state.standardTermsList = list.map((item) => {
        const index = item.index;
        const selectedTerm = props.selectedTerms.default
          .filter(o => !!o)
          .find(st => st.index === index);
        item.checked = selectedTerm && selectedTerm.checked;
        item.new = selectedTerm && selectedTerm.new;
        item.unchecked = selectedTerm && selectedTerm.unchecked;
        if (selectedTerm && selectedTerm.selectedValues) {
          item.selectedValues = selectedTerm.selectedValues;
        }
        if (item.checked !== undefined) {
          this.state.termsCount += 1;
        }
        return item;
      });
    } else {
      this.state.standardTermsList = props.standardTermsList && props.standardTermsList.map(item => item ? ({...item}) : {});
    }
    if (this.state.termsCount) {
      this.state.hideTerms = true;
    }
   this.props.refTerms && this.props.refTerms(this)

  }

  handleAddTerm = () => {
    this.setState(this.termSelector);
  };

  handleAddMultipleTerms = () => {
    const {newUserTermValue, userTerms} = this.state;
    const newTerms = parseTextIntoParagraphs(newUserTermValue).map(text=>{
      return {
        value: String(text), 
        userInput: true, 
        key: Math.random()
      }
    });
    this.setState({newUserTermValue: '', userTerms: [...userTerms, ...newTerms]})
  }

  termSelector = state => ({...state, newUserTermValue: '', userTerms: [...state.userTerms, { minHeight: this.refs.newTermInput?.muiComponent?.input?.state?.height, value: String(state.newUserTermValue), userInput: true, key: Math.random() }] })

  handleAddBL = () => {
    this.setState(this.blSelector);
  };

  handleAddMultipleBLTerms = () => {
    const {newBillOfLadingValue, billOfLading} = this.state;
    const newTerms = parseTextIntoParagraphs(newBillOfLadingValue).map(text=>{
      return {
        value: String(text), 
        userInput: true, 
        key: Math.random()
      }
    });
    this.setState({newBillOfLadingValue: '', billOfLading: [...billOfLading, ...newTerms]})
  }

  blSelector = state => ({...state, newBillOfLadingValue: '', billOfLading: [...state.billOfLading, { minHeight: this.refs.newBillOfLadingInput?.muiComponent?.input?.state?.height, value: String(state.newBillOfLadingValue), userInput: true, key: Math.random() }] })

  changesExist = () => {
    return (this.state.changes.length && this.state.changes.every(this.changeIsValid) || this.state.selectedList?.name !== this.state.termsListName) || this.state.standardTermsList.some(item => item && item.checked === true);
  }

  updateUserTermValue = (value) => {
    if (value && value.length >= 2) {
      this.setState({
        needSave: true
      })
    }
    this.setState({
      newUserTermValue: value,
    })
  }

  updateNewBillOfLadingValue = (value) => {
    if (value && value.length >= 2) {
      this.setState({
        needSave: true
      })
    }
    this.setState({
      newBillOfLadingValue: value,
    })
  }

  updateBillOfLadingValue = (remark, ev, value) => {
    const billOfLading = [...this.state.billOfLading];
    const index = billOfLading.indexOf(remark);
    billOfLading[index] = {...remark, value}
    this.setState({
      billOfLading,
      needSave: true
    })

  }

  additionalTermsChangedOrExist = () => {
    let hasAdditionals =  this.state.userTerms.length > 0 && this.state.userTerms.every(term => term && term?.value.length > 0) || this.state.newUserTermValue.length >= 2;
    return hasAdditionals;

  };

  billOfLadingChangedOrExist = () => {
    let hasBillOfLading = this.props.showBillOfLading ? (this.state.billOfLading.length > 0 && this.state.billOfLading.every(bl => bl && bl?.value.length > 0)) || this.state.newBillOfLadingValue.length >= 2 : false;
    if (!this.props.showBillOfLading) {
      return null;
    }
    return hasBillOfLading

  };



  handleRemoveTerm = (index) => {
    this.setState({userTerms: removeFromArray(this.state.userTerms, index) },
      () => this.setState({
        needSave: this.listNeedsSave()
      })
    );
  };

  handleRemoveRemark = (index) => {
    this.setState({ billOfLading: removeFromArray(this.state.billOfLading, index) },
    () => this.setState({
      needSave: this.listNeedsSave()
    }));
  };

  hideUnchekedTerms = (e) => {
    this.setState(state => ({ hideTerms: !this.state.hideTerms }));
  };

  listNeedsSave = () => this.additionalTermsChangedOrExist() || this.changesExist() || this.billOfLadingChangedOrExist()

  termsCounter = (action, index) => {
    let currentValue = this.state.termsCount;
    currentValue = action ? currentValue + 1 : currentValue - 1;
    this.setState({
      termsCount: currentValue,
      hideTerms: currentValue ? this.state.hideTerms : false }, () => this.setState({needSave: this.listNeedsSave(),}));
  };

  updateList = async () => {
    const terms = this.prepareTerms();
    try {
      const res = await Terms.update(terms, this.state.selectedList?._id, this.state.termsListName);
      if (res.status === 200) {
        this.setState(state => ({
          userTerms: terms.additionalTerms,
          newUserTermValue: '',
          needSave: false,
          selectedList: res.data,
          alerts: [],
          changes: [],
          renaming: false,
        }))
      }
      this.refs.termsAutocomplete.fetchAllData();
    } catch (error) {
      console.log(error)
    }
  }

  changeIsValid = item => item.hasOwnProperty('checked') || item.selectedValues.length

  prepareTerms = () => {
    const terms = {
      date: new Date()
    };
    const findValuesChanges = this.state.standardTermsList.filter(term => term.checked);
    const filteredChanges = this.state.changes.filter(this.changeIsValid);
    const standardTerms = lodashUnionBy(filteredChanges, findValuesChanges, 'index').filter(term => term.checked);
    if (!this.changesExist() && !this.additionalTermsChangedOrExist() && !this.billOfLadingChangedOrExist()) {
      this.addAlert(emptyList);
      return null;
    }
    const userTerms = this.resetTermsBeforeSend(this.state.userTerms);
    if (this.props.showBillOfLading) {
      const blTerms = this.resetTermsBeforeSend(this.state.billOfLading);
      if (blTerms && blTerms.length) {
        terms.billOfLading = this.filterTermsArr(blTerms);
      }
      else {
        terms.billOfLading = [];
      }
    }
    terms.standardTerms = standardTerms;
    terms.additionalTerms = this.filterTermsArr(userTerms);
    if (this.state.newUserTermValue.length >= 2) {
      let obj = {
        value: this.state.newUserTermValue,
        key: Math.random() * 5
      };
      if (this.props.hasPreview) {
        obj.minHeight = this.refs.newTermInput?.muiComponent?.input?.state?.height || 0;
      }
      terms.additionalTerms.push(obj)
    }
    if (this.state.newBillOfLadingValue.length >= 2) {
      let obj = {
        value: this.state.newBillOfLadingValue,
        key: Math.random() * 5
      };
      if (this.props.hasPreview) {
        obj.minHeight = this.refs.newBillOfLadingInput?.muiComponent?.input?.state?.height || 0;
      }
      terms.billOfLading.push(obj);
    }
    return terms;
  }

  resetTermsBeforeSend = terms => terms.map(term => {
    const termCopy = {
      ...term
    };
    if (termCopy) {
      if (termCopy.added) {
        delete termCopy.added
      }
      if (termCopy.locked) {
        delete termCopy.locked
      }
      if(termCopy.userInput) {
        delete termCopy.userInput;
      }
    };
    return termCopy;
  })

  deleteAlert = id => {
    this.setState(state => ({
      alerts: state.alerts.filter(alert => alert.id !== id)
    }))
  }

  addAlert = alert => {
    this.setState( state => ({
      alerts: [...new Set([...state.alerts, alert])]
    }), () => {
      this.refs.alerts.scrollIntoView()
    });
  }

  listNameExists = () => {
    let names = this.refs.termsAutocomplete.state.allValues || [];
    let currentName = lodashEscapeRexExp(this.state.termsListName.trim());
    if (currentName.match(/COPY/gi)) {
      currentName = currentName.replace(/ COPY[ \d]*/gi, '').trim();
    }
    let ifCopy = new RegExp(`${currentName}( COPY)([ \\d])*`, 'i');
    const similarNames = names.filter(name => name.text.trim() === currentName || name.text.trim().match(ifCopy));
    return {similarNames, currentName};
  }

  filterTermsArr = (arr) => {
    return arr.filter((item, index, arr) => index === arr.findIndex(t => t.value && item.value && t.value.trim() === item.value.trim()) && item.value);
  }

  listCanBeSaved = () => this.state.standardTermsList.some(item => item && item.checked) || this.additionalTermsChangedOrExist() || this.billOfLadingChangedOrExist();

  handleAlerts = () => {
    if (!this.state.termsListName) {
      this.addAlert(emptyName)
    }
    else {
      this.deleteAlert(emptyName.id)
    }
    if (!this.state.needSave || !this.listCanBeSaved()) {
      this.addAlert(emptyList);
    }
    else {
      this.deleteAlert(emptyList.id)
    }

    if (!this.state.termsListName || (!this.state.needSave || !this.listCanBeSaved())) {
      return true;
    }
    else {
      return false;
    }
  }

  save = async () => {
    console.log(this.changesExist(), this.additionalTermsChangedOrExist(), this.billOfLadingChangedOrExist(), 'TOGETHER', !this.changesExist() && !this.additionalTermsChangedOrExist() && this.billOfLadingChangedOrExist() !== true);
    const errors = this.handleAlerts();
    if (errors) {
      return;
    }
    if (this.state.selectedList && this.state.termsListName.trim() === this.state.selectedList?.name.trim() || this.state.renaming) {
      this.updateList();
      return;
    }
    const terms = this.prepareTerms();
    if (!terms) {
      return;
    }
    let listName = this.state.termsListName;
    let {similarNames, currentName} = this.listNameExists();
    if (similarNames.length) {
      if (similarNames.length === 1) {
        listName = `${currentName} COPY`;
      }
      else {
        const highestNumberInCopy = similarNames.reduce((a,b) => {
          const aNumber = Number(/\d+$/.exec(a?.text || a));
          const bNumber = Number(/\d+$/.exec(b.text));
          if (!aNumber && bNumber) {
            return bNumber;
          }
          if (!bNumber && aNumber) {
            return aNumber;
          }
          if (aNumber && bNumber) {
            return Math.max(aNumber, bNumber);
          }
        });
        if (!highestNumberInCopy) {
          listName = `${currentName} COPY 1`;
        }
        else {
          listName = `${currentName} COPY ${highestNumberInCopy + 1}`;
        }

      }
    }
    try {
      const res = await Terms.create(terms, listName, this.props.termsType);
      if (res.status === 200) {
        this.setState( state => {
          const newState = {
            termsListName: listName,
            userTerms: terms.additionalTerms,
            newUserTermValue: '',
            newBillOfLadingValue: '',
            needSave: false,
            selectedList: res.data,
            alerts: [],
            changes: [],
            renaming: false,
          };
          if (terms.billOfLading) {
            newState.billOfLading = terms.billOfLading;
          }
          return newState;
        })
        this.refs.termsAutocomplete.fetchAllData();
      }
    } catch (error) {
      console.log(error)
    }
  }



  excludeTermsFromPastList = (terms, pastTerms) => {
    return terms.filter(term => {
      const termFromPastList = pastTerms[this.props.termsType].additionalTerms.find(pastTerm => pastTerm.value.trim() === term.value.trim());
      if (termFromPastList) {
        return false;
      }
      else {
        return true;
      }
    })
  }

  letOnlyUserInputTerms = terms => terms.filter(term => term && term.userInput);

  findById = async value => {
    try {
      this.setState({
        termsListName: value.name,
      })
      const res = await Terms.findById(value._id)
      if (res.status === 200) {
        const {[this.props.termsType]: {standardTerms, additionalTerms, billOfLading = []}} = res.data;
        const newStandardTermsList = this.props.standardTermsList.map((term, index) => {
          const inTerms = standardTerms.find(resTerm => term.index === resTerm.index);
          const termInProps = this.props.selectedTerms ? this.props.selectedTerms.default.find(item => item && item.index === term.index) : null;
          let defaultTerm = {
            ...term,
            checked: (termInProps && termInProps.checked !== undefined && this.props.softDelete) ? false : undefined,
            selectedValues: termInProps ? termInProps?.selectedValues : term.selectedValues,
          }


          let finalTerm = inTerms ? {
            ...term,
            checked: inTerms.checked,
            selectedValues: inTerms.selectedValues ? inTerms.selectedValues : term.selectedValues
          } : defaultTerm;

           if (termInProps) {
            if (termInProps.checked === false && (inTerms && termInProps.index !== inTerms.index)) {
              finalTerm.checked = false;
            }
            if (termInProps.hasOwnProperty('new')) {
              finalTerm.new = termInProps.new;
            }
            if (termInProps.hasOwnProperty('unchecked')) {
              finalTerm.unchecked = termInProps.unchecked;
            }
           }

          return finalTerm;
        })
        this.setState(state => {
          const currentList = this.state.selectedList ? {...this.state.selectedList} : null;
          let userTerms = [...state.userTerms];
          let blTerms = [...state.billOfLading];
          if (!this.props.isCounterOffer && res.data._id !== currentList?._id) {
            userTerms = this.filterTermsArr([...this.letOnlyUserInputTerms(userTerms), ...additionalTerms]);
            if (currentList && currentList[this.props.termsType]?.billOfLading) {
              blTerms = this.filterTermsArr([...this.letOnlyUserInputTerms(blTerms), ...billOfLading]);
            }
          }
          else {
            userTerms = this.filterTermsArr([...userTerms, ...additionalTerms]);
            blTerms = this.filterTermsArr([...blTerms, ...billOfLading]);
          }
          return ({
            standardTermsList: newStandardTermsList,
            userTerms: userTerms.filter(userTerm => userTerm && userTerm.value.trim() !== state.newUserTermValue.trim()),
            billOfLading: blTerms,
            termsCount: this.countStandardTerms(newStandardTermsList),
            hideTerms: false,
            needSave: false,
            alerts: [],
            renaming: false,
            selectedList: res.data,
            /* changesNotSaved: false, */
          })
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  updateInput = value => {
    if (value === this.state.termsListName) {
      return;
    }
    if (!value) {
      this.setState({
        termsListName: '',
      })
      return;
    }
    this.setState({
      termsListName: value,
    })
    if (value.length >= 1 && !((!!value) && (value.constructor === Object))) {
      this.deleteAlert(emptyName.id);
      this.setState({
        needSave: true
      });
    }
  }

  emptyTermsFromAnotherUser = () => {
    if (!this.props.selectedTerms) {
      return true;
    }
    const emptyStandard = this.props.selectedTerms.default ? !this.props.selectedTerms.default.length : true;
    const emptyAdditional = this.props.selectedTerms.user ? !this.props.selectedTerms.user.length : true;
    const emptyBl = this.props.billOfLading ? !this.props.billOfLading.length : true;
    return emptyStandard && emptyAdditional && emptyBl;
  }

  selectList = async (value, ...args) => {
    if (value === this.state.termsListName) {
      return;
    }
    if (!value) {
      this.setState({
        termsListName: '',
      })
      return;
    }
    if (value._id && value.name) {
      if (this.state.selectedList === null && !this.emptyTermsFromAnotherUser() && this.props.isCounterOffer) {
        this.setState({
          needSave: false,
          showReplaceDialog: value
        })
        return;
      }
      this.findById(value);
      this.setState({
        needSave: false,
      });
    }
    else {
      this.setState({
        termsListName: value,
      })
      if (value.length >= 1) {
        this.deleteAlert(emptyName.id);
      }
    }
  }

  ifChangeExist = item => {
    if (!item || !item.index) return null;
    const changeIndex = this.state.changes.findIndex(change => change.index === item.index);
    if (changeIndex >= 0) {
      return this.state.changes[changeIndex];
    }
    else {
      return null;
    }
  }

  modifyChange = (change, fields) => {
    let index = 0;
    if (change) {
      index = this.state.changes.findIndex(sChange => sChange.index === change.index);
      if (index < 0) {
        index = this.state.changes.length + 1;
      }
      change = {
        ...change,
        ...fields
      }
    }
    const changes = [...this.state.changes];
    changes.splice(index, 1, change);
    console.log('modify')
    this.setState({
      changes,
      needSave: true
    })
  }

  countStandardTerms = list => list.filter(item => item.hasOwnProperty('checked') && item.checked !== undefined).length;

  checkboxChanged = item => {
    this.deleteAlert(emptyList.id);
    const standardTerms = [...this.state.standardTermsList];
    const termIndex = standardTerms.findIndex(t => item.index === t.index);
    const term = this.state.standardTermsList[termIndex];
    term.checked = item.checked;
    this.setState({
      standardTermsList: standardTerms
    })
    const existedChange = this.ifChangeExist(item);

    if (existedChange) {
     this.modifyChange(existedChange, item);
     return;
    }
    const changes = [...this.state.changes];
    changes.push({
      ...term,
      checked: item.checked
    });
    this.setState({
      changes,
      needSave: true
    })
  }

  updateAdditionalTerm = (term, value) => {
    const terms = [...this.state.userTerms];
    const index = terms.indexOf(term);
    terms[index] = {...term, value }
    this.setState({
      userTerms: terms,
      needSave: true
    })
  }

  startRename = () => {
    this.setState({
      renaming: true
    })
    setTimeout(() => {
      this.refs.termsAutocomplete.refs.input.refs.searchTextField.input.focus()
    }, 500)
  }

  clear = () => {
    const standardTermsList = this.props.standardTermsList.map(item => {
      const termInProps = this.props.selectedTerms?.default?.find(term => term && term.index === item.index);
      if (termInProps) {
        return {...item, ...termInProps, checked: termInProps.checked && termInProps.checked === true ? false : termInProps.checked};
      }
      else {
        return {...item, checked: undefined, selectedValues: []};
      }
    })
    this.setState({
      standardTermsList,
      userTerms: [],
      billOfLading: [],
      termsCount: this.countStandardTerms(standardTermsList),
      changes: [],
      needSave: false,
      hideTerms: false,
      alerts: [],
      renaming: false,
      newUserTermValue: '',
      newBillOfLadingValue: '',
    })

  }

  changeTermValue = (changeIndex, term) => {
    let change = this.state.changes.length && this.state.changes.find(change => change.index === changeIndex)
      || this.state.standardTermsList.find(term => term.index === changeIndex);
    change = JSON.parse(JSON.stringify(change || {}));
    let values = change?.selectedValues ? [...change.selectedValues] : [];
    console.log('change', change, values)
    values[term.index] = term.selectedValue;
    this.modifyChange(change, {
      selectedValues: values
    })
  }

  resetInputValue = () => {
    if (this.refs.termsAutocomplete && this.refs.termsAutocomplete.setValue) {
      this.refs.termsAutocomplete.setValue('');
      return true;
    }
    else {
      return null;
    }
  }

  del = async (id = null) => {
    const listId = id || this.state.selectedList?._id;
    if (!listId) return;
    try {
      const res = await Terms.deleteById(listId);

      if (res.status === 200 && (!id || id === this.state.selectedList?._id)) {
       this.resetInputValue();
       this.setState({
        selectedList: false,
        renaming: false,
        alerts: [],
        needSave: this.listCanBeSaved(),
        termsListName: '',
       })
      }
      this.refs.termsAutocomplete.fetchAllData();
    } catch (error) {
      console.log(error)
    }
  }

  resetStateProperty = property => {
    this.setState({
      [property]: null
    })
  }

  computeTopMargin = () => {
    const {inDialog} = this.props;
    let topMargin = (this.props.topOffset ? this.props.topOffset : 0);
    if (inDialog) {
      const dialogHeader = document.querySelector('.dialog_header_title');
      if (dialogHeader) {
        topMargin += Number(dialogHeader.offsetHeight) - 1;
      }
    }
    else {
      const pageHeader = document.getElementById('shipnext-header') || document.querySelector('.Header-root-I-DIE') || document.querySelector('.I-DIE');
      if (pageHeader) {
        const notificationsNode = pageHeader.nextElementSibling;
        topMargin += pageHeader.offsetHeight;
        if (notificationsNode && !notificationsNode.classList.contains('page_content')) {
          topMargin += +notificationsNode.offsetHeight;
        }
      }
    }
    return `${topMargin}px`
  }

  componentDidMount() {
      const {user} = this.props;
      if (!user) return;
      const options = {
        rootMargin: '0px',
        threshold: 1.0
      }
      const callback = (entries) => {
        entries.forEach((entry) => {
          const bar = this.refs.termsBar;
          if (!bar) return;
          console.log(entry.isIntersecting, entry.boundingClientRect.top)
          const barWidth = bar?.offsetWidth;
            if(!entry.isIntersecting && entry.boundingClientRect.top < 200) {
                bar.style.width = `${barWidth}px`;
                bar.style.top = this.computeTopMargin();
                bar.classList.add(s.sticky);
            }
            else {
              bar.style.width = '100%';
              bar.style.top = '0px';
              bar.classList.remove(s.sticky)
            }
        })
      };
      try {
        this.termsBarObserver = new IntersectionObserver(callback, options);
        this.termsBarObserver.observe(this.refs.barIntersected);
      } catch (error) {
        console.log(error);
      }
      if (this.props.attachRef) {
        this.props.attachRef(this);
      }
      setTimeout(this.incStandardLimit, 200);
  }
  incStandardLimit = ()=> {
    if(this.state.standardLimit > this.state.standardTermsList.length){
      return;
    }
    this.tick = setTimeout(()=> {
      this.setState({ standardLimit: this.state.standardLimit + 10 },this.incStandardLimit );
    },200);
  }

  componentWillUnmount() {
    if (this.termsBarObserver) {
      try {
        this.termsBarObserver.unobserve(this.refs.barIntersected);
      } catch (error) {
        console.log(error);
      }

    }
    this.props.refTerms && this.props.refTerms(null);
  }
  filterAutocomplete = (searchText, key) => searchText === this.state.termsListName && key.indexOf(this.state.termsListName) !== -1;

  closeReplaceDialog = () => {
    this.setState({
      showReplaceDialog: false
    });
    this.resetInputValue();
  }

  replaceTerms = () => {
    this.findById(this.state.showReplaceDialog);
    this.setState({
      showReplaceDialog: false
    })
  }

  handleTermsCategoriesChange = (val) =>  {
    this.setState({ selectedCategories: val });
  }

  getSelectedCategories = () => {
    return this.state.selectedCategories || this.props.selectedCategories || [];
  }

  render() {
    const {user, autocompleteClassName = ''} = this.props;
    const namePrefix = this.props.namePrefix;
    const {needSave, userTerms, termsCount, termsListName, selectedList, standardTermsList, newUserTermValue} = this.state;
    const renameDisabled = !selectedList;
    const clearDisabled = !standardTermsList.some(term => term.checked) && !userTerms.length && !newUserTermValue;
    const deleteDisabled = !selectedList;

    const filterAutocomplete = selectedList && termsListName && selectedList.name === termsListName;
    const selectedCategories = this.getSelectedCategories();
    return (
      <div>
        <div className="row">
          {this.props.categoriesSelectorEnabled && this.props.categories?.length ? <div className="col-12 col-md-3">
            <MultiCheckbox
              name={'standardTermsCategories'}
              list={this.props.categories}
              selectAllAvailable
              value={selectedCategories}
              onChange={this.handleTermsCategoriesChange}
              fullWidth
              floatingLabelText={'Terms'}
            />
          </div> : null}
          {this.props.headerChildren}
        </div>
          {
            user
              && (
                <div>
                    <div className={cx(s.terms_bar, "terms_autocomplete_bar", autocompleteClassName)} ref="termsBar">
                      <span style={{display: needSave ? 'inline-block' : 'none'}} className={s.star}>*</span>
                      <TermsAutocomplete
                          value={termsListName}
                          onNewRequest={this.selectList}
                          updateInput={this.updateInput}
                          del={this.del}
                          filter={filterAutocomplete ? AutoComplete.noFilter : this.filterAutocomplete}
                          className={s.autocomplete}
                          name="termsList"
                          textFieldStyle={{
                            height: '28px',
                            borderRadius: "4px",
                          }}
                          menuProps={{
                            menuItemStyle: {
                              minHeight: '32px',
                              lineHeight: '32px',
                              height: '32px',
                              fontSize: '12px',
                              fontWeight: '400',
                            },
                            onItemTouchTap: (ev, child) => {
                              const index = parseInt(child.key, 10);
                              const chosenRequest = this.refs.termsAutocomplete.state.allValues[index];
                              const shouldDelete = ev.target.closest("[data-type='delete']");
                              if (shouldDelete && chosenRequest) {
                                this.del(chosenRequest._value._id);
                                ev.stopPropagation();
                              }
                              else {
                                this.refs.termsAutocomplete.refs.input.handleItemTouchTap(ev, child);
                              }
                            },
                            className: 'terms_menu',
                          }}
                          fullWidth
                          insetChildren
                          popoverProps={{ style: { padding:'0px 8px' } }}
                          placeholder="Choose Executed Terms&Conditions or Create New"
                          params={{termsType: this.props.termsType}}
                          ref="termsAutocomplete"
                          openOnFocus
                          sendOnlyObjectValue
                      />
                      <div className={s.terms_bar_icons}>
                        <SaveIcon
                          style={{
                            width: '21px',
                            height: '21px',
                          }}
                          color="#26405F"
                          className={cx(needSave ? s.green : '')}
                          onClick={this.save}
                        />
                        <IconMenu
                              iconButtonElement={<IconButton><MoreVertIcon color="#26405F" /></IconButton>}
                              anchorOrigin={{horizontal: 'left', vertical: 'top'}}
                              targetOrigin={{horizontal: 'left', vertical: 'top'}}
                            >
                              <MenuItem className={s.tab_menu_item} style={MENU_ITEM_STYLE} disabled={renameDisabled} primaryText="Rename" onClick={!renameDisabled && this.startRename} />
                              <MenuItem className={s.tab_menu_item} style={MENU_ITEM_STYLE} disabled={!needSave} primaryText="Save" onClick={needSave && this.save} />
                              <MenuItem className={s.tab_menu_item} style={MENU_ITEM_STYLE} disabled={clearDisabled} onClick={!clearDisabled && this.clear} primaryText="Clear" />
                              <MenuItem className={s.tab_menu_item} style={MENU_ITEM_STYLE} disabled={deleteDisabled} primaryText="Delete" onClick={!deleteDisabled && this.del.bind(this,null, null)} />
                        </IconMenu>
                      </div>
                    </div>
                <span ref="barIntersected" style={{width: '100%', height: '1px'}}></span>
                <div className={s.alerts} ref="alerts">
                  {
                    this.state.alerts.map(alert => <Alert del={this.deleteAlert.bind(this, alert.id)}>{alert.text}</Alert>)
                  }
                </div>
          </div>
              )

          }
        {this.props.showStandard
          ? <div>
            <List ref="list">
            <div className={s.standard_terms_wrapper}>
              {this.state.termsCount > 0
                ? <div className={t.style_toggle_wrapper}>
                  <Toggle
                    className={t.style_toggle}
                    label={
                      <span>
                        Show only selected ({this.state.termsCount} terms)
                      </span>
                    }
                    toggled={this.state.hideTerms}
                    onToggle={this.hideUnchekedTerms}
                  />
                </div>
                : null}
              <ListItem
                ref="standardList"
                key="0"
                className={s.terms_list_wrapper}
                initiallyOpen
                primaryTogglesNestedList
                nestedItems={this.state.standardTermsList.slice(0, this.state.standardLimit).map((termsTemplate, index) => {
                  const categoryFitted = selectedCategories.length === 0 || selectedCategories.length === 3
                  || selectedCategories.some(sc => termsTemplate[sc]);
                  return (
                  <StandardTerm
                    previousValues={this.props.previousValues}
                    hideTerms={this.state.hideTerms}
                    termsCounter={this.termsCounter}
                    change={this.checkboxChanged}
                    changeValue={this.changeTermValue}
                    key={`${index},${termsTemplate.selectedValues}`}
                    categoryFitted={categoryFitted}
                    {...termsTemplate}
                  />
                  )}
                )}
                primaryText={<div>STANDARD TERMS</div>}
              />
              <ListItem
                key="1"
                className={s.terms_list_wrapper}
                initiallyOpen
                primaryTogglesNestedList
                nestedItems={[
                  <ClearProps key="0">
                    <div key="0" className="row">
                      {this.state.userTerms.map((term, index) => (
                        <div
                          key={(term._id || term.key || term.value)}
                          className={cx('col-12', s.term_row)}
                        >
                          <TermsTextArea 
                            placeholder="Input text"
                            requiredError="required"
                            name={namePrefix + '[' + index + '][value]'}
                            validations="minLength:2"
                            validationError="min 2 characters"
                            required
                            onChange={this.updateAdditionalTerm.bind(this, term)}
                            value={term.value}
                          />
                          {
                            this.props.hasPreview
                            ? (
                              <FormsyInput
                                type="hidden"
                                requiredError="required"
                                name={namePrefix + '[' + index + '][minHeight]'}
                                value={term.minHeight}
                                hintStyle={hintStyle}
                                inputStyle={inputStyle}
                                floatingLabelStyle={floatingLabelStyle}
                                floatingLabelFocusStyle={floatingLabelFocusStyle}
                              />
                            )
                            : null
                          }
                          <FormsyInput
                            type="hidden"
                            requiredError="required"
                            name={namePrefix + '[' + index + '][locked]'}
                            value={false}
                            hintStyle={hintStyle}
                            inputStyle={inputStyle}
                            floatingLabelStyle={floatingLabelStyle}
                            floatingLabelFocusStyle={floatingLabelFocusStyle}
                          />
                          <span className={s.delete_button}>
                            <NavigationClose
                              onClick={this.handleRemoveTerm.bind(
                                this,
                                index,
                              )}
                            />
                          </span>
                        </div>
                      ))
                      }
                    </div>
                    <div className={cx(s.additionalTermsInputContainer)}>
                      <div
                        key="1"
                        className={cx(s.add_cargo_button, s.add_term)}
                      >
                        <TermsTextArea 
                              placeholder="Write or paste your terms here..."
                              name={this.state.newUserTermValue ? namePrefix + '[' + this.state.userTerms.length + '][value]' : 'newUserTermValue'}
                              validations="minLength:2"
                              validationError="min 2 characters"
                              onChange={this.updateUserTermValue}
                              value={this.state.newUserTermValue}
                              ref="newTermInput"
                            />
                          {
                              this.props.hasPreview
                              ? (
                                <FormsyInput
                                  type="hidden"
                                  requiredError="required"
                                  name={this.state.newUserTermValue ? namePrefix + '[' + this.state.userTerms.length + '][minHeight]' : 'newUserTermMinHeight'}
                                  value={this.refs.newTermInput?.muiComponent?.input?.state?.height || 0}
                                  hintStyle={hintStyle}
                                  inputStyle={inputStyle}
                                  floatingLabelStyle={floatingLabelStyle}
                                  floatingLabelFocusStyle={floatingLabelFocusStyle}
                                />
                              )
                              : null
                            }
                        <span onMouseDown={this.handleAddTerm} style={{marginLeft: '8px'}}>
                          <ContentAdd className={s.add_icon} />

                        </span>
                      </div>
                      <AddMultipleTermsButton label='Add multiple terms' onClick={this.handleAddMultipleTerms} disabled={parseTextIntoParagraphs(this.state.newUserTermValue).length < 2} />
                    </div>
                  </ClearProps>,
                ]}
                primaryText={<div>ADDITIONAL TERMS {(this.state.userTerms.length && `(${this.state.userTerms.length})`) || ''}</div>}
              />
              {this.props.showBillOfLading ?
                <ListItem
                  key="2"
                  className={s.terms_list_wrapper}
                  initiallyOpen
                  primaryTogglesNestedList
                  nestedItems={[
                    <ClearProps key="0">
                      <div key="0" className="row">
                        {this.state.billOfLading.map((term, index) => (
                          <div
                            key={term._id || term.key || term.value}
                            className={cx('col-12', s.term_row)}
                          >
                            <TermsTextArea 
                              placeholder="Input text"
                              requiredError="required"
                              name={'billOfLading[' + index + '][value]'}
                              validations="minLength:2"
                              validationError="min 2 characters"
                              required
                              onChange={this.updateBillOfLadingValue.bind(this, term)}
                              value={term.value}
                            />
                            <FormsyInput
                              type="hidden"
                              requiredError="required"
                              name={'billOfLading[' + index + '][_id]'}
                              value={term._id}
                              hintStyle={hintStyle}
                              inputStyle={inputStyle}
                              floatingLabelStyle={floatingLabelStyle}
                              floatingLabelFocusStyle={floatingLabelFocusStyle}
                            />
                            <span className={s.delete_button}>
                              <NavigationClose
                                onClick={this.handleRemoveRemark.bind(
                                  this,
                                  index,
                                )}
                              />
                            </span>
                          </div>
                        ))}
                      </div>
                      <div className={cx(s.additionalTermsInputContainer)}>
                        <div
                          key="1"
                          className={cx(s.add_cargo_button, s.add_term)}
                        >
                          <TermsTextArea 
                              placeholder="Write or paste your bill of lading remarks here..."
                              name={this.state.newBillOfLadingValue ? 'billOfLading' + '[' + this.state.billOfLading.length + '][value]' : 'newBillOfLadingValue'}
                              validations="minLength:2"
                              validationError="min 2 characters"
                              onChange={this.updateNewBillOfLadingValue}
                              value={this.state.newBillOfLadingValue}
                              ref="newBillOfLadingInput"
                            />
                            {
                                this.props.hasPreview
                                ? (
                                  <FormsyInput
                                    type="hidden"
                                    requiredError="required"
                                    name={this.state.newBillOfLadingValue ? 'billOfLading' + '[' + this.state.billOfLading.length + '][minHeight]' : 'newBillOfLadingMinHeight'}
                                    value={this.refs.newBillOfLadingInput?.muiComponent?.input?.state?.height || 0}
                                    hintStyle={hintStyle}
                                    inputStyle={inputStyle}
                                    floatingLabelStyle={floatingLabelStyle}
                                    floatingLabelFocusStyle={floatingLabelFocusStyle}
                                  />
                                )
                                : null
                              }
                          <span onMouseDown={this.handleAddBL} style={{marginLeft: '8px'}}>
                            <ContentAdd className={s.add_icon} />
                          </span>
                        </div>
                        <AddMultipleTermsButton label='Add multiple remarks' onClick={this.handleAddMultipleBLTerms} disabled={parseTextIntoParagraphs(this.state.newBillOfLadingValue).length < 2} />
                      </div>
                    </ClearProps>,
                  ]}
                  primaryText={<div>BILL OF LADING REMARKS {(this.state.billOfLading.length && `(${this.state.billOfLading.length})`) || ''}</div>}
                />
                : null}
            </div>
          </List>
          </div>
          : null}
          <ReplaceDialog open={this.state.showReplaceDialog} close={this.closeReplaceDialog} accept={this.replaceTerms}></ReplaceDialog>
          {/* <NotSavedChangesDialog open={this.state.changesNotSaved} close={this.closeNotSavedDialog} accept={this.saveChangesAccept}></NotSavedChangesDialog> */}
      </div>
    );
  }
}

class TermsTextArea extends Component {
  constructor(props){
    super(props);
    this.textAreaRef = null;
    this.state= {
      height: null
    }
  }

  componentDidMount(){
    this.countHeight()
  }

  componentDidUpdate(prevProps){
    if(prevProps.value!==this.props.value) {
      this.countHeight()
    }
  }

  countHeight = () => {
    if (this.textAreaRef) {
      const scrollHeight = this.textAreaRef.scrollHeight;
      if (!this.props.value || this.props.value === '') {
        this.setState({ height: 'auto' });
      } else {
        this.setState({ height: `${scrollHeight}px` });
      }
    }
  };

  render(){
    const {...rest} = this.props;
    const {height} = this.state
    return <TextArea 
      textAreaRef={element => {
        this.textAreaRef = element
        }
      }
    {...rest}
    onInput={this.countHeight}
    style={{...textAreaStyles,height: height || 'auto', overflow:'hidden'}}/>
  }
}

function AddMultipleTermsButton(props){
  return <div style={{display:'flex',alignItems:'center',gap:'4px'}}>
    <RaisedButton
      styles='small'
      backgroundColor='#fff'
      disabledLabelColor= 'rgba(67,128,199,0.4)' 
      disabledBackgroundColor= '#fff'
      labelColor='rgb(67,128,199)'
      buttonStyle={{border:'1px solid #E6E6E6'}}
      labelStyle={{textTransform:'none'}} {...props} />
    <WithTooltip
      style={{cursor: 'pointer'}}
      tip={(
        <div style={{
          display:'flex',
          flexDirection:'column', 
          gap:'8px', 
          maxWidth:'500px', 
          width:'500px', 
          background: '#26374C', 
          borderRadius: '8px', 
          padding:'8px 12px', 
          color:'#FFF',
          boxShadow: '1px 1px 7px 0px rgba(0, 0, 0, 0.20)'
        }}>
          <video width="490" height="368" autoPlay loop muted playsInline style={{maxWidth:'100%'}}>
            <source src={'/videos/TermsDescription.mp4'} type="video/mp4" />
          </video>
          <div>The term generator allows you to create individual terms from a text area field. You can type your terms directly into the field or paste it from another source. Each term must be written on a new line, separated by pressing Enter. Once you have finished, click the ‘Add multiple terms’ button, and the system will process the field, turning each line into a separate term. This functionality makes it easy to organize and manage terms efficiently.</div>
        </div>
      )}>
      <InfoIcon color='#B2B2B2' style={{width: 16, height: 16}}/>
    </WithTooltip>
  </div>
}



function Alert({children, del}) {
  return (
    <div className={s.alert}>
      <WarningIcon style={{fill: '#E34848', marginRight: '8px', width: '24px', height: '24px'}}></WarningIcon>
      <p>{children}</p>
      <ContentClear onClick={del} style={{ marginLeft: 'auto', width: '16px', height: '16px', cursor: 'pointer'}}></ContentClear>
    </div>
  )
}

/* function NotSavedChangesDialog({accept, open, close}) {
  return (
    <Dialog
      title="LIST IS NOT SAVED"
      titleClassName={s.dialogTitle}
      handleClose={close}
      open={open}
      contentClassName={s.dialogContent}
      bodyClassName={s.dialogBody}
      actionsContainerClassName={s.actions}
      actions={
        <div className={s.actions}>
          <RaisedButton label="Cancel" secondary onClick={close} />
          <RaisedButton label="Ok" style={{marginLeft: '8px'}} primary onClick={accept} />
        </div>
      }
    >
        Would you like to save this list of Terms and Conditions?
    </Dialog>
  )
} */

function ReplaceDialog({accept, open, close}) {
  return (
    <Dialog
      title="ATTENTION"
      titleClassName={s.dialogTitle}
      handleClose={close}
      open={open}
      contentClassName={s.dialogContent}
      bodyClassName={s.dialogBody}
      actionsContainerClassName={s.actionsContainer}
      actions={
        <div className={s.actions}>
          <RaisedButton label="No" secondary onClick={close} />
          <RaisedButton label="Yes" style={{marginLeft: '8px'}} primary onClick={accept} />
        </div>
      }
    >
        Do you want to replace terms?
    </Dialog>
  )
}

export default connect(state => ({user: state?.login?.user}))(withStyles(s)(withStyles(t)(StandardTermsList)));

function ClearProps({ children }) {
  return <div>{React.Children.map(children, el => el)}</div>;
}
