import React, { Component } from 'react'
import v from './VesselEdit.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import createReactClass from 'create-react-class';

const TextArea = createReactClass({
  mixins: [{
      ...Formsy.Mixin,
      setValue: function setValue(value, cb) {
        this.setState({
          _value: value,
          _isPristine: false
        }, function () {
          this.context.formsy.validate(this);
          this.props.onChange && this.props.onChange(value);
          //this.props._validate(this);
          cb && cb();
        }.bind(this));
      }

  }],
  update(ev) {
    this.setValue(ev.target.value)
  },
  handleBlur(ev) {
    const {which, keyCode, target} = ev;
    if ((which === 9 || keyCode === 9) && target) {
      ev.preventDefault();
      const {selectionStart: start, selectionEnd: end} = target;
      const val = String(this.state._value).substring(0, start) + '\t' + String(this.state._value).substring(end);
      this.setValue(val, () => {
        if (target) {
          target.focus();
          target.selectionStart = target.selectionEnd = start + 4;
        }
      });

    }

  },
  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setValue(nextProps.value);
    }
  },
  render() {
      const {name, textAreaRef} = this.props;
      return (
          <textarea ref={textAreaRef} onClick={e => e.preventDefault()} onKeyDown={this.handleBlur} name={name} className={`${v.textarea} ${this.props.classes} ${(!this.state._isValid) && !this.state._isPristine && 'with_error'}`} {...this.props} value={this.getValue()} onChange={this.update}></textarea>
      )
  }
});

export default withStyles(v)(TextArea);
