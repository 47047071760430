import React, { Component } from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Export.scss";
import Dialog from "../../Common/Dialog";
import Formsy from "formsy-react";
import { RaisedButtonBlue } from "../../Common/RaisedButton";
import { makeExportGroups, defaultValues } from './export.constants'
import { exportVessels } from "./exportVessels";
import ExportGroup from './ExportGroup';

const getInitialValues = (key, allowedGroups) => {
  let values
  try {
    const json = localStorage.getItem(key)
    if (json) {
      const data = JSON.parse(json)
      values = {}

      const allowedKeys = new Set()
      for (const group of allowedGroups) {
        for (const field of group.fields) {
          allowedKeys.add(field.name)
        }
      }

      for (const key in data) {
        if (allowedKeys.has(key) && data[key]) {
          values[key] = true
        }
      }
    }
  } catch {}

  return values || defaultValues
}

/**
 * @property {Object[]} props.selectedVessels - Array of selected vessel objects.
 * @property {Function} props.onClose - Callback function to handle the close action.
 * @property {string} props.storageKey - A key for storing data in local storage.
 * @property {boolean} props.hideVesselOpenPositions? - Hide fields related to the open position: open position, ref, and tc rate.
 * @property {boolean} props.hideTcRate? - Hide TC Rate field if the table on the page does not have it, like in suitable fleet.
 */
export default withStyles(s)(class ExportDialog extends Component {
  constructor(props) {
    super(props);

    this.exportGroups = makeExportGroups({
      hideVesselOpenPositions: props.hideVesselOpenPositions,
      hideTcRate: props.hideTcRate,
    })

    this.state = {
      values: getInitialValues(props.storageKey, this.exportGroups),
      isSubmitting: false,
      error: false,
    }
  }

  setValues = (set) => {
    const values = { ...this.state.values, ...set }

    this.setState(s => ({ ...s, values }))

    localStorage.setItem(this.props.storageKey, JSON.stringify(values))
  }

  setIsSubmitting = (isSubmitting) => {
    this.setState(s => ({ ...s, isSubmitting }))
  }

  setError = (error) => {
    this.setState(s => ({ ...s, error }))
  }

  export = async () => {
    this.setIsSubmitting(true)
    this.setError(false)

    try {
      const { selectedVessels } = this.props
      const { values } = this.state

      // Preserve same key order as in UI, so that columns in Excel reflect it.
      const orderedKeys = []
      for (const group of this.exportGroups) {
        for (const field of group.fields) {
          if (values[field.name]) {
            orderedKeys.push(field.name)
          }
        }
      }

      await exportVessels({ selectedVessels, orderedKeys })

      this.props.onClose()
    } catch (err) {
      console.error(err)
      this.setError(true)
    } finally {
      this.setIsSubmitting(false)
    }
  }

  render() {
    const { values, isSubmitting, error } = this.state
    const { onClose } = this.props

    return (
      <Dialog
        open
        handleClose={onClose}
        titleStyle={{ padding: "0 0 0 0", overflow: 'visible' }}
        closeIconStyle={{ top: '20px' }}
        onClick={e => e.stopPropagation()}
        bodyStyle={{ padding: 0 }}
        contentStyle={{ width: 850, maxWidth: 850 }}
        contentClassName={s.modal_content}
      >
        <div className={s.modal_title}>EXPORT SETTINGS</div>
        <Formsy.Form onSubmit={this.export}>
          <div className={s.content}>
            {this.exportGroups.map((group, i) => (
              <ExportGroup
                key={i}
                exportGroups={this.exportGroups}
                group={group}
                values={values}
                setValues={this.setValues}
              />
            ))}
          </div>

          <div className={s.actions}>
            {error && <div className={s.error}>Export failed, please try again later.</div>}

            {isSubmitting && <div className={s.spinner}/>}
            <RaisedButtonBlue label='Export' type='submit' />
          </div>
        </Formsy.Form>
      </Dialog>
    )
  }
})
