import React, { useState } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './AddPopover.scss';
import { Menu, Popover } from 'material-ui';


const AddPopover = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const openAddSelect = (ev) => {
    ev.preventDefault();
    setOpen(true);
    setAnchor(ev.currentTarget);
    
  }
  const closeAddSelect = () => {
    setOpen(false);
    setAnchor(null);
  }
  return (
    <>
      <div className={cx(s.add_button, open && s.active)} onClick={openAddSelect} />
      <Popover
        open={open}
        anchorEl={anchor}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'right', vertical: 'top' }}
        onRequestClose={closeAddSelect}
        style={{ marginTop: "4px" }}
      >
        <Menu onClick={() => closeAddSelect()}>
          {children}
        </Menu>
      </Popover>
    </>
  );
}
  export default withStyles(s)(AddPopover);