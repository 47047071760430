/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable quote-props */
import PropTypes from 'prop-types';

import React from 'react';
import ReactDOM from 'react-dom';
import formDataToObject from 'form-data-to-object';
import MainStepper, { SlightlyOptimizedAnimatedStep } from '../../Common/Stepper';
import ContractSelect from '../../Common/ContractSelect';
import RaisedButton from '../../Common/RaisedButton';
import FormsyInput from '../../Common/FormsyInput';
import Commission from '../../Common/Commission';
import CargoList from '../CargoList';
import LoadingDischargingRate from '../LoadingDischargingRate';
import Terms from '../Terms';
import { scrollToAnchor } from '../../../core/utils';
import s from './Request.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Ports from '../../Cargo/Request/Ports';
import {getDefaultPorts, mapTypeOfTransportationsToTerms} from '../utils';
import SimpleForm from '../SimpleForm';
import HiddenRequiredStepInput from '../../Monitor/TCOrders/HiddenRequiredStepInput';
import styled from 'styled-components';
import ActionInfoOutline from 'material-ui/svg-icons/action/info-outline';
import factory from './FormStateProvider';
import Preamble from './Preamble';
import _SpinnerBtn from '../../Common/_SpinnerBtn';
import { v4 as uuidv4 } from 'uuid';
import NarrowFormsyText from "../../NewInputs/NarrowFormsyText";
import {NarrowCompanyAutocomplete} from "../../NewInputs/NarrowCompanyAutocomplete";
import VesselRequirementsForm from "./VesselRequirementsForm";

const StyledStepper = styled.div`
  .stepper_label.valid svg, .stepper_label.active svg {
    fill: var(--bg-blue) !important;
  }
  .stepper_transition:not(.animated_step_opened_transition_complete) {
    .terms_autocomplete_bar {
      position: relative !important;
    }
  }

`;

const MOCK_FN = () => { };
const ContractInfo = ({ style = {}, ...rest }) => (
  <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
    <ActionInfoOutline style={{ fill: "var(--border-color)", width: 24, height: 24, ...style }} {...rest} />
  </div>
);

const formValidateDelay = 250;



class Request extends factory(React.PureComponent) {
  vesselRequirementsRef: null

  static contextTypes = {
    cargoTypes: PropTypes.array,
    voyageTerms: PropTypes.array,
    showMessage: PropTypes.func,
    countries: PropTypes.array,
  };

  static defaultProps = {
    isEditing: false,
    editButtonLabel: "Save changes",
    cargoListProps: {},
    simpleFormEnabled: true,
    preambleEnabled: true,
    simpleFromPreviewEnabled: true,
    accountNameEnabled: true,
  }

  static DEFAULT_REQUEST_CONTRACT_ID = "58fa1f720552673300695993"

  constructor(props, context) {
    super(props);

    const state = this.getDefaultState(props);

    this.fields = {};
    this._form = props.cargo;
    this._mainForm = null;
    this._mainStepper = null;

    const { cargo } = props.cargo || {};
    if (cargo) {
      state.cargoList = cargo.filter(c => !!c);
      state.cargoList.forEach(cargo => {
        if (!cargo.cargoName) {
          return;
        }
        cargo.id = cargo.id ?? uuidv4();
        cargo.cargo = cargo.cargoName;
        cargo.name = cargo.cargoName.name || cargo.cargoName;
        if (props.fromExchange || props.isFillingSystem) {
          if (props.cargo.cargoType) {
            cargo.cargoType = context.cargoTypes.find(
              ct => ct._id === props.cargo.cargoType,
            );
          }
          if (cargo.si) {
            if (cargo.si.unitsWeight) {
              cargo.stowage = {
                ...cargo.stowage,
                unitsWeight: { value: (cargo.si.unitsWeight / 1000) }, // converting kilos to mt
              };
            }
            if (cargo.si.unitsVolume) {
              cargo.stowage = {
                ...cargo.stowage,
                unitsVolume: { value: cargo.si.unitsVolume },
              };
            }
          }
          if (cargo.packing?.category === "packed") {
            cargo.stowage = {
              ...cargo.stowage,
              category: cargo.packing.category,
              name: cargo.packing?.pack?.name,
            };
          }
        }
        cargo.defaultValues = {};
        if (cargo.stackable && cargo.stackable.value) {
          cargo.defaultValues['[stackable][value]'] = true;
        }
        if (cargo.dangerous && cargo.dangerous.value) {
          cargo.defaultValues['[dangerous][value]'] = true;
        }
        if (cargo.military) {
          cargo.defaultValues['[military]'] = true;
        }
        cargo.defaultValues['[category]'] = cargo.packing.category;

        if (cargo.terms) {
          cargo.defaultValues.terms = cargo.terms;
        }
      });
      state.cargoCount = state.cargoList.length;
      if (props.fromExchange || props.isFillingSystem) {
        if (props.cargo.loadingPort) {
          state.ports.loadingPorts = [{ ...state.ports.loadingPorts[0], port: props.cargo.loadingPort }];
          if (props.cargo.readinessDate) {
            state.ports.loadingPorts[0] = { ...state.ports.loadingPorts[0], readinessDate: props.cargo.readinessDate };
          }
          if (props.cargo.cancellingDate) {
            state.ports.loadingPorts[0] = { ...state.ports.loadingPorts[0], cancellingDate: props.cargo.cancellingDate };
          }
          if (props.cargo.loadingPortDetails) {
            state.ports.loadingPorts[0] = { ...state.ports.loadingPorts[0], details: props.cargo.loadingPortDetails };
          }
        }
        if (props.cargo.unloadingPort) {
          state.ports.unloadingPorts = [{ ...state.ports.unloadingPorts[0], port: props.cargo.unloadingPort }];
          if (props.cargo.unloadingPortDetails) {
            state.ports.unloadingPorts[0] = { ...state.ports.unloadingPorts[0], details: props.cargo.unloadingPortDetails };
          }
        }
        if (props.cargo.ldRates) {
          state.ld = props.cargo.ldRates;
        }
        if (props.cargo.comm) {
          state.commission.comm = props.cargo.comm;
        }
        if (props.cargo.deductible) {
          state.commission.deductible = props.cargo.deductible;
        }
        if (props.cargo.pus) {
          state.commission.pus = props.cargo.pus;
        }
      }
    }
    if (this.props.vessel) {
      state.preamble.vessel = this.props.vessel;
    }
    state.cargoCount = state.cargoList?.length ?? 1;
    state.termsCategories = mapTypeOfTransportationsToTerms(state.cargoList.map(c => c?.typeOfTransportation));
    this.state = state;
    this.initialState = state;
    this.genericRef = this.genericRef.bind(this);
    this.handleTermsInvalidSubmit = this.handleTermsInvalidSubmit.bind(this);
    this.handleContractChange = this.handleContractChange.bind(this);
    this.handleSend = this.handleSend.bind(this);
  }

  handleGenericChange = values => {
    const start = performance.now();
    console.debug(values);
    const ret = new Promise((res, rej) => {
      this.setState(state => {
        const newState = typeof values === "function" ? values(state) : { ...values };
        if (newState.cargoList) {
          const termsCategories = mapTypeOfTransportationsToTerms(newState.cargoList.map(c => c?.typeOfTransportation));
          if (String(state.termsCategories) !== String(termsCategories)) {
            newState.termsCategories = termsCategories;
          }
        }
        return ({
          ...state,
          ...newState,
        });
      }, () => {
        const newRequest = Object.keys(this.getDefaultRequestState()).reduce((acc, key) => ({...acc, [key]: this.state[key] }), {});
        res(this.state);
        console.debug(newRequest);
        if (this.props.onChange) {
          this.props.onChange(newRequest);
        }
      });
    });
   console.debug(performance.now() - start);
    return ret;
  }

  getDefaultRequestState = () => ({
    cargoList: [{
      stowage: {},
      clauses: {},
      id: uuidv4(),
    }],
    cargoCount: 1,
    ports: {
      loadingPorts: getDefaultPorts(),
      unloadingPorts: getDefaultPorts(),
    },
    freights: [{
      curr: {
        value: '',
        currency: 'USD',
        term: 'per mt',
        method: 'fios',
        details: '',
      },
      key: 1,
    }],
    charges: [{
      type: '',
      value: '',
      currency: 'USD',
      term: '',
      details: '',
      key: 1,
    }],
    demurrage: '',
    demurrageOpts: {
      currency: 'USD',
      timeUnit: 'perDay',
      terms: '',
    },
    detention: '',
    detentionOpts: {
      currency: 'USD',
      timeUnit: 'perDay',
      terms: '',
    },
    preamble: this.getDefaultPreambleState(),
    ld: {},
    contract: this.constructor.DEFAULT_REQUEST_CONTRACT_ID,
    commission: {
      comm: '',
      deductible: false,
      pus: false,
    },
    simpleForm: "",
  })

  getDefaultPreambleState = () => ({
    clientPlace: '',
    clientDate: '',
    clientContractRefNo: '',
    vessel: '',
    vesselDetails: '',
    counteragents: [{
      key: 1,
      type: 'Carrier',
      company: '',
      details: '',
    }],
  })

  getDefaultState = (props) => ({
    titles: {
      portsTitle: 'PORTS & DATES',
    },
    blockedSteps: {
      isTermsStepBlocked: true,
      isPreambleStepBlocked: true,
      isLdRatesStepBlocked: true,
      isVesselRequirementsStepBlocked: true,
    },
    activeStepIndex: 0,
    cargoCount: 1,
    termsCounter: 0,
    isPopulating: false,
    ...(Object.assign({}, this.getDefaultRequestState(), Object.fromEntries(Object.entries({ ...(props.request || {}) }).filter(([key, val]) => !!val)))),
    termsKey: "1",
    terms: null,
    cargoType: '',
    voyageSettings: '',
    country: undefined,
    typePortPol: false,
    typePortPod: false,
    altPortPol: false,
    nextPortPol: false,
    altPortPod: false,
    nextPortPod: false,
  })

  componentWillReceiveProps(nextProps) {
    if (nextProps.request !== this.props.request) {
      const newValues = Object.assign({}, this.getDefaultRequestState(), nextProps.request);
      this.setState(state => ({
        ...state,
        ...newValues,
        cargoCount: Array.isArray(newValues.cargoList) ? newValues.cargoList.filter(c => !!c).length : state.cargoCount ?? 1,
      }));
    }

    if (nextProps.isEditing !== this.props.isEditing) {
      nextProps.isEditing ? this.unblockAllSteps() : this.blockAllSteps();
    }

    if (this.props.hash === '#preview' && nextProps.hash !== '#preview') {
      this.unblockAndBlockTransition();
      this.setState({ preview: undefined });
    }
  }

  componentDidMount() {
    if (this.props.innerRef) {
      this.props.innerRef(this);
    }
    if (this.props.isEditing) {
      this.unblockAllSteps();
    }
  }

  handleSend() {
    this.genericUnblockStep('isTermsStepBlocked', false).then(() => this.fields.requestType.setValue('request'));
  }

  unblockAllSteps = () => {
    return Promise.all(Object.keys(this.state.blockedSteps).map(step => this.genericUnblockStep(step, false)));
  }

  blockAllSteps = () => {
    return Promise.all(Object.keys(this.state.blockedSteps).map(step => this.genericUnblockStep(step, true)));
  }

  genericRef(name, element) {
    this.fields[name] = element;
  }

  handleCommChange = (name, ev, val) => this.onChange('commission')(name)(val ?? ev?.target?.value ?? ev);

  handleContractChange(ev, contract) {
    this.handleGenericChange({ contract: contract?._id || "" });
  }

  handleTermsInvalidSubmit() {
    const selector = '.terms_tab [data-valid=false]';
    const invalidNodes = document.querySelectorAll(selector);
    if (!invalidNodes || !invalidNodes.length) {
      return;
    }
    const tabAttr = invalidNodes[0].attributes.getNamedItem('data-tab');
    if (tabAttr && tabAttr.value === 'standard-terms') {
      try {
        const list = this._termsRef?.refs?.standardList;
        if (!list?.state?.open) {
          list.handleNestedListToggle(new MouseEvent('click'));
        }
      } catch (e) {
        console.error(e);
      }
      setTimeout(() => {
        scrollToAnchor(invalidNodes[0]);
      }, 0);
    } else {
      scrollToAnchor(invalidNodes[0]);
    }
  }

  handleSubmit = (request) => {
    request.loadingPorts = this.state.ports.loadingPorts;
    request.unloadingPorts = this.state.ports.unloadingPorts;
    request.vesselRequirements = this.vesselRequirementsRef.getState()

    const previewErrors = this.validateForm();

    if (this.state.freights) {
      request.freights = this.state.freights.map(f => ({ ...f.curr })).filter(f => !f.deleted && f.value);
    }
    if (this.state.charges) {
      request.charges = this.state.charges.map(f => ({ ...f.curr })).filter(f => !f.deleted && f.value);
    }

    this.props.handlePreview(request, previewErrors);
  }

  getAllFormsRefs = forms => {
    // save forms refs to fill up fields, recognized from simple cargo
    this._forms = forms;
  }

  validateForm = () => {
    if (!this._forms) return;
    const invalidInputs = this._forms.map(form => form.inputs.filter(input => !input.isValid())).flat();
    const requiredErrMsg = label => `Define ${label}`;

    const colsDictionary = {
      "POL": [
        {
          match: /loadingPorts\[(\d*)\]\[(port)\]/,
          error: requiredErrMsg("port of loading"),
        },
        {
          match: /loadingPorts\[(\d*)\]\[(readinessDate)\]/,
          error: requiredErrMsg("readiness date"),
        },
        {
          match: /loadingPorts\[(\d*)\]\[(cancellingDate)\]/,
          error: requiredErrMsg("cancelling date"),
        },
      ],
      "POD": [
        {
          match: /unloadingPorts\[(\d*)\]\[(port)\]/,
          error: requiredErrMsg("port of discharging"),
        },
      ],
      "CARGO": [
        {
          match: [/cargo\[(\d*)\]\[(cargoName)\]/, /cargo\[(\d*)\]\[(cargoType)\]/],
          error: `${requiredErrMsg("cargo")}, ${requiredErrMsg("total weight or volume")}`,
        },
        {
          match: [/cargo\[(\d*)\]\[packing\]\[pack\]\[(unitsWeight)\]\[value\]/, /cargo\[(\d*)\]\[packing\]\[pack\]\[(unitsVolume)\]\[value\]/],
          error: requiredErrMsg("total weight or volume"),
        },
      ],
      "LOADING RATE": [
        {
          match: /ldRates\[loading\]\[(quantity)\]/,
          error: requiredErrMsg("loading rate"),
        },
        {
          match: /ldRates\[loading\]\[(dimension)\]/,
          error: requiredErrMsg("term"),
        },
      ],
      "DISCHARGING RATE": [
        {
          match: /ldRates\[discharging\]\[(quantity)\]/,
          error: requiredErrMsg("discharging rate"),
        },
        {
          match: /ldRates\[discharging\]\[(dimension)\]/,
          error: requiredErrMsg("term"),
        },
      ],
      'FREIGHT': [
        {
          match: /freight\[value]/,
          error: requiredErrMsg('freight'),
        },
      ],
    }

    const errors = invalidInputs.map(input => {
      const inputValue = input.getValue();
      const inputName = input.props.name;

      let fieldInPreview = null;

      Object.entries(colsDictionary).forEach(([key, fields]) => {
        let index;
        let fieldName = "";
        const requiredField = fields.find(field => {
          const found = Array.isArray(field.match) ? field.match.reduce((acc, pattern) => {
            if (acc) return acc;
            const match = inputName.match(pattern);
            if (match) {
              return match;
            }
          }, null) : inputName.match(field.match);

          if (!Array.isArray(found)) return null;

          if (found.length > 2) {
            [, index, fieldName] = found;
          }
          else {
            [, fieldName] = found;
          }
          return found;
        });

        if (requiredField) {
          fieldInPreview = {
            type: key,
            config: requiredField,
            fieldName,
            index,
          };
        }
      });

      if (!inputValue) {
        return fieldInPreview;
      }
      else if (fieldInPreview) {
        fieldInPreview.config.error = `Invalid ${inputValue}`;
        return fieldInPreview;
      }

    });

    return errors.filter(error => !!error);
  }

  openStepWithInvalidInput = () => {
    if (!this._forms) return;

    const invalidFormIndex = this._forms.findIndex(form => !form.state.isValid);

    if (invalidFormIndex >= 0) {
        this.manuallyOpenStep(invalidFormIndex);
        setTimeout(() => {
          // also display errors in inputs
          this._forms[invalidFormIndex].submit();
        }, 1);
    }
    else {
      // if all forms are valid, head back to PORTS step
      this.openSpecificFormStep("[data-id='ports']");
    }
  }

  openSpecificFormStep = (selector = "") => {
    if (!this._forms) return;

    this._forms.forEach((form, index) => {
      const FormNode = ReactDOM.findDOMNode(form);

      if (!FormNode) return;

      const isFormInWantedStep = FormNode.querySelector(selector);

      if (isFormInWantedStep) {
        this.manuallyOpenStep(index);
      }
    })
  }

  openSimpleFormStep = () => {
    this.openSpecificFormStep("[data-id='simpleForm']");
  }

  manuallyOpenStep = (stepIndex) => {
    const { _mainStepper } = this;

    if (!_mainStepper) return;

    _mainStepper.handleOpenStep(stepIndex);
  }


  populateRequestValues = async ({ cargoList, ports, terms, preamble, ldRates, commission, ...rest }) => {
    this.setState({
      isPopulating: true,
    })

    const stateUpdater = state => {
      cargoList = cargoList?.length ? cargoList.map(c => ({ ...c, id: c.id ?? uuidv4() })) : [{
        id: uuidv4()
      }];
      const ld = ldRates || {};
      return ({
        cargoList,
        ports,
        ld: {
          'loading': [],
          'discharging': [],
          ...ld,
        },
        preamble: preamble ?? this.getDefaultPreambleState(),
        cargoCount: cargoList.length || 1,
        activeStepIndex: 0,
        termsKey: String(+state.termsKey + 1),
        terms: terms ? { default: [], user: [], ...terms } : null,
        commission,
      });
    }



    ports = ports || {};

    if (!ports?.unloadingPorts?.length) {
      ports.unloadingPorts = getDefaultPorts();
    }
    if (!ports?.loadingPorts?.length) {
      ports.loadingPorts = getDefaultPorts();
    }

    //const { _forms } = this;

    await this.handleGenericChange(stateUpdater);

    //this.populateFormWithValues(allFormNamesToSet);

    setTimeout(() => this.setState({
      isPopulating: false,
    }), 1);
  }
  // DEPRECATED since form is controlled
  //resetInputWithExeption = (inputNames) => input => !inputNames.includes(input?.props?.name) && this.resetInputValue(input);

  handleSimpleFormPreview = (errors) => {
    const { _forms } = this;

    if (!_forms) return;

    let requestModel = _forms.reduce((model, form) => ({...model, ...formDataToObject.toObj(form.getModel())}), {});

    this.unblockAllSteps();

    this.handleSubmit(requestModel);

  }

  genericUnblockStep = (name, val = false) => {
    return new Promise((res, rej) => this.setState(state => ({
      ...state,
      blockedSteps: {
        ...state.blockedSteps,
        [name]: val,
      }
    }), () => setTimeout(res, formValidateDelay + 1)));
  }

  saveStepperRef = el => {
    this._mainStepper = el;
  }

   handleNext = () => {
     this._mainStepper.handleNext();
   }

  render() {
    const { titles, voyageSettings, terms, termsKey, clientRefNo, account } = this.state;
  
    const { errors, styles, loading, isWidget } = this.props;

    const now = new Date();
    now.setDate(now.getDate() + 1);

    const cargoListProps = {
      cargoList: this.state.cargoList,
      activeStepIndex: this.state.activeStepIndex,
      cargoCount: this.state.cargoCount,
      freights: this.state.freights,
      charges: this.state.charges,
      demurrage: this.state.demurrage,
      demurrageOpts: this.state.demurrageOpts,
      detention: this.state.detention,
      detentionOpts: this.state.detentionOpts,
      files: this.state.files,
    };

    return <StyledStepper innerRef={el => this._mainForm = el}>
      <MainStepper
        submitLabel="Next"
        handleSubmit={this.handleSubmit}
        errors={errors}
        defaultActive={0}
        noButtons
        ref={this.saveStepperRef}
        getAllFormsRefs={this.getAllFormsRefs}
        transitionEl={SlightlyOptimizedAnimatedStep}
        formValidateDelay={formValidateDelay}
      >
        {this.props.simpleFormEnabled ? (
          <SimpleForm
            value={this.state.simpleForm}
            label="SIMPLE FORM"
            populateRequestValues={this.populateRequestValues}
            handleNext={this.handleNext}
            handleSimpleFormPreview={this.props.simpleFromPreviewEnabled ? this.handleSimpleFormPreview : undefined}
          />
        ) : null}
        {this.props.preambleEnabled ? (
          <div unblockStep={() => this.genericUnblockStep('isPreambleStepBlocked')} label="PREAMBLE">
            <Preamble preamble={this.state.preamble}/>
            <HiddenRequiredStepInput
              value={undefined}
              name="isTermsComplete"
              required={this.state.blockedSteps.isPreambleStepBlocked}
            />
          </div>
        ) : null}
        <Ports
          ports={this.state.ports}
          label={titles.portsTitle}
          onInvalidSubmit={this.handlePortsInvalidSubmit}
        />
        <div
          label={
            (this.state.cargoType && this.state.cargoType.name) ||
            'CARGO'
          }
        >
          {this.props.accountNameEnabled ? (
            <div className="row">
              <div className="col-12 col-md-4">
                <NarrowFormsyText
                  floatingLabelText="Cargo Reference(s)"
                  name={`clientRefNo`}
                  fullWidth
                  onChange={(e, val) => {
                    this.handleGenericChange({ clientRefNo: val });
                  }}
                  value={clientRefNo}
                />
              </div>
              <div className={"col-12 col-md-4"}>
                <NarrowCompanyAutocomplete
                  floatingLabelText={`Account Name`}
                  name={`account`}
                  value={account}
                  validations="maxLength:100"
                  validationErrors={{
                    maxLength: 'max 100 characters',
                  }}
                  errorStyle={{ bottom: "8px" }}
                  onNewRequest={(e, val) => {
                    this.handleGenericChange({ account: val });
                  }}
                />
              </div>
            </div>
          ) : null}
          <CargoList
            {
              ...cargoListProps
            }
            handleGenericChange={this.handleGenericChange}
            defaultValue={this._form && this._form.cargo}
            {...this.props.cargoListProps}
          />
        </div>

        <div unblockStep={() => this.genericUnblockStep('isLdRatesStepBlocked')} label="LOADING & DISCHARGING RATE">
          <LoadingDischargingRate
            loading={this.state.ld?.loading ?? {}}
            discharging={this.state.ld?.discharging ?? {}}
          />
          <HiddenRequiredStepInput
            value={undefined}
            name="isTermsComplete"
            required={this.state.blockedSteps.isLdRatesStepBlocked}
          />
        </div>

        <div unblockStep={() => this.genericUnblockStep('isVesselRequirementsStepBlocked')} label="VESSEL REQUIREMENTS">
          <VesselRequirementsForm
            attachRef={instance => this.vesselRequirementsRef = instance}
            initialValues={this.props.cargo?.vesselRequirements}
          />
          <HiddenRequiredStepInput
            name="isVesselRequirementsStepBlocked"
            required={this.state.blockedSteps.isVesselRequirementsStepBlocked}
          />
        </div>

        <div
          className="terms_tab"
          onInvalidSubmit={this.handleTermsInvalidSubmit}
          label="TERMS"
        >
          {
            // used to show that step is complete after user clicks Preview button, because by default if it doesn't has required fields it's showing as completed step
          }
          <HiddenRequiredStepInput
            value={undefined}
            name="isTermsComplete"
            required={this.state.blockedSteps.isTermsStepBlocked}
          />
          <Terms
            showStandard
            termsType="voyageTerms"
            key={termsKey || "1"}
            standardTermsList={this.context.voyageTerms}
            selectedTerms={(this._form && this._form.terms) || (voyageSettings && voyageSettings.defaultContract && voyageSettings.defaultContract.terms) || terms}
            ref="terms"
            topOffset={49}
            attachRef={this.attachTermsRef}
            softDelete={false}
            selectedCategories={this.state.termsCategories}
            headerChildren={[
              <div className="col-12 col-md-3">
                <ContractSelect
                  Icon={ContractInfo}
                  hintText="Select contract"
                  floatingLabelText="Select contract"
                  requiredError="required"
                  name="contract"
                  ref="contract"
                  autoWidth
                  fullWidth
                  required
                  onChange={this.handleContractChange}
                  value={this.state.contract?._id || this.state.contract || ''}
                  isBidding={false}
                />

              </div>,
              <div className={`col-12 col-md-6`}>
                <Commission
                  handleChange={this.handleCommChange}
                  comm={this.state.commission.comm}
                  deductible={this.state.commission.deductible}
                  pus={this.state.commission.pus}
                />
              </div>
            ]}
          />

          <div>
            <FormsyInput
              ref={element => this.genericRef('requestType', element)}
              type="hidden"
              name="requestType"
            />
            {
              this.props.isEditing
                ? (
                  <_SpinnerBtn
                    label={this.props.editButtonLabel}
                    disableTouchRipple
                    disableFocusRipple
                    primary
                    type="submit"
                    onClick={this.handleSend}
                    style={{ margin: '0px 12px 30px 0' }}
                    disabled={this.props.loading}
                    loading={this.props.loading}
                  />
                )
                : (
                  <RaisedButton
                    label="Preview"
                    disableTouchRipple
                    disableFocusRipple
                    primary
                    type="submit"
                    onClick={this.handleSend}
                    style={{ margin: '0px 12px 30px 0' }}
                  />
                )
            }

          </div>
        </div>
      </MainStepper>
    </StyledStepper>
  }

  attachTermsRef = (el) => {
    this._termsRef = el;
  }
}

export default withStyles(s)(Request);
