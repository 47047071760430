import React, { Component } from 'react'
import v from "./VesselEdit.scss";
import NarrowFormsyText from "../../NewInputs/NarrowFormsyText";

/**
 * It's a separate component to prevent re-render of the whole form on imo change
 */
export default class ImoInput extends Component {
  state = {
    imoNumber: ''
  }

  onChange = (e) => {
    this.setState({ imoNumber: e.target.value })
    this.props.checkShipImo(e)
  }

  render() {
    const { edit, shipImoError } = this.props
    const { imoNumber } = this.state

    return (
      <div className={shipImoError && v.small} style={{ flexWrap: shipImoError ? 'wrap' : '' }}>
        <NarrowFormsyText
          fullWidth
          floatingLabelText="IMO*"
          validations="isLength:7,isInt"
          validationErrors={{
            isInt: imoNumber.startsWith('0') ? 'Cannot start with 0' : 'Only digits',
            isLength: 'Must be 7 digits',
          }}
          requiredError="required"
          name="imoNumber"
          required
          disabled={edit}
          onChange={this.onChange}
          ref={node => this.props.innerRef(node)}
        />
      </div>
    )
  }
}
