import React, { useEffect, useMemo, useState } from "react";
import styles from "./NavigationItem.scss";
import cx from "classnames";
import Link from "../../Link/Link";
import WithTooltip from "../../Common/WithTooltip";
import withStyles from "isomorphic-style-loader/lib/withStyles";

const NavigationItem = props => {
  const {
    title,
    route,
    icon,
    isActive = false,
    isOpen,
    clickHandler,
    isHelpItem,
    userSensetive,
    user,
    barCountsObj = {}
  } = props;

  const onClick = event => {
    event.preventDefault();
    clickHandler();
  };

  const tipPosition = useMemo(() => {
    if (isHelpItem) {
      if (isOpen) {
        return { x: 150, y: -40 };
      } else {
        return { x: 40, y: -40 };
      }
    }

    return { x: 40, y: -30 };
  }, [isOpen, isHelpItem]);

  const routeString = useMemo(() => {
    if (userSensetive && !user) {
      return "/login";
    }

    return route || '/';
  }, [userSensetive, route]);


  const navItemTitle = useMemo(() => {
    if (barCountsObj[route]) {
      return `${title} '(' + (${barCountsObj[route]}) + ')'`;
    }

    return title;
  }, [barCountsObj, title, route]);

  return (
    <WithTooltip
      noArrow
      theme={{ name: "dark_theme" }}
      showOnEventName={isHelpItem ? "onClick" : "onMouseOver"}
      offset={tipPosition}
      tip={() => {
        if (isHelpItem) {
          return (
            <div className={styles.tip_info_wrapper}>
              <div className={styles.help_link}>
                <Link to="/help">
                  Help & Feedback
                </Link>
              </div>
              <div className={styles.help_link}>
                <Link to="/legal">
                  Terms & Conditions
                </Link>
              </div>
              <div className={styles.help_link}>
                <Link to="/rules-regulations">
                  Rules & Regulations
                </Link>
              </div>
              <div className={styles.help_link}>
                <Link to="/copyright">
                  Copyright Policy
                </Link>
              </div>
              <div className={styles.help_link}>
                <Link to="/privacy">
                  Privacy Policy
                </Link>
              </div>
              <div className={styles.help_link}>
                <Link to="/cookies">
                  Cookies Policy
                </Link>
              </div>
            </div>
          );
        }

        if (!isOpen) {
          return <div className={styles.tip_wrapper}>{title}</div>;
        }
      }}
    >
      <Link
        data-title={title}
        to={routeString}
        handleClick={clickHandler && onClick}
        className={cx(
          styles.container,
          isActive && styles.container_active,
          isOpen && styles.container_open
        )}
      >
        <div className={styles.icon_wrapper}>{icon}</div>
        <div className={styles.title}>{navItemTitle}</div>
      </Link>
    </WithTooltip>
  );
};

export default React.memo(withStyles(styles)(NavigationItem));
