import React from 'react'
import s from './InputBtn.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import Formsy from 'formsy-react';
import cx from 'classnames';
import createReactClass from 'create-react-class';

const Input = createReactClass({
  mixins: [{
    ...Formsy.Mixin
  }],

  handleChange(ev) {
    this.setValue(ev.target.value);
    if (this.props.handleChange) {
      this.props.handleChange(ev.target.value);
    }
  },

  render() {
    const isValid = this.isFormSubmitted() ? this.isValid() : true;
    return <input onChange={this.handleChange} className={cx(s.input, isValid ? "" : s.input_error)} type="text" {...this.props} />
  }
})

function InputBtn({ btnLabel = "Submit", validations = "isEmail", value = "", handleChange = ev => undefined, name = "email", placeholder = "", muiTheme, ...rest }) {
  return (
    <div className={s.container}>
      <Input handleChange={handleChange} value={value} validations={validations} required name={name} placeholder={placeholder} type="text" {...rest} />
      <button className={s.btn}  style={{backgroundColor: muiTheme.raisedButton.primaryColor, color:  muiTheme.raisedButton.primaryTextColor}} type="submit">
        {btnLabel}
      </button>
    </div>
  )
}

export default muiThemeable()(withStyles(s)(InputBtn))
