import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styled from "styled-components";
import { Pane } from "../Common/SplitPane";
import { Avatar } from "./Avatar";
import history from '../../core/history';
import Tags from '../Common/Tags/Tags';
import { WithUserUploads } from '../../core/HOC';
import Collapse from '../Common/Collapse';
import ExtendedForm from '../Common/ExtendedForm';
import Attachments from '../Cargo/Preview/Attachments';
import UploadedFileExtensionErr from '../Common/UploadedFileExtensionErr';
import { HiddenButtons } from '../Common/HiddenButtons';
import { AddAttachment } from '../Monitor/Edit/Attachments';
import { scrollToAnchor, UUIDToObjectId } from '../../core/utils';
import CommonComments from '../Common/Comments/CommonComments';
import CircleEditBtn from '../Common/CircleEditBtn';
import { SocialMedia } from './ContactView';
import CompanyMembers from './CompanyMembers';
import {phoneTypeMap} from "./ContactPhonesEdit";
import {formatIndustry, formatSector} from "./contactBookUtils";
import CollapseField from '../Common/CollapseField';
import ChatAndMailIcon from '../Icons/ChatAndMailIcon';
import WithTooltip from '../Common/WithTooltip';
import cx from 'classnames';

const Body = styled.div`
  padding: 20px 20px 48px 20px;
  color: var(--text-dark333);
  font-size: 14px;
  .contact-name-wrapper {
    display: flex;
    .contact-name-block {
      position: relative;
      flex: 1;
      margin-left: 24px;
      .contact-name, .contact-surname {
          font-size: 24px;
          font-weight: 700;
          text-transform: uppercase;
      }
      .contact-name {
        padding-right: 24px;
      }
      .contact-position {
        margin: 8px 0;
        font-size: 12px;
        color: #999;
      }
      .contact-position-popover {
        padding: 12px;
      }
      .contact-edit-icon {
        height: fit-content;
        position: absolute;
        right: 0;
        top: 0;
      }
      .social-media-block {
        display: flex;
        .social-media {
          margin-right: 8px;
          .social-media-icon {
            transition: opacity linear 0.2s;
            > :hover {
              opacity: 0.75;
            }
          }
        }
      }
    }
  }
  .contact-info-wrapper {
    .contact-info-block {
      display: flex;
      justify-content: space-between;
      .contact-info-1col, .contact-info-2col {
        width: 49%;
        .contact-info-field {
          padding-top: 12px;
          .contact-phones-collapse, .contact-emails-collapse {
            max-height: unset;
            > div:first-child {
              flex-direction: row-reverse;
              justify-content: start;
              position: relative;
              svg {
                position: absolute;
                bottom: 0;
                right: 45%;
              }
              h3 {
                font-size: 14px;
                text-transform: none;
                font-weight: 400;
                line-height: inherit;
                .contact-info-field-label {
                  padding-bottom: 4px;
                  font-size: 12px;
                  color: #999;
                }
              }
            }
          }
          .contact-emails-collapse {
            > div:first-child {
              svg {
                right: 25%;
              }
            }
          }
        }
        .contact-info-field-label {
          padding-bottom: 4px;
          font-size: 12px;
          color: #999;
        }
      }
    }
    .contact-details-field {
      padding: 12px 0px;
      h3 {
        margin: 0;
        font-size: 12px;
      }
      .contact-details-label {
        padding-bottom: 4px;
        font-size: 12px;
        color: #999;
      }
      .contact-first {
        font-size: 12px;
      }
    }
    .add-attachment-btn {
      display: flex;
      background-color: #000 !important;
    }
  }
`;

const AddAttachmentWrapper = styled.div`
  display: flex;
  label {
    svg {
      path {
        fill: #4380C7 !important;
      }
    }
    span {
      color: #4380C7 !important;
    }
  }
`;

const TAGS_AUTOCOMPLETE_PROPS = {
  style: { zIndex: 10, position: "relative" },
  apiMethodName: 'getAllContactTags',
};

class CompanyView extends Component {
  static contextTypes = {
    showMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      attachmentsChanged: false,
      attachmentsKey: 1,
      attachments: props.company.attachments || [],
      initialAttachments: props.company.attachments || [],
      tags: props.company.tags || [],
      };
  }
  collapses = {};

  handleClose = () => {
    if (this.props.closeEmailsList) {
      this.props.closeEmailsList();
    }
    history.push(`/$1/$2/-/-/-/---`);
  }

  removeAttachment = (e, id) => {
    const newAttachments = this.state.attachments.filter(
      attachment => attachment?._id !== id
    );
    this.setState(
      state => ({
        attachments: newAttachments,
        attachmentsKey: state.attachmentsKey + 1 || 1
      }),
      this.checkForAttachmentsChanges.bind(this, newAttachments)
    );
  };

  cancelAttachments = () => {
    this.setState(state => ({
      attachmentsKey: state?.attachmentsKey + 1 || 1,
      attachments: state.initialAttachments,
      attachmentsChanged: false,
    }));
  };

  handleSaveAttachments = () => {
    const attachmentIds = this.state.attachments.map(a => a._id);
    this.props.updateCompany({ id: this.props.company.id, attachmentIds: attachmentIds }).then(res => {
      if (res.data?.attachments) {
        this.setState({
          attachments: res.data.attachments,
          initialAttachments: res.data.attachments,
        });
        this.props.company.attachments = res.data.attachments;
      }
      this.setState({
          attachmentsChanged: false,
        });
    });
  }

  setAttachmentsChange = () => this.setState({ attachmentsChanged: true });
  invalidSubmit = (model) => {
    const invalid =
      document.querySelector(`input[data-valid=false]`) ||
      document.querySelector(`.error`) ||
      document.querySelector(`.with_error`);

    this.openCollapsesWithErrors();
    if (invalid) {
      scrollToAnchor(invalid);
    }
  };

  openCollapsesWithErrors = () => {
    for (let ref in this.collapses) {
      if (this.collapses[ref] && this.collapses[ref].findInvalidInputs) {
        this.collapses[ref].findInvalidInputs();
      }
    }
  };

  onFilesUploaded = (validatedFiles, uploadedFiles) => {
    const newAttachments = [...this.state.attachments, ...(uploadedFiles.map(f => ({ ...f, canEdit: true })))];
    this.setState(
      state => ({ attachments: newAttachments }),
      this.checkForAttachmentsChanges.bind(this, newAttachments)
    );
    this.collapses.attachmentsConsCollapse &&
      this.collapses.attachmentsConsCollapse.show();
  };

  checkForAttachmentsChanges = newAttachments => {
    const noChanges =
      newAttachments.every(
        (attachment, i) =>
          this.state.initialAttachments.findIndex(
            a => a._id === attachment._id
          ) >= 0
      ) && newAttachments.length === this.state.initialAttachments.length;
    this.setState({
      attachmentsChanged: !noChanges,
    });
  };

  handleSaveTags = async (tag) => {
    const newTags = [...this.state.tags, tag].filter(t => t.canEdit !== false);
    this.props.updateCompany({ id: this.props.company.id, tags: newTags }).then(res => {
      if (res.data) {
        this.context.showMessage({
          message: "Tag was successfully added.",
        });
        this.setState({ tags: res.data.tags });
      }
    });
  }
  handleDeleteTag = async (tag) => {
    const newTags = this.state.tags.filter(t => t.id !== tag.id).filter(t => t.canEdit !== false);
    this.props.updateCompany({ id: this.props.company.id, tags: newTags }).then(res => {
      if (res.data) {
        this.context.showMessage({
          message: "Tag was successfully deleted.",
        });
        this.setState({ tags: res.data.tags });
      }
    });
  }

  renderMembersPart = () =>  {
    return <CompanyMembers members={this.props.company.persons} />;
  }
  openMalilList = () => {
    this.props.openEmailsList(this.props.company.emails); 
  }

  render() {
    const company = this.props.company;
    const { attachments, initialAttachments, attachmentsChanged, attachmentsKey, tags } = this.state;
    return (
      <Pane
        bodyProps={{ style: { height: '100%' } }}
        headerProps={{
          useBorder: true,
          children: company.emails.length ? <WithTooltip
          horizontalPosition="left"
          whiteArrow={false} tip={<div className={cx("tooltip-default", "dark")} >View&nbsp;email&nbsp;history</div>}
          style={{ cursor: "pointer", marginLeft: "100%" }}
          >
            <div onClick={this.openMalilList}>
              <ChatAndMailIcon />
            </div>
            </WithTooltip> : '',
          onClose: this.handleClose,
          style: { justifyContent: "flex-end", flexWrap: "nowrap" },
        }}
        scrollable
      >
        <Body>
          <div className={'contact-name-wrapper'}>
            <Avatar contact={company} size={'large'} />
            <div className={'contact-name-block'}>
              <div className={'contact-name'}>{company.name}</div>
              <div className={'contact-position'}>{company.city ? company.city.name + ' / ' : null} {company.country?.name}</div>
              <SocialMedia contact={company} />
              <div className={"contact-edit-icon"}>
                <CircleEditBtn iconStyle={{ width: "14px", height: "14px" }} style={{ width: "20px", height: "20px" }} onClick={this.props.onEdit} />
              </div>
            </div>
          </div>
          <div className={"contact-info-wrapper"}>
            <div className={'contact-info-block'}>
              <div className={"contact-info-1col"}>
                <div className={'contact-info-field'}>
                <CollapseField
                  title={
                    <div className={'contact-info-field nopadding'}>
                      <div className={'contact-info-field-label'}>
                        Email
                      </div>
                        <div>
                          {company.emails.length ? <a href={`mailto:${company.emails[0]}`} >{company.emails[0]}</a> : "---"}
                        </div>
                    </div>
                    }
                >
                  { company.emails.length > 1 && company.emails.map((email, i) => {
                      if (i > 0) {
                        return (
                          <div key={email}>
                            <a href={`mailto:${email}`} >{email}</a>
                          </div>);
                      }
                      return null;
                    })}
                  </CollapseField>
                  </div>

                <div className={'contact-info-field'}>
                  <div className={'contact-info-field-label'}>
                    Address
                  </div>
                  <div>
                    {company.address || "---"}
                  </div>
                </div>
                <div className={'contact-info-field'}>
                  <div className={'contact-info-field-label'}>
                    Industry
                  </div>
                  <div>
                    {formatIndustry(company.industry) || "---"}
                  </div>
                </div>
                <div className={'contact-info-field'}>
                  <div className={'contact-info-field-label'}>
                    Website
                  </div>
                  <div>
                    {company.website || "---"}
                  </div>
                </div>
              </div>
              <div className={"contact-info-2col"}>
                <div className={'contact-info-field'}>
                  <CollapseField
                    title={
                      <div className={'contact-info-field nopadding'}>
                        <div className={'contact-info-field-label'}>
                          { phoneTypeMap[company.phones[0]?.type || 'landline']}
                        </div>
                        {company.phones.length ? company.phones[0].number : "---"}
                      </div>
                      }
                  >
                    { company.phones.length > 1 && company.phones.map((phone, i) => {
                        if (i > 0) {
                          return (
                            <div className={'contact-info-field'}>
                              <div className={'contact-info-field-label'}>
                                {phoneTypeMap[phone.type]}
                              </div>
                              {phone.number}
                            </div>);
                        }
                          return null;
                    })}
                  </CollapseField>
                </div>
                <div className={'contact-info-field'}>
                  <div className={'contact-info-field-label'}>
                    Postal Code
                  </div>
                  <div>
                    {company.postalCode || "---"}
                  </div>
                </div>
                <div className={'contact-info-field'}>
                  <div className={'contact-info-field-label'}>
                    Sector
                  </div>
                  <div>
                    {formatSector(company.sector) || "---"}
                  </div>
                </div>
              </div>
            </div>
            <div className={'contact-details-field'}>
              <h3>ADDITIONAL DETAILS</h3>
              <div className={'contact-first'}>
                {company.details || "---"}
              </div>
            </div>
          </div>
          {this.renderMembersPart()}
          <Tags handleSave={this.handleSaveTags} handleDelete={this.handleDeleteTag} entityId={company.id} tags={tags} opened entity="cargoRequest" isContactTags autocompleteProps={TAGS_AUTOCOMPLETE_PROPS} />
          <WithUserUploads key={attachmentsKey} shouldUpload onSuccess={this.onFilesUploaded} maxFiles={Number.MAX_VALUE}>
                {({ erroredFiles, validateUserUploads, uploadedFiles, accept, uploading }) => {
                  return (<Collapse
                    defaultOpen
                    innerRef={el => (this.collapses.attachmentsConsCollapse = el)}
                    title={initialAttachments.length ? `ATTACHMENTS (${initialAttachments.length})` : "ATTACHMENTS"}
                    additionals={
                      <AddAttachmentWrapper>

                        <AddAttachment
                          key={attachmentsKey}
                          changesCallback={this.setAttachmentsChange}
                          success={validateUserUploads}
                        />
                      </AddAttachmentWrapper>
                    }
                  >
                    <ExtendedForm
                      ref="attachmentsForm"
                      noValidate
                      onValidSubmit={this.handleSaveAttachments}
                      onInvalidSubmit={this.invalidSubmit}
                    >
                      <Attachments
                        changesCallback={this.setAttachmentsChange}
                        handleRemove={this.removeAttachment}
                        attachments={attachments}
                      />
                      <div>{erroredFiles.length ? <UploadedFileExtensionErr className={'upload_error'} accept={accept} erroredFiles={erroredFiles} /> : null}</div>
                      <HiddenButtons
                        shouldShow={attachmentsChanged}
                        handleCancel={this.cancelAttachments}
                      />
                    </ExtendedForm>
                  </Collapse>);
                }}
          </WithUserUploads>
          <CommonComments isContactComment publicToggleEnabled={false} entityType={'contact'} entity={this.props.company} />
        </Body>
      </Pane>
    );
  }
}

export default CompanyView;

