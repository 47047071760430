import React, { Component, PureComponent } from 'react'
import IconButton from 'material-ui/IconButton';
import ArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import s from './Collapse.scss';
import cx from "classnames";
import withStyles from 'isomorphic-style-loader/lib/withStyles';

export class Collapse extends Component {
    static defaultProps = {
      headerClass: '',
      titleClass: '',
      isHidden: false,
      defaultOpen: true,
      innerRef: () => void(0),
      noBorder: false,
      locked: false,
    }
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.isHidden || this.props.locked ? false : this.props.defaultOpen,
            initialMount: true,
        };
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.adjustHeight = this.adjustHeight.bind(this);
        this.setAutoHeight = this.setAutoHeight.bind(this);
        this.show = this.show.bind(this);
        this.body = null;
        this.container = null;
        this.header = null;
    }

    toggleCollapse() {
        if (this.props.isHidden || this.props.locked) return;
        this.container.classList.remove(s.no_animation);
        if(!this.state.open) {
          this.beforeOpen();
        }
        this.setState({ open: !this.state.open }, this.adjustHeight)
    }

    show(ev, cb) {
        this.beforeOpen();
        return new Promise((res, rej) => this.setState({ open: true }, () => {
          res();
          this.setAutoHeight();
          cb && cb();
        }));
    }

    hide = () => {
      this.container && this.container.classList.add(s.no_animation);
      this.setState({open: false}, this.adjustHeight)
    }

    componentDidMount() {
        const { open } = this.state;
        this.setState({initialMount: false})
        this.props.innerRef(this);
        open ? this.setAutoHeight() : this.adjustHeight(true);
    }

    setAutoHeight() {
        if (this.state.open && this.container) {
            this.container.style.height = 'auto';
            this.container.style.overflow = this.state.open ? 'unset' : 'hidden';
        }
        if (!this.state.open) {
          this.body.style.height = '0px';
          this.body.style.overflow = 'hidden';
          this.container.style.overflow = 'visible';
        }
        this?.container?.removeEventListener('transitionend', this.setAutoHeight);
    }

    beforeOpen() {
      this.container.style.overflow = 'hidden';
      this.body.style.height = 'auto';
      this.body.style.overflow = 'unset';
    }

    componentWillUnmount() {
      if (this.container) {
        this.container.removeEventListener('transitionend', this.setAutoHeight);
      }
        this.setAutoHeight();
        this.props.innerRef(null);
    }

    componentWillReceiveProps(nextProps) {
      if(this.props.locked) {
        return
      }
      if (nextProps.isHidden !== this.props.isHidden) {
        this.container.classList.remove(s.no_animation);
        this.setState({ open: !nextProps.isHidden }, this.adjustHeight);
      }
      if (!this.props.forceToggleCollapse && nextProps.forceToggleCollapse) {
        this.beforeOpen();
        this.props.setForceToggleCollapse(false);
      }
    }

    findInvalidInputs = () => {
      setTimeout(() => {
        if (this.body && !this.state.open) {
          const invalid = this.body.querySelector('.error:not(.pristine)') || this.body.querySelector('.with_error');
          if (invalid) {
            this.toggleCollapse();
          }
        }
      }, 100);
    }

    adjustHeight(withoutAnimation = false) {
        if (withoutAnimation && this.container) {
          this.container.classList.add(s.no_animation)
        }
        if (!this.state.open) {
            if (this.container && this.header && this.body) {
              this.container.style.overflow = 'hidden';
                this.container.style.height = `${this.container.offsetHeight}px`;
                setTimeout(() => {
                  if (this.container) {
                    this.container.style.height = `${this.header.offsetHeight + 1}px`
                  }
                }, 1);
            }
            if (withoutAnimation) {
                this.body.style.height = '0px';
                this.body.style.overflow = 'hidden';
                this.container.style.overflow = 'visible';
            }
        } else if (this.container && this.body && this.header) {
                this.container.style.height = `${this.body.offsetHeight + this.header.offsetHeight}px`;
        }
        this.container.addEventListener('transitionend', this.setAutoHeight);
    }

    setState(state, callback) {
      super.setState(state, callback);
      if(state.open !== undefined && state.open !== this.state.open) {
        if(this.props.onToggle) {
          this.props.onToggle(state.open);
        }

      }
    }

  render() {
        const {open, initialMount} = this.state;
        // TODO: change classes prop for className
        // NOTE: "classes" prop is legacy. Please, use "className" prop instead, because it supports styled components overrides
        const {children, title = 'Info', additionals, titleClass="", headerClass="", isHidden, noBorder, style = {}, classes = '', className = '', titleStyle = {}, renderClosed=true } = this.props;
        let { headerStyle = {} } = this.props;
        headerStyle = {cursor: isHidden ? 'default' : 'pointer', ...headerStyle};
        if (noBorder && open) {
          headerStyle.border = 'none'
        }
        return (
            <div tabIndex="1" style={style} onKeyDown={e => e.which === 13 ? this.toggleCollapse : null} className={cx(s.collapse_wrapper, classes, className)} ref={node => this.container = node}>
                <div className={cx(s.collapse_header, headerClass, this.state.open && 'open')} style={headerStyle} ref={node => this.header = node}>
                    <div className={s.left} onClick={this.toggleCollapse}>
                        {
                          !isHidden
                            ?  <ArrowUp style={{transition: '0.3s ease-in-out', transform: `rotate(${open ? '0deg' : '-180deg'})`}}></ArrowUp>
                            : null
                        }
                        <h2 className={cx(s.title, titleClass)} style={titleStyle}>{title}{this.props.locked && this.props.lockIcon}</h2>
                    </div>
                    {additionals && !this.props.locked ? additionals : null}
                </div>
                <div onFocus={this.show} style={initialMount && !open ? {height: '0px'} : undefined} className={s.collapse_body} ref={node => this.body = node}>
                  {
                    ((initialMount || !renderClosed) && !open) || this.props.locked
                    ? null
                    : children
                  }
                </div>
            </div>
        )
    }
}

export default withStyles(s)(Collapse)
