import type { Consumptions, Place } from './types';
import { formatNumber } from '../../core/format';

export function calcDays(distance = 0, speed = 14, wf = 5) {
  if(!speed) {return 0};
  return (Math.round(100000 * (((distance / speed) / 24) * (1 + ((wf || 0) / 100)))) / 100000) || 0;
}

export function onEnterNextInput(e) {
  const keyCode = e.which;
  if (keyCode !== 13) { //enter
    return;
  }
  e.preventDefault();
  e.cancelBubble = true;
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
  const input = e.currentTarget;
  const inputs = Array.from(document.querySelectorAll(`form input[type=text], form button[type=submit]`));
  if (inputs && input) {
    const nextInput = inputs[inputs.indexOf(input) + 1];
    if (nextInput && nextInput.focus) {
      nextInput.focus();
      nextInput.setSelectionRange && nextInput.setSelectionRange(100, 100);
      nextInput.click && nextInput.click();
    }
  }
  return false;
}

export function stopEnterPropagation(e) {
  const keyCode = e.which;
  if (keyCode === 13) { //enter
    e.preventDefault();
    e.cancelBubble = true;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    return false;
  }
}

function getLdSpeedDivider(speed = '') {
  if (speed.indexOf('day') !== -1) {
    return 1;
  }
  if (speed.indexOf('hour') !== -1) {
    return 24;
  }
  return 1; //TODO check user terms for hours
}

export function getWorkDaysFromLdRate(ldrate = {}, cargoRequest) {
  const speedDivider = getLdSpeedDivider(ldrate.speed);
  let dimension = ldrate.dimension || 'mt';
  if (!ldrate.quantity) {
    return 0;
  }
  if (dimension === 'mt' && ldrate.quantity < 10 && speedDivider === 1) {
    dimension = 'days';
  }
  switch (dimension) {
    case 'days':
    case 'day':
    case 'total days':
    case 'working days':
    case 'weather working days':
      return ldrate.quantity;
    case 'hours':
    case 'total hours':
    case 'working hours':
      return ldrate.quantity / 24;
    case 'mt per weather working day':
    case 'mt per weather working day of 24 consecutive hours':
      return cargoRequest.totalValues.weight / (ldrate.quantity * 1000);
    case 'mt':
      return cargoRequest.totalValues.weight / (speedDivider * ldrate.quantity * 1000);
    case 'cubic feet':
      return (cargoRequest.totalValues.volume * 35.3147) / (speedDivider * ldrate.quantity);
    case 'm3':
      return (cargoRequest.totalValues.volume) / (speedDivider * ldrate.quantity);
    case 'long tons':
      return cargoRequest.totalValues.weight / (speedDivider * ldrate.quantity * 1016);
    case 'short tons':
      return cargoRequest.totalValues.weight / (speedDivider * ldrate.quantity * 907);
  }

  return 0;
}

/**
 *
 * @param {Array<Consumption>} consList
 * @param {String} type
 * @returns {Consumption}
 */
function consByTypeFinder(consList = [], type) {
  return consList.filter(c => c.typeOfFuel.indexOf(type) === 0).sort((a, b) => a.quantity - b.quantity)[0];
}


/**
 *
 * @param {Object} v
 * @returns {Promise<Consumptions>}
 */
export function getConsumptions(v) {

  const laden = {};
  const ballast = {};
  const working = {};
  const idle = {};

  laden.ifo = consByTypeFinder(v.consumptions.laden, 'IFO');
  laden.mdo = consByTypeFinder(v.consumptions.laden, 'MDO') || consByTypeFinder(v.consumptions.laden, 'MGO');

  ballast.ifo = consByTypeFinder(v.consumptions.ballast, 'IFO');
  ballast.mdo = consByTypeFinder(v.consumptions.ballast, 'MDO') || consByTypeFinder(v.consumptions.ballast, 'MGO');

  working.ifo = consByTypeFinder(v.consumptions.portWorking, 'IFO');
  working.mdo = consByTypeFinder(v.consumptions.portWorking, 'MDO') || consByTypeFinder(v.consumptions.portWorking, 'MGO');

  idle.ifo = consByTypeFinder(v.consumptions.portIdle, 'IFO');
  idle.mdo = consByTypeFinder(v.consumptions.portIdle, 'MDO') || consByTypeFinder(v.consumptions.portIdle, 'MGO');

  if (!laden.ifo) {
    laden.ifo = {
      typeOfFuel: 'IFO 180',
      quantity: (0.0257030375 * (Math.pow(v.dw.summer, -0.5897792229))) * v.dw.summer * (v.speeds.laden || 14),
    }
  }
  if (!ballast.ifo) {
    ballast.ifo = {
      typeOfFuel: 'IFO 180',
      quantity: (0.0302465511 * (Math.pow(v.dw.summer, -0.6094907379))) * v.dw.summer * (v.speeds.ballast || 14),
    }
  }

  if (!working.ifo && !working.mdo) {
    working.ifo = {
      typeOfFuel: laden.ifo.typeOfFuel,
      quantity: 0.05 * (laden.ifo.quantity + ballast.ifo.quantity),
    };
  }
  if (!idle.ifo && !idle.mdo) {
    idle.ifo = {
      typeOfFuel: laden.ifo.typeOfFuel,
      quantity: 0.035 * (laden.ifo.quantity + ballast.ifo.quantity),
    };
  }

  if (!laden.mdo) {
    laden.mdo = {
      typeOfFuel: 'MGO',
      quantity: 0.1,
    };
  }
  if (!ballast.mdo) {
    ballast.mdo = {
      typeOfFuel: 'MGO',
      quantity: 0.1,
    };
  }

  if (!working.ifo) {
    working.ifo = {
      typeOfFuel: laden.ifo.typeOfFuel,
      quantity: 0,
    };
  }
  if (!working.mdo) {
    working.mdo = {
      typeOfFuel: 'MGO',
      quantity: 0.1,
    };
  }
  if (!idle.ifo) {
    idle.ifo = {
      typeOfFuel: laden.ifo.typeOfFuel,
      quantity: 0,
    };
  }
  if (!idle.mdo) {
    idle.mdo = {
      typeOfFuel: 'MGO',
      quantity: 0.1,
    };
  }
  return { laden, ballast, working, idle };
}


const FUEL_TYPE_MAP = {
  'IFO': 'FO',
  'IFO 60': 'FO 180',
  'IFO 80': 'FO 180',
  'IFO 120': 'FO 180',
  'IFO 180': 'FO 180',
  'IFO 380': 'FO 380',
  'MDO': 'MDO',
  'MGO': 'MGO',
};

export function consumptionsV1ToCalculator(v, fuel) {
  if(!v.dw) {
    return;
  }
  const c = getConsumptions(v);
  const con = {
    foType: FUEL_TYPE_MAP[c.laden.ifo.typeOfFuel],
    doType: FUEL_TYPE_MAP[c.laden.mdo.typeOfFuel],
    foPrice: undefined,
    doPrice: undefined,
    laden: { fo: { cons: round(c.laden.ifo.quantity) }, do: { cons: round(c.laden.mdo.quantity) } },
    ballast: { fo: { cons: round(c.ballast.ifo.quantity) }, do: { cons: round(c.ballast.mdo.quantity) } },
    working: { fo: { cons: round(c.working.ifo.quantity) }, do: { cons: round(c.working.mdo.quantity) } },
    idle: { fo: { cons: round(c.idle.ifo.quantity) }, do: { cons: round(c.idle.mdo.quantity) } },
  };
  if (fuel) {
    con.foPrice = fuel[con.foType];
    con.doPrice = fuel[con.doType];
  }
  return {
    st: con,
    ls: { ...con, foType: 'LS ' + con.foType, doType: 'LS ' + con.doType },
  };
}

export function round(number, fractionDigits = 2) {
  const coef = Math.pow(10, fractionDigits);
  return Math.round(number * coef) / coef;
}

/** @typedef {Object} Consumption
 * @property {String} typeOfFuel
 * @property {Number} quantity
 */

/** @typedef {Object} Consumptions
 * @property {Consumption} laden.ifo
 * @property {Consumption} laden.mdo
 * @property {Consumption} ballast.ifo
 * @property {Consumption} ballast.mdo
 * @property {Consumption} working.ifo
 * @property {Consumption} working.mdo
 * @property {Consumption} idle.ifo
 * @property {Consumption} idle.mdo
 */

const emptyConsumptions = {
  full: { ballastSpeed: undefined,ladenSpeed:undefined, main: { normal: {type:'vlsfo'}, eca: {type:'vlsfo'} }, aux: { normal: {type:'lsmgo'}, eca: {type:'lsmgo'} } },
  eco: { ballastSpeed: undefined,ladenSpeed:undefined, main: { normal: {type:'vlsfo'}, eca: {type:'vlsfo'} }, aux: { normal: {type:'lsmgo'}, eca: {type:'lsmgo'} } },
  mode: 'full',
}

export function parseConsV3(cons) {
  const full = cons.full;
  const eco = cons.eco;

  const parsedCons = {};

  parsedCons.ballast = formatCon(full.ballastSpeed,full.main.normal.ballast,full.aux.normal.ballast,full.main.normal._type,full.aux.normal._type);
  parsedCons.laden = formatCon(full.ladenSpeed,full.main.normal.laden,full.aux.normal.laden,full.main.normal._type,full.aux.normal._type);
  parsedCons.ecoballast = formatCon(eco.ballastSpeed,eco.main.normal.ballast,eco.aux.normal.ballast,eco.main.normal._type,eco.aux.normal._type);
  parsedCons.ecoladen = formatCon(eco.ladenSpeed,eco.main.normal.laden,eco.aux.normal.laden,eco.main.normal._type,eco.aux.normal._type);
  parsedCons.working = formatCon(0,full.main.normal.working,full.aux.normal.working,full.main.normal._type,full.aux.normal._type);
  parsedCons.idle = formatCon(0,full.main.normal.idle,full.aux.normal.idle,full.main.normal._type,full.aux.normal._type);

  return parsedCons;
}

export function formatCon(speed, main, aux, mainType, auxType) {
  let ret = '';
  if(speed) {
    ret += formatNumber(speed, '',' kn');
    if(main || aux) {
      ret += '/';
    }
  }
  if(main) {
    ret += `${formatNumber(main)}mt\u00A0${mainType}`;
    if (aux) {
      ret += ' +\u00A0';
    }
  }
  if(aux) {
    ret += `${formatNumber(aux)}mt\u00A0${auxType}`;
  }
  return ret;
}

export function convertConsumptionsV2(consV2, privateCons) {
  let laden, ballast, working, idle, ecoladen, ecoballast, eco, ecoworking, ecoidle;
  let newConsumptions: Consumptions = JSON.parse(JSON.stringify(emptyConsumptions));
  if (Array.isArray(privateCons) && privateCons.length) {
    consV2 = privateCons;
  }

  if (!consV2) {
    return newConsumptions;
  }

  laden = consFinder('Laden', consV2) || consFinder('Laden', consV2, false);
  ballast = consFinder('Ballast', consV2) || consFinder('Ballast', consV2, false);
  working = consFinder('Working', consV2);
  idle = consFinder('Idle', consV2);
  eco = consFinder('Eco', consV2);
  ecoladen = consFinder('Eco Laden', consV2) || eco;
  ecoballast =  consFinder('Eco Ballast', consV2) || eco;
  ecoworking = working;
  ecoidle = idle;

  const context = {laden,ballast,working,idle,ecoladen, ecoballast,ecoworking,ecoidle};

  ["laden", "ballast", "working", "idle", "ecoladen", "ecoballast","ecoworking","ecoidle"].forEach(key => {
    let mode = "full";
    let oldCon = context[key];
    if(key.indexOf("eco")===0) {
      mode = 'eco';
      key = key.slice(3);
    }
    let con = newConsumptions[mode];
    let main = oldCon?.main;
    let aux = oldCon?.aux;
    if(key==='laden') {
      con.ladenSpeed = oldCon?.speed;
    }
    if(key==='ballast') {
      con.ballastSpeed = oldCon?.speed;
    }
    if(main?.cons) {
      con.main.normal[key] = main.cons;
      con.main.eca[key] = main.cons;
      if(!con.main.normal._type) {
        //con.main.normal.type =  (main.type || '').replace(' ','').toLocaleLowerCase();
        //con.main.eca.type = (main.type || '').replace(' ','').toLocaleLowerCase();
        con.main.normal._type = main.type;
        con.main.eca._type = main.type;
      }
    }
    if(aux?.cons) {
      con.aux.normal[key] = aux.cons;
      con.aux.eca[key] = aux.cons;
      if(!con.aux.normal._type) {
        con.aux.normal.type = (aux.type || '').replace(' ','').toLocaleLowerCase();
        con.aux.eca.type = (aux.type || '').replace(' ','').toLocaleLowerCase();
        con.aux.normal._type = aux.type;
        con.aux.eca._type = aux.type;
      }
    }

  })

  return newConsumptions;
}

function consFinder(mode: 'Laden' | 'Ballast' | 'Eco' | 'Eco Laden' | 'Eco Ballast' | 'Working' | 'Idle', consV2, consRequired = true) {
  let cons = consV2.filter(c => c.mode === mode);
  let speed;
  let ifo;
  let mdo;
  let main;
  let aux;
  if (cons.length) {
    speed = cons[0].speed;
    for (let i = 0; i < cons.length; i++) {
      const con = cons[i];
      if(!ifo && con.ifo?.cons) {
        ifo = con.ifo;
        ifo.type = ifo.typeIfo;
      }
      if(!mdo && con.mdo?.cons) {
        mdo = con.mdo;
        mdo.type = mdo.typeMdo;
      }
    }
  }
  if(ifo?.cons) {
    main = ifo;
  }
  if(mdo?.cons) {
    aux = mdo;
    if(mode !== "Idle" && mode !== 'Working'){
      if(!ifo?.cons) {
        main = mdo
        aux = undefined;
      } else if (mdo.cons > ifo.cons){
        main = mdo;
        aux = ifo;
      }
    }
  }
  if(!main && !aux && (!speed || consRequired)) {
    return;
  }
  return {main, aux, speed};

}

export function or0(value) {
  return value || 0;
}

export function isEcaPort(port: Place){
  return !!port.isInEcaZone;
}

export function getMtCmbFrtFromIntake(intake ={value:0, unit: 'mt'}, sf = 1) {
  let mt,cbm = 0;
  if(!sf) {
    sf = 1;
  }
  if (!intake?.unit) {
    intake.unit = 'mt';
  }
  intake.value = intake.value || 0;
  let frt;
  switch (intake.unit) { //TODO use convertUnit
    case 'mt':
      mt = intake.value;
      cbm = intake.value * sf;
      frt = mt;
      break;
    case 'cbm':
      cbm = intake.value;
      mt = cbm / sf;
      frt = cbm;
      break;
    case 'cbf':
      cbm = intake.value / 35.3147;
      mt = cbm / sf;
      frt = cbm;
      break;
    case 'lt':
      mt = intake.value * 0.907185;
      cbm = mt * sf;
      frt = mt;
      break;
    default:
      throw new Error("Unsupported unit " + intake.unit);
  }
  return {mt, cbm, frt};
}

export function mtToOriginalIntakeUnit(intake = { value: 0, unit: 'mt' },mtValue=0, vessel, sf =1){
  let value = 0
  if(!sf) {
    sf = 1;
  }
 /* let vsf = 1;
  const capacity = vessel.capacity?.bale || vessel.capacity?.grain
  if(vessel.dw?.summer && capacity) {
    vsf = capacity / vessel.dw.summer
  }*/
  switch (intake.unit) { //TODO use convertUnit
    case 'mt':
      value = mtValue;
      break;
    case 'cbm':
      value = mtValue * sf;
      break;
    case 'cbf':
      value = mtValue * sf *  35.3147;
      break;
    case 'lt':
      value = mtValue / 0.907185;
      break;
    default:
      throw new Error("Unsupported unit " + intake.unit);
  }
  return value;
}

export function convertUnit(from='mt', to='mt', sf=1, value=0) {
  if(!sf) {
    sf = 1;
  }
  if(from === to || !value) {
    return value;
  }// sf cbm/mt = 0,028769889* cbf/lt
  switch(from+'-'+to){
    case 'mt-cbm':
      return value * sf;
    case 'mt-cbf':
      return  value * sf * 35.3147;
    case 'lt-cbm':
      return 0.907185 * value * sf;
    case 'lt-cbf':
      return 0.907185 * value * sf * 35.3147;
    case 'cbm-cbf':
      return value * 35.3147;
    case 'mt-lt':
      return value / 0.907185;

    case 'cbm-mt':
      return value / sf;
    case 'cbf-mt':
      return value / sf / 35.3147;
    case 'cbm-lt':
      return value / sf /  0.907185;
    case 'cbf-lt':
      return value / 35.3147 / sf / 0.907185  ;
    case 'cbf-cbm':
      return value / 35.3147;
    case 'lt-mt':
      return value * 0.907185;
    default:
      throw new Error('Unsupported units ' + from + to);

  }

}

export function prepareVessel(vessel) {
  const capacity = vessel.capacity;
  if(capacity) {
    vessel.capacity.grainCbf = round(or0(vessel.capacity.grain) * 35.3147,0);
    vessel.capacity.baleCbf = round(or0(vessel.capacity.bale) * 35.3147,0);
  }
  vessel.draftFt = round(or0(vessel.draft) * 3.28084,2);
  const consumptions = vessel.consumptions;
  if(consumptions) {
    if(consumptions.tpc && !consumptions.tpi) {
      consumptions.tpi = round(consumptions.tpc * 2.54,2 );
    }
    if(consumptions.tpi && !consumptions.tpc) {
      consumptions.tpc = round(consumptions.tpi / 2.54,2 );
    }
  }
  return vessel;
}

export function fixFuelTypes(consumptions, scrubbers) {
  if (!scrubbers) {
    consumptions = { ...consumptions };
    ['eco', 'full'].forEach(mode => {
      ['main', 'aux'].forEach(engine => {
        ['normal', 'eca'].forEach(zone => {
          if (zone === 'eca' && engine === 'main') {
            consumptions[mode] = { ...consumptions[mode] };
            consumptions[mode][engine] = { ...consumptions[mode][engine] };
            consumptions[mode][engine][zone] = { ...consumptions[mode][engine][zone] };
            consumptions[mode][engine][zone].type = 'lsmgo';
          }
        });
      });
    });
    return consumptions;
  }

  function fixLsFuelTypes(con) {
    if (con.type.indexOf('lsfo') !== -1) {
      con = { ...con };
      con.type = 'ifo380';
    }
    return con;
  }

  consumptions = { ...consumptions };
  ['eco', 'full'].forEach(mode => {
    ['main', 'aux'].forEach(engine => {
      ['normal', 'eca'].forEach(zone => {
        consumptions[mode] = { ...consumptions[mode] }
        consumptions[mode][engine] = { ...consumptions[mode][engine] }
        consumptions[mode][engine][zone] = fixLsFuelTypes(consumptions[mode][engine][zone])
        if (zone === 'eca' && engine === 'main') {
          consumptions[mode][engine][zone].type = 'ifo380';
        }
      });
    });
  });
  return consumptions;
}

export const fuelTypes = [
  {
    id: "vlsfo",
    name: "VLS FO",
    isLs: true,
    carbonEmission: 3.151,
  },
  {
    id: "ulsfo",
    name: "ULS FO",
    isLs: true,
    carbonEmission: 3.151,
  },
  {
    id: "lsfo",
    name: "LSFO",
    carbonEmission: 3.151,
  },
  {
    id: "ifo180",
    name: "HFO 180",
    carbonEmission: 3.114,
  },
  {
    id: "ifo380",
    name: "HFO 380",
    carbonEmission: 3.114,
  },
  {
    id: "hfo",
    name: "HFO",
    carbonEmission: 3.114,
  },
  {
    id: "mgo",
    name: "MGO",
    carbonEmission: 3.206,
  },
  {
    id: "lsmgo",
    name: "LS MGO",
    carbonEmission: 3.206,
  },
  {
    id: "lng",
    name: "LNG",
    carbonEmission: 2.75,
  },
  {
    id: "h2",
    name: "H2",
    carbonEmission: 0,
  },
  {
    id: "methanol",
    name: "Methanol",
    carbonEmission: 1.375,
  },
  {
    id: "ammonia",
    name: "Ammonia",
    carbonEmission: 0,
  },
];


export const carbonEmissionByFuelTypeMap = fuelTypes.reduce((acc, ft) => { acc[ft.id] = ft.carbonEmission; return acc; }, {});




export function calculateDistanceNm(lat1, lng1, lat2, lng2) {
  const R = 3958.8;
  const radLat1 = lat1 * Math.PI / 180;
  const radLat2 = lat2 * Math.PI / 180;
  const deltaLat = (lat2 - lat1) * Math.PI / 180;
  const deltaLng = (lng2 - lng1) * Math.PI / 180;

  const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(radLat1) * Math.cos(radLat2) *
    Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}

export function getNearestPoints(routePoints, center, maxDistance) {
  return routePoints.filter(point => {
    const distance = calculateDistanceNm(point[1], point[0], center.lat, center.lng);
    return distance <= maxDistance;
  });
}

const SUETZ_CANAL_CENTER = { lat: 30.5852, lng: 32.2723 };

export function getSuezDirection(routePoints) {
  routePoints = getNearestPoints(routePoints, SUETZ_CANAL_CENTER, 50);
  if (!routePoints || routePoints.length < 2) {
    throw new Error("Not enough data to calculate suez direction");
  }

  const startPoint = routePoints[0];
  const endPoint = routePoints[routePoints.length - 1];

  if (startPoint[1] < endPoint[1]) {
    return "north";
  }
    return "south";
}

export function buildViaDaDetailsFromVia(via, prevDetails) {
  const newDa = { total: 0, daList: via.map(v => ({ canalId: v, value: 0 })) };
  newDa.daList.push({ canalId: '000000000000000000000000', value: 0 });
  if (prevDetails) {
    newDa.daList.forEach(da => {
      const prevDa = prevDetails.daList.find(_da => _da.canalId === da.canalId);
      if (prevDa) {
        da = Object.assign(da, prevDa);
      }
      return da;
    });
  }
  newDa.total = newDa.daList.reduce((acc, da) => acc + or0(da.value), 0);
  return newDa;
}
const unitMap = {'lt': 'ust'};
export function formatUnit(unit) {
  return unitMap[unit] || unit;
}

export function getSuezDaFromDetails(daDetails) {
  if (!daDetails?.daList) {
    return;
  }
 return daDetails.daList.find((da) => da.canalId === '5ada202ea63c49531e40f0bc');
}
