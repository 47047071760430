exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".QCiTY{background:none;padding:0;width:100%;cursor:pointer;height:28px;font-family:inherit}._331hq{padding-top:8px}._14JGn{padding-left:6px}._113l5{padding-right:2px}.EqqOg{display:-ms-flexbox;display:flex}._12LfK{-ms-flex-align:center;align-items:center}._1iTQX{border-width:0;border-style:solid;border-bottom-width:1px}.Mubul{border-color:var(--stroke-light-gray1)}.oy18L{font-weight:500}._1Wm8q{line-height:24px}._7dDjh{font-size:12px}\n", ""]);

// Exports
exports.locals = {
	"toggle_button": "QCiTY",
	"pt_8px": "_331hq",
	"pl_6px": "_14JGn",
	"pr_2px": "_113l5",
	"flex": "EqqOg",
	"items_center": "_12LfK",
	"border_b": "_1iTQX",
	"border_light_gray1": "Mubul",
	"font_medium": "oy18L",
	"line_h_24px": "_1Wm8q",
	"text_12px": "_7dDjh"
};