import PropTypes from 'prop-types';
import React from 'react';
import s from './Cargo.scss';
import FormsyCheckbox from '../../components/Common/CheckBox';
import MenuItem from 'material-ui/MenuItem';
import FormsyRadio from 'formsy-material-ui/lib/FormsyRadio';
import FormsyRadioGroup from 'formsy-material-ui/lib/FormsyRadioGroup';
import MiniPackingList from './MiniPackingList';
import NarrowFormsyText from '../NewInputs/NarrowFormsyText';
import NarrowSelect from '../NewInputs/NarrowSelect';
import { accessObjectValueWithName } from './utils';
import memoizeOne from 'memoize-one';
import Switch from '../Common/Switch';
import { MinMaxInput } from './../Monitor/TCOrders/Steps/VesselRequirements'
import MultiCheckbox from '../Common/MultiCheckbox';
import { set } from '../../core/utils';
import Densities from "./Densities";
import ContainerTypeSelect from "../Common/ContainerTypeSelect";


const displayNone = { display: 'none' };
function calc(str) {
  var state = Object.assign({}, this, this['']);
  try {
    return Math.round(eval(str) * 100) / 100;
  } catch (e) {}
}
const dependantFields = {
  "[unitsWeight][value]": fields => fields["[unitsVolume][value]"]?.state?._value,
  "[unitsVolume][value]": fields => fields["[unitsWeight][value]"]?.state?._value,
}

const isWeightVolumeRequired = (name, fields) => {
  const dependableFieldHasValue = dependantFields[name]?.(fields);
  return dependableFieldHasValue ? "" : "*"
};

const flipNestedFields = {
  '[stackable][dunnaging]': '[stackable][nested]',
  '[stackable][nested]': '[stackable][dunnaging]',
}

function getDotName(name) {
  return name.replace(/\]\[/g, '.').replace(/[\[\]]/g, '');
}

export default class Stowage extends React.PureComponent {
  state = { metricSystem: 'mt', touched: {} };
  static propTypes = {
    categories: PropTypes.array,
    cargoName: PropTypes.string,
    handleChangeTitle: PropTypes.func.isRequired,
    stowageFields: PropTypes.array,
    handleAddCargo: PropTypes.func.isRequired,
    namePrefix: PropTypes.string,
    packingList: PropTypes.array,
    tabIndex: PropTypes.number,
    otherFields: PropTypes.array,
    defaultValues: PropTypes.object,
    flipNested: PropTypes.bool,
  };

  static defaultProps = {
    categories: ['unpacked'],
    stateDataPrefix: "stowage",
  };

  static contextTypes = {
    onChange: PropTypes.func,
    selector: PropTypes.func,
  }

  constructor(props, context) {
    super(props);
    if (props.defaultValues) {
      this.state = Object.assign(this.state, props.defaultValues);
      console.log(props.defaultValues);
    }

    this.onChange = context.onChange("cargoList")
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.data.stowageSpread !== this.props.data.stowageSpread) {
  //     this.props.stowageFields.forEach(field => {
  //       if (field.spread) {
  //         if (this.props.data.stowageSpread) {
  //           field.spread.forEach(spreadField => this.handleGenericChange(spreadField.name, true)(null, accessObjectValueWithName(this.props.data, field.name) || ""));
  //         }
  //         else {
  //           this.handleGenericChange(field.name, true)(null, accessObjectValueWithName(this.props.data, field.spread.find(s => s.minMaxType === 'min')?.name) || "");
  //         }
  //       }
  //     });
  //   }
  // }

  componentDidMount() {
    //setTimeout(this.changeTitle, 1000);
    // const categoryRadioBtnName = '[category]';
    // this.fields[categoryRadioBtnName] &&
    //   this.props.handleChange(categoryRadioBtnName)(null, this.props.categories[0]);
    // this.setState({
    //   '[category]': this.state['[category]'] || this.props.categories[0],
    // });
  }

  // shouldComponentUpdate(nextProps, nextState, nextContext) {
  //   if (
  //     this.props.stowageFields !== nextProps.stowageFields ||
  //     this.props.otherFields !== nextProps.otherFields ||
  //     this.state['[category]'] !== nextState['[category]'] ||
  //     this.state['[stackable][value]'] !== nextState['[stackable][value]'] ||
  //     this.state['[dangerous][value]'] !== nextState['[dangerous][value]'] ||
  //     this.state['[military]'] !== nextState['[military]'] ||
  //     this.state['[name]'] !== nextState['[name]']
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }

  // componentWillReceiveProps(nextProps, nextContext) {
  //   const oldCategories = this.props.categories || ['unpacked'];
  //   const newCategories = nextProps.categories || ['unpacked'];
  //   if (
  //     oldCategories[0] !== newCategories[0] ||
  //     oldCategories[1] !== newCategories[1]
  //   ) {
  //     const categoryRadioBtnName = '[category]';
  //     if (newCategories.indexOf(this.props.data[categoryRadioBtnName]) === -1) {
  //       this.props.handleChange(categoryRadioBtnName)(null, newCategories[0]);
  //       console.log('set category');
  //       //this.setState({ '[category]': newCategories[0] });
  //     }
  //   }

  //   if (this.props.cargoName !== nextProps.cargoName) {
  //     this.changeTitle();
  //   }
  // }

  // changeTitle = () => {
  //   let title = '';
  //   let values = nameFields.map(fn => {
  //     return accessObjectValueWithName(this.props.data, fn) || null;
  //   });
  //   if (values[2]) {
  //     values[2] = 'Stackable';
  //   }
  //   if (values[3]) {
  //     values[3] = values[3] + ' mt';
  //   }
  //   if (!this.props.categories || this.props.categories.length == 1) {
  //     values[1] = null;
  //   }
  //   title = values.filter(v => !!v).join(', ');
  //   title =
  //     (this.props.cargoName || 'Enter cargo name') +
  //     (title ? ', ' + title : '');
  //   this.props.handleChangeTitle && this.props.handleChangeTitle(title);
  // };

  handleGenericChange = (name, calculated = false) => async (e, val) => {
    val = val ?? e?.target?.value ?? e;
    const newData = this.computeUpdatedStowage(this.props.data,name,e, val);
    return this.props.handleChange('$root')(e, newData);
  }

  computeUpdatedStowage(stowage, name, e, val) {
    stowage = { ...stowage };
    const dotName = getDotName(name);
    set(stowage, dotName,val);
    if(dotName === 'military' && val === false) {
      set(stowage, 'dangerous.value', false);
    }
    if (this.props.flipNested) {
      if (name in flipNestedFields && accessObjectValueWithName(stowage, flipNestedFields[name])) {
        set(stowage, getDotName(flipNestedFields[name]), false);
      }
    }
    if (dotName === 'stowageSpread') {
      if (val) {
        if (stowage.unitsWeight?.value) {
            stowage.unitsWeight.valueMin = stowage.unitsWeight.value;
            stowage.unitsWeight.valueMax = stowage.unitsWeight.value;
        }
        if (stowage.unitsVolume?.value) {
          stowage.unitsVolume.valueMin = stowage.unitsVolume.value;
          stowage.unitsVolume.valueMax = stowage.unitsVolume.value;
        }
      } else {
        if (stowage.unitsWeight?.valueMin || stowage.unitsWeight?.valueMax ) {
          stowage.unitsWeight.value = stowage.unitsWeight.valueMin || stowage.unitsWeight.valueMax;
        }
        if (stowage.unitsVolume?.valueMin || stowage.unitsVolume?.valueMax ) {
          stowage.unitsVolume.value = stowage.unitsVolume.valueMin || stowage.unitsVolume.valueMax;
        }
      }
    }
    Object.keys(this.fields).forEach(fieldName => {
      const input = this.fields[fieldName];
      if((fieldName === name) || !input || !input.calculated || input.calculated.indexOf(dotName) === -1) {
        return;
      }
      /*const val = accessObjectValueWithName(newData, fieldName);*/
      if (
        input.touched
        // || this.state.touched[fieldName]
      ) {
        return;
      }
      let cv = calc.call(stowage, input.calculated);
      if (
        cv !== void 0 &&
        cv !== null &&
        !isNaN(cv) &&
        isFinite(cv) &&
        cv !== input.getValue()
      ) {
        stowage = this.computeUpdatedStowage(stowage,fieldName, e, cv);
      }
    });
    return stowage;
  }

  // handleGenericChange(name, e, value) {
  //   //this.state[name]=value;
  //   setTimeout(() => {
  //     this.setState({ [name]: value }, () => {
  //       let formState = formDataToObject.toObj(this.state);
  //       if (this.props.flipNested) {
  //         if (
  //           name === '[stackable][dunnaging]' &&
  //           e &&
  //           this.fields['[stackable][nested]']
  //         ) {
  //           this.fields['[stackable][nested]'].setValue(!value);
  //           return;
  //         } else if (
  //           name === '[stackable][nested]' &&
  //           e &&
  //           this.fields['[stackable][dunnaging]']
  //         ) {
  //           this.fields['[stackable][dunnaging]'].setValue(!value);
  //           return;
  //         }
  //       }

  //       //setTimeout(()=>{
  //       Object.keys(this.fields).forEach(fieldName => {
  //         const input = this.fields[fieldName];
  //         if (
  //           fieldName === name ||
  //           !input ||
  //           !input.calculated ||
  //           input.touched
  //         ) {
  //           return;
  //         }
  //         let cv = calc.call(formState, input.calculated);
  //         if (
  //           cv !== void 0 &&
  //           cv !== null &&
  //           !isNaN(cv) &&
  //           isFinite(cv) &&
  //           cv !== input.getValue()
  //         ) {
  //           this.state[fieldName] = cv;
  //           input.setValue(cv);
  //           formState = formDataToObject.toObj(this.state);
  //         }
  //       });
  //       // });
  //     });

  //     if (nameFields.indexOf(name) !== -1) {
  //       this.changeTitle();
  //     }
  //   }, 0);
  // }

  fields = {};
  genericRef(name, element) {
    this.fields[name] = element;
  }

  renderFields = memoizeOne((accum = [], fields, name, shouldDisableAll) => {
    let { namePrefix, data = {} } = this.props;
    data = data || {};
    const fieldsList = fields;
    if (!fieldsList) {
      return accum;
    }
    const cargoName = this.props.cargoName || 'cargo';
    const packing = this.fields['[name]'] && this.fields['[name]'].getValue();
    const packingName = packing || 'packing';
    fieldsList.forEach((field) => {
      let disabled = shouldDisableAll;
      if (React.isValidElement(field)) {
        return accum.push(field);
      }
      const unit = (field.units && field.units[this.state.metricSystem]) || '';
      let validationErrors = { ...(field.validationErrors || {}) };
      let validations='';
      const requiredOnDependable = {};
      if (field.validationDepends) {
        field.$validationErrors = undefined;
        const dependableFieldValue = accessObjectValueWithName(this.props.data, field.validationDepends);
        const validationRules = Object.keys(validationErrors);

        validationErrors = validationRules.reduce((rules, rule) => {
          const validationRulePassedText = typeof validationErrors[rule] === 'function' ? validationErrors[rule](dependableFieldValue, data) : validationErrors[rule];
          if (validationRulePassedText) {
            rules[rule] = validationRulePassedText;
            if (rule === 'required') {
              requiredOnDependable.required = true;
              requiredOnDependable.requiredError = validationRulePassedText;
            }
          }
          return rules;
        }, {});
      }

      if (validationErrors && !field.$validationErrors) {
        const _validationErrors = {};
        const _validations = []
        Object.keys(validationErrors).forEach((key) => { _validationErrors[key.split(':')[0]] = validationErrors[key]; _validations.push(key); });
        field.$validationErrors = _validationErrors;
        validationErrors = _validationErrors;
        validations = _validations.join();
        field.$validations = validations;
      } else {
        validationErrors = field.$validationErrors;
        validations = field.$validations;
      }


      let label = "";

      if (field.label) {
        label = field.label
          .replace('{{unit}}', unit)
          .replace('{{cargoName}}', cargoName)
          .replace('{{packingName}}', packingName);
      }

      if ((field.required) && !String(label).includes('*')) {
        label += '*';
      }
      else if (validationErrors?.totalWeight || validationErrors?.totalVolume) {
        label += isWeightVolumeRequired(field.name, this.fields)
      }
      let fullName = namePrefix + field.name;

      let value = accessObjectValueWithName(this.props.data, field.name);

      if (
        field.name.indexOf('[dangerous]') === 0 ||
        field.name.indexOf('[military]') === 0 ||
        field.name.indexOf('[propelled]') === 0
      ) {
        fullName = fullName.replace('[packing][pack]', '');

        // const cargoData = this.context.selector?.('cargoList')?.[this.props.tabIndex];
        // if (cargoData && cargoData.cargo) {
        //   value = accessObjectValueWithName(cargoData.cargo, field.name);
        // }
      }
      if (
        field.if
        //((!this.fields[field.if] || !this.fields[field.if].getValue()) &&
      ) {
        const conditionShouldBeTrue = field.if[0] !== "!";

        disabled = conditionShouldBeTrue ? !accessObjectValueWithName(this.props.data, field.if) : !!accessObjectValueWithName(this.props.data, field.if);
      }
      let input;

      if (field.hidden) {
        accum.push(
          <NarrowFormsyText
            key={fullName}
            type="hidden"
            name={fullName}
            calculated={field.calculated}
            unit={unit}
            data-tabindex={this.props.tabIndex}
            innerRef={this.genericRef.bind(this, field.name)}
            floatingLabelText={label}
            style={displayNone}
            value={value || field.defaultValue || ""}
          />,
        );
        return accum;
      }
      switch (field.type) {
        case 'text':
          input = (
            <NarrowFormsyText
              floatingLabelText={label}
              validations={validations}
              validationErrors={validationErrors}
              requiredError="required"
              name={fullName}
              required={field.required}
              {...requiredOnDependable}
              calculated={field.calculated}
              //onBlur={this.handleGenericChange.bind(this, field.name)}
              defaultValue={this.state[field.name] || field.defaultValue}
              innerRef={this.genericRef.bind(this, field.name)}
              unit={unit}
              disabled={!!disabled}
              data-tabindex={this.props.tabIndex}
              fullWidth
              value={value || ""}
              onBlur={this.handleGenericChange(field.name)}
            />
          )

            if (field.minMaxType) {
              input = (
                <MinMaxInput
                  val={accessObjectValueWithName(this.props.data, field.name.includes('Min') ? field.name.replace('Min', 'Max') : field.name.replace('Max', 'Min')) || ""}
                  min={field.minVal || 0}
                  max={field.maxVal || 9999999}
                  extraDef={{
                    isNumeric: 'only numbers',
                    ...(field.extraDef || {}),
                  }}
                  type={field.minMaxType}
                  bothRequired={field.if === '[stowageSpread]'}
                >
                  {
                    input
                  }
                </MinMaxInput>
              )
            }
          break;
        case 'select':
          input = (
            <NarrowSelect
              hintText={label}
              floatingLabelText={label}
              style={{fontSize: '13px'}}
              requiredError="required"
              listStyle={{ maxHeight: '300px', overflowY: 'auto' }}
              menuStyle={{ maxHeight: '300px', overflowY: 'auto' }}
              maxHeight={300}
              name={fullName}
              fullWidth
              required={field.required}
              {...requiredOnDependable}
              innerRef={this.genericRef.bind(this, field.name)}
              disabled={!!disabled}
              autoWidth
              data-tabindex={this.props.tabIndex}
              value={value || ""}
              onChange={this.handleGenericChange(field.name)}
            >
              {field.values.map(v => {
                return (
                  <MenuItem
                    key={v.value}
                    value={v.value}
                    primaryText={v.label}
                    style={{fontSize: '13px'}}
                  />
                );
              })}
            </NarrowSelect>
          );
          break;
        case 'multiselect':
        { const _val = value || [];
          input = (
            <MultiCheckbox
              hintText={label}
              floatingLabelText={label}
              requiredError="required"
              name={fullName}
              fullWidth
              required={field.required}
              {...requiredOnDependable}
              innerRef={this.genericRef.bind(this, field.name)}
              disabled={!!disabled}
              data-tabindex={this.props.tabIndex}
              value={_val}
              list={field.values}
              popoverProps={{
                style: {
                  height: 300,
                  overflow: 'hidden',
                }
              }}
              onChange={(val) => {
                return this.handleGenericChange(field.name)(val);
              }}
            />
          ); }
          break;
        case 'checkbox':
          input = (
            <FormsyCheckbox
              className={s.checkbox}
              name={fullName}
              label={label}
              //onChange={this.handleGenericChange.bind(this, field.name)}
              ref={this.genericRef.bind(this, field.name)}
              defaultValue={this.state[field.name] || field.defaultValue}
              disabled={!!disabled}
              data-tabindex={this.props.tabIndex}
              labelStyle={{fontSize: '12px'}}
              value={!!value}
              onChange={(ev, val) => this.handleGenericChange(field.name)(null, !!val)}
            />
          );

          break;
        case 'PackingType':
          if (this.props.packingList && this.props.packingList.length == 1) {
            value = this.props.packingList[0].packingType;
          }
          let { packingList } = this.props;
          if (field.filter) {
            packingList = packingList.filter(field.filter);
          }
          input = (
            <NarrowSelect
              hintText={label}
              floatingLabelText={label}
              style={{fontSize: '13px'}}
              requiredError="required"
              listStyle={{ maxHeight: '300px', overflowY: 'auto' }}
              menuStyle={{ maxHeight: '300px', overflowY: 'auto' }}
              maxHeight={300}
              name={fullName}
              fullWidth
              required={field.required}
              {...requiredOnDependable}
              //onChange={this.handleGenericChange.bind(this, field.name)}
              innerRef={this.genericRef.bind(this, field.name)}
              disabled={!!disabled}
              data-tabindex={this.props.tabIndex}
              onChange={this.handleGenericChange(field.name)}
              value={value || ""}
            >
              {packingList.map(p => {
                return (
                  <MenuItem
                    className={p.recommended ? 'recommended_menu_item' : ''}
                    key={p.packingType}
                    value={p.packingType}
                    primaryText={p.packingType}
                    style={{fontSize: '13px'}}
                  />
                );
              })}
            </NarrowSelect>
          );
          break;
        case 'miniPackingList':
          input = (<MiniPackingList
            name={fullName}
            validations={'isExisty'}
            ref={this.genericRef.bind(this, field.name)}
            //onChange={this.handleGenericChange.bind(this, field.name)}
            onChange={this.handleGenericChange(field.name)}
            value={value}
          />);
          break;
        case 'switch':
          input = (
            <Switch
              name={fullName}
              value={value}
              leftLabel={field.leftLabel}
              rightLabel={field.rightLabel}
              onChange={this.handleGenericChange(field.name)}
            />
          );
          break;
        case 'h5':
          accum.push(<div className="col-12"><h5>{field.value}</h5></div>);
          return accum;
        case 'densities':
          input = <Densities onChange={this.handleGenericChange(field.name)} name={fullName} value={value}/>
          break;
        case 'containerTypeSelect':
          input = <ContainerTypeSelect onChange={this.handleGenericChange(field.name)} name={fullName} value={value}/>
          break;
        default:
          input = <div />;
      }
      if (disabled) {
        if (field.preserveColumn === false) {
          return accum;
        }
        input = <div className={field.col} />;
      }
      input = (
        <div key={fullName} className={field.col || 'col-12 col-md-6'}>
          {input}
        </div>
      );
      if (field.nextLine) {
        accum.push(<div style={{ width: "100%" }} />)
      }
      if (field.style) {
        input = React.cloneElement(input, { style: Object.assign(input.props.style || {}, field.style) });
      }
      if (field.spread && this.props.data.stowageSpread && !disabled) {
        input = this.renderFields(accum, field.spread, fullName);
      }
      else {
        accum.push(input);
      }
      if (
        (field.type == 'checkbox' &&
          //(!this.fields[field.name] || !this.fields[field.name].getValue()) &&
          !value)
      ) {
        this.renderFields(accum, field.fields, fullName, true);
      } else {
        this.renderFields(accum, field.fields, fullName);
      }
    });
    return accum;
  });

  mapCategory = category => {
    if (!category) return {};
    return typeof category === 'object' && category !== null ? category : ({
      label: category,
      value: category,
    });
  }

  handleStowageSpread = (val) => {
    this.handleGenericChange('[stowageSpread]')(val);
  }

  render() {
    const { namePrefix, categories = ['unpacked'], withoutSpread } = this.props;
    const packedField = this.fields['[category]'];
    const packed =
      this.props.data.category || (packedField && packedField.getValue());

    let stowageFields = this.renderFields([], this.props.stowageFields);

    if (stowageFields?.length && !withoutSpread) {
      const standardSpreadSwitch = (
        <div className="col-12">
          <Switch
            name={`${namePrefix}[stowageSpread]`}
            value={this.props.data.stowageSpread || ""}
            leftLabel="STANDARD"
            rightLabel="RANGE"
            onChange={this.handleStowageSpread}
          />
        </div>
      );
      if (this.props.stowageFields[0]?.type === 'miniPackingList') {
        stowageFields = [
          stowageFields[0],
          standardSpreadSwitch,
          stowageFields.slice(1),
        ];
      }
      else {
        stowageFields = [
          standardSpreadSwitch,
          <br />,
          ...stowageFields,
        ];
      }
    }
    return (
      <span>
        <div className={s.stowage_container}>
          <div className={s.stowage_container}>
            <div className="row">
              {
                stowageFields
              }
            </div>
          </div>

        </div>
        {this.props.otherFields.length
          ? <div className={s.packing_container}>
              <div className={s.stowage_title}>
                PACKING
              </div>
              <div className={s.packing_container}>
                <div className="row">
                  <div
                    className="col-12 col-md-8"
                    style={{ width: "min-content", flex: "unset", marginRight: "16px", display: categories[1] ? 'flex' : 'none', alignItems: 'center' }}
                  >
                    <FormsyRadioGroup
                      name={namePrefix.replace('[pack]', '') + '[category]'}
                      ref={this.genericRef.bind(this, '[category]')}
                      onChange={this.handleGenericChange('[category]')}
                      className={s.packing_radio_group}
                      required
                      value={packed}
                      requiredError="required"
                      dataTabindex={this.props.tabIndex}
                    >
                      {
                        categories.map(category => {
                          const { label, value } = this.mapCategory(category);
                          return (
                              <FormsyRadio
                                value={value}
                                label={label}
                                className={packed == value ? 'checked' : null}
                                labelStyle={{fontSize: '13px'}}
                                style={{ marginRight: "22px", whiteSpace: "nowrap" }}
                              />
                          )
                        })
                      }
                    </FormsyRadioGroup>
                  </div>

                  {packed
                    ? this.renderFields(
                        [],
                        this.props.otherFields
                      )
                    : null}
                </div>
              </div>
            </div>
          : null}
      </span>
    );
  }
}
