import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import _Dialog from 'material-ui/Dialog';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from 'classnames';
import s from './Dialog.scss';
import MenuContext from '../context/Menu';

const dialogStyles = {
  style: { top: '48px', height: 'calc(100vh - 48px)', zIndex: 1199 },
  bodyStyle: {
    padding: '0 0 32px',
    overflowY: 'hidden!important',
  },
  contentStyle: {},
  overlayStyle: { display: 'none' },
  titleStyle: {
    display: 'none',
  }
};

const DialogHalfScreen = ({
  open,
  handleClose,
  autoScrollBodyContent = true,
  anchor = '#app .page_content',
  position = 'right',
  menuOpen,
  noEddie,
  children,
  style = {},
  className,
  eddie,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [eddieCloser, setEddieCloser] = useState(null);
  const context = useContext(MenuContext);

  const getStyle = useCallback(() => {
    if (typeof document === 'undefined') {
      return dialogStyles.style;
    }
    const page = document.querySelector(anchor);
    if (!page) {
      return dialogStyles.style;
    }
    const offsetTop = page.getBoundingClientRect().top;
    return { ...dialogStyles.style, top: `${offsetTop - 1}px`, height: `calc(100vh - ${offsetTop}px)` };
  }, [anchor]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const isMenuOpen = useMemo(() => {
    if (menuOpen !== undefined) {
      return menuOpen;
    }
    return context?.menuOpen;
  })

  useEffect(() => {
    if (open && !noEddie && eddie?.open) {
      const openInEddie = async () => {
        const closer = await eddie.open(position, '', undefined, () => children);
        setEddieCloser(() => closer);
      };
      openInEddie();
    }
    return () => {
      if (eddieCloser) {
        eddieCloser();
      }
    };
  }, [open, noEddie, position, children, eddie, eddieCloser]);

  if (eddie && !noEddie) {
    return null;
  }


  console.log(className);
  return (
    <_Dialog
      modal={false}
      open={isOpen}
      onRequestClose={handleClose}
      autoScrollBodyContent={autoScrollBodyContent}
      bodyStyle={dialogStyles.bodyStyle}
      overlayStyle={dialogStyles.overlayStyle}
      repositionOnUpdate={false}
      autoDetectWindowHeight={false}
      {...dialogStyles}
      {...rest}
      style={{ ...getStyle(), ...style }}
      id='dialog-half-screen'
      className={cx(s.rightHalfDialog, className, isMenuOpen && s.rightHalfDialog_menu_open, s[position], 'unique-dialog-class')}
      contentClassName={cx()}
    >
      <MenuContext.Provider value={{ ...context}}>
        {children}
      </ MenuContext.Provider>
    </_Dialog>
  );
};

DialogHalfScreen.propTypes = {
  handleClose: PropTypes.func.isRequired,
  autoScrollBodyContent: PropTypes.bool,
  anchor: PropTypes.string,
  position: PropTypes.string,
  noEddie: PropTypes.bool,
  eddie: PropTypes.object, // Добавил eddie в PropTypes
};
DialogHalfScreen.defaultProps = {
  autoScrollBodyContent: true,
  position: 'right',
  anchor: '#app .page_content',
};

export default withStyles(s)(DialogHalfScreen);
