exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1RU1P{font-family:\"Roboto\", sans-serif;font-size:12px;line-height:28px}._1RU1P ._20ZK5{-ms-flex:0 1 161px;flex:0 1 161px;min-width:28px}._1RU1P ._20ZK5 ._3ff6t{background:transparent;padding:0}._1RU1P ._2aPDm{-ms-flex:1 1;flex:1 1;border-radius:8px;overflow:hidden}._1RU1P ._2aPDm>div{background:#F5F5F5;padding:0 6px}._1RU1P ._2aPDm ._3y0Zx{display:-ms-flexbox;display:flex;width:100%}._1RU1P ._2aPDm ._3y0Zx ._216xA{min-width:80px;overflow:hidden;text-overflow:ellipsis;padding-left:8px}._1RU1P ._2aPDm ._1i7rY{display:-ms-flexbox;display:flex}._1RU1P ._2aPDm ._1i7rY ._3sowS>span{white-space:normal}._1RU1P ._2aPDm ._1i7rY ._3c5bN{min-width:80px;text-align:left;padding-left:8px}._1RU1P ._2aPDm ._1MuTb{display:-ms-flexbox;display:flex;line-height:28px}._1RU1P ._2aPDm ._1MuTb>span{white-space:normal}._1RU1P ._3ynCn{height:4px;background:#fff}._1RU1P._2fVGy ._2aPDm>div{background:#DDECF6}._1RU1P._2fVGy ._3ynCn{background:transparent}._1RU1P a{color:#4380C7}._1RU1P ._3JZL6,._1RU1P a._3JZL6,._1RU1P ._25aoo{text-decoration:line-through !important;-webkit-text-decoration-color:#D0021B !important;text-decoration-color:#D0021B !important}._1RU1P ._3JZL6+.I6A-o,._1RU1P ._2uupi{color:#D0021B}._1RU1P ._3JZL6+.I6A-o a,._1RU1P ._3JZL6+.I6A-o ._216xA a,._1RU1P ._2uupi a,._1RU1P ._2uupi ._216xA a{color:#D0021B !important;-webkit-text-decoration-color:#D0021B !important;text-decoration-color:#D0021B !important}._1RU1P .POD._3sowS{display:none}._1RU1P ._3ff6t,._1RU1P ._2D9YM,._3c5bN{font-weight:700}._1RU1P>div>span{white-space:nowrap}._3JZL6+.I6A-o,.I6A-o+.I6A-o,.I6A-o+._3JZL6,._3JZL6+._3JZL6{margin-left:3px}br._3JZL6+.I6A-o{margin-left:0px}\n", ""]);

// Exports
exports.locals = {
	"wrapper": "_1RU1P",
	"port_label": "_20ZK5",
	"title": "_3ff6t",
	"port_details": "_2aPDm",
	"port_wrapper": "_3y0Zx",
	"port": "_216xA",
	"laycan_wrapper": "_1i7rY",
	"laycan_details": "_3sowS",
	"laycan_type": "_3c5bN",
	"details": "_1MuTb",
	"separator": "_3ynCn",
	"vessel_side": "_2fVGy",
	"old": "_3JZL6",
	"deleted": "_25aoo",
	"new": "I6A-o",
	"created": "_2uupi",
	"date_range": "_2D9YM"
};