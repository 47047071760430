exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._35FX7,._3ldAs{width:100%;height:100%}._35FX7{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;background-color:white}._3FvBm{margin-right:32px;color:#4D9E0E;font-size:12px;line-height:14px}._3FvBm>div:nth-child(1){background-color:#EDEDED;display:inline-block;border-radius:50%;margin-right:6px}._3FvBm svg{fill:#4D9E0E !important;width:20px !important;height:20px !important}._3K8KW{padding:0 16px 16px 16px}.dZ2ua{width:100%;min-width:320px;padding-top:25px}.dZ2ua a{font-size:12px}.dZ2ua ._2sjBq{height:70px;margin-top:-13px}.dZ2ua ._2sjBq ._1dLcr{font-family:Roboto;font-size:10px;font-style:normal;font-weight:500;line-height:20px;color:#4380C7;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;gap:4px;width:100%;padding:6px 12px}._3CfzY{font-weight:bold;line-height:23px;font-size:18px;padding-top:32px;color:#333333}.wR-Bv{margin-top:30px;display:-ms-flexbox;display:flex;-ms-flex-pack:end;justify-content:flex-end}.wR-Bv>div+div{margin-left:8px}._2EPRa{position:relative;z-index:8}.ZDNd_>div>div>div{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.Zjc7N{border-radius:50px !important;overflow:hidden}.Zjc7N button{border-radius:50px !important}\n", ""]);

// Exports
exports.locals = {
	"root": "_35FX7",
	"container": "_3ldAs",
	"add_position_button": "_3FvBm",
	"add_position": "_3K8KW",
	"add_position_container": "dZ2ua",
	"vessel_autocomplete_wrapper": "_2sjBq",
	"add_ship_link": "_1dLcr",
	"add_position_title": "_3CfzY",
	"add_position_buttons": "wR-Bv",
	"menuitem_name_ship": "_2EPRa",
	"vessel_name_long": "ZDNd_",
	"rounded_btn": "Zjc7N"
};