exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3hvCA{text-decoration:line-through;-webkit-text-decoration-color:#D0021B;text-decoration-color:#D0021B}._3hvCA a{text-decoration:line-through !important;-webkit-text-decoration-color:#D0021B !important;text-decoration-color:#D0021B !important}._3hvCA+._25ZMt,._1Fq9E{color:#D0021B;margin-left:3px}._3hvCA+._25ZMt a,._1Fq9E a{color:#D0021B !important;-webkit-text-decoration-color:#D0021B !important;text-decoration-color:#D0021B !important}._1_ie1 ._3hvCA{-webkit-text-decoration-color:inherit;text-decoration-color:inherit}._1_ie1 ._3hvCA+._25ZMt{color:inherit;background-color:rgba(127,201,49,0.2)}._1_ie1 ._25ZMt{margin-right:3px}._3e6y3 ._3hvCA{-webkit-text-decoration-color:inherit;text-decoration-color:inherit;white-space:break-spaces;text-align:justify}._3e6y3 ._3hvCA+._25ZMt{color:inherit;background-color:rgba(127,201,49,0.2)}._3e6y3 ._25ZMt{white-space:break-spaces;margin-right:3px;text-align:justify}._25ZMt._2Rnck{margin-left:0}._2VWj3{white-space:pre-line}\n", ""]);

// Exports
exports.locals = {
	"old": "_3hvCA",
	"new": "_25ZMt",
	"newforced": "_1Fq9E",
	"green": "_1_ie1",
	"green_preLine": "_3e6y3",
	"nomargin": "_2Rnck",
	"multiline": "_2VWj3"
};