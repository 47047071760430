import PropTypes from 'prop-types';
import React from 'react';
import keycode from 'keycode';
import Formsy from 'formsy-react';
import AutoComplete from 'material-ui/AutoComplete';
import { setMuiComponentAndMaybeFocus } from 'formsy-react/src/utils';
import cx from 'classnames';
import { debounce } from '../../core/utils';
import createReactClass from 'create-react-class';

const SimpleFormsyAutoComplete = createReactClass({

  propTypes: {
    defaultValue: PropTypes.any,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    value: PropTypes.any,
  },

  mixins: [Formsy.Mixin],

  getInitialState() {
    return {
      value: this.props.defaultValue || this.props.value || '',
    };
  },

  componentWillMount() {
    this.setValue(this.props.defaultValue || this.props.value || '');
    this.debouncedSetValue = debounce((value) => {
      this.setValue(value);
    }, 50);
  },

  handleChange: function handleChange(value) {
    this.debouncedSetValue(value);
    if (this.props.onChange) this.props.onChange(null, value);
  },

  handleUpdateInput: function handleUpdateInput(value) {
    this.debouncedSetValue(value);
    if (this.props.onChange) this.props.onChange(null, value);
  },

  setMuiComponentAndMaybeFocus: setMuiComponentAndMaybeFocus,

  render() {
    const {
      defaultValue, // eslint-disable-line no-unused-vars
      onFocus,
      previousValue,
      requiredError,
      ...rest
    } = this.props;
    const value = this.getValue();
    const { isRequired, isPristine, isValid, isFormSubmitted, customRequiredError } = this;
    const isRequiredError =
      !isPristine() &&
      !isValid() &&
      isFormSubmitted() &&
      ((isRequired() && requiredError) || customRequiredError);
    const errorText = this.getErrorMessage() || isRequiredError;
    return (
      <AutoComplete
        disabled={this.isFormDisabled()}
        menuProps={{ menuItemStyle: { style: { fontSize: '13px' } }, className: 'autocomplete_menu' }}
        className={cx(this.props.className, previousValue && previousValue !== this.state.value ? 'dirty' : null)}
        menuStyle={{ maxHeight: '200px', overflowY: 'auto' }}
        {...rest}
        errorText={errorText}
        onBlur={this.handleBlur}
        onNewRequest={this.handleChange}
        onUpdateInput={this.handleUpdateInput}
        onFocus={onFocus}
        onKeyDown={this.handleKeyDown}
        searchText={value}
        ref={this.setMuiComponentAndMaybeFocus}
        data-valid={this.isValid()}
      />
    );
  },
});

export default SimpleFormsyAutoComplete;
