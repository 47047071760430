import React, { Component } from 'react';
import styled from 'styled-components';
import DialogGreyTitle from '../Common/DialogGreyTitle';
import s from '../Settings/ProfileInfo.scss';
import cx from 'classnames';
import RaisedButton from '../Common/RaisedButton';
import ActionSearch from 'material-ui/svg-icons/action/search';
import TextField from 'material-ui/TextField';
import { escapeRegExp } from '../../core/utils';
import StyledToggle from '../Common/StyledToggle';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Highlight from '../Monitor/Highlight';

const Wrapper = styled.div`
  display: flex;
  .open_in_new_icon {
    font-size: 16px;
    cursor: pointer;
    margin-top: -2px;
    margin-right: 4px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  font-size: 12px;
  line-height: 24px;
  color: var(--text-dark333);
  display: flex;
  flex-direction: column;
  footer {
    height: 48px;
    display: flex;
    justify-content: flex-end;
  }
`;

class EmailSharedDialog extends Component {
  state = {
    searchMemberText: '',
    allMembers: [],
    filteredMembers: [],
  }
  constructor(props) {
    super(props);
    let members = props.user.company?.participants ?? [];
    members = members.filter(member => member.role !== 'pending' && (member.user && member.user._id !== props.user._id));
    members = members.map(m => {
      m = { ...m };
      m.toggled = this.props.visibleTo.some(m2 => m2._id === m?.user._id);
      m.locked = this.props.visibleTo.some(m2 => m2._id === m?.user._id && m2.locked);
      m.fullTextSearch = `${m.user.email} ${m.user.name || ''} ${m.user.surname || ''}`;
      return m;
    });
    this.state.allMembers = members;
    this.state.filteredMembers = members;
  }

  handleMemberSearch = (e, value) => {
    if (value) {
      const reg = escapeRegExp(value);
      this.setState({ searchMemberText: value, filteredMembers: this.state.allMembers.filter(m => reg.test(m.fullTextSearch)), searchMemberRegexp: reg });
    } else {
      this.setState({ searchMemberText: '', searchMemberRegexp: null, filteredMembers: this.state.allMembers });
    }
  };

  handleToggle = (member, e, toggled) => {
    const i = this.state.allMembers.findIndex(m => m.user._id === member.user._id);
    const all = [...this.state.allMembers];
    all[i] = { ...member, toggled: !member.locked ? toggled : member.toggled };
    let filtered = all;
    if (this.state.searchMemberText) {
      const reg = escapeRegExp(this.state.searchMemberText);
      filtered = all.filter(m => reg.test(m.fullTextSearch));
    }
    this.setState({ filteredMembers: filtered, allMembers: all });
  }
  handleToggleAll = (e, toggled) => {
    const all = [...this.state.allMembers].map(m => ({ ...m, toggled: m.locked !== true ? toggled : m.toggled }));
    let filtered = all;
    if (this.state.searchMemberText) {
      const reg = escapeRegExp(this.state.searchMemberText);
      filtered = all.filter(m => reg.test(m.fullTextSearch));
    }
    this.setState({ filteredMembers: filtered, allMembers: all });
  }

  handleSave = () => {
    const self = (this.props.user.company?.participants?.find(m => m.user._id === this.props.user._id)).user;
    this.props.handleSave(this.state.allMembers.filter(m => m.toggled).map(m => m.user).concat(self));
  }

  render() {
    const members = this.state.filteredMembers;
    const allMembers = this.state.allMembers;
    const allSelected = allMembers.every(member => member.toggled);
    const searchRegExp = this.state.searchMemberRegexp;

    return (
      <Wrapper>
        {this.props.opened ?
          <DialogGreyTitle title="SHARE TO" handleClose={this.props.handleClose} open autoScrollBodyContent contentStyle={{ width: '50%', maxWidth: '50%' }} bodyStyle={{ display: 'flex', height: '100%' }} repositionOnUpdate={false} >
            <ContentWrapper className={s.info_company} style={{ padding: "0" }}>
              <div className={s.members} style={{ padding: "20px 0" }}>
                <div className={s.whitelist_search} style={{ margin: "0", justifyContent: "space-between" }}>
                  <label
                    htmlFor="share_search"
                    className={s.whitelist_search_icon}
                  >
                    <ActionSearch />
                  </label>
                  <TextField
                    style={{ height: "30px", fontSize: "12px" }}
                    hintStyle={{ fontSize: "12px" }}
                    className={s.whitelist_search_input}
                    underlineStyle={{ display: "none" }}
                    hintText="Start typing to search..."
                    onChange={this.handleMemberSearch}
                    value={this.state.searchMemberText}
                    id="share_search"
                  />
                  <div>
                    <StyledToggle
                      trackSwitchedStyle={{ backgroundColor: "#4380C7", border: "1px solid #4380C7" }}
                      label={"Enabled all"}
                      labelPosition="left"
                      labelStyle={{
                      fontSize: '10px',
                      color: '#999999',
                      width: "auto",
                      left: "0",
                      }}
                      toggled={allSelected}
                      onToggle={this.handleToggleAll}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "500", fontSize: "10px" }}>
                  <span>
                    Name/Email
                  </span>
                  <span style={{ marginRight: "6px" }}>
                    Access
                  </span>
                </div>
                <div className={s.info}>
                  {members.map((member) => (
                    <div className={s.wrapper_list} style={{ minHeight: "46px" }}>
                    <div
                      className={cx(s.list)}
                      style={{ width: '100%', paddingBottom: "8px" }}
                    >
                      <div className={s.list_member_info}>
                        <p style={{ fontSize: "12px", marginBottom: "2px" }}><Highlight search={searchRegExp}>{member.user && ((member.user.name || '') + ' ' + (member.user.surname || ''))}</Highlight></p>
                        <span className={s.email} style={{ fontSize: "10px" }}><Highlight search={searchRegExp}>{member.user && member.user.email}</Highlight></span>
                      </div>
                      <span className={cx(s.delete_button, (member.locked ? s.disabled : null))}>
                        <StyledToggle
                          trackSwitchedStyle={{ backgroundColor: "#4380C7", border: "1px solid #4380C7" }}
                          toggled={member.toggled}
                          onToggle={this.handleToggle.bind(this, member)}
                        />
                      </span>
                    </div>
                  </div>))}
                </div>
              </div>
              <footer>
                <RaisedButton style={{ marginRight: "8px" }} secondary label={'Cancel'} onClick={this.props.handleClose}/>
                <RaisedButton primary label={'save'} onClick={this.handleSave} />
              </footer>
            </ContentWrapper>
          </DialogGreyTitle>
          : null}
      </Wrapper>
    );
  }
}

export default withStyles(s)(EmailSharedDialog);
