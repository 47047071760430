exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".XH7HR{padding-top:0 !important;overflow-y:scroll !important}.XH7HR>div{height:100%}.XH7HR ._2sjEL{width:100% !important;max-width:100% !important;-webkit-transform:none !important;transform:none !important;height:100%}.XH7HR ._2sjEL>div{height:100%}.XH7HR ._2WLfK{max-height:100% !important;padding:0 !important}.XH7HR ._32mkw{width:136px;margin-left:20px}.XH7HR h2{margin-bottom:10px;margin-left:20px;font-size:18px;font-weight:400;line-height:22px;letter-spacing:0.5px}.XH7HR ._18DZH{margin-bottom:20px;padding-bottom:72%;background-image:url(/images/about_mob.png);background-repeat:no-repeat;background-size:cover}.XH7HR .JA3dc{padding-left:20px;padding-right:20px}\n", ""]);

// Exports
exports.locals = {
	"modal": "XH7HR",
	"content_modal": "_2sjEL",
	"wrapper_modal": "_2WLfK",
	"logo": "_32mkw",
	"laptop": "_18DZH",
	"container_app": "JA3dc"
};