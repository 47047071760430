import Api from './api';
import {mergeCargoDetails} from "../../components/Monitor/Offer/utils";
import {toUTC} from "../utils";
/**
 * @class
 */
const contractTypeMap = {
  tc: 'time',
  voyage: 'voyage',
  bookingnote: 'voyage',
};
export default class Contract extends Api {

  static endpoint = '/contracts';

  static async createOfflineVoyageContract(request) {
    return Contract.fetch('/offline-contracts/voyage', {
      method: 'POST',
      body: JSON.stringify(request),
    }, '/api/v2');
  }

  static async createOfflineTimeCharterContract(request) {
    return Contract.fetch('/offline-contracts/tc', {
      method: 'POST',
      body: JSON.stringify(request),
    }, '/api/v2');
  }
  static async createOfflineTimeCharterContractPreview(request) {
    return Contract.fetch('/offline-contracts/tc/preview', {
      method: 'POST',
      body: JSON.stringify(request),
    }, '/api/v2');
  }

  static async deleteOfflineContract(contractId) {
    return Contract.fetch(`/offline-contracts/${contractId}`, {
      method: 'DELETE',
    }, '/api/v2');
  }
  static async deleteTcOfflineContract(contractId) {
    return Contract.fetch(`/offline-contracts/tc/${contractId}`, {
      method: 'DELETE',
    }, '/api/v2');
  }

  static async uploadUserFile(contractType, type, contractId, uploadedFileIds) {
    return Contract.fetch(`/offline-contracts/${contractType}/${contractId}/attachments/${type}`, {
      method: 'PUT',
      body: JSON.stringify(uploadedFileIds),
    }, '/api/v2');
  }

  static async editOfflineContract(id, request) {
    return Contract.fetch(`/offline-contracts/voyage/${id}`, {
      method: 'PUT',
      body: JSON.stringify(request),
    }, '/api/v2');
  }

  static async editTotalFreight(id, request) {
    return Contract.fetch(`/offline-contracts/voyage/${id}/rootFields`, {
      method: 'PATCH',
      body: JSON.stringify(request),
    }, '/api/v2');
  }

  static async getSurchargeTypes(token) {
    return Contract.fetch('/offline-contracts/chargeTypes/', {
      method: 'GET',
      token,
    }, '/api/v2');
  }

  static async getFixedContracts(token) {
    return Promise.all([
      Contract.getVoyageContracts(token),
      Contract.getTimecharterContracts(token),
    ]).then(res => ({
      data: [
        ...res[0].data.map((c) => {
          c.type = 'tc';
          return c;
        }),
        ...res[1].data.map((c) => {
          c.type = 'tc';
          return c;
        }),
      ],
    }));
  }

  static async getVoyageContracts(params = {}) {

    if (!params.filters) {
      params.filters = {};
    }

    // if (!params.page) {
    //   params.page = 1;
    // }

    return Contract.fetch('/contracts/cargo', {
      method: 'POST',
      body: JSON.stringify(params),
    }, '/api/v1/', undefined, true);
  }
  static async getVoyageContractsByVesselId(params = {}, vesselId) {

    if (!params.filters) {
      params.filters = {};
    }
    return Contract.fetch(`/contracts/cargo?vesselId=${vesselId}`, {
      method: 'POST',
      body: JSON.stringify(params),
    });
  }

  static async getBookingNoteContracts(params = {}) {

    if (!params.filters) {
      params.filters = {};
    }

    // if (!params.page) {
    //   params.page = 1;
    // }

    return Contract.fetch('/contracts/booking-note', {
      method: 'POST',
      body: JSON.stringify(params),
    });
  }

  static async getTimecharterContracts(params = {}) {
    if (!params.filters) {
      params.filters = {};
    }

    // if (!params.page) {
    //   params.page = 1;
    // }
    return Contract.fetch('/contracts/time', {
      method: 'POST',
      body: JSON.stringify(params),
    }, '/api/v1', undefined, true);
  }
  static async getTimecharterContractsByVesselId(params = {}, vesselId) {
    if (!params.filters) {
      params.filters = {};
    }
    return Contract.fetch(`/contracts/time?vesselId=${vesselId}`, {
      method: 'POST',
      body: JSON.stringify(params),
    });
  }

  static async getVoyageContractById(contractId, type = 'voyage', token) {
    const res = await Contract.fetch('/contracts/' + contractTypeMap[type] + '/' + contractId, {
      method: 'GET',
      token,
    });
    if (res.data?.cargoRequest) {
      mergeCargoDetails(res.data.cargoRequest);
    }
    return res;
  }

  static async getAll() {
    const voyage = await Contract.fetch('/contracts/voyage/names', { method: 'GET' });
    const time = await Contract.fetch('/contracts/time/names', { method: 'GET' });
    time.data.forEach(c => (c.type = 'timeCharter'));
    voyage.data.forEach(c => (c.type = 'voyage'));
    return { data: [...voyage.data, ...time.data] };
  }

  static async fix({ contractId, type = 'voyage' }, token) {
    return Contract.fetch('/contracts/' + contractTypeMap[type] + '/' + contractId + '/fix', {
      method: 'PUT',
      token,
    });
  }
  static async reject({ contractId, type = 'voyage' }, token) {
    return Contract.fetch('/contracts/' + contractTypeMap[type] + '/' + contractId + '/reject', {
      method: 'PUT',
      token,
    });
  }
  static async cancel({ contractId, type = 'voyage' }, token) {
    return Contract.fetch('/contracts/' + contractTypeMap[type] + '/' + contractId + '/cancel', {
      method: 'PUT',
      token,
    });
  }
  static async extendTime({ contractId, time, type = 'voyage' }, token) {
    return Contract.fetch('/contracts/' + contractTypeMap[type] + '/' + contractId + '/extend', {
      method: 'PUT',
      token,
      body: JSON.stringify({ time }),
    });
  }
  static async deleteCargo({ contractId, cargoRequestId }, token) {
    return { status: 200, data: {} }; //TODO mock;
  }
  static async reopenCargo({ contractId, cargoRequestId }, token) {
    return Contract.fetch('/contracts/voyage/' + contractId + '/reopen', {
      method: 'PUT',
      token,
    });
  }
  static async deletePos({ contractId, posId, type = 'voyage' }, token) {
    return { status: 200, data: {} }; //TODO mock;
  }
  static async reopenPos({ contractId, posId, type = 'voyage' }, token) {
    return Contract.fetch('/contracts/' + contractTypeMap[type] + '/' + contractId + '/reopen', {
      method: 'PUT',
      token,
    });
  }

  static async getDocumentsStatistic({ startDate, endDate, groupBy }) {
    switch (groupBy) {
      case 'day':
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59);
        break;
      case 'month':
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
        endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0, 23, 59, 59);
        break;
      case 'year':
        startDate = new Date(startDate.getFullYear(), 0, 1);
        endDate = new Date(endDate.getFullYear(), 11, 31, 23, 59, 59);
    }
    return Contract.fetch('/stats/documents', {
      method: 'POST',
      body: JSON.stringify({ from: toUTC(startDate).toISOString(), until: toUTC(endDate).toISOString(), period: groupBy + 's' }),
    }, '/api/v2');
  }

  static async getAllDocumentsByEntityId(entityType, entityId, filters = {}) {
    const types = {
      cargo: "cargoRequest",
      vessel: "vessel",
    };
    const res = await Contract.fetch(`/filingSystem/allDocuments/${types[entityType]}/${entityId}`, {
      method: 'POST',
      body: JSON.stringify({ filters }),
    }, '/api/v2');
    return res;
  }
}
