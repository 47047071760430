import React from 'react'
import { addToSelected } from './../../actions/vessel';
import { connect } from 'react-redux';
import SelectedAction from '../Common/SelectedAction';

function AddToSelected({ selectedVessels, handleSuccess = () => { }, addToSelected, dark, ...rest }) {

  const handleClick = async () => {
    if (!selectedVessels.length) return;

    const ISDELETE = false;

    try {
      await addToSelected({
        delete: ISDELETE,
        selectedVessels,
      });
      handleSuccess(selectedVessels, ISDELETE);
    } catch (error) {
      console.error(error);
    }
  }

  const isAllSelected = selectedVessels.every(v => !!v.inSelectedVessels);

  return (
    <SelectedAction
      isDark={!dark}
      tooltip={isAllSelected ? "Already in My Fleet" : "Add to My Fleet"}
      disabled={!selectedVessels.length || isAllSelected}
      onClick={handleClick}
      {...rest}
    />
  )
}

export default connect(null, { addToSelected })(AddToSelected);
