import React from "react";
import pure from "recompose/pure";
import SvgIcon from "material-ui/SvgIcon";

let Ship = ({ opacity, fill = "#fff", ...props }) => (
  <span {...props}>
    <span className="icon-wrapper">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_39822_56567)">
          <path
            d="M15.7576 16.3667C14.7655 16.3667 13.7543 16.0232 12.8766 15.4127C11.1213 16.6528 8.86995 16.6528 7.11466 15.4127C6.23701 16.0232 5.24489 16.3667 4.23368 16.3667H2.80273V17.7976H4.23368C5.22581 17.7976 6.19885 17.5496 7.11466 17.0917C8.92719 18.0266 11.0641 18.0266 12.8766 17.0917C13.7733 17.5687 14.7655 17.7976 15.7576 17.7976H17.1885V16.3667H15.7576ZM4.2146 14.9357H4.25276C5.39752 14.9357 6.4278 14.3061 7.13374 13.5048C7.83967 14.3061 8.85087 14.9357 10.0147 14.9357C11.1595 14.9357 12.1898 14.3061 12.8957 13.5048C13.6016 14.3061 14.6128 14.9357 15.7767 14.9357H15.8148L17.1695 10.1277C17.2267 9.93693 17.2076 9.74614 17.1313 9.57443C17.0359 9.40271 16.8833 9.26916 16.6925 9.21192L15.7767 8.90665V5.58685C15.7767 4.8046 15.128 4.1559 14.3457 4.1559H12.1707V1.99994H7.85875V4.1559H5.70279C4.92054 4.1559 4.27184 4.8046 4.27184 5.58685V8.90665L3.33696 9.21192C3.14616 9.26916 2.99353 9.40271 2.89813 9.57443C2.80273 9.74614 2.78366 9.95601 2.85997 10.1277L4.2146 14.9357ZM5.70279 5.58685H14.3266V8.44875L10.0147 7.03688L5.70279 8.44875V5.58685Z"
            fill={fill}
            opacity={opacity}
          />
        </g>
        <defs>
          <clipPath id="clip0_39822_56567">
            <rect
              width="14.4"
              height="16"
              fill={fill}
              transform="translate(2.7998 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </span>
  </span>
);

Ship = pure(Ship);
Ship.displayName = "Ship";
Ship.muiName = "SvgIcon";

export default Ship;
