/** @name NEWS_ERROR */
export default function NEWS_ERROR(state, action) {
  const newState = {};
  let errorMessage = action.payload;

  if (action.payload.errors[0].messages[0]) {
    errorMessage = action.payload.errors[0].messages[0];
  }

  newState.newsList = {
    ...state.newsList,
    loading: false,
    errorMessage: errorMessage,
  };

  return { ...state, ...newState };
}
