exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3X117{margin:0;font-size:12px;font-weight:400;white-space:pre-line;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;padding-bottom:14px}._1QXCC{width:100%;display:-ms-flexbox;display:flex;padding-bottom:8px;border-bottom:1px solid var(--stroke-light-gray2)}._1QXCC>div:first-child{-ms-flex:1 1;flex:1 1}._3iT24{line-height:19px;color:var(--text-dark);margin:0px;width:100%;padding-top:8px}.J5wgw{font-weight:700;line-height:18px;color:#333;margin:0px;font-size:12px;padding-bottom:4px}.J5wgw._1cqMT{width:100%;padding-bottom:6px;border-bottom:1px solid #E6E6E6;word-break:break-word}._3kotf{display:-ms-flexbox;display:flex;width:100%;-ms-flex-pack:justify;justify-content:space-between}._3IWxg{margin-left:4px;margin-top:-1px}._3IWxg a{line-height:20px;color:#4380C7;text-decoration:underline !important}._2z208{display:-ms-flexbox;display:flex;-ms-flex:1 1;flex:1 1}._2yceX{line-height:20px;color:var(--text-medium)}._17Pax{font-size:10px;text-align:right}\n", ""]);

// Exports
exports.locals = {
	"body_message": "_3X117",
	"body_message_header": "_1QXCC",
	"body_message_text": "_3iT24",
	"body_message_bold": "J5wgw",
	"subject": "_1cqMT",
	"body_message_from_date": "_3kotf",
	"body_message_mail": "_3IWxg",
	"body_message_mails": "_2z208",
	"body_message_light": "_2yceX",
	"ref": "_17Pax"
};