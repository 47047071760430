import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Header.scss';
import Link from '../Link';
import Navigation from '../Navigation';
import { connect } from 'react-redux';
import { logout, openLogin } from '../../actions/login';
import Logo_Color from '../Icons/Logo_Color';
import LeftBar from '../LeftBar/LeftBar';
import cx from 'classnames';

import TradingDesk from '../Icons/TradingDesk';
import PortsIcon from '../Icons/PortsIcon';
import Documents from '../Icons/Documents';
import Exchange from '../Icons/Exchange';
import FleetMonitor from '../Icons/FleetMonitor';
import CargoMonitor from '../Icons/CargoMonitor';

const dropDownStyles = {
  hiding: { display: 'block', opacity: 0 },
  hidden: { display: 'none', opacity: 0 },
  showing: { display: 'block', opacity: 0 },
  visible: { display: 'block', opacity: 1 },
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverSolutions: false,
      dropDownStyle: dropDownStyles.hidden,
    };
  }
  onMouseEnterHandlerSolutions = () => {
    this.showDropdown();
  };

  onMouseLeaveHandlerSolutions = () => {
    this.hideDropdownDelayed();
  };
  onMouseEnterHandlerDropdown = () => {
    this.clearHidingTimers();
  };
  onMouseLeaveHandlerDropdown = () => {
    this.hideDropdown();
  };

  showDropdown = () => {
    this.clearHidingTimers();
    this.setState({ dropDownStyle: dropDownStyles.showing });
    setTimeout(() => this.setState({ dropDownStyle: dropDownStyles.visible }));
  };

  hideDropdown = () => {
    this.setState({ dropDownStyle: dropDownStyles.hiding }, () => {
      this.hideDropdownTimer = setTimeout(() => this.setState({ dropDownStyle: dropDownStyles.hidden }), 350);
    });
  };

  hideDropdownDelayed = () => {
    this.hiddingDropdownTimer = setTimeout(this.hideDropdown, 350);
  };

  clearHidingTimers = () => {
    clearTimeout(this.hiddingDropdownTimer);
    clearTimeout(this.hideDropdownTimer);
  };

  render() {
    const { scroll, main, login, loginConnect, user, port } = this.props;
    return (
      <div
        className={cx(
          'main_page_header',
          s.root,
          s.main,
          scroll === 'top' ? s.scroll : '',
          scroll === 'bottom' ? s.scroll_down : '',
          main || login ? s.main_page : '',
          login ? s.login : '',
          port ? s.header_in_port : '',
          scroll === 'top' ? 'top' : '',
          scroll === 'bottom' ? 'bottom' : '',
        )}
      >
        <div className={s.container}>
          <div className={s.logo}>
            <span
              className={cx(
                s.sandwich,
                user ? s.sandwich_login : ''
              )}
            >
              {/* something */}
              {/* <LeftBar
                hideLogo
                scroll={scroll === 'top' ? scroll : null}
                loginPage={login}
                loginConnectPage={loginConnect}
              /> */}
            </span>
            <Link
              className={cx(s.logo_main_page, user ? s.logo_main_login : '')}
              to={user ? '/main-deck/general' : '/'}
            >
              <span
                className={cx(
                  s.logo_main,
                  loginConnect ? s.logo_login_connect : ''
                )}
              >
                <span className="logo"></span>
              </span>
            </Link>
          </div>

          <div className={s.wrapper_right}>
            {!login && !loginConnect ?
              <Navigation
                user={user}
                className={s.nav}
                login={login}
                loginConnect={loginConnect}
                onMouseEnterHandlerSolutions={this.onMouseEnterHandlerSolutions}
                onMouseLeaveHandlerSolutions={this.onMouseLeaveHandlerSolutions}
              />
              : null
            }
          </div>
        </div>
        <div
          style={this.state.dropDownStyle}
          id="wrapper_dropdown"
          className={s.wrapper_dropdown}
          onMouseEnter={this.onMouseEnterHandlerDropdown}
          onMouseLeave={this.onMouseLeaveHandlerDropdown}
        >
          <ul className={s.dropdown_menu_solutions}>
            <li>
              <Link to={'/solution-shipnext-marketplace'}>
                <TradingDesk />
                <span>SHIPNEXT<br />Trading Desk</span>
              </Link>
            </li>
            <li>
              <Link to={'/solution-shipnext-cargo-monitor'}>
                <CargoMonitor />
                <span>SHIPNEXT<br />Cargo Monitor</span>
              </Link>
            </li>
            <li>
              <Link to={'/solution-shipnext-fleet-monitor'}>
                <FleetMonitor />
                <span>SHIPNEXT<br />Fleet Monitor</span>
              </Link>
            </li>
            <li>
              <Link to={'/solution-shipnext-exchange'}>
                <Exchange />
                <span>SHIPNEXT<br />Exchange</span>
              </Link>
            </li>
            <li>
              <Link to={'/solution-shipnext-documents'}>
                <Documents />
                <span>SHIPNEXT<br />Documents</span>
              </Link>
            </li>
            <li>
              <Link to={'/solution-shipnext-ports'}>
                <PortsIcon />
                <span>SHIPNEXT<br />Ports</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default connect(state => state.login, { logout, openLogin })(
  withStyles(s)(Header),
);
