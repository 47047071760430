const css = require('./FormsyPhoneNumber._css');
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTelInput from 'react-telephone-input';
import cx from 'classnames';
import createReactClass from 'create-react-class';

Formsy.addValidationRule('isPhoneNumber', function(values, value) {
  return value && value.phone.length > 6 && value.phone.length < 16;
});
const styles = {
  focused: { color: 'rgb(127, 201, 49)' },
  invalid: { color: 'rgb(244, 67, 54)' },
};
const FormsyPhoneNumber = createReactClass({
  contextTypes: {
    muiTheme: PropTypes.object
  },
  mixins: [
    {
      ...Formsy.Mixin,
      ...{
        resetValue: function resetValue() {
          this.setState(
            {
              _value: this.state._pristineValue || { phone: '' },
              _isPristine: true,
            },
            function() {
              this.context.formsy.validate(this);
            },
          );
        },
        setValue: function setValue(value) {
          if (value && !value.formatted) {
            value.formatted = value.code + '' + value.phone;
          }

          this.setState(
            {
              _value: value || { phone: '' },
              _isPristine: false,
            },
            function() {
              this.context.formsy.validate(this);
            }.bind(this),
          );
        },
      },
    },
  ],
  getInitialState() {
    if (this.props.defaultCountry && this.props.defaultCountry.phoneCode && this.props.defaultCountry.phoneCode[0]) {
      const defaultPhoneCode = this.props.defaultCountry.phoneCode[0];
      return {
        defaultPhoneCode: defaultPhoneCode,
        _value: {
          code: defaultPhoneCode,
          phone: '',
          formatted: '+' + defaultPhoneCode,
        },
        _originValue: this.props.previousValue?.code + this.props.previousValue?.phone || '+' + defaultPhoneCode
      };
    }
  },
  componentDidMount() {
    if (this.props.defaultValue && this.props.defaultValue.code) {
      this.setValue(this.props.defaultValue);
    }
  },
  componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.defaultCountry &&
      nextProps.defaultCountry !== this.props.defaultCountry &&
      nextProps.defaultCountry.phoneCode &&
      nextProps.defaultCountry.phoneCode[0]
    ) {
      this.setState({
        defaultPhoneCode: nextProps.defaultCountry.phoneCode[0],
      });
      if (
        (this.state._value.formatted === '' &&
          this.state._value.formatted === '+') ||
        this.state._value.formatted ===
          '+' + ((this.props.defaultCountry && this.props.defaultCountry.phoneCode && this.props.defaultCountry.phoneCode[0]) || '')
      ) {
        const value = { ...this.state._value };
        value.formatted = '+' + nextProps.defaultCountry.phoneCode[0];
        value.code = nextProps.defaultCountry.phoneCode[0];
        this.setState({ _value: value });
      }
    }
  },
  handleFocus: function() {
    if (this.state._value && this.state._value.formatted === '+') {
      const value = { ...this.state._value };
      value.formatted = '+' + this.state._value.code;
      this.setState({ _value: value, focused: true });
    } else {
      this.setState({ focused: true });
    }
  },

  handleBlur: function(formatted, { dialCode }) {
    this.setState({ focused: false });
    const phone = formatted.replace('+' + dialCode, '').replace('+', '');
    const newValue = { phone: phone, code: '+' + dialCode, formatted: formatted };

    this.setValue(newValue);
  },
  handleChange: function(formatted, { dialCode }) {
    const phone = formatted.replace('+' + dialCode, '').replace('+', '');

    const newValue = { phone: phone, code: '+' + dialCode, formatted: formatted };
    if (this.isValidValue(newValue)) {
      this.setValue(newValue);
    } else {
      this.setState({ _value: newValue });
    }
  },
  render: function() {
    let value = this.getValue();
    const errorText = !this.isPristine() && this.getErrorMessage();
    const dirtyClass = this.props.spPhone && this.state._originValue !== undefined && this.state._originValue !== this.state._value.formatted;
    return (
      <div className={cx('formsy-tel-input', errorText ? 'invalid' : null, dirtyClass ? 'dirty' : null)}>
        <label
          style={
            this.state.focused
              ? errorText ? styles.invalid : {
                color: (this.context.muiTheme?.palette?.primary1Color || styles.focused.color)
              }
              : null
          }
        >
          {this.props.noAsterisk ? "Phone number:" : "Phone number *:"}
        </label>
        <ReactTelInput
          defaultCountry="us"
          flagsImagePath="/images/flags.png"
          autoFormat={false}
          value={value && value.formatted}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
        />
        <hr
          style={{ transform: this.state.focused ? 'scaleX(1)' : 'scaleX(0)' }}
          className="hr_focused"
        />
        <div className="error_text" style={this.props.spPhone ? { bottom: '-4px' } : {}}>{errorText}</div>
      </div>
    );
  },
});

FormsyPhoneNumber.propTypes = ReactTelInput.propTypes;
FormsyPhoneNumber.defaultProps = { defaultCountry: { phoneCode: ['+1'] } };

export default withStyles(css)(FormsyPhoneNumber);
