//common
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { round } from './../../../core/utils';

//actions
import {
  getSnpList,
  saveHireIdea,
  saveTCIndex,
  updateSaleOffer,
  updateSingleSalesOrder,
} from './../../../actions/vessel';

//components
import ExtendedForm from '../../Common/ExtendedForm';
import RaisedButton from '../../Common/RaisedButton';
import Info from '../../Icons/Info';
import WithTooltip from '../../Common/WithTooltip';
import OnlyNumbersInput from './OnlyNumbersInput';

//styles
import s from './styles.scss';
import cx from 'classnames';
import HireIdeaTooltip from './HireIdeaTooltip';
import HireIndices from '../HireIndices';
import DialogHalfScreen from '../../Common/DialogHalfScreen';

class VesselFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
      tcIndexKey: 0
    };
    this.prevState = {};
  }

  handleSave = () => {
    const {
      vesselId,
      tcIndex,
      hireIdea,
      isMine,
      isExist,
      salePrice,
      ideaPrice,
      offerId
    } = this.state;
    const { prevState } = this.constructor;

    const isTcIndexUpdated = prevState.tcIndex !== tcIndex;
    const isHireIdeaUpdated = prevState.hireIdea !== hireIdea;
    const isSaleOfferUpdated =
      prevState.salePrice !== salePrice || prevState.ideaPrice !== ideaPrice;

    const promiseTcIndex = isTcIndexUpdated
      ? this.props.saveTCIndex(vesselId, { tcIndex })
      : true;
    const promiseHireIdea = isHireIdeaUpdated
      ? this.props.saveHireIdea(vesselId, { hireIdea: hireIdea === undefined || hireIdea === "" ? null : hireIdea })
      : true;
    const promiseSaleOffer =
      isExist && isSaleOfferUpdated
        ? this.props.updateSaleOffer({
            ideaPrice: ideaPrice || undefined,
            salePrice: salePrice && salePrice > 0 ? salePrice : undefined,
            vesselId,
            offerId,
            isMine
          })
        : true;

    Promise.all([promiseTcIndex, promiseHireIdea, promiseSaleOffer])
      .then(res => this.props.updateVessel(tcIndex, hireIdea))
      .then(res => isMine
        ? this.props.updateSingleSalesOrder({_id: offerId, salePrice,ideaPrice})
        : true
      )
      .then(res => this.setState({ changed: false }))
      .catch(error => {
        console.log(error);
      });
  };

  updating = (props = this.props) => {
    let { tcIndex = "" } = props.vessel || {};
    const hireIdea = props.vessel?.hireIdea?.value || "";
    const fixedRate = props.vessel?.fixedRate?.value || "";
    const { _id: offerId, type } = props.salesOffers?.offerToFooter || {};
    const { salePrice, ideaPrice } = props.salesOffers?.offerToFooter || {};
    const vesselId = props.vessel._id;
    // const isMine = props.salesOffers?.MyOffer ? type === 1 : type === 0;
    const isMine = props.salesOffers?.myOffer ? true : false;
    const isExist = props.salesOffers?.isExist;

    if (tcIndex) {
      tcIndex = round(tcIndex, 2);
    }

    this.setState(
      {
        vesselId,
        tcIndex,
        hireIdea,
        isMine,
        isExist,
        salePrice,
        ideaPrice,
        offerId,
        fixedRate,
      },
      () => (this.constructor.prevState = this.state)
    );
  };

  componentDidMount() {
    this.updating();
  }

  componentWillReceiveProps(nextProps) {
    this.updating(nextProps);
  }

  onChange = (name, value) => {
    this.setState({ [name]: value, changed: true });
  };

  openHireIndices = () => {
   this.props.openHireIndices();
  };

  closeHireIndices = () => {
    this.props.closeHireIndices();
  };

  render() {
    return (
      <div className={s.tc}>
        <ExtendedForm
          ref="form"
          onValidSubmit={this.handleSave}
          onInvalidSubmit={this.invalidSubmit}
        >
          <div className={s.tc_wrapper}>
            <div className={cx(s.tc_input, s.index_input)}>
              <div className={s.tc_input__wrapper}>
                <WithTooltip
                  dark
                  tip={({ reposition }) => <HireIdeaTooltip vessel={this.props.vessel} reposition={reposition} type={"tcIndexes"} openHireIndices={this.openHireIndices} channels={this.props.channels} /> }
                  offset={{ x: 0, y: this.state.tcIndex ? -26 : -12 }}
                  whiteArrow
                >
                  <OnlyNumbersInput
                    name={"tcIndex"}
                    disabled
                    label={"T/C index ($)"}
                    value={this.state.tcIndex}
                    blur
                    key={this.state.tcIndexKey}
                  />
                </WithTooltip>
              </div>
            </div>
            <div className={cx(s.tc_input, s.hire_input)}>
              <div className={s.tc_input__tooltip}>
                <WithTooltip
                 dark
                  tip={
                    <div
                      style={{
                        lineHeight: "16px",
                        width: "220px",
                        borderRadius: "4px",
                        background: "#26374C",
                        color: "#fff",
                        fontSize: "12px",
                        padding: "12px",
                        boxShadow: `1px 1px 15px rgba(0, 0, 0, 0.3)`
                      }}
                      className={cx(s.alert, s.dark)}
                    >
                      This Time Charter idea is used and displayed for the
                      User’s individual use only, and will not be disclosed or
                      shared with other SHIPNEXT users.
                    </div>
                  }
                  horizontalPosition="right"
                >
                  <Info style={{ width: 18, height: 18 }}></Info>
                </WithTooltip>
              </div>
              <div className={s.tc_input__wrapper}>
                <WithTooltip
                  dark
                  className={s.hire_idea_tooltip}
                  tip={({ reposition }) => <HireIdeaTooltip vessel={this.props.vessel} reposition={reposition}
                                                            type={'hireIdeas'} openHireIndices={this.openHireIndices}
                  />}
                  offset={{ x: 0, y: this.state.tcIndex ? -26 : -12 }}
                  whiteArrow
                  bottomArrow
                  /*showOnEventName={'onClick'}*/
                >
                  <OnlyNumbersInput
                    name={"hireIdea"}
                    label={"Hire Idea"}
                    value={this.state.hireIdea}
                    disabled
                    blur={false}
                  />
                </WithTooltip>
              </div>
              <span className={cx("material-icons", s.edit_icon)} onClick={this.openHireIndices}>
                  edit
            </span>
            </div>

            <div className={cx(s.tc_input, s.rates_input)}>
              <div className={s.tc_input__wrapper}>
                <WithTooltip
                  className={s.hire_idea_tooltip}
                  dark
                  tip={({ reposition }) => <HireIdeaTooltip
                    vessel={this.props.vessel}
                    reposition={reposition}
                    type={'fixedRates'}
                    openHireIndices={this.openHireIndices}
                  />}
                  offset={{ x: 0, y: this.state.tcIndex ? -26 : -12 }}
                  whiteArrow
                  bottomArrow
                >
                  <OnlyNumbersInput
                    name={"fixedRate"}
                    label={"Fixed Rate"}
                    value={this.state.fixedRate}
                    disabled
                    blur={false}
                  />
                </WithTooltip>
              </div>
              <span className={cx("material-icons", s.edit_icon)} onClick={this.openHireIndices}>
                edit
              </span>
            </div>

            {this.state.isExist && (
              <div style={{ display: "flex" }}>
                <div className={s.tc_input}>
                  <div className={s.tc_input__wrapper}>
                    <OnlyNumbersInput
                      name={"ideaPrice"}
                      label={"Price Idea ($)"}
                      value={this.state.ideaPrice}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className={s.tc_input}>
                  <div className={s.tc_input__tooltip}>
                    <WithTooltip
                      dark
                      tip={
                        <div
                          style={{
                            lineHeight: "16px",
                            width: "220px",
                            borderRadius: "4px",
                            background: "#26374C",
                            color: "#fff",
                            fontSize: "12px",
                            padding: "12px",
                            boxShadow: `1px 1px 15px rgba(0, 0, 0, 0.3)`
                          }}
                          className={cx(s.alert, s.dark)}
                        >
                          Any amendments will remain personal or available to
                          your team, and will not be visible to other users of
                          SHIPNEXT.
                        </div>
                      }
                      horizontalPosition="right"
                    >
                      <Info style={{ width: 18, height: 18 }}></Info>
                    </WithTooltip>
                  </div>
                  <div className={s.tc_input__wrapper}>
                    <OnlyNumbersInput
                      name={"salePrice"}
                      label={"Sale Price ($)"}
                      value={this.state.salePrice}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {this.state.changed && (
            <RaisedButton
              label={"SAVE"}
              disableTouchRipple={true}
              disableFocusRipple={true}
              primary={true}
              type="submit"
              disabled={!this.state.changed}
              style={{
                borderRadius: "15px",
                minWidth: "72px"
              }}
              buttonStyle={{
                borderRadius: "15px",
                height: "28px",
                lineHeight: "28px",
                width: "72px"
              }}
            />
          )}
        </ExtendedForm>
      </div>
    );
  }
}
export default connect(null, {
  saveTCIndex,
  saveHireIdea,
  updateSaleOffer,
  getSnpList,
  updateSingleSalesOrder
})(withStyles(s)(VesselFooter));
