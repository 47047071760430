exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".cHu_g{padding:48px 48px 0px 48px;padding:3rem 3rem 0rem 3rem;width:100%;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;max-width:1200px;-ms-flex-direction:column;flex-direction:column;margin:0 auto}._2kx5d{width:100%;-ms-flex-pack:justify;justify-content:space-between;display:-ms-flexbox;display:flex;-ms-flex-align:end;align-items:flex-end}._1DLN9{width:auto;max-width:100%;margin:0 auto;border-collapse:collapse;border-spacing:0px;table-layout:fixed}._3y_O9{background-color:#F5F5F5 !important;color:#787878 !important;max-width:500px;width:30%;border:1px solid #E6E6E6;border-bottom:none;font-size:10px !important;white-space:normal !important;word-wrap:break-word;text-transform:uppercase !important}._3tkfX{background-color:#F5F5F5 !important;color:#787878 !important;width:80px;max-width:80px;border:1px solid #E6E6E6;white-space:normal !important;word-wrap:break-word;text-align:center !important;text-transform:uppercase !important;font-size:10px !important;border-bottom:none}._3tkfX.TQtWp{font-weight:bold !important}._2IJyG{max-width:500px;width:30%;border:1px solid #E6E6E6;white-space:normal !important;word-wrap:break-word;color:#4380C7;font-size:12px !important;padding-right:0px !important}._2tjsA{max-width:80px;border:1px solid #E6E6E6;white-space:normal !important;word-wrap:break-word;width:80px;color:#333333;font-size:12px !important;text-align:center !important}\n", ""]);

// Exports
exports.locals = {
	"TableWrapper": "cHu_g",
	"BetweenContainer": "_2kx5d",
	"BorderedTable": "_1DLN9",
	"BigHeader": "_3y_O9",
	"SmallHeader": "_3tkfX",
	"Highlighted": "TQtWp",
	"BigRow": "_2IJyG",
	"SmallRow": "_2tjsA"
};