import React, {useContext} from 'react';
import Page from '../Page/Page';
import SplitPane from '../Common/SplitPane';
import EmailsTable from './EmailsTable';
import cx from 'classnames';
import MenuContext from '../context/Menu';

const EmailsPage = (props) => {
  const context = useContext(MenuContext);
  return (
    <Page headerTitle="EXCHANGE">
      <div style={{ position: 'relative', overflow: 'hidden', width: '100%', height: '100%' }}>
        <SplitPane
          className={cx('h-100')}
          left={<EmailsTable menuOpen={context?.menuOpen} {...props} />}
          right={props.rightSection?.component}
        />
      </div>
    </Page>
  );
};

export default EmailsPage;