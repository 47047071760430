import React, { useContext, useEffect, useState } from "react";
import styles from "./LeftBar.scss";
import cx from "classnames";
import LogoIconMini from "../Icons/LogoIconMini";
import LogoutIcon from "../Icons/LogoutIcon";
import HelpIcon from "../Icons/HelpIcon";
import NavigationItem from "./components/NavigationItem";
import LogoNameIcon from "../Icons/LogoNameIcon";
import { naviagtionItems, settingsItem } from "./navigationItems";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import Link from "../Link/Link";
import PubSub from "pubsub-js";
import { debounce } from "../../core/utils";

const LeftBar = props => {
  const { user, menuOpen, barCountsObj } = props;
  const [path, setPath] = useState(null);

  useEffect(() => {
    if (window) {
      setPath(window.location.pathname);
    }
  }, []);

  const handleClose = () => {
    props.menuOpenToggle(false);
  };

  const updateMainDeck = debounce((ev, cb = handleClose) => {
    ev?.stopPropagation();
    PubSub?.publish("monitor::updateList");
    cb && cb();
  }, 2500);

  const logoutHandler = () => {
    props.logout();
  };

  return (
    <section
      className={cx(styles.container, menuOpen && styles.container_open)}
    >
      <div className={cx(styles.menu_header)}>
        <Link
          className={cx(styles.logo_wrapper)}
          handleClick={updateMainDeck}
          to="/main-deck/general"
        >
          <LogoIconMini />
          <div className={cx(styles.logo_text)}>
            <LogoNameIcon />
          </div>
        </Link>
        {user ? (
          <div className={styles.user_info_wrapper}>
            <div className={styles.user_name}>
              {(user.name || "") + " " + (user.surname || "")}
            </div>
            <div className={styles.user_email}>{user.email}</div>
          </div>
        ) : (
          <NavigationItem
            isOpen={menuOpen}
            route="/login"
            title="Log in"
            icon={<LogoutIcon />}
            user={user}
          />
        )}
      </div>
      <div className={cx(styles.menu_body)}>
        {naviagtionItems.map((item, index) => (
          <NavigationItem
            key={item.route}
            {...item}
            isOpen={menuOpen}
            isActive={path && path.indexOf(item.path) === 0}
            user={user}
            barCountsObj={barCountsObj}
          />
        ))}
        {user && (
          <NavigationItem
            key={settingsItem.route}
            isOpen={menuOpen}
            {...settingsItem}
            isActive={path && path.indexOf(settingsItem.path) === 0}
            user={user}
          />
        )}
      </div>
      <div className={cx(styles.menu_footer)}>
        <NavigationItem
          isOpen={menuOpen}
          isHelpItem
          clickHandler={() => {}}
          title="Help"
          icon={<HelpIcon />}
          user={user}
        />
        {user && (
          <NavigationItem
            isOpen={menuOpen}
            clickHandler={logoutHandler}
            title="Log out"
            icon={<LogoutIcon />}
            user={user}
          />
        )}
      </div>
    </section>
  );
};

export default React.memo(withStyles(styles)(LeftBar));
