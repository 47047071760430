import formDataToObject from 'form-data-to-object';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Stepper from 'material-ui/Stepper/Stepper';
import Step from 'material-ui/Stepper/Step';
import StepContent from 'material-ui/Stepper/StepContent';
import StepButton from 'material-ui/Stepper/StepButton';
import cx from 'classnames';
import Formsy from 'formsy-react';
import RaisedButton from './RaisedButton';
import ExtendedForm from './ExtendedForm';
import { sleep } from '../../core/utils';

const styles = {
  label: {
    // backgroundColor: 'rgba(0,0,0,0.07)',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    cursor: 'pointer',
    color: 'var(--text-dark)',
    height: '60px',
    fontSize: '15px',

    /*    paddingLeft:'13px',*/
  },
  content: { paddingTop: '28px', marginTop: '-9px' },
  stepper: { width: '100%' },
};

export class animatedStep extends Component {
  height = 'auto';
  constructor(props) {
    super(props);
    if (!props.open) {
      this.height = '0px';
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      this.element.style.height = this.refs.wrapper.clientHeight + 'px';
      let offset = this.element.offsetHeight;
      offset += 0;
      console.debug(offset);
      if (nextProps.open) {
        this.height = this.refs.wrapper.clientHeight + 'px';
        setTimeout(() => {
          this.element.style['transition-delay'] = '0s';
          this.element.style.height = 'auto';
          this.height = 'auto';
        }, 700);
      } else {
        this.height = '0px';
        setTimeout(() => {
          this.element.style['transition-delay'] = '0.35s';
        }, 500);
      }
    }
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-find-dom-node
    this.element = ReactDOM.findDOMNode(this);
    if (this.props.open) {
      this.element.style['transition-delay'] = '0s';
    } else {
      this.element.style['transition-delay'] = '0.35s';
    }
  }

  render() {
    return (
      <div
        className={'stepper_transition'}
        style={{ height: this.height, overflow: 'hidden' }}
      >
        <div ref="wrapper">{this.props.children}</div>
      </div>
    );
  }
}

// Doesn't render it's children when "open" prop is false, unlike animatedStep
// Because of that uncontrolled children will lose their state on unmount
// Use only if sure that children won't lose state and rely on props;
// if you need to keep children mounted and therefore preserve state, pass "justNoShow" prop to
// only hide children by using "display: none" style
export class OptimizedAnimatedStep extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isHidden: !props.open,
        height: props.open ? 'auto' : '0px',
      };
    }

    computeOpenedStyles = ev => {
      this._animatedStep.style['transition-delay'] = '0s';
      this.setState({
        height: 'auto',
      });
      this._animatedStep.classList.add("animated_step_opened_transition_complete");
      //this._animatedStep.removeEventListener('transitionend', this.computeOpenedStyles);
    }

    computeClosedStyles = ev => {
      this._animatedStep.style['transition-delay'] = '0.35s';
      this.setState({
        isHidden: true,
      });
      this._animatedStep.classList.add("animated_step_closed_transition_complete");
      //this._animatedStep.removeEventListener('transitionend', this.computeClosedStyles);
    }

    removeClasses = (node, classes = ["animated_step_opened_transition_complete", "animated_step_closed_transition_complete"]) => {
      classes.forEach(className => node.classList.remove(className));

      return node;
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.open !== this.props.open) {
        this.removeClasses(this._animatedStep);
        if (nextProps.open) {
          this.setState({
            isHidden: false,
          }, () => {
            const height = `${this.refs.wrapper.clientHeight}px`;
            this.setState({
              height,
            }, () => {
              // because in some cases, for unknown reason, transitionend event is dispatched even before the transition starts
              // i have to use setTimeout to make sure expand animation gets called AFTER collapse animation
              setTimeout(this.computeOpenedStyles, 700);
              //this._animatedStep.addEventListener('transitionend', this.computeOpenedStyles);
            });
          });
        } else {
          const height = `${this.refs.wrapper.clientHeight}px`;
          this.setState({
            height,
          }, () => {
            this.setState({
              height: '0px',
            });
            // because in some cases, for unknown reason, transitionend event is dispatched even before the transition starts
            // i have to use setTimeout to make sure expand animation gets called AFTER collapse animation
            setTimeout(this.computeClosedStyles, 500);
            //this._animatedStep.addEventListener('transitionend', this.computeClosedStyles);
          })
        }
      }
    }

    componentDidMount() {
      this.removeClasses(this._animatedStep);
      // eslint-disable-next-line react/no-find-dom-node
      //this.element = ReactDOM.findDOMNode(this);
      if (this.props.open) {
        this._animatedStep.style['transition-delay'] = '0s';
      } else {
        this._animatedStep.style['transition-delay'] = '0.35s';
      }
    }

    render() {
      const { isHidden, height } = this.state;

      const { justNoShow } = this.props;

      const wrapperProps = {
        ref: 'wrapper',
        children: this.props.children,
      };

      if (justNoShow && isHidden) {
        wrapperProps.style = {
          display: 'none',
        }
      }
      else if (isHidden) {
        Reflect.deleteProperty(wrapperProps, 'children');
      }
      return (
        <div
          className={'stepper_transition'}
          ref={el => this._animatedStep = el}
          style={{ height: height, overflow: 'hidden' }}
        >
          <div ref="wrapper" {...wrapperProps} />
        </div>
      );
    }
}

export const SlightlyOptimizedAnimatedStep = (props) => <OptimizedAnimatedStep {...props} justNoShow />;

class _Stepper extends Component {
  state = {
    finished: false,
    stepIndex: 0,
    validForms: [],
    values: [],
  };
  constructor(props) {
    super(props);
    if (props.defaultActive) {
      this.state.stepIndex = props.defaultActive;
    }
    this.state.children = props.children.filter(c => !!c);
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      const formData = formDataToObject.fromObj(this.props.defaultValue);
      setTimeout(() => {
        this.forms.forEach((form) => {
          form?.reset?.(formData);
        });
      }, 0);
    }
    if (this.props.getAllFormsRefs) {
      this.props.getAllFormsRefs(this.forms);
    }
  }

  componentWillUnmount() {
    this.persistForm();
  }

  persistForm() {
    let res = {};
    if (typeof window !== 'undefined' && this.props.lskey) {
      this.forms.forEach((form) => {
        if (!form) return;
        res = { ...res, ...form.getModel() };
      });

      if (window.localStorage && window.localStorage.setItem) {
        window.localStorage.setItem(this.props.lskey, JSON.stringify(res));
      }
    }
  }

  forceNextSubmit = () => {
    const { stepIndex } = this.state;
    if (!this.state.validForms[stepIndex]) {
      this.forms[stepIndex].submit();
      this.handleInvalidSubmit(stepIndex, this.props.children[stepIndex].props.onInvalidSubmit);
      return;
    };
    this.handleNext();
  }

  handleNext = async () => {
    await sleep(this.props.formValidateDelay);
    await sleep(1);
    this.persistForm();
    const { stepIndex } = this.state;
    if (!this.state.validForms[stepIndex]) return;
    if(this.props.handlePartialSubmit) {
      this.props.handlePartialSubmit(stepIndex, this.getModel());
    }
    if (stepIndex >= this.state.children.length - 1) {
      this.handleSubmit();
      return;
    }
    this.setState(
      {
        stepIndex: stepIndex + 1,
        finished: stepIndex >= this.state.children.length,
      }
    );
  };

  handleSubmit = () => {
    let res = {};
    this.forms.forEach((form) => {
      if (!form) return;
      res = { ...res, ...formDataToObject.toObj(form.getModel()) };
    });
    this.props.handleSubmit && this.props.handleSubmit(res);
  };

  handlePrev = () => {
    const { stepIndex } = this.state;
    if (stepIndex > 0) {
      this.setState({ stepIndex: stepIndex - 1 });
    }
  };

  handleOpenStep(index, cb) {
    if (index === this.state.stepIndex) {
      cb && cb();
      return;
    }
    let previousValid = true;
    for (let i = 0; i < index; i++) {
      previousValid = previousValid && this.state.validForms[i];
    }
    if (index < this.state.stepIndex || this.isPreviousValid(index)) {
      this.setState({ stepIndex: index }, cb);
    } else {
      this.forms[this.state.stepIndex] &&
        this.forms[this.state.stepIndex].submit();
    }
  }

  isPreviousValid(index) {
    if (index === 0) {
      return true;
    }
    let previousValid = true;
    for (let i = 0; i < index; i++) {
      previousValid = previousValid && this.state.validForms[i];
    }
    return previousValid;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ children: nextProps.children.filter(c => !!c) });
    if (nextProps.errors && this.props.errors !== nextProps.errors) {
      const formi = this.findFormIndexByInputName(nextProps.errors[0].field);
      if (formi !== undefined) {
        this.handleOpenStep(formi, () => {
          setTimeout(() => {
            this.forms[formi].updateInputsWithError({
              [nextProps.errors[0].field]: nextProps.errors[0].messages[0],
            });
          }, 500);
        });
      }
    }
  }

  findFormIndexByInputName(name) {
    for (let i = 0; i < this.state.values.length; i++) {
      const form = this.state.values[i];
      if (form && Object.keys(form).indexOf(name) !== -1) {
        return i;
      }
    }
    return undefined;
  }
  handleValid(i) {
    if (this.state.validForms[i]) return;
    this.state.validForms[i] = true;
    this.setState({ validForms: this.state.validForms });
  }
  handleInvalid(i) {
    if (!this.state.validForms[i]) return;
    this.state.validForms[i] = false;
    this.setState({ validForms: this.state.validForms });
  }
  handleChange(i, cb, values, isChanged) {
    if (!isChanged) return;
    this.state.values[i] = values;
    if (cb && this.forms[i]) {
      const model = this.forms[i].getModel();
      cb(model);
    }
  }
  forms = [];
  refForm(i, element) {
    this.forms[i] = element;
  }

  handleInvalidSubmit(index, handler) {
    if (handler && typeof handler === 'function') {
      let res = {};
      this.forms.forEach((form) => {
        if (!form) return;
        res = { ...res, ...formDataToObject.toObj(form.getModel()) };
      });
      return handler(res);
    }
// eslint-disable-next-line react/no-find-dom-node
    const domForm = ReactDOM.findDOMNode(this.forms[index]);
    if (domForm) {
      const invalidNode = domForm.querySelector('[data-valid=false]');
      if (invalidNode) {
        invalidNode.scrollIntoView();
      }
    }
  }
  renderStepActions(step) {
    const isPrevDisabled = this.props.disabledSteps && this.props.disabledSteps[step - 1];
    return (
      <div style={{ margin: '32px 0 26px 0' }}>
        {step !== 0 && !isPrevDisabled
          ? <RaisedButton
            label="Back"
            disableTouchRipple
            disableFocusRipple
            secondary
            onClick={this.handlePrev}
            style={{ marginRight: 12 }}
          />
          : null}

        <RaisedButton
          label={
            step < this.state.children.length - 1
              ? 'NEXT'
              : this.props.submitLabel
          }
          disableTouchRipple
          disableFocusRipple
          primary
          type="submit"
          style={{ marginRight: 12 }}
        />
      </div>
    );
  }


  getModel = () => {
    let res = {};
    this.forms.forEach((form) => {
      if (!form) return;
      res = { ...res, ...formDataToObject.toObj(form.getModel()) };
    });
    return res;
  };

  resetSteps = (stepIndexes, data) => {
    for (let i = 0; i < stepIndexes.length; i++) {
      const stepIndex = stepIndexes[i];
      this.forms[stepIndex].reset(data);
    }
  };



  render() {
    let { disabledSteps = [] , className = "", transitionEl, ...props } = this.props;
    const { stepIndex } = this.state;
    let children = this.state.children;

    return (
      <Stepper
        style={styles.stepper}
        {...props}
        activeStep={stepIndex}
        orientation="vertical"
        linear={false}
        className={`stepper ${className}`}
        ref={el => this._stepper = el}
      >
        {children.map((child, i) => (
          <Step completed={this.state.validForms[i]} key={i}>
            <StepButton
              className={cx(
                  'stepper_label',
                  stepIndex === i ? 'active' : '',
                  this.state.validForms[i] ? 'valid' : '',
                  this.isPreviousValid(i) ? '' : 'disabled',
                  disabledSteps[i] ? 'disabled' : '',
                )}
              onClick={() => {
                this.handleOpenStep(i);
              }}
              style={styles.label}
              iconContainerStyle={styles.iconContainerStyle}
            >
              {child.props.label}
            </StepButton>
            <StepContent transition={transitionEl} style={styles.content}>
              <ExtendedForm
                delay={this.props.formValidateDelay}
                noValidate
                onValid={this.handleValid.bind(this, i)}
                onInvalid={this.handleInvalid.bind(this, i)}
                onChange={this.handleChange.bind(this, i, child.props.onChange)}
                onSubmit={child.props.disabledDefaultSubmit ? undefined : async () => {
                  if (child.props.unblockStep) {
                    await child.props.unblockStep();
                  }
                  this.handleNext();
                }}
                onInvalidSubmit={
                  this.handleInvalidSubmit.bind(this, i, child.props.onInvalidSubmit)
                }
                ref={this.refForm.bind(this, i)}
              >
                {child}
                {this.props.noButtons || child.props.noButtons ? null : this.renderStepActions(i)}
              </ExtendedForm>
            </StepContent>
          </Step>
          ))}
      </Stepper>
    );
  }
}

_Stepper.propTypes = {};
_Stepper.defaultProps = {
  transitionEl: animatedStep,
  formValidateDelay: 1,
};

export default _Stepper;
