import history from '../../core/history';

export default function (state) {
  if (state.invite) {
    const invite = state.invite;
    state.invite = undefined;
    switch (invite.type) {
      case 'BillOfLading':
        return setTimeout(() => history.push('/contracts/bill-of-lading/' + invite.data.billOfLadingId), 0);
        break;
      case 'Invoice':
        return setTimeout(() => history.push(`/contracts/invoice/${invite?.data?.invoiceId}`), 0);
        break;
    }
  }
}
