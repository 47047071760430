import React from "react";
import pure from "recompose/pure";
import SvgIcon from "material-ui/SvgIcon";

let ChartIcon = (opacity = 0.4, ...props) => (
  <span {...props}>
    <span className="icon-wrapper">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.2278 16.5398H4.05029V3.3623C4.05029 2.93506 3.70239 2.58716 3.27515 2.58716C2.8479 2.58716 2.5 2.93506 2.5 3.3623V17.3149C2.5 17.7422 2.8479 18.0901 3.27515 18.0901H17.2278C17.655 18.0901 18.0029 17.7422 18.0029 17.3149C18.0029 16.8877 17.655 16.5398 17.2278 16.5398Z"
          fill="white"
        />
        <path
          d="M6.98058 12.3742L9.6112 9.08438L12.1106 10.9582C12.4188 11.1901 12.8522 11.1596 13.1238 10.8849L16.9995 7.00919C17.3016 6.70706 17.3016 6.21573 16.9995 5.9136C16.6974 5.61148 16.206 5.61148 15.9039 5.9136L12.5043 9.31631L9.94079 7.39371C9.60509 7.14346 9.13207 7.20145 8.86962 7.52798L5.76903 11.4037C5.50048 11.7394 5.55541 12.2246 5.89111 12.4932C6.16576 12.7129 6.72118 12.7007 6.98058 12.3742Z"
          fill="white"
        />
      </svg>
    </span>
  </span>
);

ChartIcon = pure(ChartIcon);
ChartIcon.displayName = "ChartIcon";
ChartIcon.muiName = "SvgIcon";

export default ChartIcon;
