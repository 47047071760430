exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2y2vZ{width:208px;height:24px;background-color:#ffffff;border:1px solid #D7D7D7;border-radius:100px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;padding:0 10px 0 5px;margin-left:10px;position:relative}._2y2vZ input{outline:none;border:none !important;background-color:transparent;padding-right:14px;width:calc(100% - 24px);font-size:10px;line-height:16px;font-family:Roboto, sans-serif}._2y2vZ input::-webkit-input-placeholder{color:#999999}._2y2vZ input::-moz-placeholder{color:#999999}._2y2vZ input:-ms-input-placeholder{color:#999999}._2y2vZ input::-ms-input-placeholder{color:#999999}._2y2vZ input::placeholder{color:#999999}._2y2vZ .f90ki{cursor:pointer;position:absolute;right:5px;top:0;bottom:0;margin:auto}._1z-Q0{padding-top:8px;background:#FFFFFF;-webkit-box-shadow:1px 1px 7px rgba(0,0,0,0.25);box-shadow:1px 1px 7px rgba(0,0,0,0.25);border-radius:4px;z-index:999;position:absolute;top:30px;left:10px;width:198px;max-height:208px;overflow:scroll}._1z-Q0>div>span{min-height:28px !important;line-height:28px !important;display:-ms-flexbox !important;display:flex !important;-ms-flex-align:center;align-items:center}._1z-Q0>div>span>div{width:100%}._1z-Q0>div>span>div>div{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}.xNCZy{text-overflow:ellipsis;overflow:hidden;max-width:120px;font-family:'Roboto';font-style:normal;font-weight:500;font-size:12px;line-height:140%;color:#333333}._3uahj{font-family:'Roboto';font-style:normal;font-weight:normal;font-size:12px;line-height:140%;color:#999999}\n", ""]);

// Exports
exports.locals = {
	"search": "_2y2vZ",
	"close": "f90ki",
	"search_results": "_1z-Q0",
	"results_port_name": "xNCZy",
	"results_port_unlocode": "_3uahj"
};