import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import s from "./Page.css";
import styles from "./Page.scss";
import Header2 from "../Header/Header2";
import TopNotifications from "../Common/TopNotifications";
import Footer from "../Footer/Footer";
import LeftBar from "../LeftBarNew/LeftBar";
import { connect } from "react-redux";
import { logout, openLogin } from "../../actions/login";
import MenuContext from "../context/Menu";

const Page = ({
  headerTitle,
  headerLeft,
  headerRight,
  children,
  className,
  noHeader = false,
  withFooter = false,
  user,
  counters,
  id,
  logout
}) => {
  const { menuOpen, menuOpenToggle } = useContext(MenuContext);

  useEffect(() => {
    document.querySelector(".page_content")?.scrollTo?.(0, 0);
  }, [headerTitle]);

  const cargoBidsCount = counters?.cargoBids;
  const contractBidsCount = counters?.contractBids;
  const barCountsObj = {
    "/my/cargo": cargoBidsCount,
    "/contracts": contractBidsCount
  };

  return (
    <div className={cx(styles.page_root, menuOpen && "page_root_open")}>
      {!noHeader && (
        <LeftBar
          menuOpen={menuOpen}
          menuOpenToggle={menuOpenToggle}
          logout={logout}
          user={user}
          barCountsObj={barCountsObj}
        />
      )}
      <div id={id} className={cx(s.root, styles.root, className)}>
        {!noHeader && (
          <Header2
            title={headerTitle}
            left={headerLeft}
            right={headerRight}
            menuOpenToggle={menuOpenToggle}
          />
        )}
        {!noHeader && <TopNotifications />}
        <div
          className={cx(
            "page_content",
            s.content,
            withFooter && s.with_footer,
            noHeader && s.no_header
          )}
        >
          {React.Children.only(children)}
          {withFooter && (
            <div style={{ alignSelf: "normal" }}>
              <Footer />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Page.propTypes = {
  headerTitle: PropTypes.node,
  headerLeft: PropTypes.node,
  headerRight: PropTypes.node
};

export default connect(
  state => ({
    user: state.login.user,
    counters: state.login.counters,
    cargoRequestList: state.cargo.requestList,
    cargoTcOfferList: state.cargo.tcOfferList,
    vesselRequestList: state.vessel.requestList,
    vesselTcOfferList: state.vessel.tcOfferList,
    contractList: state.contracts.contractList
  }),
  { logout, openLogin }
)(withStyles(s, styles)(Page));