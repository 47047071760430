import React from "react";
import pure from "recompose/pure";
import SvgIcon from "material-ui/SvgIcon";

let Calculator = (props) => {
  return (
    <span {...props}>
      <span className="icon-wrapper">
        <svg width="20" height="20" viewBox="0 0 20 20" fill={props.fill || "#fff"}>
          <path
            fill={props.fill || "#fff"}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.1406 17.337C16.1406 17.7032 15.8307 18 15.4483 18H4.83293C4.45059 18 4.14062 17.7032 4.14062 17.337V2.66298C4.14062 2.29684 4.45059 2 4.83293 2H15.4483C15.8307 2 16.1406 2.29684 16.1406 2.66298V17.337ZM13.3714 16.0111C13.8812 16.0111 14.2945 15.6153 14.2945 15.1271C14.2945 14.6389 13.8812 14.2431 13.3714 14.2431C12.8616 14.2431 12.4483 14.6389 12.4483 15.1271C12.4483 15.6153 12.8616 16.0111 13.3714 16.0111ZM6.90986 12.9171C7.41966 12.9171 7.83293 12.5214 7.83293 12.0331C7.83293 11.5449 7.41966 11.1492 6.90986 11.1492C6.40005 11.1492 5.98678 11.5449 5.98678 12.0331C5.98678 12.5214 6.40005 12.9171 6.90986 12.9171ZM10.1406 12.9171C10.6504 12.9171 11.0637 12.5214 11.0637 12.0331C11.0637 11.5449 10.6504 11.1492 10.1406 11.1492C9.63082 11.1492 9.21755 11.5449 9.21755 12.0331C9.21755 12.5214 9.63082 12.9171 10.1406 12.9171ZM13.3714 12.9171C13.8812 12.9171 14.2945 12.5214 14.2945 12.0331C14.2945 11.5449 13.8812 11.1492 13.3714 11.1492C12.8616 11.1492 12.4483 11.5449 12.4483 12.0331C12.4483 12.5214 12.8616 12.9171 13.3714 12.9171ZM6.90986 9.8232C7.41966 9.8232 7.83293 9.42743 7.83293 8.93923C7.83293 8.45102 7.41966 8.05525 6.90986 8.05525C6.40005 8.05525 5.98678 8.45102 5.98678 8.93923C5.98678 9.42743 6.40005 9.8232 6.90986 9.8232ZM10.1406 9.8232C10.6504 9.8232 11.0637 9.42743 11.0637 8.93923C11.0637 8.45102 10.6504 8.05525 10.1406 8.05525C9.63082 8.05525 9.21755 8.45102 9.21755 8.93923C9.21755 9.42743 9.63082 9.8232 10.1406 9.8232ZM13.3714 9.8232C13.8812 9.8232 14.2945 9.42743 14.2945 8.93923C14.2945 8.45102 13.8812 8.05525 13.3714 8.05525C12.8616 8.05525 12.4483 8.45102 12.4483 8.93923C12.4483 9.42743 12.8616 9.8232 13.3714 9.8232ZM10.1406 14.2431H6.90986C6.40004 14.2431 5.98678 14.6389 5.98678 15.1271C5.98678 15.6153 6.40004 16.0111 6.90986 16.0111H10.1406C10.6504 16.0111 11.0637 15.6153 11.0637 15.1271C11.0637 14.6389 10.6504 14.2431 10.1406 14.2431ZM6.07909 3.98895V6.64088H14.2945V3.98895H6.07909Z"
          />
        </svg>
      </span>
    </span>
  );
};

Calculator = pure(Calculator);
Calculator.displayName = "Cargo";
Calculator.muiName = "SvgIcon";

export default Calculator;
